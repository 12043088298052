import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {Link, useLocation, useParams} from "react-router-dom";
import {MaterialReactTable} from "material-react-table";
import {Box} from "@mui/material";
import Select from "react-select";
import ReactElementCommons from "../../commons/ReactElementCommons";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import AuthorizationsTable from "../authorizations/AuthorizationsTable";
import {Alert} from "react-bootstrap";
import ViewEntryActionButton from "../shared/tables/ViewEntryActionButton";
import Loading from "../Loading";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";
import DoesNotExist from "../shared/DoesNotExist";

export default function UpdateRole(props: any) {
    const [alert, setAlert] = useState({
        class: "d-none",
        message: ""
    });
    const [roleExists, setRoleExists] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [role, setRole]: any = useState({});
    const [departments, setDepartments]: any = useState([]);
    const [organizationOptions, setOrganizationOptions]: any = useState([]);
    const [organizationSelect, setOrganizationSelect]: any = useState(null);
    const [departmentOptions, setDepartmentOptions]: any = useState([]);
    const [departmentSelect, setDepartmentSelect]: any = useState(null);
    const [roleUsersTableData, setRoleUsersTableData]: any = useState([]);
    const [loadingRoleUsers, setLoadingRoleUsers]: any = useState(false);
    const [roleManagersTableData, setRoleManagersTableData]: any = useState([]);
    const [roleManagersRowSelection, setRoleManagersRowSelection]: any = useState({});
    const [loadingRoleManagers, setLoadingRoleManagers]: any = useState(false);
    const [authorizations, setAuthorizations]: any = useState([]);

    const params = useParams();
    const location = useLocation();

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setIsLoading(true);
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [userResponse]: any = await Promise.all([
            RequestCommons.request(process.env.REACT_APP_AUTH_API + "/roles/" + params.id, null, null, 'get', 'application/x-www-form-urlencoded', false)
        ]);
        if (userResponse.status === 204) {
            // display not exists warning
            setRoleExists(false);
        } else {
            setRoleExists(true);
            await Promise.all([
                loadRole(),
                loadAuthorizations(),
                loadRoleManagers(),
                loadRoleUsers()
            ])
        }
    }

    async function loadRole() {
        const [role, organizations, departments]: any = await Promise.all([
            RequestCommons.request(process.env.REACT_APP_AUTH_API + "/roles/" + params.id + "?populate=1"),
            RequestCommons.request(process.env.REACT_APP_AUTH_API + "/organizations?populate=1"),
            RequestCommons.request(process.env.REACT_APP_AUTH_API + "/departments?populate=1")
        ]);

        setRole(role);

        const organizationOptions: any[] = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id");
        setOrganizationOptions(organizationOptions);
        setOrganizationSelect(organizationOptions.find((option: any) => option.value === role.organization_id));

        setDepartments(departments);

        const departmentOptions: any = ReactElementCommons.createReactSelectOptionsFromList(departments, "name", "id");
        setDepartmentOptions(departmentOptions);
        setDepartmentSelect(departmentOptions.find((option: any) => option.value === role.department_id));
    }

    async function loadAuthorizations() {
        const url = process.env.REACT_APP_AUTH_API + "/authorizations?role_id=" + params.id + "&populate=1";
        const authorizations: any = await RequestCommons.request(url);
        setAuthorizations(authorizations);
    }

    async function loadRoleManagers() {
        if (!loadingRoleManagers) {
            setLoadingRoleManagers(true);
            let url = process.env.REACT_APP_AUTH_API + "/users/roles?populate=1";
            const userRoles: any = await RequestCommons.request(url);

            url = process.env.REACT_APP_AUTH_API + "/roles/managers?role_id=" + params.id + "&filter=user_role_id&sort=1&unique=1";
            const roleManagers: any = await RequestCommons.request(url);

            const roleManagersRowSelection: any = {};
            for (let i = 0; i < roleManagers.length; i++) {
                const roleManager: any = roleManagers[i];
                roleManagersRowSelection[roleManager] = true;
            }
            setRoleManagersRowSelection(roleManagersRowSelection);

            const roleManagersTableData: any = [];
            for (let i = 0; i < userRoles.length; i++) {
                const userRole: any = userRoles[i];
                const selected = roleManagers.includes(userRole.id);
                if (selected || props.session.permissions.update) {
                    userRole.selected = selected ? "Yes" : "No";
                    roleManagersTableData.push(userRole);
                }
            }
            setRoleManagersTableData(roleManagersTableData);
            setLoadingRoleManagers(false);
        }
    }

    async function loadRoleUsers() {
        if (!loadingRoleUsers) {
            setLoadingRoleUsers(true);
            const url = process.env.REACT_APP_AUTH_API + "/users/roles?role_id=" + params.id + "&populate=1";
            const userRoles: any = await RequestCommons.request(url);
            setRoleUsersTableData(userRoles);
            setLoadingRoleUsers(false);
        }
    }

    async function updateRole(event: any) {
        event.preventDefault();
        try {
            setIsUpdating(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const formData: any = new FormData(event.target);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/roles/" + role.id;
            await RequestCommons.request(url, null, payload, "PUT");
            const managerIds: any = [];
            for (let managerId in roleManagersRowSelection) {
                managerIds.push(parseInt(managerId));
            }
            await replaceRoleManagers(role.id, managerIds);
            await loadData();
            setAlert({
                class: "alert-success text-center",
                message: "Updated."
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsUpdating(false);
        }
    }

    async function replaceRoleManagers(roleId: any, userRoleIds: any) {
        const entries = [];
        for (let i = 0; i < userRoleIds.length; i++) {
            entries.push({
                "role_id": roleId,
                "user_role_id": userRoleIds[i]
            });
        }
        const body: any = JSON.stringify(entries);
        let url = process.env.REACT_APP_AUTH_API + "/roles/managers?role_id=" + params.id;
        await RequestCommons.request(url, null, body, "put", "application/json").then(async function () {
            console.log("Replaced role managers.");
        }).catch(function (error) {
            console.error(error);
        });
    }

    const roleManagersTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'user.first_name',
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'email.email',
            header: 'Email',
        },
        {
            accessorKey: 'role.organization.name',
            header: 'Organization',
        }
    ];

    const roleUsersTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'user.id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'user.first_name',
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'email.email',
            header: 'Primary Email',
        }
    ];

    function renderRoleManagerRowActions(data: any) {
        const id = data.row.original.id;
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton to={"/users/roles/" + id}/>
        </Box>;
    }

    function renderRoleUserRowActions(data: any) {
        const id = data.row.original.id;
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton to={"/users/" + id}/>
        </Box>;
    }

    function onSelectOrganizationChange(option: any) {
        setOrganizationSelect(option);
        setDepartmentSelect(null);

        const organizationId = option.value;

        const departmentOptions: any = [];
        for (let i = 0; i < departments.length; i++) {
            const department = departments[i];
            if (department.organization_id === organizationId) {
                const departmentOption = {
                    value: department.id,
                    label: department.name
                };
                departmentOptions.push(departmentOption);
            }
        }

        setDepartmentOptions(departmentOptions);
    }

    if (isLoading) {
        return <Loading/>;
    } else if (!roleExists) {
        return <DoesNotExist/>;
    } else {
        let buttonText: any = "Update";
        if (isUpdating) {
            buttonText = <span>
                    <span className={"loading-spinner"}>
                        <i className="bi bi-arrow-clockwise"></i>
                    </span>
                    <span className={"ms-2"}>Updating...</span>
                </span>;
        }

        return <div className={"w-100 p-5 pt-3 m-auto"} style={{
            maxWidth: "2500px"
        }}>
            <div className={"row"}>
                <div className={"col-lg-3"}>
                    <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT}>
                        <form onSubmit={updateRole}>
                            <h2>Role</h2>
                            <div className="mb-3">
                                <label className="form-label">Id</label>
                                <input name="id" type="number" className="form-control" defaultValue={role.id}
                                       disabled/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Organization</label>
                                <Select
                                    name="organization_id"
                                    value={organizationSelect}
                                    options={organizationOptions}
                                    onChange={onSelectOrganizationChange}
                                    isDisabled={!props.session.permissions.update}
                                    required
                                />
                                <small>Required</small>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Department</label>
                                <Select
                                    name="department_id"
                                    isDisabled={!props.session.permissions.update}
                                    value={departmentSelect}
                                    options={departmentOptions}
                                    onChange={setDepartmentSelect}
                                />
                                <small>Required</small>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Name</label>
                                <input name="name" className="form-control" defaultValue={role.name} required
                                       disabled={!props.session.permissions.update}/>
                                <small>Required</small>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Description</label>
                                <textarea name="description"
                                          disabled={!props.session.permissions.update}
                                          className="form-control" defaultValue={role.description}/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Disabled</label>
                                <ReactSelectDisabled required value={role.disabled}
                                                     isDisabled={!props.session.permissions.update}/>
                            </div>
                            {props.session.permissions.update ?
                                <button type="submit" className="btn btn-warning form-control"
                                        disabled={isUpdating}>{buttonText}</button> : null}
                            <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
                        </form>
                        <div className={"row mt-3"}>
                            <div className={"col"}>
                                <ReturnToLastPageLink/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"col-lg-9 mb-3"}>
                    <div className={"bg-white p-3 rounded shadow-sm border"}>
                        <nav>
                            <div className="nav nav-pills" id="nav-tab" role="tablist">
                                <button className={"nav-link" + (location.hash === "" ? " active" : "")}
                                        id="nav-authorizations-tab" data-bs-toggle="tab"
                                        data-bs-target="#nav-authorizations"
                                        type="button" role="tab" aria-controls="nav-authorizations"
                                        aria-selected="false">
                                    Authorizations ({authorizations.length})
                                </button>
                                <button className={"nav-link" + (location.hash === "#managers" ? " active" : "")}
                                        id="nav-managers-tab" data-bs-toggle="tab"
                                        data-bs-target="#nav-managers"
                                        type="button" role="tab" aria-controls="nav-managers"
                                        aria-selected="false">
                                    Managers
                                    ({Object.values(roleManagersRowSelection).filter(i => i === true).length})
                                </button>
                                <button className={"nav-link" + (location.hash === "#user-roles" ? " active" : "")}
                                        id="nav-users-tab" data-bs-toggle="tab"
                                        data-bs-target="#nav-users"
                                        type="button" role="tab" aria-controls="nav-users"
                                        aria-selected="false">
                                    User Roles
                                    ({roleUsersTableData.length})
                                </button>
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className={"tab-pane" + (location.hash === "" ? " active" : "")}
                                 id="nav-authorizations" role="tabpanel"
                                 aria-labelledby="nav-authorizations-tab">
                                <AuthorizationsTable {...props} authorizations={authorizations}/>
                            </div>
                            <div className={"tab-pane" + (location.hash === "#user-roles" ? " active" : "")}
                                 id="nav-users" role="tabpanel"
                                 aria-labelledby="nav-users-tab">
                                <MaterialReactTable
                                    muiTablePaperProps={props.config.materialReactTable.muiTablePaperProps}
                                    columns={roleUsersTableColumns}
                                    data={roleUsersTableData}
                                    state={{
                                        isLoading: false,
                                        density: "compact"
                                    }}
                                    enableRowActions={true}
                                    renderRowActions={renderRoleUserRowActions}
                                    initialState={{
                                        columnVisibility: {
                                            selected: false
                                        },
                                        sorting: [{
                                            id: "selected",
                                            desc: true
                                        }, {
                                            id: "user.last_name",
                                            desc: false
                                        }, {
                                            id: "user.first_name",
                                            desc: false
                                        }, {
                                            id: "email.email",
                                            desc: false
                                        }]
                                    }}
                                    getRowId={(originalRow: any) => originalRow.id}
                                    renderTopToolbarCustomActions={() => {
                                        return (
                                            <h2 className={"me-3 d-inline-block align-top"}>User Roles</h2>
                                        );
                                    }}
                                />
                            </div>
                            <div className={"tab-pane" + (location.hash === "#managers" ? " active" : "")}
                                 id="nav-managers" role="tabpanel"
                                 aria-labelledby="nav-managers-tab">
                                <MaterialReactTable
                                    muiTablePaperProps={props.config.materialReactTable.muiTablePaperProps}
                                    columns={roleManagersTableColumns}
                                    data={roleManagersTableData}
                                    enableSelectAll={false}
                                    enableRowSelection={props.session.permissions.update}
                                    onRowSelectionChange={setRoleManagersRowSelection}
                                    state={{
                                        rowSelection: roleManagersRowSelection,
                                        isLoading: false,
                                        density: "compact"
                                    }}
                                    enableRowActions={true}
                                    renderRowActions={renderRoleManagerRowActions}
                                    initialState={{
                                        columnVisibility: {
                                            selected: false
                                        },
                                        sorting: [{
                                            id: "selected",
                                            desc: true
                                        }, {
                                            id: "user.last_name",
                                            desc: false
                                        }, {
                                            id: "user.first_name",
                                            desc: false
                                        }]
                                    }}
                                    getRowId={(originalRow: any) => originalRow.id}
                                    renderTopToolbarCustomActions={() => {
                                        return (
                                            <div>
                                                <h2 className={"me-3 d-inline-block align-top"}>Managers</h2>
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}