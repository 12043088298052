import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {Link, useNavigate} from "react-router-dom";
import Select from "react-select";
import ReactElementCommons from "../../commons/ReactElementCommons";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";

export default function CreateGroup(props: any) {
    const navigate = useNavigate();

    const [alert, setAlert]: any = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [isRequesting, setIsRequesting] = useState(false);
    const [organizationOptions, setOrganizationOptions] = useState([]);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
    }

    async function loadData() {
        let url = process.env.REACT_APP_AUTH_API + "/organizations";
        const organizations: any = await RequestCommons.request(url);
        const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id");
        setOrganizationOptions(organizationOptions);
    }

    async function createGroup(event: any) {
        event.preventDefault();
        try {
            setIsRequesting(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const formData: any = new FormData(event.target);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/groups";
            const id = await RequestCommons.request(url, null, payload, "POST");
            setAlert({
                class: "alert-success text-center",
                message: <>
                    <div>Created group.</div>
                    <div>
                        <Link className={"text-decoration-none"} to={"/groups/" + id}>Click here to update group.</Link>
                    </div>
                </>
            });
            navigate("/groups/" + id);
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsRequesting(false);
        }
    }

    let buttonText: any = "Create";
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Creating...</span>
            </span>;
    }

    return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " container m-auto"}>
        <div className={"mb-3 d-none"}>
            <ReturnToLastPageLink/>
        </div>
        <form onSubmit={createGroup}>
            <div className={"d-inline-block align-middle"}>
                <h2 className={"d-inline-block align-middle me-3"}>Create Group</h2>
                <div className={"d-inline-block align-middle"}>
                    <i className="text-danger bi bi-exclamation-triangle-fill"
                       title={"Consult with Engineering before creating a group."}/>
                </div>
            </div>
            <div className={"row"}>
                <div className="col-12 col-lg mb-3">
                    <label className="form-label">Name</label>
                    <input name="name" className="form-control" required autoFocus
                           placeholder={"Example: C3 Users - SBB"}
                    />
                    <small>Required</small>
                </div>
                <div className="col-12 col-lg mb-3">
                    <label className="form-label">Organization</label>
                    <Select
                        name="organization_id"
                        options={organizationOptions}
                        required
                    />
                    <small>Required</small>
                </div>
                <div className="col-12 col-lg mb-3">
                    <label className="form-label">Disabled</label>
                    <ReactSelectDisabled required={true}/>
                </div>
            </div>
            <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea name="description"
                          className="form-control"
                          placeholder={"Enter a brief description about what this group will control."}
                          required/>
                <small>Required</small>
            </div>
            <div className={"mt-3"}>
                <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
            </div>
            <div className={"row mt-3"}>
                <div className={"col"}>
                    <ReturnToLastPageLink/>
                </div>
                <div className={"col text-end"}>
                    <button type="submit" className="btn btn-primary ps-5 pe-5"
                            disabled={isRequesting}>{buttonText}</button>
                </div>
            </div>
        </form>
    </div>;
}