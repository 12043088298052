import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../../../commons/RequestCommons"
import {Box} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import AuthCommons from "../../../../../commons/AuthCommons";
import ViewEntryActionButton from "../../../../shared/tables/ViewEntryActionButton";
import DeleteEntryActionButton from "../../../../shared/tables/DeleteEntryActionButton";

export default function ViewUserRoleC3Managers(props: any) {
    const [tableData, setTableData]: any = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const managers: any = await AuthCommons.request("/users/roles/managers/c3?populate=1");
        setTableData(managers);
    }

    function deleteUserDailyReviewManager(id: number) {
        const c = window.confirm("Are you sure you want to delete this entry?");
        if (c) {
            const url = process.env.REACT_APP_AUTH_API + "/users/roles/managers/c3/" + id;
            RequestCommons.request(url, null, null, "DELETE").then(async function () {
                await loadData();
            }).catch(function (error: any) {
                console.error(error);
                alert(error.detail);
            });
        }
    }

    function renderRowActions(data: any) {
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton to={"/users/roles/" + data.row.original.user_role.id}/>
            {props.session.permissions.delete ?
                <DeleteEntryActionButton onClick={function (event: any) {
                    event.preventDefault();
                    deleteUserDailyReviewManager(data.row.original.id);
                }}/> : null}
        </Box>;
    }

    const defaultPageSize = 10;

    const columns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'user_role.user.first_name', //access nested data with dot notation
            header: 'User First Name',
        },
        {
            accessorKey: 'user_role.user.last_name', //access nested data with dot notation
            header: 'User Last Name',
        },
        {
            accessorKey: 'user_role.email.email', //access nested data with dot notation
            header: 'User Role Email',
        },
        {
            accessorKey: 'user_role.role.organization.name', //access nested data with dot notation
            header: 'User Role Org',
        },
        {
            accessorKey: 'manager_user_role.user.first_name',
            header: 'Manager First Name',
        },
        {
            accessorKey: 'manager_user_role.user.last_name',
            header: 'Manager Last Name',
        },
        {
            accessorKey: 'manager_user_role.email.email',
            header: 'Manager Role Email',
        },
        {
            accessorKey: 'manager_user_role.role.organization.name',
            header: 'Manager Role Org',
        }
    ];

    const initialState = {
        sorting: [
            {
                id: 'user_role.user.last_name', //sort by age by default on page load
                desc: false
            },
            {
                id: 'user_role.user.first_name', //sort by age by default on page load
                desc: false
            }
        ],
        pagination: {
            pageSize: defaultPageSize,
            pageIndex: 0
        }
    };

    return <div className={"w-100 p-5 pt-3 m-auto"} style={{
        maxWidth: "2500px"
    }}>
        <div className={"shadow-sm bg-white rounded border"}>
            <MaterialReactTable
                muiTablePaperProps={props.config.materialReactTable.muiTablePaperProps}
                muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}
                state={{
                    isLoading: isLoading,
                    density: "compact"
                }}
                initialState={initialState}
                enableRowActions={true}
                renderRowActions={renderRowActions}
                enablePagination={true}
                renderTopToolbarCustomActions={() => {
                    return <div>
                        <h2 className={"me-3 d-inline-block align-top"}>C3 Managers</h2>
                    </div>;
                }}
            />
        </div>
    </div>;
}