import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {Link} from "react-router-dom";
import Select from "react-select";
import ReactElementCommons from "../../commons/ReactElementCommons";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";

export default function CreateResource(props: any) {
    const [alert, setAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [isRequesting, setIsRequesting] = useState(false);
    const [organizationOptions, setOrganizationOptions] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
    }

    async function loadData() {
        let url = process.env.REACT_APP_AUTH_API + "/organizations";
        const organizations: any = await RequestCommons.request(url);
        const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id");
        setOrganizationOptions(organizationOptions);

        url = process.env.REACT_APP_AUTH_API + "/resources/types";
        const types: any = await RequestCommons.request(url);
        const typeOptions: any = ReactElementCommons.createReactSelectOptionsFromList(types, "name", "id");
        setTypeOptions(typeOptions);
    }

    async function createResource(event: any) {
        event.preventDefault();
        try {
            setIsRequesting(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const form: any = event.target;
            const formData: any = new FormData(form);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/resources";
            const id = await RequestCommons.request(url, null, payload, "POST");
            setAlert({
                class: "alert-success text-center",
                message: <>
                    <div>Created resource.</div>
                    <div>
                        <Link className={"text-decoration-none"} to={"/resources/" + id}>Click here to update resource.</Link>
                    </div>
                </>
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsRequesting(false);
        }
    }

    let buttonText: any = "Create";
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Creating...</span>
            </span>;
    }

    return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
        <form onSubmit={createResource}>
            <h2>Create Resource</h2>
            <div className="mb-3">
                <label className="form-label">Type</label>
                <Select
                    name="resource_type_id"
                    options={typeOptions}
                    required
                />
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Organization</label>
                <Select
                    name="organization_id"
                    required
                    options={organizationOptions}
                />
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Name</label>
                <input name="name" className="form-control" required/>
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea name="description"
                          className="form-control"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Location</label>
                <input name="location" className="form-control"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Disabled</label>
                <ReactSelectDisabled required
                                     isDisabled={!props.session.permissions.update}/>
            </div>
            <button type="submit" className="btn btn-primary form-control"
                    disabled={isRequesting}>{buttonText}</button>
            <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
        </form>
        <div className={"row mt-3"}>
            <div className={"col"}>
                <ReturnToLastPageLink/>
            </div>
        </div>
    </div>;
}