import {Link, useNavigate} from "react-router-dom";
import * as React from "react";

export default function ReturnToLastPageLink(props: any) {
    const navigate = useNavigate();

    const to = props.to ? props.to : null;
    const label = props.label ? props.label : "Go back";
    const icon = !Object.keys(props).includes("showIcon") || props.showIcon === true ?
        <i className="bi bi-arrow-left me-2"/> : null;
    return <Link to={to} style={{textDecoration: "none"}} onClick={function (event: any) {
        event.preventDefault();
        navigate(-1);
    }}>
        {icon}
        <span>{label}</span>
    </Link>
}