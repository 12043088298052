import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../../commons/RequestCommons"
import {Link, useParams} from "react-router-dom";
import AuthCommons from "../../../../commons/AuthCommons";
import ObjectCommons from "../../../../commons/ObjectCommons";
import Select from "react-select";
import ReactElementCommons from "../../../../commons/ReactElementCommons";
import ReactSelectDisabled from "../../../../commons/ReactSelectDisabled";

export default function CreateUserPhoneNumber(props: any) {
    const [alert, setAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [isRequesting, setIsRequesting] = useState(false);
    const [userOptions, setUserOptions]: any = useState([]);
    const [organizationOptions, setOrganizationOptions]: any = useState([]);
    const [usersById, setUsersById] = useState([]);
    const [userPhoneNumbersByPhoneNumber, setUserPhoneNumbersByPhoneNumber]: any = useState({});
    const [phoneNumberAssigned, setPhoneNumberAssigned]: any = useState(false);
    const [phoneNumberAssignee, setPhoneNumberAssignee]: any = useState(null);
    const [phoneNumberTypeOptions, setPhoneNumberTypeOptions]: any = useState([]);
    const [selectedUserOption, setSelectedUserOption]: any = useState(null);

    const params = useParams();
    const userId = params.hasOwnProperty("id") && params.id ? parseInt(params.id) : null;

    useEffect(() => {
        init();
    }, [])

    async function init() {
        await loadData();
    }

    async function loadData() {
        const [users, organizations, userPhoneNumbers, phoneNumberTypes]: any = await Promise.all([
            AuthCommons.request("/users?disabled=0&order_by=last_name&order_by_direction=asc"),
            AuthCommons.request("/organizations"),
            AuthCommons.request("/users/numbers/phone?populate=1"),
            AuthCommons.request("/numbers/phone/types"),
        ]);

        const usersById: any = ObjectCommons.mapEntriesById(users);
        setUsersById(usersById);

        const userOptions: any = ReactElementCommons.createReactSelectOptionsFromList(users, ["first_name", "last_name"], "id");
        setUserOptions(userOptions);
        setSelectedUserOption(userOptions.find((option: any) => option.value === userId));

        const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id", null, true);
        setOrganizationOptions(organizationOptions);

        const phoneNumberTypeOptions: any = ReactElementCommons.createReactSelectOptionsFromList(phoneNumberTypes, "name", "id");
        setPhoneNumberTypeOptions(phoneNumberTypeOptions);

        let userPhoneNumbersByPhoneNumber: any = {};
        for (let i = 0; i < userPhoneNumbers.length; i++) {
            const userPhoneNumber = userPhoneNumbers[i];
            userPhoneNumbersByPhoneNumber[userPhoneNumber.phone_number.phone_number] = userPhoneNumber;
        }
        setUserPhoneNumbersByPhoneNumber(userPhoneNumbersByPhoneNumber);
    }

    async function createUserPhoneNumber(event: any) {
        event.preventDefault();
        try {
            setIsRequesting(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const form: any = event.target;
            const formData: any = new FormData(form);
            const payload = new URLSearchParams(formData);
            const userId = selectedUserOption.value;
            const endpoint = "/users/" + userId + "/numbers/phone";
            const url = process.env.REACT_APP_AUTH_API + endpoint;
            const id = await RequestCommons.request(url, null, payload, "POST");
            setAlert({
                class: "alert-success text-center",
                message: <>
                    <div>Created user phone number.</div>
                    <div>
                        <Link to={"/users/" + userId}>Click here to update user.</Link>
                    </div>
                </>
            });
        } catch(error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsRequesting(false);
        }
    }

    function checkPhoneNumberAssigned(phoneNumber: string) {
        const phoneNumberAssigned: any = Object.keys(userPhoneNumbersByPhoneNumber).includes(phoneNumber);
        setPhoneNumberAssigned(phoneNumberAssigned);
        if (phoneNumberAssigned) {
            const userPhoneNumber = userPhoneNumbersByPhoneNumber[phoneNumber];
            const user = usersById[userPhoneNumber.user_id];
            setPhoneNumberAssignee(user);
        }

    }

    let buttonText: any = "Create";
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Creating...</span>
            </span>;
    }

    const returnLink = userId ? "/users/" + userId : "/users/numbers/phone/";
    const returnLinkText = userId ? "Return to User" : "Return to User Number";

    let phoneNumberFieldDescription: any = <small>Required</small>;
    let phoneNumberFieldClassName = "form-control";
    if (phoneNumberAssigned) {
        phoneNumberFieldClassName += " is-invalid text-danger";
        phoneNumberFieldDescription = <small className={"text-danger"}>Phone number already assigned
            to <Link
                to={"/users/" + phoneNumberAssignee.id}>{phoneNumberAssignee.first_name + " " + phoneNumberAssignee.last_name}</Link>.
            Remove phone number from user first to continue.</small>;
    }

    return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
        <form onSubmit={createUserPhoneNumber}>
            <h1>Create User Phone Number</h1>
            <div className="mb-3">
                <label className="form-label">User</label>
                <Select
                    name="user_id"
                    isDisabled={userId !== null}
                    value={selectedUserOption}
                    options={userOptions}
                    onChange={function(event: any) {
                        setSelectedUserOption(userOptions.find((option: any) => option.value === event.value));
                    }}
                    required
                />
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Phone Number</label>
                <input name="phone_number" type={"phone_number"}
                       className={phoneNumberFieldClassName}
                       onChange={function (event: any) {
                           const phoneNumber = event.target.value;
                           checkPhoneNumberAssigned(phoneNumber);
                       }} required/>
                {phoneNumberFieldDescription}
            </div>
            <div className="mb-3">
                <label className="form-label">Type</label>
                <Select
                    name="phone_number_type_id"
                    options={phoneNumberTypeOptions}
                    required
                />
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Organization</label>
                <Select
                    name="organization_id"
                    options={organizationOptions}
                />
                <small>Optional</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Disabled</label>
                <ReactSelectDisabled required/>
            </div>
            <div className="form-check mb-3 d-none">
                <input className={"form-check-input"} type="checkbox" name="primary" value={1}/>
                <label className="form-check-label">Primary</label>
            </div>
            <button type="submit"
                    className={"btn form-control " + (phoneNumberAssigned ? "btn-danger" : "btn-primary")}
                    disabled={isRequesting || phoneNumberAssigned}>{buttonText}</button>
            <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
        </form>
        <div className={"row mt-3"}>
            <div className={"col"}>
                <Link to={returnLink} style={{textDecoration: "none"}}>
                    <i className="bi bi-arrow-left"></i>
                    <span className={"ms-2"}>{returnLinkText}</span>
                </Link>
            </div>
        </div>
    </div>;
}