import * as React from "react";
import {useEffect, useState} from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net"
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import TimeCommons from "../../commons/TimeCommons";
import {MaterialReactTable} from "material-react-table";
import {Link, useNavigate} from "react-router-dom";
import AuthCommons from "../../commons/AuthCommons";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";

export default function ViewUsers(props: any) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading]: any = useState(true);
    const [tableData, setTableData]: any = useState([]);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [users, userRoles]: any = await Promise.all([
            AuthCommons.request("/users?populate=1"),
            AuthCommons.request("/users/roles?populate=1")
        ]);
        const userRolesByUserId: any = {};
        for (let i = 0; i < userRoles.length; i++) {
            const userRole = userRoles[i];
            if (!Object.keys(userRolesByUserId).includes(userRole.user_id + "")) {
                userRolesByUserId[userRole.user_id + ""] = [];
            }
            userRolesByUserId[userRole.user_id + ""].push(userRole);
        }

        const tableData: any = [];
        for (let i = 0; i < users.length; i++) {
            const user = users[i];
            user.avatar = user.avatar ?
                <img alt={user.avatar} src={user.avatar} className={"rounded-circle"} style={{
                    height: "32px",
                    objectFit: "contain"
                }}/> : null;
            user.created = TimeCommons.formatTimestamp(user.created);
            user.date_of_birth = user.date_of_birth ? TimeCommons.formatDate(user.date_of_birth) : null;
            user.disabled = user.disabled ? "Yes" : "No";

            const userUserRoles = userRolesByUserId[user.id + ""];
            if (userUserRoles) {
                const userRoleOrganizations = [];
                for (let i = 0; i < userUserRoles.length; i++) {
                    const userUserRole = userUserRoles[i];
                    userRoleOrganizations.push(userUserRole.role.organization.short_name);
                }
                userRoleOrganizations.sort((a: any, b: any) => {
                    return a.localeCompare(b);
                });
                user.user_role_organizations = userRoleOrganizations.join(", ");
            }

            tableData.push(user);
        }
        setTableData(tableData);
    }

    const createLink = props.session.permissions.create ? <Link to={"/users/create"}
                                                                className={"d-inline-block btn btn-primary me-3"}
    >
        Create
    </Link> : null;

    const importLink = props.session.permissions.create ? <Link to={"/users/legacy/import"}
                                                                className={"d-inline-block btn btn-warning"}
    >
        Import
    </Link> : null;


    const columns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'first_name',
            header: 'First Name',
        },
        {
            accessorKey: 'last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'user_role_organizations',
            header: 'Organizations',
        },
        {
            accessorKey: 'disabled', //normal accessorKey
            header: 'Disabled',
            size: 80
        }
    ];

    const defaultPageSize = 10;
    const initialState = {
        showColumnFilters: true,
        sorting: [
            {
                id: 'id', //sort by age by default on page load
                desc: true
            }
        ],
        pagination: {
            pageSize: defaultPageSize,
            pageIndex: 0
        },
        columnFilters: [
            {
                id: 'disabled',
                value: "No"
            }
        ],
    };

    return <div className={"m-auto container shadow-sm bg-white rounded border p-3"}>
        <div className={"mb-3 d-none"}>
            <ReturnToLastPageLink/>
        </div>
        <MaterialReactTable
            muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
            enableStickyHeader={true}
            columns={columns}
            data={tableData}
            state={{
                isLoading: isLoading,
                density: "compact"
            }}
            initialState={initialState}
            enablePagination={true}
            renderTopToolbarCustomActions={() => {
                return (
                    <div>
                        <h2 className={"me-3 d-inline-block align-top"}>
                            <i className="bi bi-people-fill me-3 align-middle"/>
                            <span>Users</span>
                        </h2>
                        {createLink}
                        {importLink}
                    </div>
                );
            }}
            muiTableBodyRowProps={({row}: any) => ({
                onClick: (event) => {
                    navigate("/users/" + row.original.id);
                },
                sx: {
                    cursor: 'pointer'
                }
            })}
        />
        <div className={"mt-3 d-none"}>
            <ReturnToLastPageLink/>
        </div>
    </div>;
}