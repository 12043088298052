import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../commons/RequestCommons"
import {Link, useParams} from "react-router-dom";
import AuthCommons from "../../../commons/AuthCommons";
import ReactElementCommons from "../../../commons/ReactElementCommons";
import Select from "react-select";
import ReactSelectDisabled from "../../../commons/ReactSelectDisabled";
import {Alert} from "react-bootstrap";
import ReturnToLastPageLink from "../../shared/ReturnToLastPageLink";

export default function UpdateNavigationLocationPolicy(props: any) {
    const [alert, setAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });

    const [exists, setExists] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [navigationLocationPolicy, setNavigationLocationPolicy]: any = useState({});
    const [navigationLocationOptions, setNavigationLocationOptions]: any = useState([]);
    const [policyOptions, setPolicyOptions]: any = useState([]);
    const [navigationLocationSelect, setNavigationLocationSelect]: any = useState(null);
    const [portalPolicySelect, setPortalPolicySelect]: any = useState(null);
    const [policySelect, setPolicySelect]: any = useState(null);


    const params = useParams();

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setIsLoading(true);
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [response]: any = await Promise.all([
            AuthCommons.request("/navs/portals/policies/" + params.id, null, null, 'get', 'application/x-www-form-urlencoded', false)
        ]);

        if (response.status === 204) {
            setExists(false);
        } else {
            setExists(true);
            const navigationLocationPolicy: any = await response.json();
            setNavigationLocationPolicy(navigationLocationPolicy);

            const [navigationLocations, policies]: any = await Promise.all([
                AuthCommons.request("/navs/portals"),
                AuthCommons.request("/policies")
            ]);

            const navigationLocationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(navigationLocations, "name", "id");
            setNavigationLocationOptions(navigationLocationOptions);
            setNavigationLocationSelect(navigationLocationOptions.find((option: any) => option.value === navigationLocationPolicy.nav_id));

            const policyOptions: any = ReactElementCommons.createReactSelectOptionsFromList(policies, "name", "id");
            setPolicyOptions(policyOptions);
            setPortalPolicySelect(policyOptions.find((option: any) => option.value === navigationLocationPolicy.portal_policy_id));
            setPolicySelect(policyOptions.find((option: any) => option.value === navigationLocationPolicy.policy_id));
        }
    }

    async function UpdateNavigationLocationPolicy(event: any) {
        event.preventDefault();
        try {
            setIsUpdating(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const form: any = event.target;
            const formData: any = new FormData(form);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/navs/portals/policies/" + navigationLocationPolicy.id;
            await RequestCommons.request(url, null, payload, "PUT");
            await loadData();
            setAlert({
                class: "alert-success text-center",
                message: "Updated."
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsUpdating(false);
        }
    }

    if (isLoading) {
        return <div className={"container"}>
            <Alert variant={"primary"}>
                <div className="spinner-border spinner-border-sm me-2"
                     role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <span>Loading...</span>
            </Alert>
        </div>;
    } else if (!exists) {
        // render warning alert
        return <div className={"container"}>
            <Alert variant={"primary"}>
                <div className="bi bi-exclamation-triangle-fill  me-2"
                     role="status">
                    <span className="visually-hidden">No Entry Exists.</span>
                    <span>  No Entry Exists.</span>
                </div>
            </Alert>
        </div>;

    } else {
        let buttonText: any = "Update";
        if (isUpdating) {
            buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Updating...</span>
            </span>;
        }

        return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
            <form onSubmit={UpdateNavigationLocationPolicy}>
                <h1>Update Navigation Location Policy</h1>
                <div className="mb-3">
                    <label className="form-label">Id</label>
                    <input name="id" className="form-control" defaultValue={navigationLocationPolicy.id}
                           disabled/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Navigation Location</label>
                    <Select
                        name="nav_id"
                        options={navigationLocationOptions}
                        onChange={setNavigationLocationSelect}
                        value={navigationLocationSelect}
                        required
                    />
                    <small>Required</small>
                </div>
                <div className="mb-3">
                    <label className="form-label">Portal Policy Id</label>
                    <Select
                        name="portal_policy_id"
                        options={policyOptions}
                        onChange={setPortalPolicySelect}
                        value={portalPolicySelect}
                        required
                    />
                    <small>Required</small>
                </div>
                <div className="mb-3">
                    <label className="form-label">Policy Id</label>
                    <Select
                        name="policy_id"
                        options={policyOptions}
                        onChange={setPolicySelect}
                        value={policySelect}
                        required
                    />
                    <small>Required</small>
                </div>
                <div className="mb-3">
                    <label className="form-label">Disabled</label>
                    <ReactSelectDisabled value={navigationLocationPolicy.disabled}
                                         isDisabled={!props.session.permissions.update}
                                         required/>
                </div>
                <button type="submit" className="btn btn-warning form-control"
                        disabled={isUpdating}>{buttonText}</button>
                <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
            </form>
            <div className={"row mt-3"}>
                <div className={"col"}>
                    <ReturnToLastPageLink/>
                </div>
            </div>
        </div>;
    }
}