import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../../commons/RequestCommons"
import {MaterialReactTable} from "material-react-table";
import {Box} from "@mui/material";
import TimeCommons from "../../../../commons/TimeCommons";
import ViewEntryActionButton from "../../../shared/tables/ViewEntryActionButton";
import DeleteEntryActionButton from "../../../shared/tables/DeleteEntryActionButton";


export default function ViewGroupUserRoles(props: any) {
    const [isLoading, setIsLoading]: any = useState(true);
    const [tableData, setTableData]: any = useState([]);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const url = process.env.REACT_APP_AUTH_API + "/groups/users/roles?populate=1";
        const groupUserRoles: any = await RequestCommons.request(url);
        for (let i = 0; i < groupUserRoles.length; i++) {
            const groupUserRole = groupUserRoles[i];
            groupUserRole.disabled = groupUserRole.disabled ? "Yes" : "No";
            groupUserRole.created = TimeCommons.formatTimestamp(groupUserRole.created);
        }
        setTableData(groupUserRoles);
    }

    function deleteGroupUserRole(id: number) {
        const c = window.confirm("Are you sure you want to delete this entry?");
        if (c) {
            const url = process.env.REACT_APP_AUTH_API + "/groups/users/roles/" + id;
            RequestCommons.request(url, null, null, "DELETE").then(async function (response) {
                await loadData();
            }).catch(function (error) {
                console.error(error);
                alert(error.detail);
            });
        }
    }

    function renderRowActions(data: any) {
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton to={"/groups/" + data.row.original.group.id}/>
            {props.session.permissions.delete ?
                <DeleteEntryActionButton onClick={function (event: any) {
                    event.preventDefault();
                    deleteGroupUserRole(data.row.original.id);
                }}/> : null}
        </Box>;
    }

    const defaultPageSize = 10;
    const columns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'group.id',
            header: 'Group Id',
            size: 70
        },
        {
            accessorKey: 'group.name',
            header: 'Group Name',
        },
        {
            accessorKey: 'group.organization.name',
            header: 'Group Organization',
        },
        {
            accessorKey: 'user_role.user.id',
            header: 'User Id',
            size: 70
        },
        {
            accessorKey: 'user_role.id', //normal accessorKey
            header: 'User Role Id',
            size: 70
        },
        {
            accessorKey: 'user_role.user.first_name', //normal accessorKey
            header: 'First Name',
        },
        {
            accessorKey: 'user_role.user.last_name', //normal accessorKey
            header: 'Last Name',
        },
        {
            accessorKey: 'user_role.email.email', //normal accessorKey
            header: 'User Role Email',
        },
        {
            accessorKey: 'user_role.role.organization.name', //normal accessorKey
            header: 'Role Organization',
        },
        {
            accessorKey: 'user_role.role.department.name', //normal accessorKey
            header: 'Role Department',
        },
        {
            accessorKey: 'user_role.role.name', //normal accessorKey
            header: 'Role Name',
        },
        {
            accessorKey: 'created',
            header: 'Created',
        }
    ];

    const initialState = {
        sorting: [
            {
                id: 'group.name', //sort by age by default on page load
                desc: false
            },
            {
                id: 'user_role.user.last_name', //sort by age by default on page load
                desc: false
            },
            {
                id: 'user_role.user.first_name', //sort by age by default on page load
                desc: false
            },
            {
                id: 'user_role.role.name', //sort by age by default on page load
                desc: false
            }
        ],
        pagination: {
            pageSize: defaultPageSize,
            pageIndex: 0
        }
    };

    return <div className={"m-auto container shadow-sm bg-white rounded border"}>
        <MaterialReactTable
            muiTablePaperProps={props.config.materialReactTable.muiTablePaperProps}
            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
            enableStickyHeader={true}
            columns={columns}
            data={tableData}
            state={{
                isLoading: isLoading,
                density: "compact"
            }}
            initialState={initialState}
            enableRowActions={true}
            renderRowActions={renderRowActions}
            enablePagination={true}
            renderTopToolbarCustomActions={() => {
                return (
                    <div>
                        <h2 className={"me-3 d-inline-block align-top"}>Group User Roles</h2>
                    </div>
                );
            }}
        />
    </div>;
}