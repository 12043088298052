import AuthCommons from "../commons/AuthCommons";

export default function Test(props: any) {
    return <div>
        <form className={"mb-3"} onSubmit={async function (event: any) {
            event.preventDefault();
            try {
                const response = await AuthCommons.renewSession();
                console.log(response);
            } catch (error) {
                console.error(error);
            }
        }}>
            < button className={"btn btn-primary"}>Refresh Token</button>
        </form>
        <form className={"mb-3"} onSubmit={async function (event: any) {
            event.preventDefault();
            try {
                const response = await AuthCommons.getAuthorizations();
                console.log(response);
            } catch (error) {
                console.error(error);
            }
        }}>
            < button className={"btn btn-primary"}>Get User Policies</button>
        </form>
    </div>;
}