import * as React from "react";
import {useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {Link} from "react-router-dom";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";

export default function CreateTag(props: any) {
    const [alert, setAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [name, setName] = useState(null);
    const [color, setColor] = useState("#0099ff");
    const [isRequesting, setIsRequesting] = useState(false);

    async function createTag(event: any) {
        event.preventDefault();
        try {
            setIsRequesting(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const form: any = event.target;
            const formData: any = new FormData(form);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/tags";
            const id = await RequestCommons.request(url, null, payload, "POST");
            setAlert({
                class: "alert-success text-center",
                message: <>
                    <div>Created tag.</div>
                    <div>
                        <Link to={"/tags/" + id}>Click here to update tag.</Link>
                    </div>
                </>
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsRequesting(false);
        }
    }

    let colorEle: any = null;
    if (color) {
        colorEle = <div className={"p-5 mt-3 border"} style={{
            background: color
        }}></div>
    }

    let preview: any = <div
        className={"p-1 ps-2 pe-2 mt-3 d-inline-block border border-sm rounded text-white small"} style={{
        background: color
    }}>
        {name}
    </div>;

    let buttonText: any = "Create";
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Creating...</span>
            </span>;
    }

    return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
        <form onSubmit={createTag}>
            <h1>Create Tag</h1>
            <div className="mb-3">
                <label className="form-label">Name</label>
                <input name="name" className="form-control" required onKeyUp={function (event: any) {
                    const name = event.target.value;
                    setName(name);
                }}/>
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea name="description"
                          className="form-control"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Color</label>
                <input name="color" className="form-control" defaultValue={color} required
                       onKeyUp={function (event: any) {
                           const color = event.target.value;
                           setColor(color);
                       }}/>
                {colorEle}
            </div>
            <div className="mb-3">
                <label className="form-label">Disabled</label>
                <ReactSelectDisabled required/>
            </div>
            <div className="mb-3">
                <label className="form-label">Preview</label>
                <div>
                    {preview}
                </div>
            </div>
            <button type="submit" className="btn btn-primary form-control"
                    disabled={isRequesting}>{buttonText}</button>
            <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
        </form>
        <div className={"row mt-3"}>
            <div className={"col"}>
                <ReturnToLastPageLink/>
            </div>
        </div>
    </div>;
}