import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {useLocation, useParams} from "react-router-dom";
import TimeCommons from "../../commons/TimeCommons";
import {MaterialReactTable} from "material-react-table";
import {Box} from "@mui/material";
import AuthCommons from "../../commons/AuthCommons";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import {Accordion} from "react-bootstrap";
import Loading from "../Loading";
import ViewEntryActionButton from "../shared/tables/ViewEntryActionButton";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";
import DoesNotExist from "../shared/DoesNotExist";
import Select from "react-select";
import ReactElementCommons from "../../commons/ReactElementCommons";

export default function UpdateOrganization(props: any) {
    const [alert, setAlert] = useState({
        class: "d-none",
        message: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [organizationExists, setOrganizationExists]: any = useState(null);
    const [organization, setOrganization]: any = useState({});
    const [logo, setLogo]: any = useState(null);
    const [organizationRolesTableData, setOrganizationRolesTableData]: any = useState([]);
    const [organizationDepartmentsTableData, setOrganizationDepartmentsTableData]: any = useState([]);
    const [organizationManagersTableData, setOrganizationManagersTableData]: any = useState([]);
    const [organizationManagersRowSelection, setOrganizationManagersRowSelection]: any = useState({});
    const [originalOrganizationManagersRowSelection, setOriginalOrganizationManagersRowSelection]: any = useState({});
    const [organizationUsersTableData, setOrganizationUsersTableData]: any = useState([]);
    const [authorizations, setAuthorizations]: any = useState([]);
    const [portalPolicyOptions, setPortalPolicyOptions]: any = useState([]);
    const [selectedPortalPolicyOption, setSelectedPortalPolicyOption]: any = useState(null);
    const [emailOptions, setEmailOptions]: any = useState([]);
    const [selectedEmailOption, setSelectedEmailOption]: any = useState(null);

    const params = useParams();
    const location = useLocation();

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setIsLoading(true);
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [organizationResponse]: any = await Promise.all([
            AuthCommons.request("/organizations/" + params.id, null, null, 'get', 'application/x-www-form-urlencoded', false)
        ]);
        if (organizationResponse.status === 204) {
            // display not exists warning
            setOrganizationExists(false);
        } else {
            // continue as normal
            setOrganizationExists(true);
            const organization: any = await organizationResponse.json();
            const email_type_id = process.env.REACT_APP_ORG_SUPPORT_EMAIL_TYPE ?? 15;
            const [organizationManagers, organizationDepartments, organizationRoles, userRoles, authorizations, emails, policies]: any = await Promise.all([
                AuthCommons.request("/organizations/managers?organization_id=" + params.id + "&filter=user_role_id&sort=1&unique=1"),
                AuthCommons.request("/departments?organization_id=" + params.id + "&populate=1"),
                AuthCommons.request("/roles?organization_id=" + params.id + "&populate=1"),
                AuthCommons.request("/users/roles?populate=1"),
                AuthCommons.request("/authorizations?role_organization_id=" + params.id + "&populate=1"),
                AuthCommons.request("/emails?email_type_id=" + email_type_id + "&populate=1"),
                AuthCommons.request("/policies?policy_type_id=3"),
            ]);

            setOrganization(organization);
            setLogo(organization.logo);

            const organizationManagersRowSelection: any = {};
            for (let i = 0; i < organizationManagers.length; i++) {
                const organizationManager: any = organizationManagers[i];
                organizationManagersRowSelection[organizationManager] = true;
            }
            setOrganizationManagersRowSelection(organizationManagersRowSelection);
            setOriginalOrganizationManagersRowSelection(organizationManagersRowSelection);

            const organizationManagersTableData: any = [];
            for (let i = 0; i < userRoles.length; i++) {
                const userRole: any = userRoles[i];
                const selected = organizationManagers.includes(userRole.id);
                if (selected || props.session.permissions.update) {
                    userRole.selected = selected ? "Yes" : "No";
                    organizationManagersTableData.push(userRole);
                }
            }
            setOrganizationManagersTableData(organizationManagersTableData);
            setOrganizationDepartmentsTableData(organizationDepartments);
            setOrganizationRolesTableData(organizationRoles);

            const organizationUsersTableData: any = [];
            for (let i = 0; i < userRoles.length; i++) {
                const userRole = userRoles[i];
                if (userRole.role.organization_id == params.id) {
                    organizationUsersTableData.push(userRole);
                }
            }

            setOrganizationUsersTableData(organizationUsersTableData);
            setAuthorizations(authorizations);

            const portalPolicyOptions: any = ReactElementCommons.createReactSelectOptionsFromList(policies, "name", "id");
            setPortalPolicyOptions(portalPolicyOptions);
            setSelectedPortalPolicyOption(portalPolicyOptions.find((p: any) => organization.portal_policy_id === p.value));

            const emailOptions: any = ReactElementCommons.createReactSelectOptionsFromList(emails, "email", "id");
            setEmailOptions(emailOptions);
            setSelectedEmailOption(emailOptions.find((e: any) => organization.support_email_id === e.value));
        }
    }

    async function updateOrganization(event: any) {
        event.preventDefault();
        try {
            setIsUpdating(true);
            setAlert({
                class: "d-none",
                message: ""
            });

            const formData: any = new FormData(event.target);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/organizations/" + organization.id;
            await RequestCommons.request(url, null, payload, "PUT");

            if (originalOrganizationManagersRowSelection != organizationManagersRowSelection) {
                const managerIds: any = [];
                for (let managerId in organizationManagersRowSelection) {
                    managerIds.push(parseInt(managerId));
                }
                await replaceOrganizationManagers(organization.id, managerIds);
            }

            await loadData();
            setAlert({
                class: "alert-success text-center",
                message: "Updated."
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsUpdating(false);
        }
    }

    async function replaceOrganizationManagers(organizationId: any, userRoleIds: any) {
        const entries = [];
        for (let i = 0; i < userRoleIds.length; i++) {
            entries.push({
                "organization_id": organizationId,
                "user_role_id": userRoleIds[i]
            });
        }
        const body: any = JSON.stringify(entries);
        let url = process.env.REACT_APP_AUTH_API + "/organizations/managers?organization_id=" + params.id;
        await RequestCommons.request(url, null, body, "put", "application/json").then(async function () {
            console.log("Replaced organization managers.");
        }).catch(function (error) {
            console.error(error);
        });
    }

    const organizationManagersTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'User Role Id',
            size: 70
        },
        {
            accessorKey: 'user.first_name',
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'email.email',
            header: 'Email',
        },
        {
            accessorKey: 'role.organization.name',
            header: 'Organization',
        }
    ];

    const organizationDepartmentsTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'created',
            header: 'Created',
            size: 100
        }
    ];

    const organizationRolesTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'department.name',
            header: 'Department',
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'created',
            header: 'Created',
            size: 100
        }
    ];

    const organizationUsersTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'user.id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'user.first_name',
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'email.email',
            header: 'Email',
        },
        {
            accessorKey: 'role.organization.name',
            header: 'Organization',
        },
        {
            accessorKey: 'created',
            header: 'Created',
            size: 100
        }
    ];

    function renderOrganizationDepartmentRowActions(data: any) {
        const id = data.row.original.id;
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton to={"/departments/" + id}/>
        </Box>;
    }

    function renderOrganizationRoleRowActions(data: any) {
        const id = data.row.original.id;
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton to={"/organizations/" + id}/>
        </Box>;
    }

    function renderOrganizationManagerRowActions(data: any) {
        const id = data.row.original.id;
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton to={"/users/roles/" + id}/>
        </Box>;
    }

    function renderOrganizationUserRowActions(data: any) {
        const id = data.row.original.id;
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton to={"/users/" + id}/>
        </Box>;
    }

    if (isLoading) {
        return <Loading/>;
    } else if (!organizationExists) {
        return <DoesNotExist/>;
    } else {
        let buttonText: any = "Update";
        if (isUpdating) {
            buttonText = <span>
                    <span className={"loading-spinner"}>
                        <i className="bi bi-arrow-clockwise"></i>
                    </span>
                    <span className={"ms-2"}>Updating...</span>
                </span>;
        }

        let logoPreview = null;
        if (logo) {
            logoPreview = <div className="mb-3 d-none">
                <label className="form-label">Logo Preview</label>
                <div className={"p-5 mt-3 border text-center"}>
                    <img src={logo} style={{
                        width: "100%",
                        height: "100px",
                        objectFit: "contain"
                    }} alt={"Invalid URL"}/>
                </div>
            </div>;
        }

        const managersSelected = Object.values(organizationManagersRowSelection).filter(i => i === true).length;
        const departmentsSelected = organizationDepartmentsTableData.length;
        const rolesSelected = organizationRolesTableData.length;
        const userRolesSelected = organizationUsersTableData.length;

        return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " container m-auto w-100"}>
            <form onSubmit={updateOrganization}>
                <h2>Organization</h2>
                <div className={"row"}>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Id</label>
                        <input name="id" className="form-control" defaultValue={organization.id}
                               disabled/>
                    </div>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Name</label>
                        <input name="name" className="form-control" defaultValue={organization.name} required
                               disabled={!props.session.permissions.update}/>
                        <small>Required</small>
                    </div>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Short Name</label>
                        <input name="short_name" className="form-control"
                               disabled={!props.session.permissions.update}
                               defaultValue={organization.short_name}/>
                    </div>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Disabled</label>
                        <ReactSelectDisabled required value={organization.disabled}
                                             isDisabled={!props.session.permissions.update}/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-lg-4 mb-3"}>
                        <label className="form-label">Portal Policy</label>
                        <Select
                            name="portal_policy_id"
                            options={portalPolicyOptions}
                            onChange={function (option) {
                                setSelectedPortalPolicyOption(option);
                            }}
                            value={selectedPortalPolicyOption}
                        />
                    </div>
                    <div className={"col-lg-4 mb-3"}>
                        <label className="form-label">Support Email</label>
                        <Select
                            name="support_email_id"
                            options={emailOptions}
                            onChange={function (option) {
                                setSelectedEmailOption(option);
                            }}
                            value={selectedEmailOption}
                        />
                    </div>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Created</label>
                        <input value={TimeCommons.formatTimestamp(organization.created)}
                               className="form-control" disabled/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className="col mb-3">
                        <label className="form-label">Description</label>
                        <textarea name="description" className="form-control"
                                  disabled={!props.session.permissions.update}
                                  defaultValue={organization.description}/>
                    </div>
                    <div className="mb-3 d-none">
                        <label className="form-label">Logo</label>
                        <input name={"logo"} className="form-control" defaultValue={logo}
                               disabled={!props.session.permissions.update}
                               placeholder={"Enter a URL"}
                               onKeyUp={function (event: any) {
                                   setLogo(event.target.value);
                               }}/>
                    </div>
                    {logoPreview}
                </div>
                <Accordion defaultActiveKey={[]} className={"mb-3"}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Managers ({managersSelected})
                        </Accordion.Header>
                        <Accordion.Body>
                            <MaterialReactTable
                                muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                columns={organizationManagersTableColumns}
                                data={organizationManagersTableData}
                                enableSelectAll={false}
                                enableRowSelection={props.session.permissions.update}
                                onRowSelectionChange={setOrganizationManagersRowSelection}
                                state={{
                                    rowSelection: organizationManagersRowSelection,
                                    isLoading: false,
                                    density: "compact"
                                }}
                                enableRowActions={true}
                                renderRowActions={renderOrganizationManagerRowActions}
                                initialState={{
                                    columnVisibility: {
                                        selected: false
                                    },
                                    sorting: [{
                                        id: "selected",
                                        desc: true
                                    }, {
                                        id: "user.last_name",
                                        desc: false
                                    }, {
                                        id: "user.first_name",
                                        desc: false
                                    }]
                                }}
                                getRowId={(originalRow: any) => originalRow.id}
                                renderTopToolbarCustomActions={() => {
                                    return (
                                        <h2 className={"me-3 d-inline-block align-top"}>Managers
                                            ({managersSelected})</h2>
                                    );
                                }}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            Departments ({departmentsSelected})
                        </Accordion.Header>
                        <Accordion.Body>
                            <MaterialReactTable
                                muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                columns={organizationDepartmentsTableColumns}
                                data={organizationDepartmentsTableData}
                                state={{
                                    isLoading: false,
                                    density: "compact"
                                }}
                                enableRowActions={true}
                                renderRowActions={renderOrganizationDepartmentRowActions}
                                initialState={{
                                    columnVisibility: {
                                        selected: false
                                    },
                                    sorting: [{
                                        id: "selected",
                                        desc: true
                                    }]
                                }}
                                getRowId={(originalRow: any) => originalRow.id}
                                renderTopToolbarCustomActions={() => {
                                    return (
                                        <h2 className={"me-3 d-inline-block align-top"}>Departments
                                            ({departmentsSelected})</h2>
                                    );
                                }}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            Roles ({rolesSelected})
                        </Accordion.Header>
                        <Accordion.Body>
                            <MaterialReactTable
                                muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                columns={organizationRolesTableColumns}
                                data={organizationRolesTableData}
                                state={{
                                    isLoading: false,
                                    density: "compact"
                                }}
                                enableRowActions={true}
                                renderRowActions={renderOrganizationRoleRowActions}
                                initialState={{
                                    columnVisibility: {
                                        selected: false
                                    },
                                    sorting: [{
                                        id: "selected",
                                        desc: true
                                    }]
                                }}
                                getRowId={(originalRow: any) => originalRow.id}
                                renderTopToolbarCustomActions={() => {
                                    return (
                                        <h2 className={"me-3 d-inline-block align-top"}>Roles ({rolesSelected})</h2>
                                    );
                                }}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>
                            User Roles ({userRolesSelected})
                        </Accordion.Header>
                        <Accordion.Body>
                            <MaterialReactTable
                                muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                columns={organizationUsersTableColumns}
                                data={organizationUsersTableData}
                                state={{
                                    isLoading: false,
                                    density: "compact"
                                }}
                                enableRowActions={true}
                                renderRowActions={renderOrganizationUserRowActions}
                                initialState={{
                                    columnVisibility: {
                                        selected: false
                                    },
                                    sorting: [{
                                        id: "selected",
                                        desc: true
                                    }, {
                                        id: "user.last_name",
                                        desc: false
                                    }, {
                                        id: "user.first_name",
                                        desc: false
                                    }, {
                                        id: "role.name",
                                        desc: false
                                    }]
                                }}
                                getRowId={(originalRow: any) => originalRow.id}
                                renderTopToolbarCustomActions={() => {
                                    return (
                                        <h2 className={"me-3 d-inline-block align-top"}>User Roles
                                            ({userRolesSelected})</h2>
                                    );
                                }}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <div className={"mt-3"}>
                    <div className={"alert " + alert.class}>{alert.message}</div>
                </div>
                <div className={"row mt-3"}>
                    <div className={"col mt-2"}>
                        <ReturnToLastPageLink/>
                    </div>
                    <div className={"col text-end"}>
                        {props.session.permissions.update ?
                            <button type="submit" className="btn btn-warning ps-5 pe-5"
                                    disabled={isUpdating}>{buttonText}</button> : null}
                    </div>
                </div>
            </form>
        </div>
            ;
    }
}