import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {MaterialReactTable} from "material-react-table";
import Select from "react-select";
import AuthCommons from "../../commons/AuthCommons";
import ReactElementCommons from "../../commons/ReactElementCommons";
import TimeCommons from "../../commons/TimeCommons";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";

export default function ViewLoginLogs(props: any) {
    const [isLoading, setIsLoading]: any = useState(true);
    const [logTableData, setLogTableData]: any = useState([]);
    const [userOptions, setUserOptions]: any = useState([]);
    const [selectedUserOption, setSelectedUserOption]: any = useState(null);
    const [startDate, setStartDate]: any = useState(TimeCommons.toYmd(TimeCommons.toDate()));
    const [endDate, setEndDate]: any = useState(TimeCommons.toYmd(TimeCommons.toDate()));
    const [limit, setLimit]: any = useState(500);

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        loadLogs();
    }, [selectedUserOption, startDate, endDate]);

    async function init() {
        await loadData();
    }

    async function loadData() {
        setIsLoading(true);
        await loadUsers();
        await loadLogs();
        setIsLoading(false);
    }

    async function loadLogs() {
        const params: any = {
            limit: limit,
            order_by: "id",
            order_by_direction: "desc",
            populate: "session,user,user_role,user_role.role,user_role.role.department,user_role.role.organization,user_role.email"
        }
        if (startDate) {
            params["created_start_date"] = startDate;
            delete params["limit"];
        }
        if (endDate) {
            params["created_end_date"] = endDate;
            delete params["limit"];
        }
        if (selectedUserOption) {
            params["user_id"] = selectedUserOption.value;
            delete params["limit"];
        }

        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        const url = process.env.REACT_APP_AUTH_API + "/logs/pages/accessed?" + queryString;
        const logs: any = await RequestCommons.request(url);
        const logTableData: any = [];
        for (let i = 0; i < logs.length; i++) {
            const log: any = logs[i];
            log.created = TimeCommons.formatTimestamp(log.created);
            logTableData.push(log);
        }
        setLogTableData(logTableData);
    }

    async function loadUsers() {
        const users: any = await AuthCommons.request("/users");
        users.sort((a: any, b: any) => {
            return a.last_name.localeCompare(b.last_name) || a.first_name.localeCompare(b.first_name);
        });
        const userOptions: any = ReactElementCommons.createReactSelectOptionsFromList(users, ["first_name", "last_name"], "id");
        setUserOptions(userOptions);
    }

    const loginColumns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'user.first_name',
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'user_role.email.email', //normal accessorKey
            header: 'Email',
        },
        {
            accessorKey: 'user_role.role.organization.name', //normal accessorKey
            header: 'Org',
        },
        {
            accessorKey: 'pathname', //normal accessorKey
            header: 'Pathname',
        },
        {
            accessorKey: 'created',
            header: 'Created',
            size: 100
        }
    ];

    return <div className={"m-auto container bg-white border shadow-sm rounded p-4"}>
        <div className={"mb-3 d-none"}>
            <ReturnToLastPageLink/>
        </div>
        <h2 className={"d-inline-block me-5"}>
            <i className="bi bi-file-text-fill me-3 align-middle"/>
            <span>Page Logs</span>
        </h2>
        <form className={"d-inline-block position-relative"} style={{
            zIndex: 999
        }} onSubmit={async function (event: any) {
            event.preventDefault();
            await loadLogs();
        }}>
            <div className={"mb-3 me-3 d-inline-block"} style={{
                minWidth: "300px"
            }}>
                <label>User</label>
                <Select
                    options={userOptions}
                    onChange={setSelectedUserOption}
                    value={selectedUserOption}
                />
            </div>
            <div className={"mb-3 me-3 d-inline-block"}>
                <label>Start Date</label>
                <input
                    type={"date"}
                    name={"start-date"}
                    className={"form-control"}
                    onChange={function (event: any) {
                        setStartDate(event.target.value);
                    }}
                    onClick={function (event: any) {
                        event.target.showPicker();
                    }}
                    value={startDate}
                />
            </div>
            <div className={"mb-3 me-3 d-inline-block"}>
                <label>End Date</label>
                <input
                    type={"date"}
                    name={"end-date"}
                    className={"form-control"}
                    onChange={function (event: any) {
                        setEndDate(event.target.value);
                    }}
                    onClick={function (event: any) {
                        event.target.showPicker();
                    }}
                    value={endDate}
                />
            </div>
        </form>
        <MaterialReactTable
            muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
            enableStickyHeader={true}
            columns={loginColumns}
            data={logTableData}
            initialState={{
                showColumnFilters: true,
                sorting: [{
                    id: "id",
                    desc: true
                }],
                pagination: {
                    pageSize: 100,
                    pageIndex: 0
                }
            }}
            state={{
                isLoading: isLoading,
                density: "compact"
            }}
        />
        <div className={"mt-3 d-none"}>
            <ReturnToLastPageLink/>
        </div>
    </div>;
}