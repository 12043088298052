import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {MaterialReactTable} from "material-react-table";
import TimeCommons from "../../commons/TimeCommons";

export default function ViewSessions(props: any) {
    const [isLoading, setIsLoading]: any = useState(true);
    const [tableData, setTableData]: any = useState([]);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const params: any = {
            limit: 500,
            order_by: "id",
            order_by_direction: "desc",
            populate: 1
        }
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        const url = process.env.REACT_APP_AUTH_API + "/sessions?" + queryString;
        const sessions: any = await RequestCommons.request(url);
        for (let i = 0; i < sessions.length; i++) {
            const session = sessions[i];
            session.created_formatted = TimeCommons.formatTimestamp(session.created);
            if (session.updated) {
                session.updated_formatted = TimeCommons.formatTimestamp(session.updated);
                session.elapsed_formatted = TimeCommons.getTimeElapsed(session.created, session.updated);
            } else {
                session.elapsed_formatted = TimeCommons.getTimeElapsed(session.created);
            }
            session.cross_org = session.user_role.role.organization.id !== session.policy.organization.id ? "Yes" : "No";
            session.cross_org_details = session.cross_org ? "Role/Policy" : "";
            session.ttl = session.ttl ? TimeCommons.elapsedFromSeconds(session.ttl) : null;
            session.disabled = session.disabled ? "Yes" : "No";
        }
        setTableData(sessions);
    }

    const columns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'cross_org',
            header: 'Cross-Org',
        },
        {
            accessorKey: 'cross_org_details',
            header: 'Cross-Org Violation',
        },
        {
            accessorKey: 'user.first_name',
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'user_role.role.organization.name',
            header: 'Role Org',
        },
        {
            accessorKey: 'user_role.email.email',
            header: 'Role Email',
        },
        {
            accessorKey: 'remote_addr',
            header: 'IP',
        },
        {
            accessorKey: 'user_agent',
            header: 'User-Agent',
        },
        {
            accessorKey: 'policy.name',
            header: 'Policy',
        },
        {
            accessorKey: 'policy.resource.name',
            header: 'Resource',
        },
        {
            accessorKey: 'policy.resource.location',
            header: 'Location',
        },
        {
            accessorKey: 'policy.organization.name',
            header: 'Organization',
        },
        {
            accessorKey: 'policy.domain.name',
            header: 'Domain',
        },
        {
            accessorKey: 'policy.product.name',
            header: 'Product',
        },
        {
            accessorKey: 'ttl',
            header: 'Time-To-Live',
        },
        {
            accessorKey: 'disabled',
            header: 'Disabled',
            size: 100
        },
        {
            accessorKey: 'created_formatted',
            header: 'Created',
            size: 100
        },
        {
            accessorKey: 'updated_formatted',
            header: 'Updated',
            size: 100
        },
        {
            accessorKey: 'elapsed_formatted',
            header: 'Elapsed',
            size: 100
        }
    ];


    return <div className={"bg-white border shadow-sm rounded container p-4"}>
        <MaterialReactTable
            muiTablePaperProps={props.config.materialReactTable.muiTablePaperProps}
            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
            enableStickyHeader={true}
            columns={columns}
            data={tableData}
            initialState={{
                sorting: [{
                    id: "id",
                    desc: true
                }]
            }}
            state={{
                isLoading: isLoading,
                density: "compact"
            }}
            renderTopToolbarCustomActions={() => {
                return (
                    <div>
                        <h2 className={"me-3 d-inline-block align-top"}>Sessions</h2>
                    </div>
                );
            }}
            muiTableBodyRowProps={function (row: any) {
                return {
                    sx: {
                        background: row.row.original.cross_org === "Yes" ? "#f1e4bc" : ""
                    }
                };
            }}
        />
    </div>;
}