import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {Link, useNavigate} from "react-router-dom";
import AuthCommons from "../../commons/AuthCommons";
import Select from "react-select";
import ReactElementCommons from "../../commons/ReactElementCommons";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";
import {Accordion} from "react-bootstrap";

export default function CreatePolicy(props: any) {
    const navigate = useNavigate();

    const [alert, setAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [isRequesting, setIsRequesting] = useState(false);
    const [organizationOptions, setOrganizationOptions]: any = useState([]);
    const [resourceOptions, setResourceOptions]: any = useState([]);
    const [productOptions, setProductOptions]: any = useState([]);
    const [domainOptions, setDomainOptions]: any = useState([]);
    const [policyTypeOptions, setPolicyTypeOptions]: any = useState([]);
    const [selectedPolicyTypeOption, setSelectedPolicyTypeOption]: any = useState(null);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
    }

    async function loadData() {
        const [organizations, domains, products, resources, policyTypes]: any = await Promise.all([
            AuthCommons.request("/organizations"),
            AuthCommons.request("/domains"),
            AuthCommons.request("/products"),
            AuthCommons.request("/resources"),
            AuthCommons.request("/policies/types")
        ]);

        const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id", " ", true);
        setOrganizationOptions(organizationOptions);

        const domainOptions: any = ReactElementCommons.createReactSelectOptionsFromList(domains, "name", "id", " ", true);
        setDomainOptions(domainOptions);

        const productOptions: any = ReactElementCommons.createReactSelectOptionsFromList(products, "name", "id", " ", true);
        setProductOptions(productOptions);

        const resourceOptions: any = ReactElementCommons.createReactSelectOptionsFromList(resources, "name", "id", " ", true);
        setResourceOptions(resourceOptions);

        const policyTypeOptions: any[] = ReactElementCommons.createReactSelectOptionsFromList(policyTypes, "name", "id");
        setPolicyTypeOptions(policyTypeOptions);
        setSelectedPolicyTypeOption(policyTypeOptions[0]);
    }

    async function createPolicy(event: any) {
        event.preventDefault();
        try {
            setIsRequesting(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const form: any = event.target;
            const formData: any = new FormData(form);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/policies";
            const id = await RequestCommons.request(url, null, payload, "POST");
            setAlert({
                class: "alert-success text-center",
                message: <>
                    <div>Created policy.</div>
                    <div>
                        <Link className={"text-decoration-none"} to={"/policies/" + id}>Click here to update
                            policy.</Link>
                    </div>
                </>
            });
            navigate("/policies/" + id);
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsRequesting(false);
        }
    }

    let buttonText: any = "Create";
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Creating...</span>
            </span>;
    }

    return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " container m-auto"} style={{
        maxWidth: "1000px"
    }}>
        <div className={"mb-3 d-none"}>
            <ReturnToLastPageLink/>
        </div>
        <div className={"d-inline-block align-middle"}>
            <h2 className={"d-inline-block align-middle me-3"}>Create Policy</h2>
            <div className={"d-inline-block align-middle"}>
                <i className="text-danger bi bi-exclamation-triangle-fill"
                   title={"Consult with Engineering before creating a policy."}/>
            </div>
        </div>
        <form onSubmit={createPolicy}>
            <div className={"row"}>
                <div className="col-12 col-lg mb-3">
                    <label className="form-label">Name</label>
                    <input name="name" className="form-control" required autoFocus
                           placeholder={"Example: C3 Users - SBB"}
                    />
                    <small>Required</small>
                </div>
                <div className="col-12 col-lg mb-3">
                    <div className={""}>
                        <label className="form-label me-2">Type</label>
                        <div className={"d-inline-block"}>
                            <i
                                className="bi bi-info-circle-fill text-primary me-2"
                                title={"Consult with Engineering for more information on Policy Types."}
                            />
                        </div>
                    </div>
                    <Select
                        name="policy_type_id"
                        options={policyTypeOptions}
                        onChange={setSelectedPolicyTypeOption}
                        value={selectedPolicyTypeOption}
                        required
                    />
                    <small>Required</small>
                </div>
                <div className="col-12 col-lg mb-3">
                    <label className="form-label">Disabled</label>
                    <ReactSelectDisabled required/>
                </div>
            </div>
            <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea name="description"
                          className="form-control"
                          placeholder={"Enter a brief description about what the policy controls."}
                          required/>
                <small>Required</small>
            </div>
            <Accordion className={"mb-3"} defaultActiveKey={[]}>
                <Accordion.Item eventKey="optional">
                    <Accordion.Header>
                        Optional Settings
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className={"row"}>
                            <div className="col-12 col-lg mb-3">
                                <label className="form-label">Organization</label>
                                <Select
                                    name="organization_id"
                                    options={organizationOptions}
                                />
                                <small>Optional</small>
                            </div>
                            <div className="col-12 col-lg mb-3">
                                <label className="form-label">Domain</label>
                                <Select
                                    name="domain_id"
                                    options={domainOptions}
                                />
                                <small>Optional</small>
                            </div>
                            <div className="col-12 col-lg mb-3">
                                <label className="form-label">Product</label>
                                <Select
                                    name="product_id"
                                    options={productOptions}
                                />
                                <small>Optional</small>
                            </div>
                            <div className="col-12 col-lg mb-3">
                                <label className="form-label">Resource</label>
                                <Select
                                    name="resource_id"
                                    options={resourceOptions}
                                />
                                <small>Optional</small>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-12 col-lg mb-3">
                                <label className="form-label">URL</label>
                                <input name={"url"} className="form-control"/>
                                <small>Optional</small>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <div className={"mt-3"}>
                <div className={"alert text-center " + alert.class}>{alert.message}</div>
            </div>
            <div className={"row mt-3"}>
                <div className={"col"}>
                    <ReturnToLastPageLink/>
                </div>
                <div className={"col text-end"}>
                    <button type="submit" className="btn btn-primary ps-5 pe-5"
                            disabled={isRequesting}>{buttonText}</button>
                </div>
            </div>
        </form>
    </div>;
}