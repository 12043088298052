import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {Link} from "react-router-dom";
import AuthCommons from "../../commons/AuthCommons";
import Select from "react-select";
import ReactElementCommons from "../../commons/ReactElementCommons";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";

export default function CreateRole(props: any) {
    const [alert, setAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [isRequesting, setIsRequesting] = useState(false);
    const [organizationOptions, setOrganizationOptions]: any = useState([]);
    const [organizationSelect, setOrganizationSelect]: any = useState(null);
    const [departmentOptions, setDepartmentOptions]: any = useState([]);
    const [departmentSelect, setDepartmentSelect]: any = useState(null);
    const [departments, setDepartments]: any = useState([]);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
    }

    async function loadData() {
        const [organizations, departments]: any = await Promise.all([
            AuthCommons.request("/organizations"),
            AuthCommons.request("/departments")
        ]);

        const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id");
        setOrganizationOptions(organizationOptions);

        setDepartments(departments);
    }

    async function createRole(event: any) {
        event.preventDefault();
        try {
            setIsRequesting(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const form: any = event.target;
            const formData: any = new FormData(form);
            const payload: any = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/roles";
            const id = await RequestCommons.request(url, null, payload, "POST");
            setAlert({
                class: "alert-success text-center",
                message: <>
                    <div>Created role.</div>
                    <div>
                        <Link className={"text-decoration-none"} to={"/roles/" + id}>Click here to update role.</Link>
                    </div>
                </>
            });
        } catch(error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsRequesting(false);
        }
    }

    function organizationSelectOnChange(option: any) {
        setOrganizationSelect(option);
        setDepartmentSelect(null);

        const organizationId = option.value;
        const departmentOptions: any = [];
        if (organizationId) {
            for (let i = 0; i < departments.length; i++) {
                const department = departments[i];
                if (department.organization_id && department.organization_id === organizationId) {
                    const departmentOption = {
                        label: department.name,
                        value: department.id
                    };
                    departmentOptions.push(departmentOption);
                }
            }
        }
        setDepartmentOptions(departmentOptions);
    }

    let buttonText: any = "Create";
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Creating...</span>
            </span>;
    }

    return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
        <form onSubmit={createRole}>
            <h2>Create Role</h2>
            <div className="mb-3">
                <label className="form-label">Organization</label>
                <Select
                    name="organization_id"
                    onChange={organizationSelectOnChange}
                    options={organizationOptions}
                    value={organizationSelect}
                    required
                />
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Department</label>
                <Select
                    name="department_id"
                    isDisabled={!departmentOptions.length}
                    options={departmentOptions}
                    value={departmentSelect}
                    onChange={setDepartmentSelect}
                    required
                />
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Name</label>
                <input name="name" className="form-control" required/>
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea name="description"
                          className="form-control"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Disabled</label>
                <ReactSelectDisabled required/>
            </div>
            <button type="submit" className="btn btn-primary form-control"
                    disabled={isRequesting}>{buttonText}</button>
            <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
        </form>
        <div className={"row mt-3"}>
            <div className={"col"}>
                <ReturnToLastPageLink/>
            </div>
        </div>
    </div>;
}