import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../commons/RequestCommons"
import {Link, useParams} from "react-router-dom";
import AuthCommons from "../../../commons/AuthCommons";
import ObjectCommons from "../../../commons/ObjectCommons";
import Select from "react-select";
import ReactElementCommons from "../../../commons/ReactElementCommons";
import ReactSelectDisabled from "../../../commons/ReactSelectDisabled";

export default function CreateUserEmail(props: any) {
    const [alert, setAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [isRequesting, setIsRequesting] = useState(false);
    const [userOptions, setUserOptions]: any = useState([]);
    const [organizationOptions, setOrganizationOptions]: any = useState([]);
    const [usersById, setUsersById] = useState([]);
    const [userEmailsByEmail, setUserEmailsByEmail]: any = useState({});
    const [emailAssigned, setEmailAssigned]: any = useState(false);
    const [emailAssignee, setEmailAssignee]: any = useState(null);
    const [emailTypeOptions, setEmailTypeOptions]: any = useState([]);
    const params = useParams();
    const userId = params.hasOwnProperty("id") && params.id ? parseInt(params.id) : null;

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
    }

    async function loadData() {
        const [users, organizations, userEmails, emailTypes]: any = await Promise.all([
            AuthCommons.request("/users?disabled=0&order_by=last_name&order_by_direction=asc"),
            AuthCommons.request("/organizations"),
            AuthCommons.request("/users/emails?populate=1"),
            AuthCommons.request("/emails/types")
        ]);

        const usersById: any = ObjectCommons.mapEntriesById(users);
        setUsersById(usersById);

        const userOptions: any = ReactElementCommons.createReactSelectOptionsFromList(users, ["first_name", "last_name"], "id");
        setUserOptions(userOptions);

        const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id", " ", true);
        setOrganizationOptions(organizationOptions);

        let userEmailsByEmail: any = {};
        for (let i = 0; i < userEmails.length; i++) {
            const userEmail = userEmails[i];
            userEmailsByEmail[userEmail.email.email] = userEmail;
        }
        setUserEmailsByEmail(userEmailsByEmail);

        const emailTypeOptions: any = ReactElementCommons.createReactSelectOptionsFromList(emailTypes, "name", "id");
        setEmailTypeOptions(emailTypeOptions);
    }

    async function createUserEmail(event: any) {
        event.preventDefault();
        try {
            setIsRequesting(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const form: any = event.target;
            const formData: any = new FormData(form);
            const {user_id} = Object.fromEntries(formData);
            formData.delete("user_id");
            const payload = new URLSearchParams(formData);
            const endpoint = user_id ? "/users/" + user_id + "/emails" : "/users/" + params.id + "/emails";
            const url = process.env.REACT_APP_AUTH_API + endpoint;
            const id = await RequestCommons.request(url, null, payload, "POST");
            setAlert({
                class: "alert-success text-center",
                message: <>
                    <div>Created user email.</div>
                    <div>
                        <Link to={user_id ? "/users/" + user_id : "/users/" + params.id}>Click here to update
                            user.</Link>
                    </div>
                </>
            });
        } catch(error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsRequesting(false);
        }
    }

    function checkEmailAssigned(email: string) {
        const emailAssigned: any = Object.keys(userEmailsByEmail).includes(email);
        setEmailAssigned(emailAssigned);
        if (emailAssigned) {
            const userEmail = userEmailsByEmail[email];
            const user = usersById[userEmail.user_id];
            setEmailAssignee(user);
        }

    }

    let buttonText: any = "Create";
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Creating...</span>
            </span>;
    }

    const returnLink = params.id ? "/users/" + params.id : "/users/emails";
    const returnLinkText = params.id ? "Return to User" : "Return to User Emails";

    let emailFieldDescription: any = <small>Required</small>;
    let emailFieldClassName = "form-control";
    if (emailAssigned) {
        emailFieldClassName += " is-invalid text-danger";
        emailFieldDescription = <small className={"text-danger"}>Email already assigned
            to <Link
                to={"/users/" + emailAssignee.id}>{emailAssignee.first_name + " " + emailAssignee.last_name}</Link>.
            Remove email from user first to continue.</small>;
    }

    return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
        <form onSubmit={createUserEmail}>
            <h1>Create User Email</h1>
            <div className="mb-3">
                <label className="form-label">User</label>
                <Select
                    name="user_id"
                    isDisabled={userId !== null}
                    value={userOptions.find((option: any) => option.value === userId)}
                    options={userOptions}
                    required
                />
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Email</label>
                <input name="email" type={"email"}
                       className={emailFieldClassName}
                       onChange={function (event: any) {
                           const email = event.target.value;
                           checkEmailAssigned(email);
                       }}/>
                {emailFieldDescription}
            </div>
            <div className="mb-3">
                <label className="form-label">Type</label>
                <Select
                    name="email_type_id"
                    options={emailTypeOptions}
                    required
                />
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Organization</label>
                <Select
                    name="organization_id"
                    options={organizationOptions}
                />
                <small>Optional</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Disabled</label>
                <ReactSelectDisabled required/>
            </div>
            <div className="form-check mb-3 d-none">
                <input className={"form-check-input"} type="checkbox" name="primary" value={1}/>
                <label className="form-check-label">Primary</label>
            </div>
            <button type="submit" className={"btn form-control " + (emailAssigned ? "btn-danger" : "btn-primary")}
                    disabled={isRequesting || emailAssigned}>{buttonText}</button>
            <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
        </form>
        <div className={"row mt-3"}>
            <div className={"col"}>
                <Link to={returnLink} style={{textDecoration: "none"}}>
                    <i className="bi bi-arrow-left"></i>
                    <span className={"ms-2"}>{returnLinkText}</span>
                </Link>
            </div>
        </div>
    </div>;
}