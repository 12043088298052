import {MaterialReactTable} from "material-react-table";
import * as React from "react";
import { useNavigate } from "react-router-dom";

export default function AuthorizationsTable(props: any) {
    const navigate = useNavigate();

    for (let i = 0; i < props.authorizations.length; i++) {
        const authorization = props.authorizations[i];
        authorization.user_label_formatted = "[" + authorization.role.organization.short_name + "] ";
        authorization.user_label_formatted += authorization.user_role_email;
        authorization.policy_label_formatted = authorization.policy_name;
        if (authorization.policy?.organization?.short_name) {
            authorization.policy_label_formatted = "[" + authorization.policy.organization.short_name + "] " + authorization.policy_label_formatted;
        }
        authorization.group_label_formatted = "[" + authorization.group.organization.short_name + "] ";
        authorization.group_label_formatted += authorization.group.name;
        authorization.cross_org = authorization.user_role_policy_organization_mismatch ? "Yes" : "No";
        authorization.cross_org_details = authorization.user_role_policy_organization_mismatch ? "Role/Policy" : "Clear";
    }

    const defaultPageSize = 10;
    let columns: any = [
        {
            accessorKey: 'user.first_name', //access nested data with dot notation
            header: 'First Name',
            size: 75
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
            size: 75
        },
        {
            accessorKey: 'user_label_formatted',
            header: 'User Role',
            size: 50
        },
        {
            accessorKey: 'group_label_formatted', //access nested data with dot notation
            header: 'Group'
        },
        {
            accessorKey: 'policy_label_formatted',
            header: 'Policy',
        },
        {
            accessorKey: 'policy.description',
            header: 'Description',
            size: 250
        }
    ];

    const showGroup = !Object.keys(props).includes("showGroup") || props.showGroup === true;
    const showName = !Object.keys(props).includes("showName") || props.showName === true;
    const showUserRole = !Object.keys(props).includes("showUserRole") || props.showUserRole === true;

    const initialState = {
        sorting: [
            {
                id: 'user_label_formatted', //sort by age by default on page load
                desc: false
            },
            {
                id: 'policy_label_formatted', //sort by age by default on page load
                desc: false
            }
        ],
        pagination: {
            pageSize: defaultPageSize,
            pageIndex: 0
        }
    };

    return <MaterialReactTable
        muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
        muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
        enableStickyHeader={true}
        columns={columns}
        data={props.authorizations}
        state={{
            isLoading: props.isLoading,
            density: "compact",
            columnVisibility: {
                "group_label_formatted": showGroup,
                "user.first_name": showName,
                "user.last_name": showName,
                "user_label_formatted": showUserRole
            }
        }}
        muiTableBodyCellProps={{
            sx: {
                whiteSpace: "wrap",
                textWrap: "break-word"
            },
        }}
        enablePagination={true}
        initialState={initialState}
        renderTopToolbarCustomActions={() => {
            const title = "Highlighted rows show a cross-organizational authorization.";
            return (
                <div className={"d-inline-block align-middle"}>
                    <h2 className={"me-3 d-inline-block align-middle"}>
                        <span>Authorizations</span>
                    </h2>
                    {props.session.permissions.update ?
                        <i className="text-primary bi bi-info-circle-fill"
                           title={title}/> : null}
                </div>
            );
        }}
        muiTableBodyRowProps={function (row: any) {
            return {
                onClick: (event) => {
                    window.open("/groups/" + row.row.original.group_id, "_blank");
                },
                sx: {
                    cursor: 'pointer',
                    background: row.row.original.cross_org === "Yes" ? "#f1e4bc" : ""
                }
            };
        }}
    />
}