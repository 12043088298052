import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {useParams} from "react-router-dom";
import AuthCommons from "../../commons/AuthCommons";
import Select from "react-select";
import ReactElementCommons from "../../commons/ReactElementCommons";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";
import Loading from "../Loading";
import DoesNotExist from "../shared/DoesNotExist";

export default function UpdateResource(props: any) {
    const [alert, setAlert] = useState({
        class: "d-none",
        message: ""
    });
    const [resourceExists, setResourceExists] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [resource, setResource]: any = useState({});
    const [organizationOptions, setOrganizationOptions] = useState([]);
    const [organizationSelect, setOrganizationSelect]: any = useState(null);
    const [typeOptions, setTypeOptions] = useState([]);
    const [typeSelect, setTypeSelect]: any = useState(null);

    const params = useParams();

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setIsLoading(true);
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [response]: any = await Promise.all([
            AuthCommons.request("/resources/" + params.id, null, null, 'get', 'application/x-www-form-urlencoded', false)
        ]);
        if (response.status === 204) {
            setResourceExists(false);
        } else {
            setResourceExists(true);
            const resource: any = await response.json();
            setResource(resource);
            const [organizations, types]: any = await Promise.all([
                AuthCommons.request("/organizations"),
                AuthCommons.request("/resources/types")
            ]);

            const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id");
            setOrganizationOptions(organizationOptions);
            setOrganizationSelect(organizationOptions.find((option: any) => option.value === resource.organization_id));

            const typeOptions: any = ReactElementCommons.createReactSelectOptionsFromList(types, "name", "id");
            setTypeOptions(typeOptions);
            setTypeSelect(typeOptions.find((option: any) => option.value === resource.resource_type_id));
        }
    }

    async function updateResource(event: any) {
        event.preventDefault();
        try {
            setIsUpdating(true);
            setAlert({
                class: "d-none",
                message: ""
            });

            const formData: any = new FormData(event.target);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/resources/" + resource.id;
            await RequestCommons.request(url, null, payload, "PUT");
            await loadData();
            setAlert({
                class: "alert-success text-center",
                message: "Updated."
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsUpdating(false);
        }
    }

    if (isLoading) {
        return <Loading/>;
    } else if (!resourceExists) {
        return <DoesNotExist/>;
    } else {
        let buttonText: any = "Update";
        if (isUpdating) {
            buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Updating...</span>
            </span>;
        }

        return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
            <div className={"row mb-3"}>
                <div className={"col"}>
                    <ReturnToLastPageLink/>
                </div>
            </div>
            <form onSubmit={updateResource}>
                <h2>Resource</h2>
                <div className="mb-3">
                    <label className="form-label">Id</label>
                    <input name="id" type="number" className="form-control" defaultValue={resource.id}
                           disabled/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Type</label>
                    <Select
                        name="resource_type_id"
                        value={typeSelect}
                        onChange={setTypeSelect}
                        options={typeOptions}
                        isDisabled={!props.session.permissions.update}
                        required
                    />
                    <small>Required</small>
                </div>
                <div className="mb-3">
                    <label className="form-label">Organization</label>
                    <Select
                        name="organization_id"
                        value={organizationSelect}
                        options={organizationOptions}
                        onChange={setOrganizationSelect}
                        isDisabled={!props.session.permissions.update}
                        required
                    />
                    <small>Required</small>
                </div>
                <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input name="name" className="form-control" defaultValue={resource.name} required
                           disabled={!props.session.permissions.update}/>
                    <small>Required</small>
                </div>
                <div className="mb-3">
                    <label className="form-label">Description</label>
                    <textarea name="description"
                              disabled={!props.session.permissions.update}
                              className="form-control" defaultValue={resource.description}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Location</label>
                    <input name="location" className="form-control" defaultValue={resource.location}
                           disabled={!props.session.permissions.update}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Disabled</label>
                    <ReactSelectDisabled value={resource.disabled} required
                                         isDisabled={!props.session.permissions.update}/>
                </div>
                {props.session.permissions.update ?
                    <button type="submit" className="btn btn-warning form-control"
                            disabled={isUpdating}>{buttonText}</button> : null}
                <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
            </form>
            <div className={"row mt-3"}>
                <div className={"col"}>
                    <ReturnToLastPageLink/>
                </div>
            </div>
        </div>;
    }
}