export default class ObjectCommons {
    static mapEntriesByProperty(entries: any, property: any) {
        return Object.fromEntries(entries.map((entry: any) => [entry[property], entry]));
    }

    static mapEntriesById(entries: any) {
        return ObjectCommons.mapEntriesByProperty(entries, "id");
    }

    static groupEntriesByProperty(entries: any, property: any) {
        const entriesByProperty: any = {};
        for (let i = 0; i < entries.length; i++) {
            const entry = entries[i];
            let value = null;
            if (Array.isArray(property)) {
                value = entry;
                for (let j = 0; j < property.length; j++) {
                    const p = property[j];
                    if (value.hasOwnProperty(p)) {
                        value = value[p];
                    }
                }
            } else if (entry[property]) {
                value = entry[property];
            }

            if (!entriesByProperty[value]) {
                entriesByProperty[value] = [];
            }
            entriesByProperty[value].push(entry);
        }
        return entriesByProperty;
    }

    /**
     * Can eventually use Object.groupBy() once it is released from "experimental"
     *
     * @param entries
     * @param property
     */
    static calvinsMagicalGroupEntriesByProperty(entries: any, property: any) {
        return entries.map((o: any) => ({[o[property]]: [o]}))
            .reduce((a: any, c: any) =>
                Object.fromEntries( // Combine
                    [...Object.keys(a), ...Object.keys(c)] // Over all keys
                        .map(k => { // Combine arrays
                            return [k, [...(a[k] ?? []), ...(c[k] ?? [])]]
                        })
                )
            );
    }

    //Comparison to check if two objects are equal by sorting them and checking their length and key value pairs
    static compareObjects(obj1: any, obj2: any) {
        const sortedObj1 = Object.entries(obj1).sort()
        const sortedObj2 = Object.entries(obj2).sort()
        return (Object.keys(sortedObj1).length === Object.keys(sortedObj2).length) && sortedObj1.every((entry1, i) => {
            return (entry1[0] === sortedObj2[i][0]) && (entry1[1] === sortedObj2[i][1]) //Checks the keys and values pairs
        })
    }

    static deepGet(obj: any, keys: any) {
        return keys.reduce((xs: any, x: any) => xs?.[x] ?? null, obj);
    }

    static deepGetByPaths(obj: any, ...paths: any) {
        return paths.map((path: any) =>
            ObjectCommons.deepGet(
                obj,
                path
                    .replace(/\[([^\[\]]*)\]/g, '.$1.')
                    .split('.')
                    .filter((t: any) => t !== '')
            )
        );
    }
}