import AuthCommons from "./AuthCommons";

export default class AppCommons {

    static isAuthorizedFromSession(session: any, policyId: any) {
        return session.user.policies.includes(policyId);
    }

    static async logout() {
        return await AuthCommons.logout().finally(function () {
            AppCommons.clearSession();
        });
    }

    static getSession() {
        let session: any = null;
        try {
            session = window.localStorage.getItem("session");
            session = JSON.parse(session);
        } catch (error) {
            console.error(error);
        }
        return session;
    }

    static async getUserSession() {
        const authAdminPolicyId = process.env.REACT_APP_POLICIES_AUTH_ADMIN ? parseInt(process.env.REACT_APP_POLICIES_AUTH_ADMIN) : null;
        const authManagerPolicyId = process.env.REACT_APP_POLICIES_AUTH_MANAGER ? parseInt(process.env.REACT_APP_POLICIES_AUTH_MANAGER) : null;
        const session: any = await AuthCommons.getSession();
        session.authorizations = await AuthCommons.getAuthorizationsMe();
        session.permissions = {
            admin: await AuthCommons.isAuthorized(authAdminPolicyId),
            manager: await AuthCommons.isAuthorized(authManagerPolicyId)
        };
        session.permissions.create = session.permissions.admin || session.permissions.manager;
        session.permissions.update = session.permissions.admin || session.permissions.manager;
        session.permissions.delete = session.permissions.admin;
        AppCommons.setSession(session);
        return session;
    }

    static setSession(session: any) {
        window.localStorage.setItem("session", JSON.stringify(session));
    }

    static clearSession() {
        window.localStorage.removeItem("session");
    }

    static getCookie(name: any) {
        const cookieString = document.cookie;
        const cookies = cookieString.split("; ");

        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie.split("=");
            if (cookieName === name) {
                // Decode the cookie value before returning
                return decodeURIComponent(cookieValue);
            }
        }
        return ""; // Cookie with the given name not found
    }

    static setCookie(cname: any, cvalue: any, exdays: any) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
}