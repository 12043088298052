import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import AuthorizationsTable from "./AuthorizationsTable";

export default function ViewAuthorizations(props: any) {
    const [isLoading, setIsLoading]: any = useState(true);
    const [authorizations, setAuthorizations] = useState([]);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const url = process.env.REACT_APP_AUTH_API + "/authorizations?populate=1";
        const authorizations: any = await RequestCommons.request(url);
        setAuthorizations(authorizations);
    }

    return <div className={"w-100 p-5 pt-3 m-auto"} style={{
        maxWidth: "2500px"
    }}>
        <div className={"shadow-sm bg-white rounded border p-3"}>
            <AuthorizationsTable {...props} authorizations={authorizations} isLoading={isLoading}/>
        </div>
    </div>;
}