import * as React from "react";
import {useEffect, useState} from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import AppCommons from "../commons/AppCommons";
import AuthCommons from "../commons/AuthCommons";


export default function LoginToken(props: any) {
    const [isRequesting, setIsRequesting] = useState(true);
    const [isLoggedIn, setIsLoggedIn]: any = useState(false);
    const [alert, setAlert] = useState({
        class: "d-none",
        message: ""
    });

    const params = useParams();

    let buttonText: any = <span>
        <span className={"loading-spinner"}>
            <i className="bi bi-arrow-clockwise"></i>
        </span>
        <span className={"ms-2"}>You will be forwarded in a few moments...</span>
    </span>;

    const token = params.token;

    useEffect(() => {
        setTimeout(async function () {
            await login({token: token});
        }, 3000);
    }, []);

    async function onSubmit(event: any) {
        event.preventDefault();
        const form: any = event.target;
        const formData: any = new FormData(form);
        const credentials: any = Object.fromEntries(formData);
        await login(credentials);
    }

    async function login(credentials: any) {
        setIsRequesting(true);
        setAlert({
            class: "d-none",
            message: ""
        });

        AppCommons.clearSession();
        return await AuthCommons.loginWithToken(credentials).then(async function (response: any) {
            if (response.login) {
                const session = await AppCommons.getUserSession();
                props.setSession(session);
                setIsLoggedIn(true);
            } else {
                setAlert({
                    class: "alert-danger",
                    message: "Failed to log in."
                });
            }
        }).catch(function (error: any) {
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error.toString()
            });
        }).finally(function () {
            setIsRequesting(false);
        });
    }

    if (isLoggedIn) {
        return <Navigate to={"/"}/>;
    } else {
        return <form className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"}
                     style={{width: "100%", maxWidth: "400px"}}
                     onSubmit={onSubmit}
                     autoComplete={"on"}>
            <div className={"image-container mb-3"}>
                <img className={"w-100"} src={"/favicon.png"} style={{
                    height: "200px",
                    objectFit: "contain"
                }} alt={"favicon"}/>
            </div>
            <h1 className={"text-center mb-3"}>Auth Portal</h1>
            <div className="mb-3 d-none">
                <label className="form-label">Token</label>
                <input name={"token"} type="password" className="form-control"
                       defaultValue={token}/>
            </div>
            <button type="submit" className={"btn btn-warning form-control mb-3" + (isRequesting ? "" : " d-none")}
                    disabled>{buttonText}</button>
            <div className={"alert mb-3 text-center " + alert.class}>{alert.message}</div>
            <div className={"row"}>
                <div className={"col"}>
                    <a style={{textDecoration: "none"}}
                       href={"mailto:" + process.env.REACT_APP_EMAILS_SUPPORT}>Support</a>
                </div>
                <div className={"col text-end"}>
                    <Link style={{textDecoration: "none"}} to={"/login"}>Return to Log In</Link>
                </div>
            </div>
        </form>;
    }
}