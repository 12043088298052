import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {Link, useParams} from "react-router-dom";
import AuthCommons from "../../commons/AuthCommons";
import Select from "react-select";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import ReactElementCommons from "../../commons/ReactElementCommons";
import {Alert} from "react-bootstrap";
import Loading from "../Loading";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";
import DoesNotExist from "../shared/DoesNotExist";

export default function UpdateEmail(props: any) {
    const [alert, setAlert] = useState({
        class: "d-none",
        message: ""
    });
    const [emailExists, setEmailExists] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [organizationOptions, setOrganizationOptions]: any = useState([]);
    const [organizationSelect, setOrganizationSelect]: any = useState(null);
    const [emailTypeOptions, setEmailTypeOptions]: any = useState([]);
    const [emailTypeSelect, setEmailTypeSelect]: any = useState(null);
    const [email, setEmail]: any = useState({});

    const params = useParams();

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setIsLoading(true);
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [response]: any = await Promise.all([
            AuthCommons.request("/emails/" + params.id + "?populate=1", null, null, 'get', 'application/x-www-form-urlencoded', false)
        ]);
        if (response.status === 204) {
            // display not exists warning
            setEmailExists(false);
        } else {
            setEmailExists(true);
            const email: any = await response.json();
            setEmail(email);

            const [organizations, emailTypes]: any = await Promise.all([
                AuthCommons.request("/organizations"),
                AuthCommons.request("/emails/types"),
            ]);

            const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id", " ", true);
            setOrganizationOptions(organizationOptions);
            setOrganizationSelect(organizationOptions.find((option: any) => option.value === email.organization_id));

            const emailTypeOptions: any = ReactElementCommons.createReactSelectOptionsFromList(emailTypes, "name", "id");
            setEmailTypeOptions(emailTypeOptions);
            setEmailTypeSelect(emailTypeOptions.find((option: any) => option.value === email.email_type_id));
        }
    }

    async function updateProduct(event: any) {
        event.preventDefault();
        try {
            setIsUpdating(true);
            setAlert({
                class: "d-none",
                message: ""
            });

            const form: any = event.target;
            const formData: any = new FormData(form);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/emails/" + params.id;
            await RequestCommons.request(url, null, payload, "PUT");

            await loadData();

            setAlert({
                class: "alert-success text-center",
                message: "Updated."
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsUpdating(false);
        }
    }

    if (isLoading) {
        return <Loading/>;
    } else if (!emailExists) {
        return <DoesNotExist/>;
    } else {
        let buttonText: any = "Update";
        if (isUpdating) {
            buttonText = <span>
                    <span className={"loading-spinner"}>
                        <i className="bi bi-arrow-clockwise"></i>
                    </span>
                    <span className={"ms-2"}>Updating...</span>
                </span>;
        }
        return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
            <div className={"row mb-3"}>
                <div className={"col"}>
                    <ReturnToLastPageLink/>
                </div>
            </div>
            <form onSubmit={updateProduct}>
                <h2>Email</h2>
                <div className="mb-3">
                    <label className="form-label">Email</label>
                    <input name="email" className="form-control" required defaultValue={email.email}
                           disabled={!props.session.permissions.update}/>
                    <small>Required</small>
                </div>
                <div className="mb-3">
                    <label className="form-label">Type</label>
                    <Select
                        isDisabled={!props.session.permissions.update}
                        name="email_type_id"
                        value={emailTypeSelect}
                        options={emailTypeOptions}
                        onChange={setEmailTypeSelect}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Organization</label>
                    <Select
                        isDisabled={!props.session.permissions.update}
                        name="organization_id"
                        value={organizationSelect}
                        options={organizationOptions}
                        onChange={setOrganizationSelect}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input name="name" className="form-control" defaultValue={email.name}
                           disabled={!props.session.permissions.update}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Description</label>
                    <textarea name="description"
                              disabled={!props.session.permissions.update}
                              className="form-control" defaultValue={email.description}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Disabled</label>
                    <ReactSelectDisabled value={email.disabled}
                                         isDisabled={!props.session.permissions.update}/>
                </div>
                {props.session.permissions.update ?
                    <button type="submit" className="btn btn-warning form-control"
                            disabled={isUpdating}>{buttonText}</button> : null}
                <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
            </form>
            <div className={"row mt-3"}>
                <div className={"col"}>
                    <ReturnToLastPageLink/>
                </div>
            </div>
        </div>;

    }

}