import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../commons/RequestCommons"
import {Link, useParams} from "react-router-dom";
import Select from "react-select";
import ReactElementCommons from "../../../commons/ReactElementCommons";
import AuthCommons from "../../../commons/AuthCommons";
import {Alert} from "react-bootstrap";
import ReturnToLastPageLink from "../../shared/ReturnToLastPageLink";

export default function UpdateNavigationLocation(props: any) {
    const [alert, setAlert] = useState({
        class: "d-none",
        message: ""
    });
    const [exists, setExists] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [navigationLocation, setNavigationLocation]: any = useState({});
    const [parentSelectOptions, setParentSelectOptions]: any = useState([]);
    const [parentSelectedOption, setParentSelectedOption]: any = useState(null);

    const params = useParams();

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setIsLoading(true);
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [response]: any = await Promise.all([
            AuthCommons.request("/navs/portals/" + params.id, null, null, 'get', 'application/x-www-form-urlencoded', false)
        ]);

        if (response.status === 204) {
            setExists(false);
        } else {
            setExists(true);
            const navigationLocation: any = await response.json();
            setNavigationLocation(navigationLocation);

            const navigationLocations: any = await AuthCommons.request("/navs/portals");
            const parentSelectOptions: any = ReactElementCommons.createReactSelectOptionsFromList(navigationLocations, "name", "id", " ", true);
            setParentSelectOptions(parentSelectOptions);
            setParentSelectedOption(parentSelectOptions.find((option: any) => option.value === navigationLocation.parent));
        }
    }

    async function updateNavigationLocation(event: any) {
        event.preventDefault();
        try {
            setIsUpdating(true);
            setAlert({
                class: "d-none",
                message: ""
            });

            const formData: any = new FormData(event.target);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/navs/portals/" + navigationLocation.id;
            await RequestCommons.request(url, null, payload, "PUT");
            await loadData();
            setAlert({
                class: "alert-success text-center",
                message: "Updated."
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsUpdating(false);
        }
    }

    if (isLoading) {
        return <div className={"container"}>
            <Alert variant={"primary"}>
                <div className="spinner-border spinner-border-sm me-2"
                     role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <span>Loading...</span>
            </Alert>
        </div>;
    } else if (!exists) {
        // render warning alert
        return <div className={"container"}>
            <Alert variant={"primary"}>
                <div className="bi bi-exclamation-triangle-fill  me-2"
                     role="status">
                    <span className="visually-hidden">No Entry Exists.</span>
                    <span>  No Entry Exists.</span>
                </div>
            </Alert>
        </div>;

    } else {
        let buttonText: any = "Update";
        if (isUpdating) {
            buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Updating...</span>
            </span>;
        }

        return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
            <form onSubmit={updateNavigationLocation}>
                <h1>Navigation Location</h1>
                <div className="mb-3">
                    <label className="form-label">Id</label>
                    <input name="id" type="number" className="form-control" defaultValue={navigationLocation.id}
                           disabled/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input name="name" className="form-control" defaultValue={navigationLocation.name} required/>
                    <small>Required</small>
                </div>
                <div className="mb-3">
                <label className="form-label">URI</label>
                    <input name="href" className="form-control" defaultValue={navigationLocation.href} required/>
                    <small>Required</small>
                </div>
                <div className="mb-3">
                    <label className="form-label">Icon</label>
                    <input name="icon" className="form-control" defaultValue={navigationLocation.icon} required/>
                    <small className={"fw-bold"}>This must be defined in the `Portal Client` project. Coordinate with
                        Engineering first.</small>
                </div>
                <div className="mb-3">
                    <label className="form-label">Parent</label>
                    <Select
                        name="parent"
                        options={parentSelectOptions}
                        onChange={setParentSelectedOption}
                        value={parentSelectedOption}
                    />
                </div>
                {props.session.permissions.update ?
                    <button type="submit" className="btn btn-warning form-control"
                            disabled={isUpdating}>{buttonText}</button> : null}
                <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
            </form>
            <div className={"row mt-3"}>
                <div className={"col"}>
                    <ReturnToLastPageLink/>
                </div>
            </div>
        </div>;
    }
}