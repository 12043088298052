import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {Link} from "react-router-dom";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";
import AuthCommons from "../../commons/AuthCommons";
import ReactElementCommons from "../../commons/ReactElementCommons";
import Select from "react-select";

export default function CreateOrganization(props: any) {
    const [alert, setAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isRequesting, setIsRequesting] = useState(false);
    const [logo, setLogo]: any = useState(process.env.ORGANIZATION_LOGO_DEFAULT);
    const [portalPolicyOptions, setPortalPolicyOptions]: any = useState([]);
    const [selectedPortalPolicyOption, setSelectedPortalPolicyOption]: any = useState(null);
    const [emailOptions, setEmailOptions]: any = useState([]);
    const [selectedEmailOption, setSelectedEmailOption]: any = useState(null);


    useEffect(() => {
        init();
    }, []);

    async function init() {
        setIsLoading(true);
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [emails, policies]: any = await Promise.all([
            AuthCommons.request("/emails?email_type_id=15&populate=1"),
            AuthCommons.request("/policies?policy_type_id=3"),
        ]);

        const portalPolicyOptions: any = ReactElementCommons.createReactSelectOptionsFromList(policies, "name", "id");
        setPortalPolicyOptions(portalPolicyOptions);

        const emailOptions: any = ReactElementCommons.createReactSelectOptionsFromList(emails, "email", "id");
        setEmailOptions(emailOptions);
    }

    async function createOrganization(event: any) {
        event.preventDefault();
        try {
            setIsRequesting(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const form: any = event.target;
            const formData: any = new FormData(form);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/organizations";
            const id = await RequestCommons.request(url, null, payload, "POST");
            setAlert({
                class: "alert-success text-center",
                message: <>
                    <div>Created organization.</div>
                    <div>
                        <Link className={"text-decoration-none"} to={"/organizations/" + id}>Click here to update organization.</Link>
                    </div>
                </>
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsRequesting(false);
        }
    }

    let buttonText: any = "Create";
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Creating...</span>
            </span>;
    }

    let logoPreview = null;
    if (logo) {
        logoPreview = <div className="mb-3">
            <div className={"p-5 mt-3 border text-center"}>
                <img src={logo} style={{
                    width: "100%",
                    height: "100px",
                    objectFit: "contain"
                }} alt={"Invalid URL"}/>
            </div>
        </div>;
    }

    return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
        <form onSubmit={createOrganization}>
            <h2>Create Organization</h2>
            <div className="mb-3">
                <label className="form-label">Name</label>
                <input name="name" className="form-control" required/>
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Short Name</label>
                <input name="short_name" className="form-control"/>
            </div>
            <div className={"mb-3"}>
                <label className="form-label">Portal Policy</label>
                <Select
                    name="portal_policy_id"
                    options={portalPolicyOptions}
                    onChange={function (option) {
                        setSelectedPortalPolicyOption(option);
                    }}
                    value={selectedPortalPolicyOption}
                />
            </div>
            <div className={"mb-3"}>
                <label className="form-label">Support Email</label>
                <Select
                    name="support_email_id"
                    options={emailOptions}
                    onChange={function (option) {
                        setSelectedEmailOption(option);
                    }}
                    value={selectedEmailOption}
                />
            </div>
            <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea name="description" className="form-control"></textarea>
            </div>
            <div className="mb-3 d-none">
                <label className="form-label">Logo</label>
                <input name={"logo"} className="form-control" defaultValue={logo}
                       placeholder={"Enter a URL"}
                       onKeyUp={function (event: any) {
                           setLogo(event.target.value);
                       }}/>
            </div>
            {logoPreview}
            <div className="mb-3">
                <label className="form-label">Disabled</label>
                <ReactSelectDisabled required/>
            </div>
            <button type="submit" className="btn btn-primary form-control" disabled={isRequesting}>{buttonText}</button>
            <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
        </form>
        <div className={"row mt-3"}>
            <div className={"col"}>
                <ReturnToLastPageLink/>
            </div>
        </div>
    </div>;
}