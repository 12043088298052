import React, {useEffect, useState} from "react";
import AuthCommons from "../commons/AuthCommons";
import TimeCommons from "../commons/TimeCommons";
import {Box} from "@mui/material";
import AppCommons from "../commons/AppCommons";
import {MaterialReactTable} from "material-react-table";
import ViewEntryActionButton from "./shared/tables/ViewEntryActionButton";
import DisableEntryActionButton from "./shared/tables/DisableEntryActionButton";

export default function Sessions(props: any) {
    const [isLoadingSessions, setIsLoadingSessions] = useState(true);
    const [sessionTableData, setSessionTableData]: any = useState([]);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
    }

    async function loadData() {
        await Promise.all([
            getSessions()
        ]);
    }

    async function getSessions() {
        setIsLoadingSessions(true);
        const sessions: any = await AuthCommons.request("/sessions?disabled=0&populate=1");
        const promises = [];
        for (let i = 0; i < sessions.length; i++) {
            promises.push(processSession(sessions[i]));
        }
        await Promise.all(promises);
        setSessionTableData(sessions);
        setIsLoadingSessions(false);
    }

    async function onSessionRefreshSubmit(event: any) {
        event.preventDefault();
        await getSessions();
    }

    async function processSession(session: any) {
        session.created = TimeCommons.formatTimestamp(session.created) + " (" + TimeCommons.timeAgo(session.created) + ")";
        session.cross_org = session.user_role.role.organization.id !== session.policy.organization.id ? "Yes" : "No";
        session.cross_org_details = session.cross_org ? "Role/Policy" : "";
        session.ttl = session.ttl ? TimeCommons.elapsedFromSeconds(session.ttl) : null;
        session.last_request = null;
        if (session.expiration) {
            session.expiration = TimeCommons.formatTimestamp(session.expiration) + " (" + TimeCommons.timeAgo(session.expiration, true) + ")";
        }
    }

    function renderSessionRowActions(data: any) {
        const id = data.row.original.id;

        let disableSessionButton = null;
        if (props.session.permissions.admin && data?.row?.original?.id) {
            disableSessionButton = <DisableEntryActionButton
                onClick={async (event: any) => {
                    const confirmText = "Are you sure you want to disable this session?";
                    if (window.confirm(confirmText)) {
                        try {
                            await AuthCommons.disableSession(id);
                            await AuthCommons.renewSession().catch(function (error: any) {
                                AppCommons.clearSession();
                                window.location.href = "/session/expired";
                            });
                            await init();
                        } catch (error: any) {
                            alert(error.detail);
                        }
                    }
                }}/>;
        }

        let userLink = null;
        if (data?.row?.original?.user?.id) {
            userLink = <ViewEntryActionButton title={"View User"} to={"/users/" + data?.row?.original?.user?.id}/>;
        }
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            {userLink}
            {disableSessionButton}
        </Box>;
    }

    const sessionColumns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'user.first_name',
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'user_role.email.email',
            header: 'Role Email',
        },
        {
            accessorKey: 'user_role.role.organization.name',
            header: 'Role Org',
        },
        {
            accessorKey: 'policy.domain.name',
            header: 'Domain',
        },
        {
            accessorKey: 'policy.product.name',
            header: 'Product',
        },
        {
            accessorKey: 'remote_addr',
            header: 'IP',
        },
        {
            accessorKey: 'policy.name',
            header: 'Policy',
        },
        {
            accessorKey: 'policy.resource.name',
            header: 'Resource',
        },
        {
            accessorKey: 'policy.resource.location',
            header: 'Location',
        },
        {
            accessorKey: 'policy.organization.name',
            header: 'Organization',
        },
        {
            accessorKey: 'cross_org',
            header: 'Cross-Org',
            size: 100
        },
        {
            accessorKey: 'cross_org_details',
            header: 'Cross-Org Violation'
        },
        {
            accessorKey: 'ttl',
            header: 'Time-To-Live',
            size: 100
        },
        {
            accessorKey: 'created',
            header: 'Created',
            size: 100
        },
        {
            accessorKey: 'expiration',
            header: 'Expiration',
            size: 100
        },
    ];

    return <div className={"w-100 p-5 pt-3 m-auto"} style={{
        maxWidth: "2500px"
    }}>
        <div className={"bg-white shadow-sm rounded mb-3 border"}>
            <MaterialReactTable
                muiTablePaperProps={props.config.materialReactTable.muiTablePaperProps}
                muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                columns={sessionColumns}
                data={sessionTableData}
                initialState={{
                    sorting: [{
                        id: "id",
                        desc: true
                    }]
                }}
                state={{
                    isLoading: isLoadingSessions,
                    density: "compact"
                }}
                enableRowActions={true}
                renderRowActions={renderSessionRowActions}
                renderTopToolbarCustomActions={() => {
                    return (
                        <div>
                            <h4 className={"me-3 d-inline-block align-top"}>Active Sessions</h4>
                            <form onSubmit={onSessionRefreshSubmit} className={"d-inline-block"}>
                                <button className={"btn btn-primary"}>Refresh</button>
                            </form>
                        </div>
                    );
                }}
                muiTableBodyRowProps={function (row: any) {
                    return {
                        sx: {
                            background: row.row.original.cross_org === "Yes" ? "#f1e4bc" : ""
                        }
                    };
                }}
            />
        </div>
    </div>;
}