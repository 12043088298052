import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {Link} from "react-router-dom";
import AuthCommons from "../../commons/AuthCommons";
import Select from "react-select";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import ReactElementCommons from "../../commons/ReactElementCommons";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";


export default function CreateEmail(props: any) {
    const [alert, setAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [isRequesting, setIsRequesting] = useState(false);
    const [organizationOptions, setOrganizationOptions]: any = useState([]);
    const [emailTypeOptions, setEmailTypeOptions]: any = useState([]);
    const [emailStrings, setEmailStrings]: any = useState([]);
    const [emailExists, setEmailExists]: any = useState(false);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
    }

    async function loadData() {
        const [organizations, emails, emailTypes]: any = await Promise.all([
            AuthCommons.request("/organizations"),
            AuthCommons.request("/emails"),
            AuthCommons.request("/emails/types")
        ]);

        const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id");
        setOrganizationOptions(organizationOptions);

        const emailTypeOptions: any = ReactElementCommons.createReactSelectOptionsFromList(emailTypes, "name", "id");
        setEmailTypeOptions(emailTypeOptions);

        const emailStrings: any = [];
        for (let i = 0; i < emails.length; i++) {
            const email = emails[i];
            emailStrings.push(email["email"]);
        }
        setEmailStrings(emailStrings);
    }

    async function createEmail(event: any) {
        event.preventDefault();
        try {
            setIsRequesting(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const form: any = event.target;
            const formData: any = new FormData(form);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/emails";
            const id = await RequestCommons.request(url, null, payload, "POST");
            setAlert({
                class: "alert-success text-center",
                message: <>
                    <div>Created email.</div>
                    <div>
                        <Link className={"text-decoration-none"} to={"/emails/" + id}>Click here to update email.</Link>
                    </div>
                </>
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsRequesting(false);
        }
    }

    function checkEmailExists(email: string) {
        const emailExists: any = emailStrings.includes(email);
        setEmailExists(emailExists);
    }

    let buttonText: any = "Create";
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Creating...</span>
            </span>;
    }

    let emailFieldDescription: any = <small>Required</small>;
    if (emailExists) {
        emailFieldDescription = <small className={"text-danger"}>Email already exists.</small>;
    }

    return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
        <form onSubmit={createEmail}>
            <h2>Create Email</h2>
            <div className="mb-3">
                <label className="form-label">Email</label>
                <input name="email" type={"email"}
                       className={"form-control " + (emailExists ? "is-invalid text-danger" : "")}
                       onChange={function (event: any) {
                           const email = event.target.value;
                           checkEmailExists(email);
                       }}
                       required
                />
                {emailFieldDescription}
            </div>
            <div className="mb-3">
                <label className="form-label">Type</label>
                <Select
                    name="email_type_id"
                    options={emailTypeOptions}
                    required
                />
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Organization</label>
                <Select
                    name="organization_id"
                    options={organizationOptions}
                />
            </div>
            <div className="mb-3">
                <label className="form-label">Name</label>
                <input name="name" className="form-control"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea name="description"
                          className="form-control"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Disabled</label>
                <ReactSelectDisabled required/>
            </div>
            <button type="submit" className="btn btn-primary form-control"
                    disabled={isRequesting || emailExists}>{buttonText}</button>
            <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
        </form>
        <div className={"row mt-3"}>
            <div className={"col"}>
                <ReturnToLastPageLink/>
            </div>
        </div>
    </div>;
}