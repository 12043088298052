import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../commons/RequestCommons"
import TimeCommons from "../../../commons/TimeCommons";
import {MaterialReactTable} from "material-react-table";
import {Box} from "@mui/material";
import {Link} from "react-router-dom";
import ViewEntryActionButton from "../../shared/tables/ViewEntryActionButton";
import DeleteEntryActionButton from "../../shared/tables/DeleteEntryActionButton";

export default function ViewLegacyUsers(props: any) {
    const [tableData, setTableData]: any = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        let url = process.env.REACT_APP_AUTH_API + "/users/legacy?populate=1";
        const legacyUsers: any = await RequestCommons.request(url);
        console.log(legacyUsers.length);
        const tableData: any = [];
        for (let i = 0; i < legacyUsers.length; i++) {
            const legacyUser = legacyUsers[i];
            legacyUser.created = TimeCommons.formatTimestamp(legacyUser.created);
            const retiredMismatch = (legacyUser.legacy_retired === 1 && legacyUser.user_role.user_role_status_id === 1) ||
                (legacyUser.legacy_retired === 0 && legacyUser.user_role.user_role_status_id === 2);
            legacyUser.mismatch = retiredMismatch ? "Retired" : "No";
            tableData.push(legacyUser);
        }
        setTableData(tableData);
    }

    function deleteLegacyUser(id: number) {
        const c = window.confirm("Are you sure you want to delete this entry?");
        if (c) {
            const url = process.env.REACT_APP_AUTH_API + "/users/legacy/" + id;
            RequestCommons.request(url, null, null, "DELETE").then(async function () {
                await loadData();
            }).catch(function (error) {
                console.error(error);
                alert(error.detail);
            });
        }
    }

    const columns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'user.first_name',
            header: 'First Name'
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'user.id',
            header: 'User Id',
            size: 70
        },
        {
            accessorKey: 'user_role.id',
            header: 'User Role Id',
            size: 70
        },
        {
            accessorKey: 'user_role.role.organization.name',
            header: 'User Role Org',
        },
        {
            accessorKey: 'user_role.role.department.name',
            header: 'User Role Department',
        },
        {
            accessorKey: 'user_role.role.name',
            header: 'User Role Name',
        },
        {
            accessorKey: 'user_role.user_role_status.name',
            header: 'User Role Status',
        },
        {
            accessorKey: 'organization.name', //normal accessorKey
            header: 'Organization',
        },
        {
            accessorKey: 'legacy_user_id', //normal accessorKey
            header: 'Legacy User Id',
        },
        {
            accessorKey: 'legacy_username', //normal accessorKey
            header: 'Legacy Username',
        },
        {
            accessorKey: 'legacy_email', //normal accessorKey
            header: 'Legacy Email',
        },
        {
            accessorKey: 'legacy_phone_number', //normal accessorKey
            header: 'Legacy Phone Number',
        },
        {
            accessorKey: 'legacy_business', //normal accessorKey
            header: 'Legacy Business',
        },
        {
            accessorKey: 'legacy_department', //normal accessorKey
            header: 'Legacy Department',
        },
        {
            accessorKey: 'legacy_role', //normal accessorKey
            header: 'Legacy Role',
        },
        {
            accessorKey: 'legacy_retired', //normal accessorKey
            header: 'Legacy Retired',
        },
        {
            accessorKey: 'legacy_user_location.name', //normal accessorKey
            header: 'Legacy User Location',
        },
        {
            accessorKey: 'resource.name', //normal accessorKey
            header: 'Resource',
        },
        {
            accessorKey: 'resource.location', //normal accessorKey
            header: 'Resource Location',
        },
        {
            accessorKey: 'mismatch', //normal accessorKey
            header: 'Mismatch',
        },
        {
            accessorKey: 'disabled',
            header: 'Disabled',
        },
        {
            accessorKey: 'created',
            header: 'Created',
            size: 100
        }
    ];

    function renderRowActions(data: any) {
        const id = data.row.original.id;
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton
                to={"/users/" + data.row.original.user_id}
                title={"View User"}/>
            {props.session.permissions.delete ?
                <DeleteEntryActionButton onClick={function (event: any) {
                    event.preventDefault();
                    deleteLegacyUser(id);
                }}/> : null}
        </Box>;
    }

    const createLink = props.session.permissions.update ? <Link to={"/users/legacy/create"}
                                                                className={"d-inline-block btn btn-primary"}
    >
        Create
    </Link> : null;

    const defaultPageSize = 10;
    const initialState = {
        sorting: [
            {
                id: 'user.last_name', //sort by age by default on page load
                desc: false
            },
            {
                id: 'user.first_name', //sort by age by default on page load
                desc: false
            },
            {
                id: 'legacy_user_location.name', //sort by age by default on page load
                desc: false
            },
            {
                id: 'organization.name', //sort by age by default on page load
                desc: false
            }
        ],
        pagination: {
            pageSize: defaultPageSize,
            pageIndex: 0
        }
    };

    return <div className={"p-5 pt-2 w-100"}>
        <div className={"shadow-sm bg-white rounded border"}>
            <MaterialReactTable
                muiTablePaperProps={props.config.materialReactTable.muiTablePaperProps}
                muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}
                initialState={initialState}
                state={{
                    isLoading: isLoading,
                    density: "compact"
                }}
                enableRowActions={true} renderRowActions={renderRowActions}
                renderTopToolbarCustomActions={() => {
                    return (
                        <div>
                            <h2 className={"me-3 d-inline-block align-top"}>Legacy Users</h2>
                            {createLink}
                        </div>
                    );
                }}
                muiTableBodyRowProps={function (row: any) {
                    return {
                        sx: {
                            background: row.row.original.mismatch && row.row.original.mismatch !== "No" ? "lightcoral" : ""
                        }
                    };
                }}
                enablePagination={true}
            />
        </div>
    </div>;
}