import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../commons/RequestCommons"
import {Link, useNavigate, useParams} from "react-router-dom";
import AuthCommons from "../../../commons/AuthCommons";
import Select from "react-select";
import ReactElementCommons from "../../../commons/ReactElementCommons";
import CreatableSelect from "react-select/creatable";
import {Accordion} from "react-bootstrap";
import {MaterialReactTable} from "material-react-table";
import Alert from "react-bootstrap/Alert";
import {Box} from "@mui/material";
import ViewEntryActionButton from "../../shared/tables/ViewEntryActionButton";
import ReturnToLastPageLink from "../../shared/ReturnToLastPageLink";
import ReactSelectDisabled from "../../../commons/ReactSelectDisabled";

export default function CreateUserRoleForm(props: any) {
    const params = useParams();
    const navigate = useNavigate();

    const [alert, setAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [isRequesting, setIsRequesting] = useState(false);
    const [userOptions, setUserOptions]: any = useState([]);
    const [roleOptions, setRoleOptions]: any = useState([]);
    const [roleSelect, setRoleSelect]: any = useState(null);
    const [organizationOptions, setOrganizationOptions]: any = useState([]);
    const [organizationSelect, setOrganizationSelect]: any = useState(null);
    const [departmentOptions, setDepartmentOptions]: any = useState([]);
    const [departmentSelect, setDepartmentSelect]: any = useState(null);
    const [departments, setDepartments]: any = useState([]);
    const [roles, setRoles]: any = useState([]);
    const [emailOptions, setEmailOptions]: any = useState([]);
    const [payTypeOptions, setPayTypeOptions]: any = useState([]);
    const [userRoleStatusOptions, setUserRoleStatusOptions]: any = useState([]);
    const [selectedUserRoleStatusOption, setSelectedUserRoleStatusOption]: any = useState(null);
    const [phoneNumberOptions, setPhoneNumberOptions]: any = useState([]);

    const [userRoles, setUserRoles]: any = useState([]);
    const [groupUserRolesTableData, setGroupUserRolesTableData]: any = useState([]);
    const [groupUserRolesRowSelection, setGroupUserRolesRowSelection]: any = useState({});
    const [userRoleManagersTableData, setUserRoleManagersTableData]: any = useState([]);
    const [userRoleManagersRowSelection, setUserRoleManagersRowSelection]: any = useState({});
    const [userDailyReviewManagersTableData, setUserDailyReviewManagersTableData]: any = useState([]);
    const [userDailyReviewManagersRowSelection, setUserDailyReviewManagersRowSelection]: any = useState({});
    const [userC3ManagersTableData, setUserC3ManagersTableData]: any = useState([]);
    const [userC3ManagersRowSelection, setUserC3ManagersRowSelection]: any = useState({});
    const [copyUserRolePermissionsSelectedOption, setCopyUserRolePermissionsSelectedOption]: any = useState(null);
    const [copyPermissionsAlert, setCopyPermissionsAlert]: any = useState(null);
    const [userRoleGroupIds, setUserRoleGroupIds]: any = useState([]);
    const [userRoleOptions, setUserRoleOptions]: any = useState([]);
    const [groupUserRolesAll, setGroupUserRolesAll]: any = useState([]);

    async function copyUserRolePermissionsOnChange(option: any) {
        setCopyUserRolePermissionsSelectedOption(option);
        const userRoleId = option.value;
        const groupUserRoles = groupUserRolesAll.filter((groupUserRole: any) => {
            return groupUserRole.user_role_id === userRoleId;
        });
        const groupUserRolesRowSelectionNew = JSON.parse(JSON.stringify(groupUserRolesRowSelection));
        for (let i = 0; i < groupUserRoles.length; i++) {
            const groupUserRole = groupUserRoles[i];
            groupUserRolesRowSelectionNew[groupUserRole.group_id] = true;
        }
        setGroupUserRolesRowSelection(groupUserRolesRowSelectionNew);
        setCopyUserRolePermissionsSelectedOption(null);

        const userRolesFiltered = userRoles.filter((userRole: any) => {
            return userRole.id === userRoleId;
        });

        if (userRolesFiltered && userRolesFiltered[0]) {
            const userRole = userRolesFiltered[0];
            const name = userRole.user.first_name + " " + userRole.user.last_name;
            const alert = <Alert variant={"success"} dismissible onClose={function (event: any) {
                setCopyPermissionsAlert(null);
            }}>
                <span>Added permissions from {name}.</span>
            </Alert>;
            setCopyPermissionsAlert(alert);
        }
    }

    function renderGroupRowActions(data: any) {
        const id = data.row.original.id;
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}} title={"View Group"}>
            <ViewEntryActionButton to={"/groups/" + id} title={"View"}/>
        </Box>;
    }

    function renderManagerRowActions(data: any) {
        const id = data.row.original.id;
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton to={"/users/roles/" + id} title={"View"}/>
        </Box>;
    }

    function renderUserDailyReviewManagerRowActions(data: any) {
        const id = data.row.original.id;
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton to={"/users/roles/" + id} title={"View"}/>
        </Box>;
    }

    function renderUserC3ManagerRowActions(data: any) {
        const id = data.row.original.id;
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton to={"/users/roles/" + id} title={"View"}/>
        </Box>;
    }

    const userId = params.hasOwnProperty("id") && params.id ? parseInt(params.id) : null;
    const showUserSelect = props.hasOwnProperty("showUserSelect") ? props.showUserSelect : true;
    const showCreationLink = props.hasOwnProperty("showCreationLink") ? props.showCreationLink : true;

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        const newTableData = [];
        for (let i = 0; i < groupUserRolesTableData.length; i++) {
            const row = groupUserRolesTableData[i];
            for (let groupId in groupUserRolesRowSelection) {
                if (groupId == row.id && row.selected === "No") {
                    row.selected = "Yes";
                    row.new_selection = "Yes";
                    break;
                }
            }
            newTableData.push(row);
        }
        setGroupUserRolesTableData(newTableData);
    }, [groupUserRolesRowSelection]);

    async function init() {
        await loadData();
    }

    async function loadData() {
        const [users, organizations, departments, roles, payTypes, userRoleStatuses, userRoles, groups, groupUserRoles]: any = await Promise.all([
            AuthCommons.request("/users?disabled=0&order_by=last_name&order_by_direction=asc"),
            AuthCommons.request("/organizations"),
            AuthCommons.request("/departments"),
            AuthCommons.request("/roles"),
            AuthCommons.request("/pay/types"),
            AuthCommons.request("/users/roles/statuses"),
            AuthCommons.request("/users/roles?populate=1"),
            AuthCommons.request("/groups?populate=1"),
            AuthCommons.request("/groups/users/roles")
        ]);

        setDepartments(departments);
        setRoles(roles);
        setUserRoleManagersTableData(userRoles);
        setUserDailyReviewManagersTableData(userRoles);
        setUserC3ManagersTableData(userRoles);
        setGroupUserRolesAll(groupUserRoles);

        const groupUserRolesTableData: any = [];
        for (let i = 0; i < groups.length; i++) {
            const group = groups[i];
            const selected = false;
            if (selected || props.session.permissions.update) {
                group.selected = selected ? "Yes" : "No";
                groupUserRolesTableData.push(group);
            }
        }
        setGroupUserRolesTableData(groupUserRolesTableData);

        const userOptions: any = ReactElementCommons.createReactSelectOptionsFromList(users, ["first_name", "last_name"], "id");
        setUserOptions(userOptions);

        const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id");
        setOrganizationOptions(organizationOptions);

        const payTypeOptions: any = ReactElementCommons.createReactSelectOptionsFromList(payTypes, "name", "id");
        setPayTypeOptions(payTypeOptions);

        const userRoleStatusOptions: any = ReactElementCommons.createReactSelectOptionsFromList(userRoleStatuses, "name", "id");
        setUserRoleStatusOptions(userRoleStatusOptions);
        setSelectedUserRoleStatusOption(userRoleStatusOptions[2]);

        let phoneNumbers: any = [];
        if (userId) {
            phoneNumbers = await AuthCommons.request("/users/numbers/phone?user_id=" + userId + "&populate=1&filter=phone_number");
        } else {
            phoneNumbers = await AuthCommons.request("/numbers/phone?populate=1");
        }

        const phoneNumberOptions: any = ReactElementCommons.createReactSelectOptionsFromList(phoneNumbers, "phone_number", "id", " ", true);
        setPhoneNumberOptions(phoneNumberOptions);

        if (params.hasOwnProperty("id")) {
            await createUserEmailOptions(params.id);
        }

        userRoles.sort((a: any, b: any) => {
            return a.role.organization.short_name.localeCompare(b.role.organization.short_name)
                || a.user.last_name.localeCompare(b.user.last_name)
                || a.user.first_name.localeCompare(b.user.first_name);
        });

        const userRoleOptions: any = [];
        for (let i = 0; i < userRoles.length; i++) {
            const userRole = userRoles[i];

            let label = userRole.role.organization.short_name;
            label += " - " + userRole.user.last_name + ", " + userRole.user.first_name;
            label += " - " + userRole.email.email;
            label += userRole.disabled ? " - Disabled" : "";
            const userRoleOption = {
                label: label,
                value: userRole.id
            }
            userRoleOptions.push(userRoleOption);
        }
        setUserRoleOptions(userRoleOptions);
    }

    async function createUserRole(event: any) {
        event.preventDefault();
        let completed = null;
        try {
            setIsRequesting(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const form: any = event.target;
            const formData: any = new FormData(form);
            if (!formData.get("phone_number_id")) {
                formData.delete("phone_number_id");
            }
            const groups = Object.keys(groupUserRolesRowSelection);
            if (groups.length) {
                formData.set("groups", groups);
            }
            const dailyReviewManagers = Object.keys(userDailyReviewManagersRowSelection);
            if (dailyReviewManagers.length) {
                formData.set("daily_review_managers", dailyReviewManagers);
            }
            const c3Managers = Object.keys(userC3ManagersRowSelection);
            if (c3Managers.length) {
                formData.set("c3_managers", c3Managers);
            }
            const managers = Object.keys(userRoleManagersRowSelection);
            if (managers.length) {
                formData.set("managers", managers);
            }
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/users/roles";
            const id = await RequestCommons.request(url, null, payload, "POST");
            setAlert({
                class: "alert-success text-center",
                message: <>
                    <div>Created user role.</div>
                    <div className={showCreationLink ? "" : "d-none"}>
                        <Link className={"text-decoration-none"} to={"/users/roles/" + id}>Click here to update user
                            role.</Link>
                    </div>
                </>
            });
            completed = true;
            navigate("/users/roles/" + id);
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
            completed = false;
        } finally {
            setIsRequesting(false);
            if (props.afterComplete) {
                props.afterComplete(completed);
            }
        }
    }

    function organizationSelectOnChange(option: any) {
        setOrganizationSelect(option);
        setDepartmentSelect(null);
        setRoleSelect(null);

        const organizationId = option.value;
        const departmentOptions: any = [];
        if (organizationId) {
            for (let i = 0; i < departments.length; i++) {
                const department = departments[i];
                if (department.organization_id && department.organization_id === organizationId) {
                    const departmentOption = {
                        label: department.name,
                        value: department.id
                    };
                    departmentOptions.push(departmentOption);
                }
            }
        }
        setDepartmentOptions(departmentOptions);
    }

    function departmentSelectOnChange(option: any) {
        setDepartmentSelect(option);
        setRoleSelect(null);

        const departmentId = option.value;
        const roleOptions: any = [];
        if (departmentId) {
            for (let i = 0; i < roles.length; i++) {
                const role = roles[i];
                if (role.department_id && role.department_id === departmentId) {
                    const roleOption = {
                        label: role.name,
                        value: role.id
                    };
                    roleOptions.push(roleOption);
                }
            }
        }
        setRoleOptions(roleOptions);
    }

    async function onSelectUserChange(option: any) {
        await createUserEmailOptions(option.value);
    }

    async function createUserEmailOptions(userId: any) {
        let url = process.env.REACT_APP_AUTH_API + "/users/emails?user_id=" + userId + "&populate=1&filter=email";
        const userEmails: any = await RequestCommons.request(url);

        const emailOptions: any = [];
        for (let i = 0; i < userEmails.length; i++) {
            const email = userEmails[i];
            const emailOption = {
                value: email.id,
                label: email.email
            };
            emailOptions.push(emailOption);
        }
        setEmailOptions(emailOptions);
    }

    let buttonText: any = "Create";
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Creating...</span>
            </span>;
    }

    const styles: any = {};
    if (selectedUserRoleStatusOption?.value === 3) {
        styles["borderColor"] = "#dc3545";
        styles["background"] = "#dc3545";
        styles["color"] = "white";
    }

    const defaultPageSize = 10;
    let groupUserRolesTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'organization.name',
            header: 'Organization',
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'description',
            header: 'Description',
        }
    ];


    let userRoleManagersTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'user.first_name',
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'role.organization.name',
            header: 'Organization',
        },
        {
            accessorKey: 'email.email',
            header: 'Email',
        }
    ];

    let userDailyReviewManagersTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'user.first_name',
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'role.organization.name',
            header: 'Organization',
        },
        {
            accessorKey: 'email.email',
            header: 'Email',
        }
    ];

    let userC3ManagersTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'user.first_name',
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'role.organization.name',
            header: 'Organization',
        },
        {
            accessorKey: 'email.email',
            header: 'Email',
        }
    ];

    const groupsSelected = Object.values(groupUserRolesRowSelection).filter(i => i === true).length;
    const managersSelected = Object.values(userRoleManagersRowSelection).filter(i => i === true).length;
    const dailyReviewManagersSelected = Object.values(userDailyReviewManagersRowSelection).filter(i => i === true).length;
    const c3ManagersSelected = Object.values(userC3ManagersRowSelection).filter(i => i === true).length;

    return <form onSubmit={createUserRole}>
        {params.id ? <input name={"user_id"} type={"hidden"} value={params.id}/> : null}
        <div className={"row"}>
            <div className={"col-lg-3 mb-3" + (showUserSelect ? "" : " d-none")}>
                <label className="form-label">User</label>
                <Select
                    name="user_id"
                    isDisabled={userId !== null}
                    value={userOptions.find((option: any) => option.value === userId)}
                    onChange={onSelectUserChange}
                    options={userOptions}
                />
                <small>Required</small>
            </div>
            <div className="col-lg-3 mb-3">
                <label className="form-label">Email</label>
                <CreatableSelect
                    name={"email_id"}
                    options={emailOptions}
                    required
                />
                <small>Required</small>
            </div>
            <div className="col-lg-3 mb-3">
                <label className="form-label">Phone Number</label>
                <CreatableSelect
                    name={"phone_number_id"}
                    options={phoneNumberOptions}
                />
            </div>
            <div className="col-lg-3 mb-3">
                <label className="form-label">Pay Type</label>
                <Select
                    name="pay_type_id"
                    options={payTypeOptions}
                    required
                />
                <small>Required</small>
            </div>
        </div>
        <div className={"row"}>
            <div className="col-lg-4 mb-3">
                <label className="form-label">Organization</label>
                <Select
                    onChange={organizationSelectOnChange}
                    options={organizationOptions}
                    value={organizationSelect}
                />
                <small>Required</small>
            </div>
            <div className="col-lg-4 mb-3">
                <label className="form-label">Department</label>
                <Select
                    onChange={departmentSelectOnChange}
                    options={departmentOptions}
                    value={departmentSelect}
                    isDisabled={!departmentOptions.length}/>
                <small>Required</small>
            </div>
            <div className="col-lg-4 mb-3">
                <label className="form-label">Role</label>
                <Select
                    name="role_id"
                    value={roleSelect}
                    options={roleOptions}
                    onChange={setRoleSelect}
                    isDisabled={!roleOptions.length}
                    required
                />
                <small>Required</small>
            </div>
        </div>
        <div className={"row"}>
            <div className="col-lg-4 mb-3">
                <label className="form-label">Start Date</label>
                <input type="date" name="start_date" className="form-control" required
                       onClick={function (event: any) {
                           event.preventDefault();
                           event.target.showPicker();
                       }}
                />
                <small>Required</small>
            </div>
            <div className="col-lg-4 mb-3">
                <label className="form-label">End Date</label>
                <input type="date" name="end_date" className="form-control"
                       onClick={function (event: any) {
                           event.preventDefault();
                           event.target.showPicker();
                       }}/>
            </div>
            <div className="col-lg-4 mb-3">
                <label className="form-label">Disabled</label>
                <ReactSelectDisabled defaultValue={true} required/>
            </div>
        </div>

        <Accordion defaultActiveKey={["0"]} className={"mb-3"}>
            <Accordion.Item eventKey="1">
                <Accordion.Header>Groups ({groupsSelected})</Accordion.Header>
                <Accordion.Body>
                    <div style={{
                        position: "relative",
                        zIndex: 999,
                        paddingBottom: 0
                    }}>
                        <div className={"mb-3"}>
                            <label>Copy Permissions</label>
                            <Select
                                value={copyUserRolePermissionsSelectedOption}
                                onChange={copyUserRolePermissionsOnChange}
                                options={userRoleOptions}
                                isDisabled={!props.session.permissions.update}
                            />
                        </div>
                        <div>
                            {copyPermissionsAlert}
                        </div>
                    </div>
                    <div style={{
                        position: "relative",
                        zIndex: 0
                    }}>
                        <MaterialReactTable
                            muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                            columns={groupUserRolesTableColumns}
                            data={groupUserRolesTableData}
                            enableSelectAll={false}
                            enablePagination={true}
                            positionToolbarAlertBanner={"none"}
                            enableRowSelection={props.session.permissions.update}
                            onRowSelectionChange={setGroupUserRolesRowSelection}
                            state={{
                                rowSelection: groupUserRolesRowSelection,
                                isLoading: false,
                                density: "compact"
                            }}
                            initialState={{
                                columnVisibility: {
                                    selected: false
                                },
                                pagination: {
                                    pageSize: defaultPageSize,
                                    pageIndex: 0
                                },
                                sorting: [
                                    {
                                        id: "selected",
                                        desc: true
                                    },
                                    {
                                        id: "organization.name",
                                        desc: false
                                    },
                                    {
                                        id: "name",
                                        desc: false
                                    }
                                ]
                            }}
                            enableRowActions={true} renderRowActions={renderGroupRowActions}
                            getRowId={(originalRow: any) => originalRow.id}
                            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                            renderTopToolbarCustomActions={() => {
                                return (
                                    <h2 className={"me-3 d-inline-block align-top"}>Groups
                                        ({groupsSelected})</h2>
                                );
                            }}
                        />
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>Daily Review Managers ({dailyReviewManagersSelected})</Accordion.Header>
                <Accordion.Body>
                    <MaterialReactTable
                        muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                        columns={userDailyReviewManagersTableColumns}
                        data={userDailyReviewManagersTableData}
                        enableSelectAll={false}
                        enablePagination={true}
                        positionToolbarAlertBanner={"none"}
                        enableRowSelection={props.session.permissions.update}
                        onRowSelectionChange={setUserDailyReviewManagersRowSelection}
                        state={{
                            rowSelection: userDailyReviewManagersRowSelection,
                            isLoading: false,
                            density: "compact"
                        }}
                        enableRowActions={true}
                        renderRowActions={renderUserDailyReviewManagerRowActions}
                        initialState={{
                            columnVisibility: {
                                selected: false
                            },
                            pagination: {
                                pageSize: defaultPageSize,
                                pageIndex: 0
                            },
                            sorting: [
                                {
                                    id: "selected",
                                    desc: true
                                },
                                {
                                    id: "user.last_name",
                                    desc: false
                                },
                                {
                                    id: "user.first_name",
                                    desc: false
                                },
                                {
                                    id: "role.organization.name",
                                    desc: false
                                }
                            ]
                        }}
                        getRowId={(originalRow: any) => originalRow.id}
                        muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                        renderTopToolbarCustomActions={() => {
                            return (
                                <div>
                                    <h2 className={"me-3 d-inline-block align-top"}>Daily Review
                                        Managers ({dailyReviewManagersSelected})</h2>
                                    <ul>
                                        <li>Will be CC'd on the user's daily review emails</li>
                                    </ul>
                                </div>
                            );
                        }}
                    />
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="c3-managers">
                <Accordion.Header>C3 Managers ({c3ManagersSelected})</Accordion.Header>
                <Accordion.Body>
                    <MaterialReactTable
                        muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                        columns={userC3ManagersTableColumns}
                        data={userC3ManagersTableData}
                        enableSelectAll={false}
                        enablePagination={true}
                        positionToolbarAlertBanner={"none"}
                        enableRowSelection={props.session.permissions.update}
                        onRowSelectionChange={setUserC3ManagersRowSelection}
                        state={{
                            rowSelection: userC3ManagersRowSelection,
                            isLoading: false,
                            density: "compact"
                        }}
                        enableRowActions={true}
                        renderRowActions={renderUserC3ManagerRowActions}
                        initialState={{
                            columnVisibility: {
                                selected: false
                            },
                            pagination: {
                                pageSize: defaultPageSize,
                                pageIndex: 0
                            },
                            sorting: [
                                {
                                    id: "selected",
                                    desc: true
                                },
                                {
                                    id: "user.last_name",
                                    desc: false
                                },
                                {
                                    id: "user.first_name",
                                    desc: false
                                },
                                {
                                    id: "role.organization.name",
                                    desc: false
                                }
                            ]
                        }}
                        getRowId={(originalRow: any) => originalRow.id}
                        muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                        renderTopToolbarCustomActions={() => {
                            return (
                                <div>
                                    <h2 className={"me-3 d-inline-block align-top"}>C3 Managers
                                        ({c3ManagersSelected})</h2>
                                    <ul>
                                        <li>Will be CC'd on overnight login email warnings to users</li>
                                    </ul>
                                </div>
                            );
                        }}
                    />
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>Managers ({managersSelected})</Accordion.Header>
                <Accordion.Body>
                    <MaterialReactTable
                        muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                        columns={userRoleManagersTableColumns}
                        data={userRoleManagersTableData}
                        enableSelectAll={false}
                        enablePagination={true}
                        positionToolbarAlertBanner={"none"}
                        enableRowSelection={props.session.permissions.update}
                        onRowSelectionChange={setUserRoleManagersRowSelection}
                        state={{
                            rowSelection: userRoleManagersRowSelection,
                            isLoading: false,
                            density: "compact"
                        }}
                        enableRowActions={true}
                        renderRowActions={renderManagerRowActions}
                        initialState={{
                            columnVisibility: {
                                selected: false
                            },
                            pagination: {
                                pageSize: defaultPageSize,
                                pageIndex: 0
                            },
                            sorting: [
                                {
                                    id: "selected",
                                    desc: true
                                },
                                {
                                    id: "user.last_name",
                                    desc: false
                                },
                                {
                                    id: "user.first_name",
                                    desc: false
                                }
                            ]
                        }}
                        getRowId={(originalRow: any) => originalRow.id}
                        muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                        renderTopToolbarCustomActions={() => {
                            return (
                                <div>
                                    <h2 className={"me-3 d-inline-block align-top"}>Managers
                                        ({managersSelected})</h2>
                                    <ul>
                                        <li>Legacy manager assignments from old system, not being utilized currently
                                        </li>
                                    </ul>
                                </div>
                            );
                        }}
                    />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <div className={"mt-3"}>
            <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
        </div>
        <div className={"row mt-3"}>
            <div className={"col"}>
                <ReturnToLastPageLink/>
            </div>
            <div className={"col text-end"}>
                <button type="submit" className="btn btn-primary ps-5 pe-5"
                        disabled={isRequesting}>{buttonText}</button>
            </div>
        </div>
    </form>;
}