import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../commons/RequestCommons"
import {Link} from "react-router-dom";
import ReactSelectDisabled from "../../../commons/ReactSelectDisabled";
import AuthCommons from "../../../commons/AuthCommons";
import ReactElementCommons from "../../../commons/ReactElementCommons";
import Select from "react-select";
import ReturnToLastPageLink from "../../shared/ReturnToLastPageLink";

export default function CreateLegacyUser(props: any) {
    const [alert, setAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [isRequesting, setIsRequesting] = useState(false);
    const [userOptions, setUserOptions]: any = useState([]);
    const [resourceOptions, setResourceOptions]: any = useState([]);
    const [legacyUserLocationOptions, setLegacyUserLocationOptions]: any = useState([]);
    const [organizationOptions, setOrganizationOptions]: any = useState([]);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
    }

    async function loadData() {
        const [users, resources, legacyUserLocations, organizations]: any = await Promise.all([
            AuthCommons.request("/users?disabled=0&order_by=last_name&order_by_direction=asc"),
            AuthCommons.request("/resources?ids=150,151"),
            AuthCommons.request("/users/legacy/locations"),
            AuthCommons.request("/organizations")
        ]);

        const userOptions: any = ReactElementCommons.createReactSelectOptionsFromList(users, ["first_name", "last_name"], "id");
        setUserOptions(userOptions);

        const resourceOptions: any = ReactElementCommons.createReactSelectOptionsFromList(resources, ["name"], "id");
        setResourceOptions(resourceOptions);

        const legacyUserLocationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(legacyUserLocations, ["name"], "id");
        setLegacyUserLocationOptions(legacyUserLocationOptions);

        const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, ["name"], "id");
        setOrganizationOptions(organizationOptions);
    }

    async function CreateLegacyUser(event: any) {
        event.preventDefault();
        try {
            setIsRequesting(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const form: any = event.target;
            const formData: any = new FormData(form);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/users/legacy";
            const id = await RequestCommons.request(url, null, payload, "POST");
            setAlert({
                class: "alert-success text-center",
                message: <>
                    <div>Created legacy user.</div>
                    <div>
                        <Link to={"/users/legacy/" + id}>Click here to update legacy user.</Link>
                    </div>
                </>
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsRequesting(false);
        }
    }

    let buttonText: any = "Create";
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Creating...</span>
            </span>;
    }

    return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
        <form onSubmit={CreateLegacyUser}>
            <h1>Create Legacy User</h1>
            <div className="mb-3">
                <label className="form-label">User</label>
                <Select
                    name="user_id"
                    options={userOptions}
                />
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Legacy User Location</label>
                <Select
                    name="legacy_user_location_id"
                    options={legacyUserLocationOptions}
                />
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Resource</label>
                <Select
                    name="resource_id"
                    options={resourceOptions}
                />
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Organization</label>
                <Select
                    name="organization_id"
                    options={organizationOptions}
                />
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Legacy User Id</label>
                <input name="legacy_user_id" className="form-control" type={"number"} required/>
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Legacy Username</label>
                <input name="legacy_username" className="form-control"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Legacy Email</label>
                <input name="legacy_email" className="form-control"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Legacy Phone Number</label>
                <input name="legacy_phone_number" className="form-control"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Legacy Business</label>
                <input name="legacy_business" className="form-control"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Legacy Department</label>
                <input name="legacy_department" className="form-control"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Legacy Role</label>
                <input name="legacy_role" className="form-control"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Disabled</label>
                <ReactSelectDisabled required/>
            </div>
            <button type="submit" className="btn btn-primary form-control" disabled={isRequesting}>{buttonText}</button>
            <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
        </form>
        <div className={"row mt-3"}>
            <div className={"col"}>
                <ReturnToLastPageLink/>
            </div>
        </div>
    </div>;
}