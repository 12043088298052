import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {MaterialReactTable} from "material-react-table";
import TimeCommons from "../../commons/TimeCommons";

export default function ViewUpdateLogs(props: any) {
    const [isLoading, setIsLoading]: any = useState(true);
    const [authUpdateTableData, setAuthUpdateTableData]: any = useState([]);
    const [startDate, setStartDate]: any = useState(TimeCommons.toYmd(TimeCommons.toDate()));
    const [endDate, setEndDate]: any = useState(TimeCommons.toYmd(TimeCommons.toDate()));

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        loadData();
    }, [startDate, endDate]);

    async function init() {
        await loadData();
    }

    async function loadData() {
        setIsLoading(true);
        const params: any = {
            limit: 500,
            populate: "user,session,session.user_role,session.user_role.email,session.user_role.role,session.user_role.role.organization,session.user_role.role.department,resource"
        }
        if (startDate) {
            params["created_start_date"] = startDate;
            delete params["limit"];
        }
        if (endDate) {
            params["created_end_date"] = endDate;
            delete params["limit"];
        }
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        const url = process.env.REACT_APP_AUTH_API + "/logs/requests/portals/auth/updates?" + queryString;
        const recentRequests: any = await RequestCommons.request(url);
        const authUpdateTableData: any = [];
        for (let i = 0; i < recentRequests.length; i++) {
            const recentRequest: any = recentRequests[i];
            recentRequest.created = TimeCommons.formatTimestamp(recentRequest.created);
            let operation = null;
            switch (recentRequest.request_method) {
                case "GET":
                    operation = "Viewed";
                    break;
                case "POST":
                    operation = "Created";
                    break;
                case "PUT":
                    operation = "Updated";
                    break;
                case "DELETE":
                    operation = "Deleted";
                    break;
            }

            recentRequest.description = operation;

            authUpdateTableData.push(recentRequest);
        }
        setAuthUpdateTableData(authUpdateTableData);
        setIsLoading(false);
    }

    const authUpdateColumns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'resource.name',
            header: 'Resource',
        },
        {
            accessorKey: 'request_url',
            header: 'Endpoint',
        },
        {
            accessorKey: 'request_method',
            header: 'Method',
        },
        {
            accessorKey: 'description',
            header: 'Description',
        },
        {
            accessorKey: 'user_id', //normal accessorKey
            header: 'User Id',
            size: 70
        },
        {
            accessorKey: 'user.first_name', //normal accessorKey
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name', //normal accessorKey
            header: 'Last Name',
        },
        {
            accessorKey: 'session.user_role.id', //normal accessorKey
            header: 'User Role Id',
            size: 70
        },
        {
            accessorKey: 'session.user_role.email.email', //normal accessorKey
            header: 'User Role Email',
        },
        {
            accessorKey: 'session.user_role.role.organization.name', //normal accessorKey
            header: 'Organization',
        },
        {
            accessorKey: 'session_id',
            header: 'Session Id',
            size: 70
        },
        {
            accessorKey: 'remote_addr',
            header: 'IP',
        },
        {
            accessorKey: 'response_status',
            header: 'Status',
        },
        {
            accessorKey: 'created',
            header: 'Created',
            size: 100
        }
    ];

    return <div className={"shadow-sm bg-white rounded border p-3 container"}>
        <h2 className={""}>Updates</h2>
        <form className={"d-inline-block position-relative"} style={{
            zIndex: 999
        }} onSubmit={async function (event: any) {
            event.preventDefault();
            await loadData();
        }}>
            <div className={"mb-3 me-3 d-inline-block"}>
                <label>Start Date</label>
                <input
                    type={"date"}
                    name={"start-date"}
                    className={"form-control"}
                    onChange={function (event: any) {
                        setStartDate(event.target.value);
                    }}
                    onClick={function (event: any) {
                        event.target.showPicker();
                    }}
                    value={startDate}
                />
            </div>
            <div className={"mb-3 me-3 d-inline-block"}>
                <label>End Date</label>
                <input
                    type={"date"}
                    name={"end-date"}
                    className={"form-control"}
                    onChange={function (event: any) {
                        setEndDate(event.target.value);
                    }}
                    onClick={function (event: any) {
                        event.target.showPicker();
                    }}
                    value={endDate}
                />
            </div>
        </form>
        <MaterialReactTable
            muiTablePaperProps={props.config.materialReactTable.muiTablePaperProps}
            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
            enableStickyHeader={true}
            columns={authUpdateColumns}
            data={authUpdateTableData}
            initialState={{
                sorting: [{
                    id: "id",
                    desc: true
                }]
            }}
            state={{
                isLoading: isLoading,
                density: "compact"
            }}
        />
    </div>;
}