import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {Link} from "react-router-dom";
import Select from "react-select";
import ReactElementCommons from "../../commons/ReactElementCommons";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";

export default function CreateDomain(props: any) {
    const [alert, setAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [isRequesting, setIsRequesting] = useState(false);
    const [organizationOptions, setOrganizationOptions] = useState([]);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
    }

    async function loadData() {
        let url = process.env.REACT_APP_AUTH_API + "/organizations";
        const organizations: any = await RequestCommons.request(url);
        const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id");
        setOrganizationOptions(organizationOptions);
    }

    async function createDomain(event: any) {
        event.preventDefault();
        try {
            setIsRequesting(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const form: any = event.target;
            const formData: any = new FormData(form);
            const domain: any = Object.fromEntries(formData);
            const body = new URLSearchParams(domain);
            const url = process.env.REACT_APP_AUTH_API + "/domains";
            const id = await RequestCommons.request(url, null, body, "post");
            setAlert({
                class: "alert-success text-center",
                message: <>
                    <div>Created domain.</div>
                    <div>
                        <Link to={"/domains/" + id}>Click here to update domain.</Link>
                    </div>
                </>
            });
        } catch(error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsRequesting(false);
        }
    }

    let buttonText: any = "Create";
    if (isRequesting) {
        buttonText = <span>
                    <span className={"loading-spinner"}>
                        <i className="bi bi-arrow-clockwise"></i>
                    </span>
                    <span className={"ms-2"}>Creating...</span>
                </span>;
    }

    return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
        <form onSubmit={createDomain}>
            <h2>Create Domain</h2>
            <div className="mb-3">
                <label className="form-label">Organization</label>
                <Select
                    name="organization_id"
                    options={organizationOptions}
                    required
                />
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Name</label>
                <input name="name" className="form-control" required/>
            </div>
            <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea name="description" className="form-control"></textarea>
            </div>
            <div className="mb-3">
                <label className="form-label">URL</label>
                <input name={"url"} className="form-control"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Disabled</label>
                <ReactSelectDisabled required/>
            </div>
            <button type="submit" className="btn btn-primary form-control"
                    disabled={isRequesting}>{buttonText}</button>
            <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
        </form>
        <div className={"row mt-3"}>
            <div className={"col"}>
                <ReturnToLastPageLink/>
            </div>
        </div>
    </div>;
}