import {Button, Modal} from "react-bootstrap";
import * as React from "react";

export default function DeleteModal(props: any) {
    const title = props.title ? props.title : <>
        <i className="bi bi-exclamation-triangle-fill me-2 text-danger d-none"/>
        <span>Delete Entry</span>
    </>;
    const body = props.body ? props.body :
        <span>Are you sure you want to delete this entry?</span>;
    const alert = props.alert ? <div className={"mt-3"}>{props.alert}</div> : null;
    return <Modal show={props.show} onHide={props.onHide} backdrop={"static"}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {body}
            {alert}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide}>
                Close
            </Button>
            <Button variant="danger" onClick={props.delete}>
                Delete
            </Button>
        </Modal.Footer>
    </Modal>;
}