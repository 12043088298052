import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../commons/RequestCommons"
import {useParams} from "react-router-dom";
import TimeCommons from "../../../commons/TimeCommons";
import AuthCommons from "../../../commons/AuthCommons";
import ReactSelectDisabled from "../../../commons/ReactSelectDisabled";
import {Alert} from "react-bootstrap";
import ReturnToLastPageLink from "../../shared/ReturnToLastPageLink";
import Loading from "../../Loading";
import DoesNotExist from "../../shared/DoesNotExist";

export default function UpdateResourceType(props: any) {
    const [alert, setAlert] = useState({
        class: "d-none",
        message: ""
    });
    const [exists, setExists] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);

    const [resourceType, setResourceType]: any = useState({});

    const params = useParams();

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setIsLoading(true);
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [response]: any = await Promise.all([
            AuthCommons.request("/resources/types/" + params.id, null, null, 'get', 'application/x-www-form-urlencoded', false)
        ]);

        if (response.status === 204) {
            setExists(false);
        } else {
            setExists(true);
            const resourceType: any = await response.json();
            setResourceType(resourceType);
        }
    }

    async function updateResourceType(event: any) {
        event.preventDefault();
        try {
            setIsUpdating(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const formData: any = new FormData(event.target);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/resources/types/" + resourceType.id;
            await RequestCommons.request(url, null, payload, "PUT");
            await loadData();
            setAlert({
                class: "alert-success text-center",
                message: "Updated."
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsUpdating(false);
        }
    }

    if (isLoading) {
        return <Loading/>;
    } else if (!exists) {
        return <DoesNotExist/>;
    } else {
        let buttonText: any = "Update";
        if (isUpdating) {
            buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Updating...</span>
            </span>;
        }

        return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
            <form onSubmit={updateResourceType}>
                <h2>Resource Type</h2>
                <div className="mb-3">
                    <label className="form-label">Id</label>
                    <input name="id" className="form-control" defaultValue={resourceType.id}
                           disabled/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input name="name" className="form-control" defaultValue={resourceType.name} required
                           disabled={!props.session.permissions.update}/>
                    <small>Required</small>
                </div>
                <div className="mb-3">
                    <label className="form-label">Description</label>
                    <textarea name="description" className="form-control"
                              disabled={!props.session.permissions.update}
                              defaultValue={resourceType.description}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Disabled</label>
                    <ReactSelectDisabled value={resourceType.disabled}
                                         isDisabled={!props.session.permissions.update}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Created</label>
                    <input value={TimeCommons.formatTimestamp(resourceType.created)}
                           className="form-control" disabled/>
                </div>
                {props.session.permissions.update ?
                    <button type="submit" className="btn btn-warning form-control"
                            disabled={isUpdating}>{buttonText}</button> : null}
                <div className={"alert mt-3 " + alert.class}>{alert.message}</div>
            </form>
            <div className={"row mt-3"}>
                <div className={"col"}>
                    <ReturnToLastPageLink/>
                </div>
            </div>
        </div>;
    }
}