import * as React from "react";
import {useEffect, useState} from "react";
import {Link, Navigate} from "react-router-dom";
import AppCommons from "../commons/AppCommons";
import AuthCommons from "../commons/AuthCommons";
import VerifyMfa from "./mfa/VerifyMfa";
import CreateMfa from "./mfa/CreateMfa";
import CookieCommons from "../commons/CookieCommons";


export default function Login(props: any) {
    const [isRequesting, setIsRequesting] = useState(false);
    const [isLoggedIn, setIsLoggedIn]: any = useState(false);
    const [mfa, setMfa]: any = useState(null);
    const [alert, setAlert] = useState({
        class: "d-none",
        message: ""
    });

    useEffect(() => {
        init();
    }, []);

    async function init() {
        try {
            const session = AppCommons.getSession();
            if (session) {
                const response: any = await AuthCommons.getSession(false);
                if (response.status === 200) {
                    setIsLoggedIn(true);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    async function login(event: any) {
        event.preventDefault();

        setIsRequesting(true);
        setAlert({
            class: "d-none",
            message: ""
        });

        AppCommons.clearSession();

        const form: any = event.target;
        const formData: any = new FormData(form);
        const credentials: any = Object.fromEntries(formData);
        return await AuthCommons.login(credentials).then(async function (response: any) {
            if (response.login) {
                const session = await AppCommons.getUserSession();
                props.setSession(session);
                setIsLoggedIn(true);
            } else {
                if (response.mfa) {
                    setMfa(response.mfa);
                }
            }
        }).catch(function (error: any) {
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error.toString()
            });
        }).finally(function () {
            setIsRequesting(false);
        });
    }

    if (isLoggedIn) {
        return <Navigate to={"/"}/>;
    } else if (mfa && mfa.status === "verify") {
        return <VerifyMfa {...props} setMfa={setMfa} mfa={mfa}/>;
    } else if (mfa && mfa.status === "create") {
        return <CreateMfa {...props} setMfa={setMfa} mfa={mfa}/>;
    } else {
        let buttonText: any = "Log in";
        if (isRequesting) {
            buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Logging in...</span>
            </span>;
        }

        const mfaTokenCookie = CookieCommons.getCookie(process.env.REACT_APP_MFA_TOKEN_COOKIE_NAME);
        const mfaToken = mfaTokenCookie ? mfaTokenCookie : "";
        return <form className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"}
                     style={{width: "100%", maxWidth: "400px"}}
                     onSubmit={login}
                     autoComplete={"on"}>
            <input type={"hidden"} name={"mfa_token"} value={mfaToken}/>
            <div className={"image-container mb-3"}>
                <img className={"w-100"} src={"/favicon.png"} style={{
                    height: "200px",
                    objectFit: "contain"
                }} alt={"favicon"}/>
            </div>
            <h1 className={"text-center mb-3"}>Auth Portal</h1>
            <div className="mb-3">
                <label className="form-label">Email</label>
                <input name={"email"} type="email" className="form-control"
                       autoComplete={"on"} autoFocus required/>
            </div>
            <div className="mb-3">
                <label className="form-label">Password</label>
                <input name={"password"} type="password" className="form-control"
                       autoComplete={"on"} required/>
            </div>
            <button type="submit" className="btn btn-primary form-control mb-3"
                    disabled={isRequesting}>{buttonText}</button>
            <div className={"alert mb-3 text-center " + alert.class}>{alert.message}</div>
            <div className={"row"}>
                <div className={"col"}>
                    <a style={{textDecoration: "none"}}
                       href={"mailto:" + process.env.REACT_APP_EMAILS_SUPPORT}>Support</a>
                </div>
                <div className={"col text-end"}>
                    <Link style={{textDecoration: "none"}} to={"/password/reset"}>Forgot password?</Link>
                </div>
            </div>
        </form>;
    }
}