import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../commons/RequestCommons"
import {MaterialReactTable} from "material-react-table";
import {Box, IconButton} from "@mui/material";
import TimeCommons from "../../../commons/TimeCommons";
import {DeleteOutlined as DeleteIcon, GroupWorkOutlined as ViewGroupIcon} from "@mui/icons-material";
import {Link} from "react-router-dom";
import ViewEntryActionButton from "../../shared/tables/ViewEntryActionButton";
import DeleteEntryActionButton from "../../shared/tables/DeleteEntryActionButton";


export default function ViewGroupDepartments(props: any) {
    const [isLoading, setIsLoading]: any=  useState(true);
    const [tableData, setTableData]: any = useState([]);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const url = process.env.REACT_APP_AUTH_API + "/groups/organizations?populate=1";
        const groupOrganizations: any = await RequestCommons.request(url);
        for (let i = 0; i < groupOrganizations.length; i++) {
            const groupOrganization = groupOrganizations[i];
            groupOrganization.disabled = groupOrganization.disabled ? "Yes" : "No";
            groupOrganization.created = TimeCommons.formatTimestamp(groupOrganization.created);
        }
        setTableData(groupOrganizations);
    }

    function deleteGroupOrganization(id: number) {
        const c = window.confirm("Are you sure you want to delete this entry?");
        if (c) {
            const url = process.env.REACT_APP_AUTH_API + "/groups/organizations/" + id;
            RequestCommons.request(url, null, null, "DELETE").then(async function (response) {
                await loadData();
            }).catch(function (error) {
                console.error(error);
                alert(error.detail);
            });
        }
    }

    function renderRowActions(data: any) {
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton to={"/groups/" + data.row.original.group.id}/>
            {props.session.permissions.delete ?
                <DeleteEntryActionButton onClick={function (event: any) {
                    event.preventDefault();
                    deleteGroupOrganization(data.row.original.id);
                }}/> : null}
        </Box>;
    }

    const defaultPageSize = 10;
    const columns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'group.id',
            header: 'Group Id',
            size: 70
        },
        {
            accessorKey: 'group.name',
            header: 'Group Name',
        },
        {
            accessorKey: 'group.organization.name',
            header: 'Group Organization',
        },
        {
            accessorKey: 'organization.name',
            header: 'Organization Name',
        },
        {
            accessorKey: 'created',
            header: 'Created',
            size: 100
        }
    ];

    const initialState = {
        sorting: [
            {
                id: 'group.name', //sort by age by default on page load
                desc: false
            },
            {
                id: 'organization.name', //sort by age by default on page load
                desc: false
            }
        ],
        pagination: {
            pageSize: defaultPageSize,
            pageIndex: 0
        }
    };

    return <div className={"m-auto container shadow-sm bg-white rounded border"}>
        <MaterialReactTable
            muiTablePaperProps={props.config.materialReactTable.muiTablePaperProps}
            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
            enableStickyHeader={true}
            columns={columns}
            data={tableData}
            state={{
                isLoading: isLoading,
                density: "compact"
            }}
            initialState={initialState}
            enableRowActions={true} renderRowActions={renderRowActions}
            enablePagination={true}
            renderTopToolbarCustomActions={() => {
                return (
                    <div>
                        <h2 className={"me-3 d-inline-block align-top"}>Group Organizations</h2>
                    </div>
                );
            }}
        />
    </div>;
}