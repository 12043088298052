import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {Link, useNavigate, useParams} from "react-router-dom";
import TimeCommons from "../../commons/TimeCommons";
import {MaterialReactTable} from "material-react-table";
import {Box} from "@mui/material";
import Select from "react-select"
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import AuthCommons from "../../commons/AuthCommons";
import AuthorizationsTable from "../authorizations/AuthorizationsTable";
import {Accordion} from "react-bootstrap";
import Loading from "../Loading";
import DisableEntryActionButton from "../shared/tables/DisableEntryActionButton";
import ReactElementCommons from "../../commons/ReactElementCommons";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";
import DoesNotExist from "../shared/DoesNotExist";
import EntryDeleted from "../shared/EntryDeleted";
import DeleteModal from "../shared/DeleteModal";

export default function UpdateUser(props: any) {
    const navigate = useNavigate();
    const params = useParams();
    const [userId]: any = useState(params.id);

    const [userDeleted, setUserDeleted]: any = useState(false);
    const [userAlert, setUserAlert] = useState({
        class: "d-none",
        message: ""
    });

    const [resetPasswordAlert, setResetPasswordAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [userExists, setUserExists]: any = useState(null);
    const [user, setUser]: any = useState({});
    const [avatar, setAvatar]: any = useState(null);

    const [authorizations, setAuthorizations]: any = useState([]);

    const [userRoles, setUserRoles]: any = useState([]);
    const [userRolesTableData, setUserRolesTableData]: any = useState([]);
    const [isDualHat, setIsDualHat]: any = useState(false);

    const [userMfaSecretActiveTableData, setUserMfaSecretsTableData] = useState([]);

    const [userPrimaryEmailOptions, setUserPrimaryEmailOptions]: any = useState([]);
    const [userPrimaryEmailSelect, setUserPrimaryEmailSelect]: any = useState(null);

    const [userPrimaryPhoneNumberOptions, setUserPrimaryPhoneNumberOptions]: any = useState([]);
    const [userPrimaryPhoneNumberSelect, setUserPrimaryPhoneNumberSelect]: any = useState(null);

    const [changesMade, setChangesMade] = useState(false);

    const [deleteModalShow, setDeleteModalShow]: any = useState(false);
    const [deleteModalAlert, setDeleteModalAlert]: any = useState(null);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setIsLoading(true);
        await loadData();
    }

    async function loadData() {
        const [userResponse]: any = await Promise.all([
            RequestCommons.request(process.env.REACT_APP_AUTH_API + "/users/" + userId, null, null, 'get', 'application/x-www-form-urlencoded', false)
        ]);

        if (userResponse.status === 204) {
            // display not exists warning
            setUserExists(false);
            setIsLoading(false);
        } else {
            setUserExists(true);
            await loadUser();
            setIsLoading(false);
            await Promise.all([
                loadUserRoles(),
                loadAuthorizations(),
                loadMfa()
            ]);
        }
    }

    async function loadUser() {
        let url = process.env.REACT_APP_AUTH_API + "/users/" + userId + "?populate=1";
        const user: any = await RequestCommons.request(url);
        setUser(user);
        setAvatar(user.avatar);

        url = process.env.REACT_APP_AUTH_API + "/users/emails?user_id=" + params.id + "&populate=1";
        const userEmails: any = await RequestCommons.request(url);
        const userPrimaryEmailOptions: any = ReactElementCommons.createReactSelectOptionsFromList(userEmails, "email.email", "email_id");
        setUserPrimaryEmailOptions(userPrimaryEmailOptions);
        setUserPrimaryEmailSelect(userPrimaryEmailOptions.find((option: any) => option.value === user.primary_email_id));

        url = process.env.REACT_APP_AUTH_API + "/users/numbers/phone?user_id=" + params.id + "&populate=1";
        const userPhoneNumbers: any = await RequestCommons.request(url);
        const userPrimaryPhoneNumberOptions: any = ReactElementCommons.createReactSelectOptionsFromList(userPhoneNumbers, "phone_number.phone_number", "phone_number_id", null, true);
        setUserPrimaryPhoneNumberOptions(userPrimaryPhoneNumberOptions);
        setUserPrimaryPhoneNumberSelect(userPrimaryPhoneNumberOptions.find((option: any) => option.value === user.primary_phone_number_id));
    }

    async function loadUserRoles() {
        let url = process.env.REACT_APP_AUTH_API + "/users/roles?user_id=" + userId + "&populate=1";
        const userRoles: any = await RequestCommons.request(url);
        setUserRoles(userRoles);
        setIsDualHat(userRoles.length > 1);

        const userRolesTableData: any = [];
        for (let i = 0; i < userRoles.length; i++) {
            const userRole = userRoles[i];
            userRole.created = TimeCommons.formatTimestamp(userRole.created);
            userRole.disabled = userRole.disabled ? "Yes" : "No";
            userRolesTableData.push(userRole);
        }
        setUserRolesTableData(userRolesTableData);
    }

    async function loadAuthorizations() {
        const url = process.env.REACT_APP_AUTH_API + "/authorizations?user_id=" + userId + "&populate=1";
        const authorizations: any = await RequestCommons.request(url);
        setAuthorizations(authorizations);
    }

    async function loadMfa() {
        try {
            const secrets: any = await AuthCommons.request("/users/secrets/mfa?user_id=" + userId + "&disabled=0&populate=1");
            for (let i = 0; i < secrets.length; i++) {
                const secret = secrets[i];
                secret.created = TimeCommons.formatTimestamp(secret.created);
            }
            setUserMfaSecretsTableData(secrets);
        } catch (error) {
            console.error(error);
        }
    }

    async function updateUser(event: any) {
        event.preventDefault();
        try {
            setIsUpdating(true);
            setUserAlert({
                class: "d-none",
                message: ""
            });
            const formData: any = new FormData(event.target);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/users/" + userId;
            await RequestCommons.request(url, null, payload, "PUT");
            await loadUser();
            await loadAuthorizations();
            setUserAlert({
                class: "alert-success text-center",
                message: "Updated."
            });
        } catch (error: any) {
            console.error(error);
            setUserAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setChangesMade(false);
            setIsUpdating(false);
        }
    }

    function disableUserMfaSecret(id: number) {
        const c = window.confirm("Are you sure you want to disable this entry?");
        if (c) {
            const url = process.env.REACT_APP_AUTH_API + "/users/secrets/mfa/" + id;
            const body = {"disabled": "1"}
            const payload = new URLSearchParams(body);
            RequestCommons.request(url, null, payload, "put").then(async function () {
                await loadMfa();
            }).catch(function (error) {
                setUserAlert({
                    class: "alert-danger",
                    message: error.detail ? error.detail : error
                });
            });
        }
    }

    function renderUserMfaSecretRowActions(data: any) {
        let actions: any = null;
        if (!data.row.original.disabled) {
            actions = <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
                <DisableEntryActionButton onClick={function () {
                    disableUserMfaSecret(data.row.original.id)
                }}/>
            </Box>;
        }
        return actions;
    }

    function sendResetPasswordEmail() {
        const confirmed = window.confirm("Are you sure?");
        if (confirmed) {
            const formData: any = new FormData();
            formData.append("email", user.primary_email.email)
            const credentials: any = Object.fromEntries(formData);
            const body = new URLSearchParams(credentials);

            const url = process.env.REACT_APP_AUTH_API + "/password/reset";
            RequestCommons.request(url, process.env.REACT_APP_CONSUMER_TOKEN, body, "post").then(function (response: any) {
                setResetPasswordAlert({
                    class: "alert-success",
                    message: "Password reset email was sent."
                });
            }).catch(function (error) {
                setResetPasswordAlert({
                    class: "alert-danger",
                    message: error.detail ? error.detail : error
                });
            });
        }
    }

    function sendSetupAccountEmail() {
        const confirmed = window.confirm("Are you sure?");
        if (confirmed) {
            const formData: any = new FormData();
            formData.append("user_id", user.id);
            const credentials: any = Object.fromEntries(formData);
            const body = new URLSearchParams(credentials);

            const url = process.env.REACT_APP_AUTH_API + "/account/setup";
            RequestCommons.request(url, process.env.REACT_APP_CONSUMER_TOKEN, body, "post").then(function (response: any) {
                setResetPasswordAlert({
                    class: "alert-success",
                    message: "New account email was sent."
                });
            }).catch(function (error) {
                setResetPasswordAlert({
                    class: "alert-danger",
                    message: error.detail ? error.detail : error
                });
            });
        }
    }

    function deleteUser(event: any) {
        event.preventDefault();
        const url = process.env.REACT_APP_AUTH_API + "/users/" + user.id;
        let deleteModalAlert = <div className={"alert alert-success"}>
            User deleted.
        </div>;
        RequestCommons.request(url, null, null, "DELETE").then(async function (response) {
            await loadData();
            setUserDeleted(true);
        }).catch(function (error) {
            console.error(error);
            deleteModalAlert = <>
                <div className={"alert alert-danger"}>
                    <span>The entry you are trying to delete likely still has entries linked to it.
                        Remove all links from the accordion dropdowns below before deleting.</span>
                </div>
                <Accordion className={"mb-3"} defaultActiveKey={[]}>
                    <Accordion.Item eventKey="details">
                        <Accordion.Header>
                            Details
                        </Accordion.Header>
                        <Accordion.Body>
                            {error.detail}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </>;
        }).finally(function () {
            setDeleteModalAlert(deleteModalAlert);
        });
    }

    function toggleDeleteModalShow() {
        setDeleteModalAlert(null);
        setDeleteModalShow(!deleteModalShow);
    }

    const userRolesTableColumns: any = [
        {
            accessorKey: 'role.organization.name',
            header: 'Organization',
        },
        {
            accessorKey: 'role.department.name',
            header: 'Department',
        },
        {
            accessorKey: 'role.name',
            header: 'Role',
        },
        {
            accessorKey: 'email.email',
            header: 'Email',
        },
        {
            accessorKey: 'disabled', //normal accessorKey
            header: 'Disabled',
        }
    ];

    const userMfaSecretsTableColumns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'policy.name', //access nested data with dot notation
            header: 'Policy Name',
        },
        {
            accessorKey: 'policy.domain.name', //access nested data with dot notation
            header: 'Policy Domain',
        },
        {
            accessorKey: 'created',
            header: 'Created',
            size: 100
        }
    ];

    if (isLoading) {
        return <Loading/>;
    } else if (userDeleted) {
        return <EntryDeleted/>;
    } else if (!userExists) {
        return <DoesNotExist/>;
    } else {
        let buttonText: any = "Update";
        if (isUpdating) {
            buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Updating...</span>
            </span>;
        }

        let avatarPreview = null;
        if (avatar) {
            avatarPreview = <div className={"p-5 mt-3 border text-center"}>
                <img style={{
                    height: "100px"
                }}
                     className={"rounded-circle"}
                     src={avatar}
                     alt={avatar}/>
            </div>
        }

        return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " container m-auto w-100"}>
            <DeleteModal show={deleteModalShow} onHide={toggleDeleteModalShow} delete={deleteUser}
                         alert={deleteModalAlert}/>
            <div className={"mb-3 d-none"}>
                <ReturnToLastPageLink/>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <h2 className={"align-middle"}>
                        <i className="d-inline-block align-middle bi bi-person-fill me-3"/>
                        <span className="d-inline-block align-middle">User</span>
                    </h2>
                </div>
                <div className={"col text-end"}>
                    {props.session.permissions.delete ?
                        <form onSubmit={function (event: any) {
                            event.preventDefault();
                            toggleDeleteModalShow();
                        }}>
                            <button className={"btn btn-danger"} title={"Delete"}>
                                <i className="bi bi-trash-fill"/>
                            </button>
                        </form> : null}
                </div>
            </div>
            <form onSubmit={updateUser} className={"mb-3"}>
                <div className={"row"}>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Id</label>
                        <input name="id" className="form-control" defaultValue={user.id} disabled/>
                    </div>
                    <div className="col-12 col-lg mb-3 d-none">
                        <label className="form-label">Primary Email</label>
                        <Select name={"primary_email_id"}
                                onChange={function (option: any) {
                                    setUserPrimaryEmailSelect(option);
                                    setChangesMade(true);
                                }}
                                isDisabled={!props.session.permissions.update}
                                value={userPrimaryEmailSelect}
                                options={userPrimaryEmailOptions}
                        />
                        <small>Required</small>
                    </div>
                    <div className="col-12 col-lg mb-3 d-none">
                        <label className="form-label">Primary Phone Number</label>
                        <Select name={"primary_phone_number_id"}
                                onChange={function (option: any) {
                                    setUserPrimaryPhoneNumberSelect(option);
                                    setChangesMade(true);
                                }}
                                isDisabled={!props.session.permissions.update}
                                value={userPrimaryPhoneNumberSelect}
                                options={userPrimaryPhoneNumberOptions}
                        />
                        <small>Optional</small>
                    </div>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">First Name</label>
                        <input name="first_name" className="form-control"
                               disabled={!props.session.permissions.update}
                               defaultValue={user.first_name} onChange={() => setChangesMade(true)}/>
                        <small>Required</small>
                    </div>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Last Name</label>
                        <input name="last_name" className="form-control" defaultValue={user.last_name}
                               disabled={!props.session.permissions.update}
                               onChange={() => setChangesMade(true)}/>
                        <small>Required</small>
                    </div>
                    <div className="col-12 col-lg d-none">
                        <label className="form-label">Date of Birth</label>
                        <input type={"date"} name={"date_of_birth"} className="form-control"
                               disabled={!props.session.permissions.update}
                               defaultValue={user.date_of_birth} onChange={() => setChangesMade(true)}/>
                    </div>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Disabled</label>
                        <ReactSelectDisabled value={user.disabled} onChange={function () {
                            setChangesMade(true);
                        }} required isDisabled={!props.session.permissions.update} highlightDisabledRed={true}/>
                    </div>
                    <div className="col-12 col-lg mb-3 d-none">
                        <label className="form-label">Avatar</label>
                        <input name="avatar" className="form-control"
                               defaultValue={user.avatar}
                               placeholder={"Enter a URL"}
                               disabled={!props.session.permissions.update}
                               onKeyUp={function (event: any) {
                                   setAvatar(event.target.value);
                               }}
                               onChange={() => setChangesMade(true)}
                        />
                        {avatarPreview}
                    </div>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Created</label>
                        <input value={TimeCommons.formatTimestamp(user.created)}
                               className="form-control"
                               disabled/>
                    </div>
                </div>
                <Accordion defaultActiveKey={["0"]} className={"mb-3"}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Roles ({userRolesTableData.length})</Accordion.Header>
                        <Accordion.Body>
                            <MaterialReactTable
                                muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                columns={userRolesTableColumns}
                                data={userRolesTableData}
                                initialState={{
                                    sorting: [
                                        {
                                            id: "id",
                                            desc: false
                                        }
                                    ]
                                }}
                                state={{
                                    isLoading: false,
                                    density: "compact"
                                }}
                                positionToolbarAlertBanner={"none"}
                                getRowId={(originalRow: any) => originalRow.id}
                                muiTableBodyRowProps={({row}: any) => ({
                                    onClick: (event) => {
                                        navigate("/users/roles/" + row.original.id);
                                    },
                                    sx: {
                                        cursor: 'pointer'
                                    }
                                })}
                                renderTopToolbarCustomActions={() => {
                                    return (
                                        <div>
                                            <h2 className={"me-3 d-inline-block align-top"}>Roles</h2>
                                            {props.session.permissions.update ?
                                                <Link to={"/users/" + userId + "/roles/create"}
                                                      className={"btn btn-primary d-inline-block"}>Add</Link>
                                                : null}
                                        </div>
                                    );
                                }}
                                renderBottomToolbar={false}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Authorizations ({authorizations.length})</Accordion.Header>
                        <Accordion.Body>
                            <AuthorizationsTable {...props} authorizations={authorizations} showName={false}/>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Account Settings</Accordion.Header>
                        <Accordion.Body>
                            <MaterialReactTable
                                muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                columns={userMfaSecretsTableColumns}
                                data={userMfaSecretActiveTableData}
                                state={{
                                    isLoading: false,
                                    sorting: [{
                                        id: "id",
                                        desc: true
                                    }],
                                    density: "compact"
                                }}
                                getRowId={(originalRow: any) => originalRow.id}
                                enableRowActions={true}
                                positionToolbarAlertBanner={"none"}
                                renderRowActions={renderUserMfaSecretRowActions}
                                renderBottomToolbar={false}
                                renderToolbarInternalActions={() => <div></div>}
                                renderTopToolbarCustomActions={() => {
                                    return <>
                                        <h2 className={"me-3"}>MFA Secrets</h2>
                                    </>;
                                }}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <div className={"mb-3"}>
                    <div className={"alert mt-3 text-center " + userAlert.class}>{userAlert.message}</div>
                </div>
                <div className={"row"}>
                    <div className={"col"}>
                        <ReturnToLastPageLink/>
                    </div>
                    <div className={"col text-end"}>
                        {props.session.permissions.update ?
                            <button type="submit" className="btn btn-warning ms-auto ps-5 pe-5"
                                    disabled={isUpdating}>{buttonText}</button> : null}
                    </div>
                </div>
            </form>
        </div>;
    }
}