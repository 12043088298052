import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../../commons/RequestCommons"
import TimeCommons from "../../../../commons/TimeCommons";
import {Box} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import {Link} from "react-router-dom";
import ViewEntryActionButton from "../../../shared/tables/ViewEntryActionButton";
import DeleteEntryActionButton from "../../../shared/tables/DeleteEntryActionButton";

export default function ViewUserPhoneNumbers(props: any) {
    const [tableData, setTableData]: any = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        let url = process.env.REACT_APP_AUTH_API + "/users/numbers/phone?populate=1";
        const userPhoneNumbers: any = await RequestCommons.request(url);

        const tableData: any = [];
        for (let i = 0; i < userPhoneNumbers.length; i++) {
            const userPhoneNumber = userPhoneNumbers[i];
            userPhoneNumber.primary = userPhoneNumber.phone_number_id === userPhoneNumber.user.primary_phone_number_id ? "Yes" : "No";
            userPhoneNumber.created = TimeCommons.formatTimestamp(userPhoneNumber.created);
            tableData.push(userPhoneNumber);
        }
        setTableData(tableData);
    }

    function deleteUserPhoneNumber(id: number) {
        const c = window.confirm("Are you sure you want to delete this entry?");
        if (c) {
            const url = process.env.REACT_APP_AUTH_API + "/users/numbers/phone/" + id;
            RequestCommons.request(url, null, null, "DELETE").then(async function (response) {
                await loadData();
            }).catch(function (error) {
                console.error(error);
                alert(error.detail);
            });
        }
    }

    function renderRowActions(data: any) {
        const id = data.row.original.id;
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton to={"/numbers/phone/" + id}/>
            {props.session.permissions.delete ?
                <DeleteEntryActionButton onClick={function (event: any) {
                    event.preventDefault();
                    deleteUserPhoneNumber(id);
                }}/> : null}
        </Box>;
    }

    const createLink = props.session.permissions.update ? <Link to={"/users/numbers/phone/create"}
                                                                className={"d-inline-block btn btn-primary"}
    >
        Create
    </Link> : null;

    const columns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'user.id', //access nested data with dot notation
            header: 'User Id',
            size: 70
        },
        {
            accessorKey: 'user.first_name', //access nested data with dot notation
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name', //access nested data with dot notation
            header: 'Last Name',
        },
        {
            accessorKey: 'phone_number.phone_number', //access nested data with dot notation
            header: 'Phone Number',
        },
        {
            accessorKey: 'phone_number.organization.name', //access nested data with dot notation
            header: 'Organization',
        },
        {
            accessorKey: 'created',
            header: 'Created',
            size: 100
        }
    ];

    const defaultPageSize = 10;
    const initialState = {
        sorting: [
            {
                id: 'user.last_name', //sort by age by default on page load
                desc: false
            },
            {
                id: 'user.first_name', //sort by age by default on page load
                desc: false
            },
            {
                id: 'phone_number.organization.name', //sort by age by default on page load
                desc: false
            }
        ],
        pagination: {
            pageSize: defaultPageSize,
            pageIndex: 0
        }
    };

    return <div className={"m-auto container shadow-sm bg-white rounded border"}>
        <MaterialReactTable
            muiTablePaperProps={props.config.materialReactTable.muiTablePaperProps}
            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
            enableStickyHeader={true}
            columns={columns}
            data={tableData}
            state={{
                isLoading: isLoading,
                density: "compact"
            }}
            initialState={initialState}
            enableRowActions={true}
            renderRowActions={renderRowActions}
            enablePagination={true}
            renderTopToolbarCustomActions={() => {
                return (
                    <div>
                        <h2 className={"me-3 d-inline-block align-top"}>User Phone Numbers</h2>
                        {createLink}
                    </div>
                );
            }}
        />
    </div>;
}