import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../commons/RequestCommons"
import {Link, useParams} from "react-router-dom";
import ReactSelectDisabled from "../../../commons/ReactSelectDisabled";
import AuthCommons from "../../../commons/AuthCommons";
import ReactElementCommons from "../../../commons/ReactElementCommons";
import Select from "react-select";
import {Alert} from "react-bootstrap";
import ReturnToLastPageLink from "../../shared/ReturnToLastPageLink";

export default function UpdateLegacyUser(props: any) {
    const [alert, setAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [legacyExists, setLegacyExists] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [legacyUser, setLegacyUser]: any = useState({});
    const [userOptions, setUserOptions]: any = useState([]);
    const [userRoleOptions, setUserRoleOptions]: any = useState([]);
    const [resourceOptions, setResourceOptions]: any = useState([]);
    const [legacyUserLocationOptions, setLegacyUserLocationOptions]: any = useState([]);
    const [organizationOptions, setOrganizationOptions]: any = useState([]);
    const [selectedUser, setSelectedUser]: any = useState(null);
    const [selectedUserRole, setSelectedUserRole]: any = useState(null);
    const [selectedLegacyUserLocation, setSelectedLegacyUserLocation]: any = useState(null);
    const [selectedResource, setSelectedResource]: any = useState(null);
    const [selectedOrganization, setSelectedOrganization]: any = useState(null);

    const params = useParams();

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setIsLoading(true);
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [legacyUserResponse]: any = await Promise.all([
            AuthCommons.request("/users/legacy/" + params.id + "?populate=1", null, null, 'get', 'application/x-www-form-urlencoded', false)
        ]);

        if (legacyUserResponse.status === 204) {
            setLegacyExists(false);
        } else {
            const legacyUser = await legacyUserResponse.json();
            const [users, resources, legacyUserLocations, organizations, userRoles]: any = await Promise.all([
                AuthCommons.request("/users?disabled=0&order_by=last_name&order_by_direction=asc"),
                AuthCommons.request("/resources?ids=150,151"),
                AuthCommons.request("/users/legacy/locations"),
                AuthCommons.request("/organizations"),
                AuthCommons.request("/users/roles?user_id=" + legacyUser.user_id + "&populate=1")
            ]);

            console.log(userRoles);

            setLegacyUser(legacyUser);

            const userOptions: any = ReactElementCommons.createReactSelectOptionsFromList(users, ["first_name", "last_name"], "id");
            setUserOptions(userOptions);
            setSelectedUser(userOptions.find((option: any) => option.value === legacyUser.user_id));

            const userRoleOptions: any = ReactElementCommons.createReactSelectOptionsFromList(userRoles, "email.email", "email_id");
            setUserRoleOptions(userRoleOptions);
            setSelectedUserRole(userRoleOptions.find((option: any) => option.value === legacyUser.user_role_id));

            const resourceOptions: any = ReactElementCommons.createReactSelectOptionsFromList(resources, ["name"], "id");
            setResourceOptions(resourceOptions);
            setSelectedResource(resourceOptions.find((option: any) => option.value === legacyUser.resource_id));

            const legacyUserLocationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(legacyUserLocations, ["name"], "id");
            setLegacyUserLocationOptions(legacyUserLocationOptions);
            setSelectedLegacyUserLocation(legacyUserLocationOptions.find((option: any) => option.value === legacyUser.legacy_user_location_id));

            const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, ["name"], "id");
            setOrganizationOptions(organizationOptions);
            setSelectedOrganization(organizationOptions.find((option: any) => option.value === legacyUser.organization_id));
            setLegacyExists(true);
        }
    }

    async function UpdateLegacyUser(event: any) {
        event.preventDefault();
        setIsUpdating(true);

        const form: any = event.target;
        const formData: any = new FormData(form);
        const payload = new URLSearchParams(formData);

        setAlert({
            class: "d-none",
            message: ""
        });

        const url = process.env.REACT_APP_AUTH_API + "/users/legacy/" + legacyUser.id;

        await RequestCommons.request(url, null, payload, "PUT").then(function (response) {
            setAlert({
                class: "alert-success text-center",
                message: "Updated."
            });
        }).catch(function (error) {
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        });

        setIsUpdating(false);
    }

    if (isLoading) {
        return <div className={"container"}>
            <Alert variant={"primary"}>
                <div className="spinner-border spinner-border-sm me-2"
                     role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <span>Loading...</span>
            </Alert>
        </div>;
    } else if (!legacyExists) {
        // render warning alert
        return <div className={"container"}>
            <Alert variant={"primary"}>
                <div className="bi bi-exclamation-triangle-fill  me-2"
                     role="status">
                    <span className="visually-hidden">No Entry Exists.</span>
                    <span>  No Entry Exists.</span>
                </div>
            </Alert>
        </div>;
    } else {
        let buttonText: any = "Update";
        if (isUpdating) {
            buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Updating...</span>
            </span>;
        }

        return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
            <form onSubmit={UpdateLegacyUser}>
                <h1>Update Legacy User</h1>
                <div className="mb-3">
                    <label className="form-label">Id</label>
                    <input value={params.id} name="id" className="form-control" type={"number"} disabled/>
                </div>
                <div className="mb-3">
                    <label className="form-label">User</label>
                    <Select
                        name="user_id"
                        options={userOptions}
                        value={selectedUser}
                        onChange={function (option) {
                            setSelectedUser(option);
                        }}
                    />
                    <small>Required</small>
                </div>
                <div className="mb-3">
                    <label className="form-label">User Role</label>
                    <Select
                        name="user_role_id"
                        options={userRoleOptions}
                        value={selectedUserRole}
                        onChange={function (option) {
                            setSelectedUserRole(option);
                        }}
                    />
                    <small>Required</small>
                </div>
                <div className="mb-3">
                    <label className="form-label">Legacy User Location</label>
                    <Select
                        name="legacy_user_location_id"
                        options={legacyUserLocationOptions}
                        value={selectedLegacyUserLocation}
                        onChange={function (option) {
                            setSelectedLegacyUserLocation(option);
                        }}
                    />
                    <small>Required</small>
                </div>
                <div className="mb-3">
                    <label className="form-label">Resource</label>
                    <Select
                        name="resource_id"
                        options={resourceOptions}
                        value={selectedResource}
                        onChange={function (option) {
                            setSelectedResource(option);
                        }}
                    />
                    <small>Required</small>
                </div>
                <div className="mb-3">
                    <label className="form-label">Organization</label>
                    <Select
                        name="organization_id"
                        options={organizationOptions}
                        value={selectedOrganization}
                        onChange={function (option) {
                            setSelectedOrganization(option);
                        }}
                    />
                    <small>Required</small>
                </div>
                <div className="mb-3">
                    <label className="form-label">Legacy User Id</label>
                    <input name="legacy_user_id" className="form-control" type={"number"}
                           defaultValue={legacyUser.legacy_user_id} required/>
                    <small>Required</small>
                </div>
                <div className="mb-3">
                    <label className="form-label">Legacy Username</label>
                    <input name="legacy_username" className="form-control" defaultValue={legacyUser.legacy_username}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Legacy Email</label>
                    <input name="legacy_email" className="form-control" defaultValue={legacyUser.legacy_email}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Legacy Phone Number</label>
                    <input name="legacy_phone_number" className="form-control"
                           defaultValue={legacyUser.legacy_phone_number}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Legacy Business</label>
                    <input name="legacy_business" className="form-control" defaultValue={legacyUser.legacy_business}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Legacy Department</label>
                    <input name="legacy_department" className="form-control"
                           defaultValue={legacyUser.legacy_department}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Legacy Role</label>
                    <input name="legacy_role" className="form-control" defaultValue={legacyUser.legacy_role}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Disabled</label>
                    <ReactSelectDisabled value={legacyUser.disabled}/>
                </div>
                <button type="submit" className="btn btn-warning form-control"
                        disabled={isUpdating}>{buttonText}</button>
                <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
            </form>
            <div className={"row mt-3"}>
                <div className={"col"}>
                    <ReturnToLastPageLink/>
                </div>
            </div>
        </div>;
    }
}