import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {Box} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import {Link, useNavigate} from "react-router-dom";
import AuthCommons from "../../commons/AuthCommons";
import TimeCommons from "../../commons/TimeCommons";
import ViewEntryActionButton from "../shared/tables/ViewEntryActionButton";
import DeleteEntryActionButton from "../shared/tables/DeleteEntryActionButton";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";

export default function ViewPolicies(props: any) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading]: any = useState(true);
    const [tableData, setTableData]: any = useState([]);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [policies]: any = await Promise.all([
            AuthCommons.request("/policies?populate=1")
        ]);

        const tableData: any = [];
        for (let i = 0; i < policies.length; i++) {
            const policy = policies[i];
            policy.disabled = policy.disabled ? "Yes" : "No";
            policy.created = TimeCommons.formatTimestamp(policy.created);
            tableData.push(policy);
        }
        setTableData(tableData);
    }

    const createLink = props.session.permissions.update ? <Link to={"/policies/create"}
                                                                className={"d-inline-block btn btn-primary"}
    >
        Create
    </Link> : null;


    const columns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id'
        },
        {
            accessorKey: 'organization.name',
            header: 'Organization'
        },
        {
            accessorKey: 'name', //normal accessorKey
            header: 'Name',
            size: 300,
        },
        {
            accessorKey: 'description', //normal accessorKey
            header: 'Description',
            size: 500
        },
        {
            accessorKey: 'disabled', //normal accessorKey
            header: 'Disabled'
        }
    ];

    const defaultPageSize = 10;
    const initialState = {
        showColumnFilters: true,
        sorting: [
            {
                id: 'id', //sort by age by default on page load
                desc: true
            }
        ],
        pagination: {
            pageSize: defaultPageSize,
            pageIndex: 0
        },
        columnFilters: [
            {
                id: 'disabled',
                value: "No"
            }
        ],
    };

    return <div className={"m-auto container shadow-sm bg-white rounded border p-3"}>
        <div className={"mb-3 d-none"}>
            <ReturnToLastPageLink/>
        </div>
        <MaterialReactTable
            muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
            enableStickyHeader={true}
            columns={columns}
            data={tableData}
            state={{
                isLoading: isLoading,
                density: "compact"
            }}
            muiTableBodyCellProps={{
                sx: {
                    whiteSpace: "wrap",
                    textWrap: "break-word"
                },
            }}
            initialState={initialState}
            enablePagination={true}
            enableColumnResizing={true}
            renderTopToolbarCustomActions={() => {
                return (
                    <div>
                        <h2 className={"me-3 d-inline-block align-top"}>
                            <i className="bi bi-shield-lock-fill me-3 align-middle"/>
                            <span>Policies</span>
                        </h2>
                        {/*createLink*/}
                    </div>
                );
            }}
            muiTableBodyRowProps={({row}: any) => ({
                onClick: (event) => {
                    navigate("/policies/" + row.original.id);
                },
                sx: {
                    cursor: 'pointer',
                    background: row.original.disabled === "Yes" ? "#dc3545" : ""
                },
            })}
        />
        <div className={"mt-3 d-none"}>
            <ReturnToLastPageLink/>
        </div>
    </div>;
}