import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../../commons/RequestCommons"
import TimeCommons from "../../../../commons/TimeCommons";
import {MaterialReactTable} from "material-react-table";

export default function ViewLegacyUserLocations(props: any) {
    const [tableData, setTableData]: any = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        let url = process.env.REACT_APP_AUTH_API + "/users/legacy/locations";
        const legacyUserLocations: any = await RequestCommons.request(url);

        const tableData: any = [];
        for (let i = 0; i < legacyUserLocations.length; i++) {
            const legacyUserLocation = legacyUserLocations[i];
            tableData.push({
                id: legacyUserLocation.id,
                name: legacyUserLocation.name,
                description: legacyUserLocation.description,
                database_name: legacyUserLocation.database_name,
                table_name: legacyUserLocation.table_name,
                disabled: legacyUserLocation.disabled ? "Yes" : "No",
                created: TimeCommons.formatTimestamp(legacyUserLocation.created)
            });
        }
        setTableData(tableData);
    }

    const columns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'database_name', //normal accessorKey
            header: 'Database',
        },
        {
            accessorKey: 'table_name', //normal accessorKey
            header: 'Table',
        },
        {
            accessorKey: 'disabled',
            header: 'Disabled',
        },
        {
            accessorKey: 'created',
            header: 'Created',
            size: 100
        }
    ];

    return <div className={"m-auto container shadow-sm bg-white rounded border"}>
        <MaterialReactTable
            muiTablePaperProps={props.config.materialReactTable.muiTablePaperProps}
            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
            enableStickyHeader={true}
            columns={columns}
            data={tableData}
            state={{
                isLoading: isLoading,
                density: "compact"
            }}
            renderTopToolbarCustomActions={() => {
                return (
                    <div>
                        <h2 className={"me-3 d-inline-block align-top"}>Legacy User Locations</h2>
                    </div>
                );
            }}
            enablePagination={true}
        />
    </div>;
}