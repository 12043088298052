import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {useParams} from "react-router-dom";
import TimeCommons from "../../commons/TimeCommons";
import AuthCommons from "../../commons/AuthCommons";
import Select from "react-select";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import ReactElementCommons from "../../commons/ReactElementCommons";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";
import Loading from "../Loading";
import DoesNotExist from "../shared/DoesNotExist";

export default function UpdateDomain(props: any) {
    const [alert, setAlert] = useState({
        class: "d-none",
        message: ""
    });
    const [domainExists, setDomainExists] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [domain, setDomain]: any = useState({});
    const [organizationOptions, setOrganizationOptions] = useState([]);
    const [organizationSelect, setOrganizationSelect]: any = useState(null);

    const params = useParams();

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setIsLoading(true);
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [response]: any = await Promise.all([
            AuthCommons.request("/domains/" + params.id + "?populate=1", null, null, 'get', 'application/x-www-form-urlencoded', false)
        ]);
        if (response.status === 204) {
            setDomainExists(false);
        } else {
            setDomainExists(true);
            const domain: any = await response.json();
            setDomain(domain);
            const organizations: any = await AuthCommons.request("/organizations");
            const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id");
            setOrganizationOptions(organizationOptions);
            setOrganizationSelect(organizationOptions.find((option: any) => option.value === domain.organization_id));
        }
    }

    async function updateDomain(event: any) {
        event.preventDefault();
        try {
            setIsUpdating(true);
            setAlert({
                class: "d-none",
                message: ""
            });

            const url = process.env.REACT_APP_AUTH_API + "/domains/" + domain.id;
            const form: any = event.target;
            const formData = new FormData(form);
            const group: any = Object.fromEntries(formData);
            const body = new URLSearchParams(group);
            await RequestCommons.request(url, null, body, "PUT");
            await loadData();
            setAlert({
                class: "alert-success text-center",
                message: "Updated."
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsUpdating(false);
        }
    }

    if (isLoading) {
        return <Loading/>;
    } else if (!domainExists) {
        return <DoesNotExist/>;
    } else {
        let buttonText: any = "Update";
        if (isUpdating) {
            buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Updating...</span>
            </span>;
        }
        return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
            <form onSubmit={updateDomain}>
                <h2>Domain</h2>
                <div className="mb-3">
                    <label className="form-label">Id</label>
                    <input name="id" className="form-control" defaultValue={domain.id} disabled/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Organization</label>
                    <Select
                        isDisabled={!props.session.permissions.update}
                        name="organization_id"
                        required
                        value={organizationSelect}
                        options={organizationOptions}
                        onChange={setOrganizationSelect}
                    />
                    <small>Required</small>
                </div>
                <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input name="name" className="form-control" defaultValue={domain.name}
                           disabled={!props.session.permissions.update}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Description</label>
                    <textarea name="description" className="form-control"
                              disabled={!props.session.permissions.update}
                              defaultValue={domain.description}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">URL</label>
                    <input name={"url"} className="form-control" defaultValue={domain.url}
                           disabled={!props.session.permissions.update}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Disabled</label>
                    <ReactSelectDisabled value={domain.disabled} required
                                         isDisabled={!props.session.permissions.update}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Created</label>
                    <input value={TimeCommons.formatTimestamp(domain.created)} className="form-control" disabled/>
                </div>
                {props.session.permissions.update ?
                    <button type="submit" className="btn btn-warning form-control"
                            disabled={isUpdating}>{buttonText}</button> : null}
                <div className={"alert mt-3 " + alert.class}>{alert.message}</div>
            </form>
            <div className={"row mt-3"}>
                <div className={"col"}>
                    <ReturnToLastPageLink/>
                </div>
            </div>
        </div>;
    }
}