import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../commons/RequestCommons"
import {Link} from "react-router-dom";
import AuthCommons from "../../../commons/AuthCommons";
import ReactElementCommons from "../../../commons/ReactElementCommons";
import Select from "react-select";
import ReactSelectDisabled from "../../../commons/ReactSelectDisabled";
import ReturnToLastPageLink from "../../shared/ReturnToLastPageLink";

export default function CreateNavigationLocationPolicy(props: any) {
    const [alert, setAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [isRequesting, setIsRequesting] = useState(false);
    const [navigationLocationOptions, setNavigationLocationOptions]: any = useState([]);
    const [policyOptions, setPolicyOptions]: any = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    async function loadData() {
        const [navigationLocations, policies]: any = await Promise.all([
            AuthCommons.request("/navs/portals"),
            AuthCommons.request("/policies")
        ]);

        const navigationLocationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(navigationLocations, "name", "id");
        setNavigationLocationOptions(navigationLocationOptions);

        const policyOptions: any = ReactElementCommons.createReactSelectOptionsFromList(policies, "name", "id");
        setPolicyOptions(policyOptions);
    }

    async function CreateNavigationLocation(event: any) {
        event.preventDefault();
        try {
            setIsRequesting(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const form: any = event.target;
            const formData: any = new FormData(form);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/navs/portals/policies";
            const id = await RequestCommons.request(url, null, payload, "POST");
            setAlert({
                class: "alert-success text-center",
                message: <>
                    <div>Created navigation location policy.</div>
                    <div>
                        <Link to={"/navigation/locations/policies/" + id}>Click here to update navigation location
                            policy.</Link>
                    </div>
                </>
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsRequesting(false);
        }
    }

    let buttonText: any = "Create";
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Creating...</span>
            </span>;
    }

    return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
        <form onSubmit={CreateNavigationLocation}>
            <h1>Create Navigation Location Policy</h1>
            <div className="mb-3">
                <label className="form-label">Navigation Location</label>
                <Select
                    name="nav_id"
                    options={navigationLocationOptions}
                    required
                />
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Portal Policy Id</label>
                <Select
                    name="portal_policy_id"
                    options={policyOptions}
                    required
                />
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Policy Id</label>
                <Select
                    name="policy_id"
                    options={policyOptions}
                    required
                />
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Disabled</label>
                <ReactSelectDisabled required/>
            </div>
            <button type="submit" className="btn btn-primary form-control"
                    disabled={isRequesting}>{buttonText}</button>
            <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
        </form>
        <div className={"row mt-3"}>
            <div className={"col"}>
                <ReturnToLastPageLink/>
            </div>
        </div>
    </div>;
}