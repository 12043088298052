import {Alert} from "react-bootstrap";
import * as React from "react";

export default function EntryDeleted(props: any) {
    return <div className={"container text-center"}>
        <Alert variant={"danger"} className={"d-inline-block ps-5 pe-5"}>
            <i className="bi bi-exclamation-triangle-fill me-2"/>
            <span>Entry was deleted.</span>
        </Alert>
    </div>;
}