import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../commons/RequestCommons"
import {Link} from "react-router-dom";
import AuthCommons from "../../../commons/AuthCommons";
import ReactElementCommons from "../../../commons/ReactElementCommons";
import Select from "react-select";
import ReturnToLastPageLink from "../../shared/ReturnToLastPageLink";

export default function CreateNavigationLocation(props: any) {
    const [alert, setAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [isRequesting, setIsRequesting] = useState(false);
    const [navigationLocationOptions, setNavigationLocationOptions]: any = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    async function loadData() {
        const [navigationLocations]: any = await Promise.all([
            AuthCommons.request("/navs/portals")
        ]);

        const navigationLocationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(navigationLocations, "name", "id", " ", true);
        setNavigationLocationOptions(navigationLocationOptions);
    }

    async function CreateNavigationLocation(event: any) {
        event.preventDefault();
        try {
            setIsRequesting(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const form: any = event.target;
            const formData: any = new FormData(form);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/navs/portals";
            const id = await RequestCommons.request(url, null, payload, "POST");
            setAlert({
                class: "alert-success text-center",
                message: <>
                    <div>Created navigation location.</div>
                    <div>
                        <Link to={"/navigation/locations/" + id}>Click here to update navigation location.</Link>
                    </div>
                </>
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsRequesting(false);
        }
    }

    let buttonText: any = "Create";
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Creating...</span>
            </span>;
    }

    return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
        <form onSubmit={CreateNavigationLocation}>
            <h1>Create Navigation Location</h1>
            <div className="mb-3">
                <label className="form-label">Name</label>
                <input name="name" className="form-control" required/>
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">URI</label>
                <input name="href" className="form-control" required/>
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Icon</label>
                <input name="icon" className="form-control" required/>
                <small className={"fw-bold"}>This must be defined in the `Portal Client` project. Coordinate with
                    Engineering first.</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Parent</label>
                <Select
                    name="parent"
                    options={navigationLocationOptions}
                />
            </div>
            <button type="submit" className="btn btn-primary form-control"
                    disabled={isRequesting}>{buttonText}</button>
            <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
        </form>
        <div className={"row mt-3"}>
            <div className={"col"}>
                <ReturnToLastPageLink/>
            </div>
        </div>
    </div>;
}