import {Link} from "react-router-dom";
import Masonry from '@mui/lab/Masonry';
import * as React from "react";


export default function Sitemap(props: any) {
    const createClassName = props.session.permissions.create ? "" : " d-none";
    const links = [
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Authorizations</label>
            <Link className="d-block text-decoration-none" to={"/authorizations"}>View</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Daily Review Managers</label>
            <Link className="d-block text-decoration-none"
                  to={"/users/roles/managers/reviews/daily"}>View</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>C3 Managers</label>
            <Link className="d-block text-decoration-none"
                  to={"/users/roles/managers/c3"}>View</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Departments</label>
            <Link className="d-block text-decoration-none" to={"/departments"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/departments/create"}>Create</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Domains</label>
            <Link className="d-block text-decoration-none" to={"/domains"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/domains/create"}>Create</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Emails</label>
            <Link className="d-block text-decoration-none" to={"/emails"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/emails/create"}>Create</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Email Types</label>
            <Link className="d-block text-decoration-none" to={"/emails/types"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/emails/types/create"}>Create</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Groups</label>
            <Link className="d-block text-decoration-none" to={"/groups"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/groups/create"}>Create</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Group Links</label>
            <Link className="d-block text-decoration-none" to={"/groups/policies"}>Group Policies</Link>
            <Link className="d-block text-decoration-none" to={"/groups/users/roles"}>Group User
                Roles</Link>
            <Link className="d-block text-decoration-none" to={"/groups/organizations"}>Group
                Organizations</Link>
            <Link className="d-block text-decoration-none" to={"/groups/departments"}>Group
                Departments</Link>
            <Link className="d-block text-decoration-none" to={"/groups/roles"}>Group Roles</Link>
            <Link className="d-block text-decoration-none" to={"/groups/users"}>Group Users</Link>
            <Link className="d-block text-decoration-none" to={"/groups/emails"}>Group Emails</Link>
            <Link className="d-block text-decoration-none" to={"/groups/numbers/phone"}>Group Phone
                Numbers</Link>
            <Link className="d-block text-decoration-none" to={"/groups/products"}>Group Products</Link>
            <Link className="d-block text-decoration-none" to={"/groups/resources"}>Group Resources</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Legacy Users</label>
            <Link className="d-block text-decoration-none" to={"/users/legacy"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/users/legacy/create"}>Create</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/users/legacy/import"}>Import</Link>
            <Link className="d-block text-decoration-none" to={"/users/legacy/locations"}>Locations</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Logs</label>
            <Link className="d-block text-decoration-none" to={"/logs/errors"}>Errors</Link>
            <Link className="d-block text-decoration-none" to={"/logs/emails"}>Emails</Link>
            <Link className="d-block text-decoration-none" to={"/logs/logins"}>Logins</Link>
            <Link className="d-block text-decoration-none" to={"/logs/pages/accessed"}>Page Access</Link>
            <Link className="d-block text-decoration-none" to={"/logs/requests"}>Requests</Link>
            <Link className="d-block text-decoration-none" to={"/logs/sessions"}>Sessions</Link>
            <Link className="d-block text-decoration-none" to={"/logs/updates"}>Updates</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>MFA</label>
            <Link className="d-block text-decoration-none" to={"/users/mfa/secrets"}>Secrets</Link>
            <Link className="d-block text-decoration-none"
                  to={"/users/mfa/authentications"}>Authentications</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Navigation Locations</label>
            <Link className="d-block text-decoration-none" to={"/navigation/locations"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/navigation/locations/create"}>Create</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Navigation Location Policies</label>
            <Link className="d-block text-decoration-none" to={"/navigation/locations/policies"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/navigation/locations/policies/create"}>Create</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Organizations</label>
            <Link className="d-block text-decoration-none" to={"/organizations"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/organizations/create"}>Create</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Phone Numbers</label>
            <Link className="d-block text-decoration-none" to={"/numbers/phone"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/numbers/phone/create"}>Create</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Phone Number Types</label>
            <Link className="d-block text-decoration-none" to={"/numbers/phone/types"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/numbers/phone/types/create"}>Create</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Policies</label>
            <Link className="d-block text-decoration-none" to={"/policies"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/policies/create"}>Create</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Products</label>
            <Link className="d-block text-decoration-none" to={"/products"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/products/create"}>Create</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Resources</label>
            <Link className="d-block text-decoration-none" to={"/resources"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/resources/create"}>Create</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Resource Types</label>
            <Link className="d-block text-decoration-none" to={"/resources/types"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/resources/types/create"}>Create</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Roles</label>
            <Link className="d-block text-decoration-none" to={"/roles"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/roles/create"}>Create</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Sessions</label>
            <Link className="d-block text-decoration-none" to={"/sessions"}>View</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>SOP's</label>
            <Link className="d-block text-decoration-none" to={"/sops"}>View</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Tags</label>
            <Link className="d-block text-decoration-none" to={"/tags"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/tags/create"}>Create</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Tokens</label>
            <Link className="d-block text-decoration-none" to={"/tokens/create"}>Create</Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>Users</label>
            <Link className="d-block text-decoration-none" to={"/users"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/users/create"}>Create </Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>User Roles</label>
            <Link className="d-block text-decoration-none" to={"/users/roles"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/users/roles/create"}>Create </Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>User Emails</label>
            <Link className="d-block text-decoration-none" to={"/users/emails"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/users/emails/create"}>Create </Link>
        </div>,
        <div className={"mb-4"}>
            <label className={"fw-bold"}>User Phone Numbers</label>
            <Link className="d-block text-decoration-none" to={"/users/numbers/phone"}>View</Link>
            <Link className={"d-block text-decoration-none" + createClassName}
                  to={"/users/numbers/phone/create"}>Create </Link>
        </div>
    ];
    return <div className={"container bg-white p-4 border shadow-sm rounded"}>
        <h2 className={"mb-4"}>
            <i className="bi bi-diagram-3-fill me-3 align-middle"/>
            <span>Sitemap</span>
        </h2>
        <Masonry columns={Math.ceil(document.body.offsetWidth / 300)} spacing={2}>
            {links}
        </Masonry>
    </div>;
}