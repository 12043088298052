import RequestCommons from "./RequestCommons";

export default class AuthCommons {

    static async login(credentials: { email: any, password: any }) {
        let url = process.env.REACT_APP_AUTH_API + "/login";
        const body = new URLSearchParams(credentials);
        return await RequestCommons.request(url, process.env.REACT_APP_CONSUMER_TOKEN, body, "post", 'application/x-www-form-urlencoded');
    }

    static async loginWithToken(credentials: { token: any }) {
        let url = process.env.REACT_APP_AUTH_API + "/login/" + credentials.token;
        return await RequestCommons.request(url, process.env.REACT_APP_CONSUMER_TOKEN);
    }

    static async logout() {
        const url = process.env.REACT_APP_AUTH_API + "/logout";
        return await RequestCommons.request(url, null, false, "GET", 'application/x-www-form-urlencoded', false)
    }

    static async renewSession() {
        const url = process.env.REACT_APP_AUTH_API + "/sessions/me/renew";
        return await RequestCommons.request(url);
    }

    static async renewToken() {
        const url = process.env.REACT_APP_AUTH_API + "/token/renew";
        return await RequestCommons.request(url);
    }

    static async getSession(handleResponse = true) {
        const url = process.env.REACT_APP_AUTH_API + "/sessions/me?populate=1";
        return await RequestCommons.request(url, null, null, "get", 'application/x-www-form-urlencoded', handleResponse);
    }

    static async getToken() {
        const url = process.env.REACT_APP_AUTH_API + "/token";
        return await RequestCommons.request(url);
    }

    static isAuthorizedStatic(session: any, policy_id: any) {
        return session.user.policies.includes(policy_id);
    }

    static async isAuthorized(policy_id: any) {
        const url = process.env.REACT_APP_AUTH_API + "/authorize/" + policy_id;
        return await RequestCommons.request(url);
    }

    static async disableSession(id: any) {
        const url = process.env.REACT_APP_AUTH_API + "/sessions/" + id;
        const data: any = {
            "disabled": 1
        };
        const body = new URLSearchParams(data);
        return await RequestCommons.request(url, null, body, "PUT");
    }

    static async getAuthorizationsMe() {
        const url = process.env.REACT_APP_AUTH_API + "/authorizations/me";
        return await RequestCommons.request(url);
    }

    static async getUserRolesMe() {
        const url = process.env.REACT_APP_AUTH_API + "/users/roles/me";
        return await RequestCommons.request(url);
    }

    static async getGroupsMe() {
        const url = process.env.REACT_APP_AUTH_API + "/groups/users/me";
        return await RequestCommons.request(url);
    }

    static async getEmailsMe() {
        const url = process.env.REACT_APP_AUTH_API + "/users/emails/me";
        return await RequestCommons.request(url);
    }

    static async getLegacyUsersMe() {
        const url = process.env.REACT_APP_AUTH_API + "/users/legacy/me";
        return await RequestCommons.request(url);
    }

    static async getPolicyLegacyUsers(policyId: any, populate: any = null) {
        let url = process.env.REACT_APP_AUTH_API + "/policies/" + policyId + "/users/legacy";
        if (populate) {
            url += "?populate=" + populate;
        }
        return await RequestCommons.request(url);
    }

    static async getPolicyUserRoles(policyId: any, populate: any = null) {
        let url = process.env.REACT_APP_AUTH_API + "/policies/" + policyId + "/users/roles";
        if (populate) {
            url += "?populate=" + populate;
        }
        return await RequestCommons.request(url);
    }

    static async getUserPolicies(userId: any, populate: any = null) {
        let url = process.env.REACT_APP_AUTH_API + "/users/" + userId + "/policies";
        if (populate) {
            url += "?populate=" + populate;
        }
        return await RequestCommons.request(url);
    }

    static async getUserRoles(userId: any, populate: any = null) {
        let url = process.env.REACT_APP_AUTH_API + "/users/" + userId + "/roles";
        if (populate) {
            url += "?populate=" + populate;
        }
        return await RequestCommons.request(url);
    }

    static async getAuthorizations(userIds: any = null, populate: any = null) {
        let url = process.env.REACT_APP_AUTH_API + "/authorizations";

        if (userIds) {
            url += "?user_ids=" + userIds.join(",");
        }

        if (populate) {
            url += "&populate=" + populate;
        }

        return await RequestCommons.request(url);
    }

    static async getLegacyUsersOriginal() {
        const url = process.env.REACT_APP_AUTH_API + "/users/legacy/original"
        return await RequestCommons.request(url);
    }

    static async request(endpoint: string, token = null, data = null, method = "get", contentType = 'application/x-www-form-urlencoded', handleResponse = true) {
        let url = process.env.REACT_APP_AUTH_API + endpoint;
        return await RequestCommons.request(url, token, data, method, contentType, handleResponse);
    }
}