export default class TimeCommons {
    static formatTimestamp(timestamp: string) {
        return TimeCommons.toDate(timestamp).toLocaleString();
    }

    static formatDate(dateString: string, locales: string = "en-US", timeZone: string = "America/Chicago") {
        const date = TimeCommons.toDate(dateString);
        return TimeCommons.toLocaleDateString(date, locales, timeZone);
    }

    static toDate(timestamp: any = null, includeTimezoneOffset = true) {
        let date = new Date();
        let timezoneOffset = 0;
        if (timestamp) {
            date = new Date(timestamp);
            timezoneOffset = includeTimezoneOffset ? date.getTimezoneOffset() * 60 * 1000 : 0;
        }
        date.setTime(date.getTime() + timezoneOffset);
        return date;
    }

    static toLocaleDateString(date: Date, locales: string = "en-US", timeZone: string = "America/Chicago") {
        return date.toLocaleDateString(locales, {timeZone: timeZone});
    }

    static toLocaleTimeString(date: Date, locales: string = "en-US", timeZone: string = "America/Chicago") {
        return date.toLocaleTimeString(locales, {timeZone: timeZone});
    }

    static isToday(timestamp: string) {
        const date = TimeCommons.toDate(timestamp);
        const dateString = TimeCommons.toLocaleDateString(date);
        const today = TimeCommons.toDate();
        const todayString = TimeCommons.toLocaleDateString(today);
        return dateString === todayString;
    }

    /**
     * https://stackoverflow.com/a/3177838
     * @param timestamp
     * @param invert
     */
    static timeAgo(timestamp: string, invert: boolean = false) {
        const date: any = TimeCommons.toDate(timestamp);
        const now: any = new Date();
        const elapsed = invert ? date - now : now - date;
        const seconds = Math.floor(elapsed / 1000);
        return TimeCommons.elapsedFromSeconds(seconds);
    }

    static elapsedFromSeconds(seconds: any) {
        let interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + "y";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + "mo";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + "d";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + "h";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + "m";
        }
        return Math.floor(seconds) + "s";
    }

    static getTimestampHoursAgo(hoursAgo: any = null) {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        let hours = date.getHours();
        if (hoursAgo !== null) {
            hours -= hoursAgo;
        }
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    }

    static getTimeElapsed(start: any, end: any = null, format = "") {
        const startDate = TimeCommons.toDate(start);
        const endDate = TimeCommons.toDate(end);
        const elapsedMs = endDate.getTime() - startDate.getTime();
        return TimeCommons.elapsedFromSeconds(elapsedMs / 1000);
    }

    static toYmd(date: any) {
        return date.toISOString().split('T')[0];
    }
}