import * as React from "react";

export default function Loading(props: any) {
    return <div className={"container"}>
        <div className={"text-center"}>
            <div className="spinner-border spinner-border-sm me-2"
                 role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <span>Loading...</span>
        </div>
    </div>;
}