import * as React from "react";
import AppCommons from "../commons/AppCommons";
import {Link} from "react-router-dom";

export default function AppError(props: any) {
    AppCommons.clearSession();
    return <div className={"m-auto text-center card p-5"}>
        <h3 className={"ms-3"}>An error has occurred.</h3>
        <Link to={"/login"} className={"text-decoration-none"}>Click here to log in again</Link>
    </div>;
}