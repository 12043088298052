import * as React from "react";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import CreateUserRoleForm from "./CreateUserRoleForm";
import ReturnToLastPageLink from "../../shared/ReturnToLastPageLink";

export default function CreateUserRole(props: any) {
    const params = useParams();

    const [userId] = useState(params.id);

    useEffect(() => {
        init();
    }, []);

    async function init() {

    }

    const title = userId ? "Add User Role" : "Create User Role";

    return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " container m-auto"}>
        <div className={"mb-3 d-none"}>
            <ReturnToLastPageLink/>
        </div>
        <h1>{title}</h1>
        <CreateUserRoleForm {...props}/>
    </div>;
}