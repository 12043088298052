import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {useLocation, useNavigate, useParams} from "react-router-dom";
import TimeCommons from "../../commons/TimeCommons";
import {MaterialReactTable} from "material-react-table";
import AuthCommons from "../../commons/AuthCommons";
import Select from "react-select";
import ReactElementCommons from "../../commons/ReactElementCommons";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import AuthorizationsTable from "../authorizations/AuthorizationsTable";
import {Accordion} from "react-bootstrap";
import ObjectCommons from "../../commons/ObjectCommons";
import Loading from "../Loading";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";
import DoesNotExist from "../shared/DoesNotExist";
import EntryDeleted from "../shared/EntryDeleted";
import DeleteModal from "../shared/DeleteModal";

export default function UpdatePolicy(props: any) {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [alert, setAlert] = useState({
        class: "d-none",
        message: ""
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [policy, setPolicy]: any = useState({});
    const [policyExists, setPolicyExists]: any = useState(null);
    const [policyTypeOptions, setPolicyTypeOptions]: any = useState([]);
    const [policyTypeSelect, setPolicyTypeSelect]: any = useState(null);
    const [organizationOptions, setOrganizationOptions]: any = useState([]);
    const [organizationSelect, setOrganizationSelect]: any = useState(null);
    const [domainOptions, setDomainOptions]: any = useState([]);
    const [domainSelect, setDomainSelect]: any = useState(null);
    const [productOptions, setProductOptions]: any = useState([]);
    const [productSelect, setProductSelect]: any = useState(null);
    const [resourceOptions, setResourceOptions]: any = useState([]);
    const [resourceSelect, setResourceSelect]: any = useState(null);
    const [authorizations, setAuthorizations]: any = useState([]);
    const [policyGroupsTableData, setPolicyGroupsTableData]: any = useState([]);
    const [policyGroupsRowSelection, setPolicyGroupsRowSelection]: any = useState([]);
    const [policyGroupsInitialRowSelection, setPolicyGroupsInitialRowSelection]: any = useState([]);
    const [policyDeleted, setPolicyDeleted]: any = useState(false);
    const [deleteModalShow, setDeleteModalShow]: any = useState(false);
    const [deleteModalAlert, setDeleteModalAlert]: any = useState(null);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setIsLoading(true);
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [policyResponse]: any = await Promise.all([
            AuthCommons.request("/policies/" + params.id, null, null, 'get', 'application/x-www-form-urlencoded', false)
        ]);
        if (policyResponse.status === 204) {
            // display not exists warning
            setPolicyExists(false);
        } else {
            setPolicyExists(true);
            const policy: any = await policyResponse.json();
            const [organizations, domains, products, resources, authorizations, policyGroups, policyTypes]: any = await Promise.all([
                AuthCommons.request("/organizations"),
                AuthCommons.request("/domains"),
                AuthCommons.request("/products"),
                AuthCommons.request("/resources"),
                AuthCommons.request("/authorizations?policy_id=" + params.id + "&populate=1"),
                AuthCommons.request("/groups/policies?policy_id=" + params.id + "&populate=1&filter=group"),
                AuthCommons.request("/policies/types")
            ]);
            setPolicy(policy);

            const organizationOptions: any[] = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id", " ", true);
            setOrganizationOptions(organizationOptions);
            setOrganizationSelect(organizationOptions.find((option: any) => option.value === policy.organization_id));

            const domainOptions: any[] = ReactElementCommons.createReactSelectOptionsFromList(domains, "name", "id", " ", true);
            setDomainOptions(domainOptions);
            setDomainSelect(domainOptions.find((option: any) => option.value === policy.domain_id));

            const productOptions: any = ReactElementCommons.createReactSelectOptionsFromList(products, "name", "id", " ", true);
            setProductOptions(productOptions);
            setProductSelect(productOptions.find((option: any) => option.value === policy.product_id));

            const resourceOptions: any = ReactElementCommons.createReactSelectOptionsFromList(resources, "name", "id", " ", true);
            setResourceOptions(resourceOptions);
            setResourceSelect(resourceOptions.find((option: any) => option.value === policy.resource_id));

            const policyTypeOptions: any[] = ReactElementCommons.createReactSelectOptionsFromList(policyTypes, "name", "id");
            setPolicyTypeOptions(policyTypeOptions);
            setPolicyTypeSelect(policyTypeOptions.find((option: any) => option.value === policy.policy_type_id));

            setAuthorizations(authorizations);

            const [groups]: any = await Promise.all([
                AuthCommons.request("/groups?populate=1")
            ]);

            const policyGroupsTableData: any = [];
            const policyGroupsRowSelection: any = {};

            const groupsMapById: any = ObjectCommons.mapEntriesById(policyGroups);
            for (let i = 0; i < groups.length; i++) {
                const entry: any = JSON.parse(JSON.stringify(groups[i]));
                const selected = groupsMapById[entry.id]

                if (selected || props.session.permissions.update) {
                    if (selected) {
                        entry.selected = true
                        policyGroupsRowSelection[entry.id] = true
                    }
                    policyGroupsTableData.push(entry);
                }
            }

            setPolicyGroupsTableData(policyGroupsTableData);
            setPolicyGroupsRowSelection(policyGroupsRowSelection)
            setPolicyGroupsInitialRowSelection(policyGroupsRowSelection)
        }
    }

    async function updatePolicy(event: any) {
        event.preventDefault();
        try {
            setIsUpdating(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const formData: any = new FormData(event.target);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/policies/" + policy.id;
            await RequestCommons.request(url, null, payload, "PUT");
            await loadData();
            setAlert({
                class: "alert-success text-center",
                message: "Updated."
            });
            const dataToUpdate = []
            if (!ObjectCommons.compareObjects(policyGroupsInitialRowSelection, policyGroupsRowSelection)) {
                const groupIDs: any = [];
                for (let groupID in policyGroupsRowSelection) {
                    groupIDs.push(parseInt(groupID));
                }
                setPolicyGroupsInitialRowSelection(policyGroupsRowSelection)
                setPolicyGroupsRowSelection(policyGroupsRowSelection)
                dataToUpdate.push(replacePolicyGroups(policy.id, groupIDs));
            }

            await Promise.all(dataToUpdate);
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsUpdating(false);
        }
    }


    async function replacePolicyGroups(policyId: any, groupIds: any) {
        const entries = [];
        for (let i = 0; i < groupIds.length; i++) {
            entries.push({
                "group_id": groupIds[i],
                "policy_id": policyId

            });
        }
        const body: any = JSON.stringify(entries);
        let url = process.env.REACT_APP_AUTH_API + "/groups/policies?policy_id=" + policyId;
        await RequestCommons.request(url, null, body, "put", "application/json").then(async function () {
            console.log("Replaced policy groups.");
        }).catch(function (error) {
            console.error(error);
        });
    }

    function deletePolicy(event: any) {
        event.preventDefault();
        const url = process.env.REACT_APP_AUTH_API + "/policies/" + policy.id;
        let deleteModalAlert = <div className={"alert alert-success"}>
            Policy deleted.
        </div>;
        RequestCommons.request(url, null, null, "DELETE").then(async function () {
            await loadData();
            setPolicyDeleted(true);
        }).catch(function (error) {
            console.error(error);
            deleteModalAlert = <>
                <div className={"alert alert-danger"}>
                    <span>The entry you are trying to delete likely still has entries linked to it.
                        Remove all links from the accordion dropdowns below before deleting.</span>
                </div>
                <Accordion className={"mb-3"} defaultActiveKey={[]}>
                    <Accordion.Item eventKey="details">
                        <Accordion.Header>
                            Details
                        </Accordion.Header>
                        <Accordion.Body>
                            {error.detail}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </>;
        }).finally(function () {
            setDeleteModalAlert(deleteModalAlert);
        });
    }

    function toggleDeleteModalShow() {
        setDeleteModalAlert(null);
        setDeleteModalShow(!deleteModalShow);
    }

    if (isLoading) {
        return <Loading/>;
    } else if (policyDeleted) {
        return <EntryDeleted/>;
    } else if (!policyExists) {
        return <DoesNotExist/>;
    } else {
        let buttonText: any = "Update";
        if (isUpdating) {
            buttonText = <span>
                    <span className={"loading-spinner"}>
                        <i className="bi bi-arrow-clockwise"></i>
                    </span>
                    <span className={"ms-2"}>Updating...</span>
                </span>;
        }

        const policyGroupsTableColumns: any = [
            {
                accessorKey: 'selected', //access nested data with dot notation
                header: 'Selected',
            },
            {
                accessorKey: 'id', //access nested data with dot notation
                header: 'Id',
                size: 70
            },
            {
                accessorKey: 'name',
                header: 'Name',
            },
            {
                accessorKey: 'organization.name',
                header: 'Organization',
            },
            {
                accessorKey: 'description',
                header: 'Description',
            }
        ];

        const groupsSelected = Object.values(policyGroupsRowSelection).filter(i => i === true).length;
        const deleteButtonDisabled = groupsSelected > 0;
        let deleteButtonTitle = "Delete";
        if (groupsSelected > 0) {
            deleteButtonTitle = "You must remove all group assignments before deleting.";
        }

        return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " container m-auto w-100"}>
            <DeleteModal show={deleteModalShow} onHide={toggleDeleteModalShow} delete={deletePolicy}
                         alert={deleteModalAlert}/>
            <div className={"mb-3 d-none"}>
                <ReturnToLastPageLink/>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <h2 className={"align-middle"}>
                        <i className="d-inline-block align-middle bi bi-shield-lock-fill me-3"/>
                        <span className="d-inline-block align-middle">Policy</span>
                    </h2>
                </div>
                <div className={"col text-end"}>
                    {props.session.permissions.delete ?
                        <form onSubmit={function (event: any) {
                            event.preventDefault();
                            setDeleteModalShow(true);
                        }}>
                            <button className={"btn btn-danger"} title={deleteButtonTitle}
                                    disabled={deleteButtonDisabled}>
                                <i className="bi bi-trash-fill"/>
                            </button>
                        </form> : null}
                </div>
            </div>
            <form onSubmit={updatePolicy}>
                <div className={"position-relative"} style={{
                    zIndex: 999
                }}>
                    <div className={"row"}>
                        <div className="col-12 col-lg mb-3">
                            <label className="form-label">Id</label>
                            <input name="id" type="number" className="form-control" defaultValue={policy.id}
                                   disabled/>
                        </div>
                        <div className="col-12 col-lg mb-3">
                            <label className="form-label">Name</label>
                            <input name="name" className="form-control" defaultValue={policy.name}
                                   disabled={!props.session.permissions.update} required/>
                        </div>
                        <div className="col-12 col-lg mb-3">
                            <label className="form-label me-2">Type</label>
                            <div className={"d-inline-block"}>
                                <i
                                    className="bi bi-info-circle-fill text-primary me-2"
                                    title={"Consult with Engineering for more information on Policy Types."}
                                />
                            </div>
                            <Select
                                name="policy_type_id"
                                isDisabled={!props.session.permissions.update}
                                value={policyTypeSelect}
                                options={policyTypeOptions}
                                onChange={setPolicyTypeSelect}
                            />
                        </div>
                        <div className="col-12 col-lg mb-3">
                            <label className="form-label">Disabled</label>
                            <ReactSelectDisabled value={policy.disabled}
                                                 isDisabled={!props.session.permissions.update}/>
                        </div>
                        <div className="col-12 col-lg mb-3">
                            <label className="form-label">Created</label>
                            <input value={TimeCommons.formatTimestamp(policy.created)}
                                   className="form-control"
                                   disabled/>
                        </div>
                    </div>
                    <div className={"mb-3"}>
                        <div className="mb-3">
                            <label className="form-label">Description</label>
                            <textarea name="description"
                                      disabled={!props.session.permissions.update}
                                      className="form-control" defaultValue={policy.description}/>
                        </div>
                    </div>
                </div>
                <Accordion className={"mb-3"} defaultActiveKey={[]}>
                    <Accordion.Item eventKey="optional">
                        <Accordion.Header>
                            Optional Settings
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className={"row"}>
                                <div className="col-12 col-lg mb-3">
                                    <label className="form-label">Organization</label>
                                    <Select
                                        name="organization_id"
                                        isDisabled={!props.session.permissions.update}
                                        value={organizationSelect}
                                        options={organizationOptions}
                                        onChange={setOrganizationSelect}
                                    />
                                </div>
                                <div className="col-12 col-lg mb-3">
                                    <label className="form-label">Domain</label>
                                    <Select
                                        name="domain_id"
                                        isDisabled={!props.session.permissions.update}
                                        value={domainSelect}
                                        options={domainOptions}
                                        onChange={setDomainSelect}
                                    />
                                </div>
                                <div className="col-12 col-lg mb-3">
                                    <label className="form-label">Product</label>
                                    <Select
                                        name="product_id"
                                        isDisabled={!props.session.permissions.update}
                                        value={productSelect}
                                        options={productOptions}
                                        onChange={setProductSelect}
                                    />
                                </div>
                                <div className="col-12 col-lg mb-3">
                                    <label className="form-label">Resource</label>
                                    <Select
                                        name="resource_id"
                                        isDisabled={!props.session.permissions.update}
                                        value={resourceSelect}
                                        options={resourceOptions}
                                        onChange={setResourceSelect}
                                    />
                                </div>
                            </div>
                            <div className={"mb-3"}>
                                <label className="form-label">URL</label>
                                <input name={"url"} className="form-control" defaultValue={policy.url}
                                       disabled={!props.session.permissions.update}/>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="groups">
                        <Accordion.Header>
                            Groups
                            ({groupsSelected})
                        </Accordion.Header>
                        <Accordion.Body>
                            <MaterialReactTable
                                muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                columns={policyGroupsTableColumns}
                                data={policyGroupsTableData}
                                enableSelectAll={false}
                                enablePagination={true}
                                positionToolbarAlertBanner={"none"}
                                enableRowSelection={props.session.permissions.update}
                                onRowSelectionChange={function (selection: any) {
                                    setPolicyGroupsRowSelection(selection);
                                }}
                                state={{
                                    rowSelection: policyGroupsRowSelection,
                                    isLoading: false,
                                    density: "compact"
                                }}
                                initialState={{
                                    columnVisibility: {
                                        selected: false
                                    },
                                    sorting: [
                                        {
                                            id: "selected",
                                            desc: false
                                        },
                                        {
                                            id: "organization.name",
                                            desc: false
                                        },
                                        {
                                            id: "id",
                                            desc: false
                                        }
                                    ]
                                }}
                                getRowId={(originalRow: any) => originalRow.id}
                                renderTopToolbarCustomActions={() => {
                                    return (
                                        <h2 className={"me-3 d-inline-block align-top"}>Groups</h2>
                                    );
                                }}
                                muiTableBodyRowProps={({row}: any) => ({
                                    onClick: (event) => {
                                        navigate("/groups/" + row.original.id);
                                    },
                                    sx: {
                                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                    },
                                })}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="authorizations">
                        <Accordion.Header>
                            Authorizations
                            ({authorizations.length})
                        </Accordion.Header>
                        <Accordion.Body>
                            <AuthorizationsTable {...props} authorizations={authorizations}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <div className={"mt-3"}>
                    <div className={"alert text-center " + alert.class}>{alert.message}</div>
                </div>
                <div className={"row mt-3"}>
                    <div className={"col"}>
                        <ReturnToLastPageLink/>
                    </div>
                    <div className={"col text-end"}>
                        {props.session.permissions.update ?
                            <button type="submit" className="btn btn-warning ms-auto ps-5 pe-5"
                                    disabled={isUpdating}>{buttonText}</button> : null}
                    </div>
                </div>
            </form>
        </div>
            ;
    }
}