import * as React from "react";
import {useState} from "react";
import RequestCommons from "../../../commons/RequestCommons"
import {Link} from "react-router-dom";
import ReactSelectDisabled from "../../../commons/ReactSelectDisabled";
import ReturnToLastPageLink from "../../shared/ReturnToLastPageLink";

export default function CreateResourceType(props: any) {
    const [alert, setAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [isRequesting, setIsRequesting] = useState(false);

    async function createResourceType(event: any) {
        event.preventDefault();
        try {
            setIsRequesting(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const form: any = event.target;
            const formData: any = new FormData(form);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/resources/types";
            const id = await RequestCommons.request(url, null, payload, "POST");
            setAlert({
                class: "alert-success text-center",
                message: <>
                    <div>Created Resource Type.</div>
                    <div>
                        <Link className={"text-decoration-none"} to={"/resources/types/" + id}>Click here to update resource type.</Link>
                    </div>
                </>
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsRequesting(false);
        }
    }

    let buttonText: any = "Create";
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Creating...</span>
            </span>;
    }

    return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
        <form onSubmit={createResourceType}>
            <h2>Create Resource Type</h2>
            <div className="mb-3">
                <label className="form-label">Name</label>
                <input name="name" className="form-control" required/>
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea name="description" className="form-control"></textarea>
            </div>
            <div className="mb-3">
                <label className="form-label">Disabled</label>
                <ReactSelectDisabled/>
            </div>
            <button type="submit" className="btn btn-primary form-control" disabled={isRequesting}>{buttonText}</button>
            <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
        </form>
        <div className={"row mt-3"}>
            <div className={"col"}>
                <ReturnToLastPageLink/>
            </div>
        </div>
    </div>;
}