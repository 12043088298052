import * as React from "react";
import {useState} from "react";
import {Link, Navigate} from "react-router-dom";
import RequestCommons from "../../commons/RequestCommons";
import AppCommons from "../../commons/AppCommons";
import CookieCommons from "../../commons/CookieCommons";

export default function CreateMfa(props: any) {
    const [isVerified, setIsVerified] = useState(false);
    const [isRequesting, setIsRequesting] = useState(false);
    const [showSecret, setShowSecret] = useState(false);
    const [showInstructions, setShowInstructions] = useState(false);

    const [alert, setAlert] = useState({
        class: "d-none",
        message: "",
    });

    async function createMfa(code: string) {
        const submit: any = document.getElementById("mfa-submit-button");
        submit.disabled = true;
        try {
            setIsVerified(false);
            setIsRequesting(true);
            const url = process.env.REACT_APP_AUTH_API + "/mfa/create";
            const codeObj = {'code': code};
            const body = new URLSearchParams(codeObj);
            const response: any = await RequestCommons.request(url, null, body, "post");
            if(response?.mfa?.token) {
                CookieCommons.setCookie(process.env.REACT_APP_MFA_TOKEN_COOKIE_NAME, response.mfa.token, 365);
            }
            setAlert({
                class: "alert-success text-center",
                message: "Verified.",
            });
            const session = await AppCommons.getUserSession();
            props.setSession(session);
            setIsVerified(true);
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error,
            });
        } finally {
            setIsRequesting(false);
            submit.disabled = false;
        }
    }

    async function onSubmit(event: any) {
        event.preventDefault();
        const input: any = document.getElementById("input-code");
        await createMfa(input.value);
    }

    if (isVerified) {
        return <Navigate to={"/"}/>;
    } else {
        let buttonText: any = "Verify";
        if (isRequesting) {
            buttonText = <span>
            <span className={"loading-spinner"}>
              <i className="bi bi-arrow-clockwise"></i>
            </span>
            <span className={"ms-2"}>Verifying...</span>
          </span>;
        }

        let instructions = null;
        if (showInstructions) {
            instructions = <ul className={"list-group list-group-numbered mt-3"}>
                <li className={"list-group-item"}>
                    <span>Scan the QR Code into your <code>Authenticator App</code> on your mobile device</span>
                    <br/>
                    <div className={"alert alert-warning d-flex mb-1 mt-1"}>
                        <small>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                 className="bi bi-exclamation-triangle-fill me-2" role="img"
                                 aria-label="Warning:">
                                <path
                                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                            </svg>
                        </small>
                        <small>
                                  <span>If you don't have a mobile Authenticator App, you can use the <code>Google
                                      Authenticator</code> or <code>Microsoft Authenticator</code> apps from the <code>Apple
                                      App Store</code> or <code>Google Play Store</code>
                                  </span>
                        </small>
                    </div>
                </li>
                <li className={"list-group-item"}>
                    <span>Look for the new <code>{`${props.mfa.name}`}</code> entry</span>
                </li>
                <li className={"list-group-item"}>Enter the generated code below</li>
            </ul>;
        }

        return (
            <form id="mfa-form" className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"}
                  style={{width: "400px"}}
                  onSubmit={onSubmit} autoComplete={"on"}>
                <h1 className={"text-center w-100"}>Multi-Factor Authentication Setup</h1>
                <div className={"mb-3"}>
                    <img className={"w-100 mb-3"} src={`data:image/png;base64,${props.mfa.qrcode}`} style={{
                        height: "200px",
                        objectFit: "contain"
                    }}
                         alt={"qr-code-unavailable"}/>
                    <h4 className={"w-100 text-center"}>
                        <code>{props.mfa.name}</code>
                    </h4>
                    <div className={"text-left mt-4"}>
                        <button type="button" className={"btn btn-light form-control m-auto"}
                                onClick={() => setShowInstructions(!showInstructions)}>
                            Setup Instructions
                        </button>
                        {instructions}
                    </div>
                    <div className={"text-center mb-2 d-none"}>
                        {!showSecret ? (
                            <button onClick={() => setShowSecret(!showSecret)}
                                    className="btn btn-primary">
                                {" "}Enter Manually{" "}
                            </button>
                        ) : (
                            <div>
                                <b>Enter in authenticator app:</b> {props.mfa.secret}{" "}
                            </div>
                        )}
                    </div>
                </div>
                <div className="mb-3">
                    <input id={"input-code"} name={"code"} maxLength={6} placeholder="Enter 6 digit code..."
                           className="form-control" autoComplete={"on"} autoFocus
                    />
                </div>
                <div className="mb-3">
                    <button id="mfa-submit-button" className="btn btn-primary form-control mb-3"> {buttonText}</button>
                    <div className={"alert mb-3 text-center " + alert.class}>
                        {alert.message}
                    </div>
                </div>
                <div className="mb-3 text-center">
                    <Link to={"/login"} replace={true} style={{
                        textDecoration: "none"
                    }} onClick={() => {
                        props.setMfa(null);
                    }}>Return to Login</Link>
                </div>
            </form>
        );
    }
}
