import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import TimeCommons from "../../commons/TimeCommons";
import {MaterialReactTable} from "material-react-table";
import {Box} from "@mui/material";
import AuthCommons from "../../commons/AuthCommons";
import {Link, useNavigate} from "react-router-dom";
import ViewEntryActionButton from "../shared/tables/ViewEntryActionButton";
import DeleteEntryActionButton from "../shared/tables/DeleteEntryActionButton";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";

export default function ViewGroups(props: any) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading]: any = useState(true);
    const [tableData, setTableData]: any = useState([]);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [groups]: any = await Promise.all([
            AuthCommons.request("/groups?populate=1")
        ]);

        const tableData: any = [];
        for (let i = 0; i < groups.length; i++) {
            const group = groups[i];
            group.disabled = group.disabled ? "Yes" : "No";
            group.created = TimeCommons.formatTimestamp(group.created);
            tableData.push(group);
        }
        setTableData(tableData);
    }

    function deleteGroup(id: number) {
        const c = window.confirm("Are you sure you want to delete this entry?");
        if (c) {
            const url = process.env.REACT_APP_AUTH_API + "/groups/" + id;
            RequestCommons.request(url, null, null, "DELETE").then(async function () {
                await loadData();
            }).catch(function (error) {
                alert(error.detail);
            });
        }
    }

    function renderRowActions(data: any) {
        const id = data.row.original.id;
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton to={"/groups/" + id}/>
            {props.session.permissions.delete ?
                <DeleteEntryActionButton onClick={function (event: any) {
                    event.preventDefault();
                    deleteGroup(id);
                }}/> : null}
        </Box>;
    }

    const createLink = props.session.permissions.create ? <Link to={"/groups/create"}
                                                                className={"d-inline-block btn btn-primary"}
    >
        Create
    </Link> : null;

    const columns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id'
        },
        {
            accessorKey: 'organization.name',
            header: 'Organization'
        },
        {
            accessorKey: 'name',
            header: 'Name',
            size: 300
        },
        {
            accessorKey: 'description',
            header: 'Description',
            size: 500
        },
        {
            accessorKey: 'disabled', //normal accessorKey
            header: 'Disabled'
        }
    ];

    const defaultPageSize = 10;
    const initialState = {
        showColumnFilters: true,
        sorting: [
            {
                id: 'id', //sort by age by default on page load
                desc: true
            }
        ],
        pagination: {
            pageSize: defaultPageSize,
            pageIndex: 0
        },
        columnFilters: [
            {
                id: 'disabled',
                value: "No"
            }
        ],
    };

    return <div className={"m-auto container shadow-sm bg-white rounded border p-3"}>
        <div className={"mb-3 d-none"}>
            <ReturnToLastPageLink/>
        </div>
        <MaterialReactTable
            muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
            enableStickyHeader={true}
            columns={columns}
            data={tableData}
            state={{
                isLoading: isLoading,
                density: "compact"
            }}
            initialState={initialState}
            renderTopToolbarCustomActions={() => {
                return (
                    <div>
                        <h2 className={"me-3 d-inline-block align-top"}>
                            <i className="bi bi-collection-fill me-3 align-middle"/>
                            <span>Groups</span>
                        </h2>
                        {/*createLink*/}
                    </div>
                );
            }}
            muiTableBodyRowProps={({row}: any) => ({
                onClick: (event) => {
                    navigate("/groups/" + row.original.id);
                },
                sx: {
                    cursor: 'pointer'
                }
            })}
            muiTableBodyCellProps={{
                sx: {
                    whiteSpace: "wrap",
                    textWrap: "break-word"
                },
            }}
        />
        <div className={"mt-3 d-none"}>
            <ReturnToLastPageLink/>
        </div>
    </div>;
}