import ObjectCommons from "./ObjectCommons";

export default class ReactElementCommons {

    static createReactSelectOptionsFromList(list: any, labelField: any, valueField: any, labelFieldDelimiter: any = " ", includeNullOption: boolean = false, nullOptionLabel = "None", nullOptionValue = "") {
        const options = [];
        if (includeNullOption) {
            options.push({
                label: nullOptionLabel,
                value: nullOptionValue
            });
        }
        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            let label = "";
            if (labelField.includes(".")) {
                label = ObjectCommons.deepGetByPaths(item, labelField)
            } else if (Array.isArray(labelField)) {
                const labels = [];
                for (let j = 0; j < labelField.length; j++) {
                    let currentLabelField = labelField[j];
                    if (currentLabelField.includes(".")) {
                        label = ObjectCommons.deepGetByPaths(item, currentLabelField)
                    } else {
                        label = item[currentLabelField];
                    }
                    labels.push(label);
                }
                label = labels.join(labelFieldDelimiter);
            } else {
                label = item[labelField];
            }

            const option = {
                label: label,
                value: item[valueField]
            };

            options.push(option);
        }

        return options;
    }
}