import * as React from "react";
import {useEffect, useState} from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import ViewDomains from "./components/domains/ViewDomains";
import ViewUsers from "./components/users/ViewUsers";
import ViewPolicies from "./components/policies/ViewPolicies";
import ViewGroups from "./components/groups/ViewGroups";
import UpdateUser from "./components/users/UpdateUser";
import UpdatePolicy from "./components/policies/UpdatePolicy";
import UpdateGroup from "./components/groups/UpdateGroup";
import CreateGroup from "./components/groups/CreateGroup";
import CreateOrganization from "./components/organizations/CreateOrganization";
import UpdateOrganization from "./components/organizations/UpdateOrganization";
import ViewOrganizations from "./components/organizations/ViewOrganizations";
import UpdateDomain from "./components/domains/UpdateDomain";
import CreateDomain from "./components/domains/CreateDomain";
import CreateUser from "./components/users/CreateUser";
import Login from "./components/Login";
import NoMatch from "./components/NoMatch";
import Home from "./components/Home";
import Layout from "./components/layout/Layout";
import CreatePolicy from "./components/policies/CreatePolicy";
import Authorization from "./components/Authorization";
import Logout from "./components/Logout";
import ViewLegacyUsers from "./components/users/legacy/ViewLegacyUsers";
import ViewLegacyUserLocations from "./components/users/legacy/locations/ViewLegacyUserLocations";
import UpdatePassword from "./components/UpdatePassword";
import ResetPassword from "./components/ResetPassword";
import ViewProducts from "./components/products/ViewProducts";
import CreateProduct from "./components/products/CreateProduct";
import UpdateProduct from "./components/products/UpdateProduct";
import Test from "./components/Test";
import ViewTags from "./components/tags/ViewTags";
import CreateTag from "./components/tags/CreateTag";
import UpdateTag from "./components/tags/UpdateTag";
import Forbidden from "./components/Forbidden";
import Unauthorized from "./components/Unauthorized";
import ViewResources from "./components/resources/ViewResources";
import CreateResource from "./components/resources/CreateResource";
import UpdateResource from "./components/resources/UpdateResource";
import CreateDepartment from "./components/departments/CreateDepartment";
import UpdateDepartment from "./components/departments/UpdateDepartment";
import ViewDepartments from "./components/departments/ViewDepartments";
import ViewRoles from "./components/roles/ViewRoles";
import CreateRole from "./components/roles/CreateRole";
import UpdateRole from "./components/roles/UpdateRole";
import ViewUserRoles from "./components/users/roles/ViewUserRoles";
import CreateUserRole from "./components/users/roles/CreateUserRole";
import UpdateUserRole from "./components/users/roles/UpdateUserRole";
import ViewEmails from "./components/emails/ViewEmails";
import CreateEmail from "./components/emails/CreateEmail";
import UpdateEmail from "./components/emails/UpdateEmail";
import ViewUserEmails from "./components/users/emails/ViewUserEmails";
import CreateUserEmail from "./components/users/emails/CreateUserEmail";
import SessionExpired from "./components/SessionExpired";
import ImportLegacyUser from "./components/users/legacy/ImportLegacyUser";
import AppCommons from "./commons/AppCommons";
import ViewUpdateLogs from "./components/logs/ViewUpdateLogs";
import ViewErrorLogs from "./components/logs/ViewErrorLogs";
import ViewLoginLogs from "./components/logs/ViewLoginLogs";
import ViewRequestLogs from "./components/logs/ViewRequestLogs";
import AppError from "./components/Error";
import ViewPhoneNumbers from "./components/numbers/phone/ViewPhoneNumbers";
import UpdatePhoneNumber from "./components/numbers/phone/UpdatePhoneNumber";
import CreatePhoneNumber from "./components/numbers/phone/CreatePhoneNumber";
import ViewUserPhoneNumbers from "./components/users/numbers/phone/ViewUserPhoneNumbers";
import CreateUserPhoneNumber from "./components/users/numbers/phone/CreateUserPhoneNumber";
import ViewAuthorizations from "./components/authorizations/ViewAuthorizations";
import ViewEmailLogs from "./components/logs/ViewEmailLogs";
import ViewUserRoleDailyReviewManagers
    from "./components/users/roles/managers/reviews/daily/ViewUserRoleDailyReviewManagers";
import ViewUserMfaSecrets from "./components/users/mfa/secrets/ViewUserMfaSecrets";
import ViewUserMfaAuthentications from "./components/users/mfa/authentications/ViewUserMfaAuthentications";
import ViewSessions from "./components/logs/ViewSessions";
import ViewGroupUserRoles from "./components/groups/users/roles/ViewGroupUserRoles";
import ViewGroupPhoneNumbers from "./components/groups/numbers/phone/ViewGroupPhoneNumbers";
import ViewGroupEmails from "./components/groups/emails/ViewGroupEmails";
import ViewGroupPolicies from "./components/groups/policies/ViewGroupPolicies";
import ViewEmailTypes from "./components/emails/types/ViewEmailTypes";
import CreateEmailType from "./components/emails/types/CreateEmailType";
import UpdateEmailType from "./components/emails/types/UpdateEmailType";
import ViewPhoneNumberTypes from "./components/numbers/phone/types/ViewPhoneNumberTypes";
import CreatePhoneNumberType from "./components/numbers/phone/types/CreatePhoneNumberType";
import UpdatePhoneNumberType from "./components/numbers/phone/types/UpdatePhoneNumberType";
import ViewGroupUsers from "./components/groups/users/ViewGroupUsers";
import ViewGroupDepartments from "./components/groups/departments/ViewGroupDepartments";
import ViewGroupOrganizations from "./components/groups/organizations/ViewGroupOrganizations";
import ViewGroupProducts from "./components/groups/products/ViewGroupProducts";
import ViewGroupResources from "./components/groups/resources/ViewGroupResources";
import ViewGroupRoles from "./components/groups/roles/ViewGroupRoles";
import CreateLegacyUser from "./components/users/legacy/CreateLegacyUser";
import UpdateLegacyUser from "./components/users/legacy/UpdateLegacyUser";
import ViewResourceTypes from "./components/resources/types/ViewResourceTypes";
import CreateResourceType from "./components/resources/types/CreateResourceType";
import UpdateResourceType from "./components/resources/types/UpdateResourceType";
import ViewNavigationLocationPolicies from "./components/navigation/policies/ViewNavigationLocationPolicies";
import ViewNavigationLocations from "./components/navigation/locations/ViewNavigationLocations";
import UpdateNavigationLocation from "./components/navigation/locations/UpdateNavigationLocation";
import CreateNavigationLocation from "./components/navigation/locations/CreateNavigationLocation";
import UpdateNavigationLocationPolicy from "./components/navigation/policies/UpdateNavigationLocationPolicy";
import CreateNavigationLocationPolicy from "./components/navigation/policies/CreateNavigationLocationPolicy";
import LoginToken from "./components/LoginToken";
import ViewPageAccessLogs from "./components/logs/ViewPageAccessLogs";
import AuthCommons from "./commons/AuthCommons";
import Sitemap from "./components/Sitemap";
import Sessions from "./components/Sessions";
import Sops from "./components/Sops";
import CreateTokens from "./components/tokens/CreateTokens";
import ViewUserRoleC3Managers from "./components/users/roles/managers/c3/ViewUserRoleC3Managers";


export default function App() {
    const [session, setSession]: any = useState(AppCommons.getSession());
    const location = useLocation();
    const config = {
        materialReactTable: {
            muiTableContainerProps: {
                sx: {
                    maxHeight: '70vh'
                }
            },
            muiTablePaperProps: {
                elevation: 0,
                sx: {
                    padding: "20px",
                    border: null,
                }
            },
            muiTablePaperPropsNoPadding: {
                elevation: 0,
                sx: {
                    padding: "0",
                    border: null,
                }
            }
        }
    }
    const skips = [
        "/login",
        "/mfa/verify",
        "/mfa/create",
        "/logout",
        "/session/expired",
        "/password/update",
        "/password/reset",
        "/session/expired",
        "/unauthorized",
        "/error"
    ];

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        logPageAccess();
    }, [location]);

    async function logPageAccess() {
        if (session) {
            const pathname = window.location.origin + location.pathname;
            const data = {
                pathname: pathname,
                search: location.search
            };
            const payload: any = new URLSearchParams(data);
            const url = "/me/logs/pages/accessed";
            await AuthCommons.request(url, null, payload, "post", 'application/x-www-form-urlencoded', false);
        }
    }

    async function init() {
        let result = skips.filter(s => window.location.pathname.startsWith(s));
        if (!skips.includes(location.pathname) && !result.length) {
            try {
                const session = await AppCommons.getUserSession();
                setSession(session);
            } catch (error) {
                console.error(error);
                window.location.href = "/session/expired";
            }
        }
    }

    let background = null;
    if (session?.user_role?.role?.organization_id) {
        switch (session.user_role.role.organization_id) {
            case 4: // SBB
                background = "/sbb_login_background.png";
                break;
            case 6: // Cedar Pine
                background = "/cp_login_background.png";
                break;
        }
    }

    let routes;

    // If session is null (not logged in)
    if (!session) {
        routes = <Routes>
            <Route path="/login" element={<Login session={session} setSession={setSession}/>}/>
            <Route path="/login/:token" element={<LoginToken session={session} setSession={setSession}/>}/>
            <Route path="/logout" element={<Logout session={session} setSession={setSession}/>}/>
            <Route path="/password/update" element={<UpdatePassword session={session}/>}/>
            <Route path="/password/update/:token" element={<UpdatePassword session={session}/>}/>
            <Route path="/password/reset" element={<ResetPassword session={session}/>}/>
            <Route path="/session/expired" element={<SessionExpired session={session}/>}/>
            <Route path="/error" element={<AppError session={session}/>}/>
            <Route path="*" element={<Navigate replace={true} to="/login"/>
            }/>
        </Routes>
    } else {
        routes = <Routes>
            <Route path="/login"
                   element={<Login session={session} setSession={setSession} replace={true}/>}/>
            <Route path="/login/:token" element={<LoginToken session={session} setSession={setSession}/>}/>
            <Route path="/logout"
                   element={<Logout session={session} setSession={setSession} replace={true}/>}/>
            <Route path="/forbidden" element={<Forbidden session={session} replace={true}/>}/>
            <Route path="/unauthorized" element={<Unauthorized session={session} replace={true}/>}/>
            <Route path="/error" element={<AppError session={session} replace={true}/>}/>
            <Route path="/session/expired" element={<SessionExpired session={session} replace={true}/>}/>
            <Route path="/password/update" element={<UpdatePassword session={session}
                                                                    fromOtherComponent={true}/>}/>
            <Route path="/password/update/:token"
                   element={<UpdatePassword session={session}/>}/>
            <Route path="/password/reset" element={<ResetPassword session={session} replace={true}/>}/>
            <Route path="/" element={<Layout session={session} replace={true}/>}>
                <Route index element={<Home session={session} config={config} replace={true} subtitle={"Home"}/>}/>
                <Route path="/authorization" element={<Authorization session={session} replace={true}/>}/>
                <Route path="/authorizations"
                       element={<ViewAuthorizations session={session} config={config} replace={true}/>}/>
                <Route path="/test" element={<Test session={session} replace={true}/>}/>
                <Route path="/forbidden" element={<Forbidden session={session} replace={true}/>}/>
                <Route path="organizations"
                       element={<ViewOrganizations session={session} config={config} replace={true}/>}/>
                <Route path="organizations/create"
                       element={<CreateOrganization session={session} config={config} replace={true}/>}/>
                <Route path="organizations/:id"
                       element={<UpdateOrganization session={session} config={config} replace={true}/>}/>

                <Route path="domains"
                       element={<ViewDomains session={session} config={config} replace={true}/>}/>
                <Route path="domains/create"
                       element={<CreateDomain session={session} config={config} replace={true}/>}/>
                <Route path="domains/:id"
                       element={<UpdateDomain session={session} config={config} replace={true}/>}/>

                <Route path="groups"
                       element={<ViewGroups session={session} config={config} replace={true}/>}/>
                <Route path="groups/:id"
                       element={<UpdateGroup session={session} config={config} replace={true}/>}/>
                <Route path="groups/create"
                       element={<CreateGroup session={session} config={config} replace={true}/>}/>
                <Route path="groups/users/roles"
                       element={<ViewGroupUserRoles session={session} config={config} replace={true}/>}/>
                <Route path="groups/emails"
                       element={<ViewGroupEmails session={session} config={config} replace={true}/>}/>
                <Route path="groups/numbers/phone"
                       element={<ViewGroupPhoneNumbers session={session} config={config} replace={true}/>}/>
                <Route path="groups/policies"
                       element={<ViewGroupPolicies session={session} config={config} replace={true}/>}/>
                <Route path="groups/users"
                       element={<ViewGroupUsers session={session} config={config} replace={true}/>}/>
                <Route path="groups/departments"
                       element={<ViewGroupDepartments session={session} config={config} replace={true}/>}/>
                <Route path="groups/organizations"
                       element={<ViewGroupOrganizations session={session} config={config} replace={true}/>}/>
                <Route path="groups/roles"
                       element={<ViewGroupRoles session={session} config={config} replace={true}/>}/>
                <Route path="groups/products"
                       element={<ViewGroupProducts session={session} config={config} replace={true}/>}/>
                <Route path="groups/resources"
                       element={<ViewGroupResources session={session} config={config} replace={true}/>}/>

                <Route path="users" element={<ViewUsers session={session} config={config} replace={true}/>}/>
                <Route path="users/create"
                       element={<CreateUser session={session} config={config} replace={true}/>}/>
                <Route path="users/:id"
                       element={<UpdateUser session={session} config={config} replace={true}/>}/>

                <Route path="policies"
                       element={<ViewPolicies session={session} config={config} replace={true}/>}/>
                <Route path="policies/create"
                       element={<CreatePolicy session={session} config={config} replace={true}/>}/>
                <Route path="policies/:id"
                       element={<UpdatePolicy session={session} config={config} replace={true}/>}/>

                <Route path="products"
                       element={<ViewProducts session={session} config={config} replace={true}/>}/>
                <Route path="products/create"
                       element={<CreateProduct session={session} config={config} replace={true}/>}/>
                <Route path="products/:id"
                       element={<UpdateProduct session={session} config={config} replace={true}/>}/>

                <Route path="emails"
                       element={<ViewEmails session={session} config={config} replace={true}/>}/>
                <Route path="emails/create"
                       element={<CreateEmail session={session} config={config} replace={true}/>}/>
                <Route path="emails/:id"
                       element={<UpdateEmail session={session} config={config} replace={true}/>}/>

                <Route path="emails/types"
                       element={<ViewEmailTypes session={session} config={config} replace={true}/>}/>
                <Route path="emails/types/create"
                       element={<CreateEmailType session={session} config={config} replace={true}/>}/>
                <Route path="emails/types/:id"
                       element={<UpdateEmailType session={session} config={config} replace={true}/>}/>

                <Route path="numbers/phone"
                       element={<ViewPhoneNumbers session={session} config={config} replace={true}/>}/>
                <Route path="numbers/phone/create"
                       element={<CreatePhoneNumber session={session} config={config} replace={true}/>}/>
                <Route path="numbers/phone/:id"
                       element={<UpdatePhoneNumber session={session} config={config} replace={true}/>}/>

                <Route path="numbers/phone/types"
                       element={<ViewPhoneNumberTypes session={session} config={config} replace={true}/>}/>
                <Route path="numbers/phone/types/create"
                       element={<CreatePhoneNumberType session={session} config={config} replace={true}/>}/>
                <Route path="numbers/phone/types/:id"
                       element={<UpdatePhoneNumberType session={session} config={config} replace={true}/>}/>

                <Route path="logs/updates"
                       element={<ViewUpdateLogs session={session} config={config} replace={true}/>}/>
                <Route path="logs/errors"
                       element={<ViewErrorLogs session={session} config={config} replace={true}/>}/>
                <Route path="logs/logins"
                       element={<ViewLoginLogs session={session} config={config} replace={true}/>}/>
                <Route path="logs/requests"
                       element={<ViewRequestLogs session={session} config={config} replace={true}/>}/>
                <Route path="logs/emails"
                       element={<ViewEmailLogs session={session} config={config} replace={true}/>}/>
                <Route path="logs/sessions"
                       element={<ViewSessions session={session} config={config} replace={true}/>}/>
                <Route path="logs/pages/accessed"
                       element={<ViewPageAccessLogs session={session} config={config} replace={true}/>}/>

                <Route path="tags" element={<ViewTags session={session} config={config} replace={true}/>}/>
                <Route path="tags/create"
                       element={<CreateTag session={session} config={config} replace={true}/>}/>
                <Route path="tags/:id"
                       element={<UpdateTag session={session} config={config} replace={true}/>}/>

                <Route path="resources"
                       element={<ViewResources session={session} config={config} replace={true}/>}/>
                <Route path="resources/create"
                       element={<CreateResource session={session} config={config} replace={true}/>}/>
                <Route path="resources/:id"
                       element={<UpdateResource session={session} config={config} replace={true}/>}/>

                <Route path="resources/types"
                       element={<ViewResourceTypes session={session} config={config} replace={true}/>}/>
                <Route path="resources/types/create"
                       element={<CreateResourceType session={session} config={config} replace={true}/>}/>
                <Route path="resources/types/:id"
                       element={<UpdateResourceType session={session} config={config} replace={true}/>}/>

                <Route path="departments"
                       element={<ViewDepartments session={session} config={config} replace={true}/>}/>
                <Route path="departments/create"
                       element={<CreateDepartment session={session} config={config} replace={true}/>}/>
                <Route path="departments/:id"
                       element={<UpdateDepartment session={session} config={config} replace={true}/>}/>

                <Route path="roles" element={<ViewRoles session={session} config={config} replace={true}/>}/>
                <Route path="roles/create"
                       element={<CreateRole session={session} config={config} replace={true}/>}/>
                <Route path="roles/:id"
                       element={<UpdateRole session={session} config={config} replace={true}/>}/>

                <Route path="users/roles"
                       element={<ViewUserRoles session={session} config={config} replace={true}/>}/>
                <Route path="users/roles/create"
                       element={<CreateUserRole session={session} config={config} replace={true}/>}/>
                <Route path="users/:id/roles/create"
                       element={<CreateUserRole session={session} config={config} replace={true}/>}/>
                <Route path="users/roles/:id"
                       element={<UpdateUserRole session={session} config={config} replace={true}/>}/>

                <Route path="users/emails"
                       element={<ViewUserEmails session={session} config={config} replace={true}/>}/>
                <Route path="users/emails/create"
                       element={<CreateUserEmail session={session} config={config} replace={true}/>}/>
                <Route path="users/:id/emails/create"
                       element={<CreateUserEmail session={session} config={config} replace={true}/>}/>

                <Route path="users/numbers/phone"
                       element={<ViewUserPhoneNumbers session={session} config={config} replace={true}/>}/>
                <Route path="users/numbers/phone/create"
                       element={<CreateUserPhoneNumber session={session} config={config} replace={true}/>}/>
                <Route path="users/:id/numbers/phone/create"
                       element={<CreateUserPhoneNumber session={session} config={config} replace={true}/>}/>

                <Route path="users/roles/managers/reviews/daily"
                       element={<ViewUserRoleDailyReviewManagers session={session} config={config} replace={true}/>}/>
                <Route path="users/roles/managers/c3"
                       element={<ViewUserRoleC3Managers session={session} config={config} replace={true}/>}/>

                <Route path="users/legacy"
                       element={<ViewLegacyUsers session={session} config={config} replace={true}/>}/>
                <Route path="users/legacy/create"
                       element={<CreateLegacyUser session={session} config={config} replace={true}/>}/>
                <Route path="users/legacy/:id"
                       element={<UpdateLegacyUser session={session} config={config} replace={true}/>}/>
                <Route path="users/legacy/import"
                       element={<ImportLegacyUser session={session} config={config} replace={true}/>}/>
                <Route path="users/legacy/locations"
                       element={<ViewLegacyUserLocations session={session}
                                                         config={config} replace={true}/>}/>
                <Route path="users/mfa/secrets"
                       element={<ViewUserMfaSecrets session={session} config={config} replace={true}/>}/>
                <Route path="users/mfa/authentications"
                       element={<ViewUserMfaAuthentications session={session} config={config} replace={true}/>}/>

                <Route path="navigation/locations"
                       element={<ViewNavigationLocations session={session} config={config} replace={true}/>}/>
                <Route path="navigation/locations/:id"
                       element={<UpdateNavigationLocation session={session} config={config} replace={true}/>}/>
                <Route path="navigation/locations/create"
                       element={<CreateNavigationLocation session={session} config={config} replace={true}/>}/>
                <Route path="navigation/locations/policies"
                       element={<ViewNavigationLocationPolicies session={session} config={config} replace={true}/>}/>
                <Route path="navigation/locations/policies/:id"
                       element={<UpdateNavigationLocationPolicy session={session} config={config} replace={true}/>}/>
                <Route path="navigation/locations/policies/create"
                       element={<CreateNavigationLocationPolicy session={session} config={config} replace={true}/>}/>

                <Route path="sitemap"
                       element={<Sitemap session={session} config={config} replace={true}/>}/>
                <Route path="sessions"
                       element={<Sessions session={session} config={config} replace={true}/>}/>
                <Route path="sops"
                       element={<Sops session={session} config={config} replace={true}/>}/>
                <Route path="tokens/create"
                       element={<CreateTokens session={session} config={config} replace={true}/>}/>

                <Route path="*" element={<NoMatch session={session} replace={true}/>}/>
            </Route>
        </Routes>;
    }

    return <div id={"main-body"} className="d-flex flex-column bg-light" style={{
        backgroundImage: "url('" + background + "')",
        backgroundSize: "cover"
    }}>
        {routes}
    </div>
}