import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../commons/RequestCommons"
import TimeCommons from "../../../commons/TimeCommons";
import {Box} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import {Link, useNavigate} from "react-router-dom";
import AuthCommons from "../../../commons/AuthCommons";
import ViewEntryActionButton from "../../shared/tables/ViewEntryActionButton";
import DeleteEntryActionButton from "../../shared/tables/DeleteEntryActionButton";
import ReturnToLastPageLink from "../../shared/ReturnToLastPageLink";

export default function ViewUserRoles(props: any) {
    const navigate = useNavigate();

    const [tableData, setTableData]: any = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [userRoles]: any = await Promise.all([
            AuthCommons.request("/users/roles?populate=1")
        ]);

        const tableData: any = [];
        for (let i = 0; i < userRoles.length; i++) {
            const userRole = userRoles[i];
            userRole.start_date = userRole.start_date ? TimeCommons.formatDate(userRole.start_date) : null;
            userRole.end_date = userRole.end_date ? TimeCommons.formatDate(userRole.end_date) : null;
            userRole.created = TimeCommons.formatTimestamp(userRole.created);
            userRole.disabled = userRole.disabled ? "Yes" : "No";
            tableData.push(userRole);
        }
        setTableData(tableData);
    }

    const createLink = props.session.permissions.update ? <Link to={"/users/roles/create"}
                                                                className={"d-inline-block btn btn-primary"}
    >
        Create
    </Link> : null;

    const columns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'user.id', //access nested data with dot notation
            header: 'User Id',
            size: 70
        },
        {
            accessorKey: 'user.first_name', //access nested data with dot notation
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name', //access nested data with dot notation
            header: 'Last Name',
        },
        {
            accessorKey: 'email.email', //access nested data with dot notation
            header: 'Email',
        },
        {
            accessorKey: 'role.organization.name', //access nested data with dot notation
            header: 'Organization',
        },
        {
            accessorKey: 'role.department.name', //access nested data with dot notation
            header: 'Department',
        },
        {
            accessorKey: 'role.name', //access nested data with dot notation
            header: 'Role',
        },
        {
            accessorKey: 'disabled',
            header: 'Disabled',
        },
        {
            accessorKey: 'created',
            header: 'Created',
        }
    ];

    const defaultPageSize = 10;
    const initialState = {
        sorting: [
            {
                id: 'id', //sort by age by default on page load
                desc: true
            }
        ],
        pagination: {
            pageSize: defaultPageSize,
            pageIndex: 0
        },
        columnFilters: [
            {
                id: 'user_role_status.name',
                value: "Active"
            }
        ],
    };

    return <div className={"shadow-sm bg-white rounded border container p-3"}>
        <div className={"mb-3 d-none"}>
            <ReturnToLastPageLink/>
        </div>
        <MaterialReactTable
            muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
            enableStickyHeader={true}
            columns={columns}
            data={tableData}
            state={{
                isLoading: isLoading,
                density: "compact"
            }}
            initialState={initialState}
            //enableRowActions={true}
            //renderRowActions={renderRowActions}
            enablePagination={true}
            renderTopToolbarCustomActions={() => {
                return (
                    <div>
                        <h2 className={"me-3 d-inline-block align-top"}>User Roles</h2>
                        {createLink}
                    </div>
                );
            }}
            muiTableBodyRowProps={({row}: any) => ({
                onClick: (event) => {
                    navigate("/users/roles/" + row.original.id);
                },
                sx: {
                    cursor: 'pointer'
                }
            })}
        />
        <div className={"mt-3"}>
            <ReturnToLastPageLink/>
        </div>
    </div>;
}