import * as React from "react";
import {useEffect, useState} from "react";
import AppCommons from "../commons/AppCommons";
import {Navigate} from "react-router-dom";

export default function Logout(props: any) {
    const [isLoggedOut, setIsLoggedOut] = useState(false);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        AppCommons.clearSession();
        AppCommons.logout().finally(function () {
            setIsLoggedOut(true);
            props.setSession(null);
        });
    }

    if (isLoggedOut) {
        return <Navigate to={"/login"}/>;
    } else {
        return <div className={"m-auto text-center card p-5"}>
            <h4>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-3"}>Logging out...</span>
            </h4>
        </div>;
    }
}