import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {Link} from "react-router-dom";
import Select from "react-select";
import ReactElementCommons from "../../commons/ReactElementCommons";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";


export default function CreateDepartment(props: any) {
    const [alert, setAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [isRequesting, setIsRequesting] = useState(false);
    const [organizationOptions, setOrganizationOptions]: any = useState([]);
    const [organizationSelect, setOrganizationSelect]: any = useState(null);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
    }

    async function loadData() {
        let url = process.env.REACT_APP_AUTH_API + "/organizations";
        const organizations: any = await RequestCommons.request(url);
        const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id");
        setOrganizationOptions(organizationOptions);
    }

    async function createDepartment(event: any) {
        event.preventDefault();
        try {
            setIsRequesting(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const form: any = event.target;
            const formData: any = new FormData(form);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/departments";
            const id = await RequestCommons.request(url, null, payload, "POST");
            setAlert({
                class: "alert-success text-center",
                message: <>
                    <div>Created department.</div>
                    <div>
                        <Link to={"/departments/" + id}>Click here to update department.</Link>
                    </div>
                </>
            });
            setOrganizationSelect(null);
            form.reset();
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsRequesting(false);
        }
    }

    let buttonText: any = "Create";
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Creating...</span>
            </span>;
    }

    return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
        <div className={"mb-3 d-none"}>
            <ReturnToLastPageLink/>
        </div>
        <form onSubmit={createDepartment}>
            <h2>Create Department</h2>
            <div className="mb-3">
                <label className="form-label">Organization</label>
                <Select
                    onChange={setOrganizationSelect}
                    name="organization_id"
                    options={organizationOptions}
                    value={organizationSelect}
                    required
                />
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Name</label>
                <input name="name" className="form-control" required/>
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea name="description"
                          className="form-control"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Disabled</label>
                <ReactSelectDisabled required/>
            </div>
            <button type="submit" className="btn btn-primary form-control"
                    disabled={isRequesting}>{buttonText}</button>
            <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
        </form>
        <div className={"mt-3"}>
                <ReturnToLastPageLink/>
        </div>
    </div>;
}