import * as React from "react";
import {useState} from "react";
import {Link, Navigate} from "react-router-dom";
import RequestCommons from "../../commons/RequestCommons";
import AppCommons from "../../commons/AppCommons";
import CookieCommons from "../../commons/CookieCommons";

export default function VerifyMfa(props: any) {
    const [isVerified, setIsVerified] = useState(false);
    const [isRequesting, setIsRequesting] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [alert, setAlert] = useState({
        class: "d-none",
        message: "",
    });

    async function verifyMFA(code: string) {
        setIsRequesting(true);
        const submit: any = document.getElementById("mfa-submit-button");
        submit.disabled = true;
        const url = process.env.REACT_APP_AUTH_API + "/mfa/verify";
        const codeObj = {'code': code};
        const body = new URLSearchParams(codeObj);
        RequestCommons.request(url, null, body, "post").then(async function (response: any) {
            if (response?.mfa?.token) {
                CookieCommons.setCookie(process.env.REACT_APP_MFA_TOKEN_COOKIE_NAME, response.mfa.token, 365);
            }
            setAlert({
                class: "alert-success text-center",
                message: "Verified.",
            });
            const session = await AppCommons.getUserSession();
            props.setSession(session);
            setIsVerified(true);
        }).catch(function (error) {
            setShowWarning(true);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error,
            });
        }).finally(function () {
            setIsRequesting(false);
            submit.disabled = false;
        });
    }

    async function onSubmit(event: any) {
        event.preventDefault();
        const input: any = document.getElementById("input-code");
        await verifyMFA(input.value);
    }

    if (isVerified) {
        return <Navigate to={"/"}/>;
    } else {
        let buttonText: any = "Verify";

        if (isRequesting) {
            buttonText = <span>
            <span className={"loading-spinner"}>
              <i className="bi bi-arrow-clockwise"></i>
            </span>
            <span className={"ms-2"}>Verifying...</span>
          </span>;
        }

        let warning: any = null;
        if (showWarning) {
            warning = <div id={"warning"} className={"mb-3"}>
                <div className={"alert alert-warning d-flex mb-1 mt-1"}>
                    <small>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                             className="bi bi-exclamation-triangle-fill me-2" role="img"
                             aria-label="Warning:">
                            <path
                                d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                        </svg>
                    </small>
                    <small>
                          <span>If you are unable to verify your code after multiple attempts then contact the Engineering Department to re-setup your MFA.
                          </span>
                    </small>
                </div>
            </div>;
        }

        return (
            <form id="mfa-form" className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"}
                  style={{width: "400px"}}
                  onSubmit={onSubmit} autoComplete={"on"}>
                <h2 className={"text-center mb-3"}>Multi-Factor Authentication</h2>
                <h4 className={"w-100 text-center mb-3"}>
                    <code>{props.mfa.name}</code>
                </h4>
                {warning}
                <div className="mb-3">
                    <input id={"input-code"} name={"code"} maxLength={6} placeholder="Enter 6 digit code..."
                           className="form-control" autoFocus
                    />
                </div>
                <button id="mfa-submit-button" className="btn btn-primary form-control mb-3"> {buttonText}</button>
                <div className={"alert mb-3 text-center " + alert.class}>
                    {alert.message}
                </div>
                <div className="mb-3 text-center">
                    <Link to={"/login"} replace={true} style={{
                        textDecoration: "none"
                    }} onClick={() => {
                        props.setMfa(null);
                    }}>Return to Login</Link>
                </div>
            </form>
        );
    }
}
