import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../commons/RequestCommons"
import {Link} from "react-router-dom";
import Select from "react-select";
import ReactSelectDisabled from "../../../commons/ReactSelectDisabled";
import ReactElementCommons from "../../../commons/ReactElementCommons";
import AuthCommons from "../../../commons/AuthCommons";
import ReturnToLastPageLink from "../../shared/ReturnToLastPageLink";


export default function CreatePhoneNumber(props: any) {
    const [alert, setAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [isRequesting, setIsRequesting] = useState(false);
    const [organizationOptions, setOrganizationOptions]: any = useState([]);
    const [phoneNumberTypeOptions, setPhoneNumberTypeOptions]: any = useState([]);
    const [phoneNumberStrings, setPhoneNumberStrings]: any = useState([]);
    const [phoneNumberExists, setPhoneNumberExists]: any = useState(false);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
    }

    async function loadData() {
        const [organizations, phoneNumberTypes, phoneNumbers]: any = await Promise.all([
            AuthCommons.request("/organizations"),
            AuthCommons.request("/numbers/phone/types"),
            AuthCommons.request("/numbers/phone")
        ]);

        const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id", " ", true);
        setOrganizationOptions(organizationOptions);

        const phoneNumberTypeOptions: any = ReactElementCommons.createReactSelectOptionsFromList(phoneNumberTypes, "name", "id");
        setPhoneNumberTypeOptions(phoneNumberTypeOptions);

        const phoneNumberStrings: any = [];
        for (let i = 0; i < phoneNumbers.length; i++) {
            const phoneNumber = phoneNumbers[i];
            phoneNumberStrings.push(phoneNumber["phone_number"]);
        }
        setPhoneNumberStrings(phoneNumberStrings);
    }

    async function createPhoneNumber(event: any) {
        event.preventDefault();
        try {
            setIsRequesting(true);
            setAlert({
                class: "d-none",
                message: ""
            });

            const form: any = event.target;
            const formData: any = new FormData(form);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/numbers/phone";
            const id = await RequestCommons.request(url, null, payload, "POST");
            setAlert({
                class: "alert-success text-center",
                message: <>
                    <div>Created phone number.</div>
                    <div>
                        <Link to={"/numbers/phone/" + id}>Click here to update phone number.</Link>
                    </div>
                </>
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsRequesting(false);
        }
    }

    function checkPhoneNumberExists(phoneNumber: string) {
        const phoneNumberExists: any = phoneNumberStrings.includes(phoneNumber);
        setPhoneNumberExists(phoneNumberExists);
    }

    let buttonText: any = "Create";
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Creating...</span>
            </span>;
    }

    let phoneNumberFieldDescription: any = <small>Required</small>;
    if (phoneNumberExists) {
        phoneNumberFieldDescription = <small className={"text-danger"}>Phone number already exists.</small>;
    }

    return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
        <form onSubmit={createPhoneNumber}>
            <h1>Create Phone Number</h1>
            <div className="mb-3">
                <label className="form-label">Phone Number</label>
                <input name="phone_number" type={"tel"}
                       className={"form-control " + (phoneNumberExists ? "is-invalid text-danger" : "")}
                       onChange={function (event: any) {
                           const phoneNumber = event.target.value;
                           checkPhoneNumberExists(phoneNumber);
                       }}/>
                {phoneNumberFieldDescription}
            </div>
            <div className="mb-3">
                <label className="form-label">Type</label>
                <Select
                    name="phone_number_type_id"
                    options={phoneNumberTypeOptions}
                    required
                />
                <small>Required</small>
            </div>
            <div className="mb-3">
                <label className="form-label">Organization</label>
                <Select
                    name="organization_id"
                    options={organizationOptions}
                />
            </div>
            <div className="mb-3">
                <label className="form-label">Name</label>
                <input name="name" className="form-control"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Description</label>
                <textarea name="description"
                          className="form-control"/>
            </div>
            <div className="mb-3">
                <label className="form-label">Disabled</label>
                <ReactSelectDisabled required/>
            </div>
            <button type="submit" className="btn btn-primary form-control"
                    disabled={isRequesting || phoneNumberExists}>{buttonText}</button>
            <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
        </form>
        <div className={"row mt-3"}>
            <div className={"col"}>
                <ReturnToLastPageLink/>
            </div>
        </div>
    </div>;
}