import * as React from "react";
import {useState} from "react";
import RequestCommons from "../commons/RequestCommons"
import {Link} from "react-router-dom";

export default function UpdatePassword(props: any) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let returnUrl: any = urlSearchParams.get("return_url");
    if (returnUrl === null) {
        returnUrl = "/login";
    }
    const [alert, setAlert] = useState({
        class: "d-none",
        message: ""
    });
    const [isRequesting, setIsRequesting] = useState(false);
    const style = props.style ? props.style : {width: "500px"};
    const className = props.className ? props.className : process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto";
    // Holds the consumer token of password reset from the URL, if empty then that means it is not a password reset, just an update
    let token = window.location.pathname.substring(17);

    let buttonText: any = "Update";
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Updating...</span>
            </span>;
    }

    function checkPassword(password: any, password2: any) {
        if (password === password2) {
            return true;
        } else {
            setAlert({
                class: "alert-warning",
                message: "Passwords do not match."
            });
            return false;
        }
    }

    const onSubmit = function (event: any) {
        event.preventDefault();
        const form: any = event.target;
        const formData: any = new FormData(form);
        const data: any = Object.fromEntries(formData);
        //Check if a user id is passed in, meaning someone else is resetting password
        if (props.uid) {
            data["user_id"] = props.uid
        }
        const body = new URLSearchParams(data);

        setAlert({
            class: "d-none",
            message: ""
        });

        const check: any = checkPassword(data["password"], data["password_2"]);
        if (check) {
            setIsRequesting(true);
            const url = process.env.REACT_APP_AUTH_API + "/password/update";
            RequestCommons.request(url, token, body, "post", 'application/x-www-form-urlencoded', false).then(async function (response: any) {
                let message = "An error has occurred, try resetting your password again.";
                let className = "alert-danger";

                if (response.status === 200) {
                    className = "alert-success";
                    message = "Your password was updated.";
                } else if (response.status === 401) {
                    className = "alert-warning";
                    message = "Your password reset session has expired or was already used, try resetting your password again using the reset password link.";
                } else {
                    const error = await response.json();
                    message = error.detail ? error.detail : "An error has occurred. Try resetting your password again."
                }

                setAlert({
                    class: className,
                    message: message
                });

                form.reset();
            }).catch(function (error) {
                setAlert({
                    class: "alert-danger",
                    message: error.detail ? error.detail : "An error has occurred. Try resetting your password again."
                });
            }).finally(function () {
                setIsRequesting(false);
            });
        }
    }

    return <form className={className} style={style}
                 onSubmit={onSubmit}>
        <h1>Update Password</h1>
        <div className="mb-3">
            <label className="form-label">Password</label>
            <input name={"password"} type="password" className="form-control" required autoFocus autoComplete="on"/>
        </div>
        <div className="mb-3">
            <label className="form-label">Repeat Password</label>
            <input name={"password_2"} type="password" className="form-control" required autoComplete="on"/>
        </div>
        <button type="submit" className="btn btn-primary form-control"
                disabled={isRequesting}>{buttonText}</button>
        <div className={"mt-3 alert " + alert.class}>{alert.message}</div>
        {props.fromOtherComponent ? "" :
            <div className={"mt-3"}>
                <Link to={returnUrl} style={{textDecoration: "none"}}>
                    <i className="bi bi-arrow-left"></i>
                    <span className={"ms-2"}>Return to Log In</span>
                </Link>
            </div>
        }
    </form>;
}