import * as React from "react";
import {Outlet, useLocation} from "react-router-dom";
import Nav from "./Nav";
import Footer from "./Footer";
import StringCommons from "../../commons/StringCommons";

export default function Layout(props: any) {
    const location = useLocation();

    let subtitle = "";
    if (location.pathname !== "/") {
        const pathnameParts = location.pathname.split("/");
        pathnameParts.splice(0, 1);
        for (let i = 0; i < pathnameParts.length; i++) {
            pathnameParts[i] = StringCommons.capitalizeFirstLetter(pathnameParts[i]);
        }

        if (pathnameParts.length > 0) {
            subtitle = " - " + pathnameParts.join(" - ");
        }
    }

    document.title = "Auth Portal" + subtitle;

    return <>
        <div id={"page-content"}>
            <Nav {...props}/>
            <div id={"inner-body"} className={"d-flex pb-3 pt-3"}>
                <Outlet/>
            </div>
        </div>
        <Footer {...props}/>
    </>;
}