import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {MaterialReactTable} from "material-react-table";
import TimeCommons from "../../commons/TimeCommons";
import AuthCommons from "../../commons/AuthCommons";
import ReactElementCommons from "../../commons/ReactElementCommons";
import Select from "react-select";

export default function ViewLoginLogs(props: any) {
    const [isLoading, setIsLoading]: any = useState(true);
    const [loginTableData, setLoginTableData]: any = useState([]);
    const [userOptions, setUserOptions]: any = useState([]);
    const [selectedUserOption, setSelectedUserOption]: any = useState(null);
    const [startDate, setStartDate]: any = useState(TimeCommons.toYmd(TimeCommons.toDate()));
    const [endDate, setEndDate]: any = useState(TimeCommons.toYmd(TimeCommons.toDate()));

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        loadLogs();
    }, [selectedUserOption, startDate, endDate]);

    async function init() {
        await loadData();
    }

    async function loadData() {
        setIsLoading(true);
        await loadUsers();
        await loadLogs();
        setIsLoading(false);
    }

    async function loadLogs() {
        const params: any = {
            limit: 500,
            order_by: "id",
            order_by_direction: "desc",
            populate: "user,session,session.user_role,session.user_role.email,session.user_role.role,session.user_role.role.organization,session.user_role.role.department,resource,policy"
        }
        if (startDate) {
            params["created_start_date"] = startDate;
            delete params["limit"];
        }
        if (endDate) {
            params["created_end_date"] = endDate;
            delete params["limit"];
        }
        if (selectedUserOption) {
            params["user_id"] = selectedUserOption.value;
            delete params["limit"];
        }
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        const url = process.env.REACT_APP_AUTH_API + "/logs/authentications?" + queryString;
        const recentLogins: any = await RequestCommons.request(url);
        const loginTableData: any = [];
        for (let i = 0; i < recentLogins.length; i++) {
            const recentLogin: any = recentLogins[i];
            recentLogin.created = TimeCommons.formatTimestamp(recentLogin.created);
            recentLogin.success = recentLogin.error === 0 ? "Yes" : "No";
            loginTableData.push(recentLogin);
        }
        setLoginTableData(loginTableData);
    }

    async function loadUsers() {
        const users: any = await AuthCommons.request("/users");
        users.sort((a: any, b: any) => {
            return a.last_name.localeCompare(b.last_name) || a.first_name.localeCompare(b.first_name);
        });
        const userOptions: any = ReactElementCommons.createReactSelectOptionsFromList(users, ["first_name", "last_name"], "id");
        setUserOptions(userOptions);
    }

    const loginColumns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'user.first_name',
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'email', //normal accessorKey
            header: 'Email',
        },
        {
            accessorKey: 'policy.name',
            header: 'Portal',
        },
        {
            accessorKey: 'success', //normal accessorKey
            header: 'Success',
        },
        {
            accessorKey: 'created',
            header: 'Created',
            size: 100
        }
    ];

    return <div className={"m-auto container bg-white border shadow-sm rounded p-4"}>
        <h2 className={""}>Logins</h2>
        <form className={"d-inline-block position-relative"} style={{
            zIndex: 999
        }} onSubmit={async function (event: any) {
            event.preventDefault();
            await loadLogs();
        }}>
            <div className={"mb-3 me-3 d-inline-block"} style={{
                minWidth: "300px"
            }}>
                <label>User</label>
                <Select
                    options={userOptions}
                    onChange={setSelectedUserOption}
                    value={selectedUserOption}
                />
            </div>
            <div className={"mb-3 me-3 d-inline-block"}>
                <label>Start Date</label>
                <input
                    type={"date"}
                    name={"start-date"}
                    className={"form-control"}
                    onChange={function (event: any) {
                        setStartDate(event.target.value);
                    }}
                    onClick={function (event: any) {
                        event.target.showPicker();
                    }}
                    value={startDate}
                />
            </div>
            <div className={"mb-3 me-3 d-inline-block"}>
                <label>End Date</label>
                <input
                    type={"date"}
                    name={"end-date"}
                    className={"form-control"}
                    onChange={function (event: any) {
                        setEndDate(event.target.value);
                    }}
                    onClick={function (event: any) {
                        event.target.showPicker();
                    }}
                    value={endDate}
                />
            </div>
        </form>
        <MaterialReactTable
            muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
            enableStickyHeader={true}
            columns={loginColumns}
            data={loginTableData}
            initialState={{
                sorting: [{
                    id: "id",
                    desc: true
                }]
            }}
            state={{
                isLoading: isLoading,
                density: "compact"
            }}
        />
    </div>;
}