import * as React from "react";
import {Link} from "react-router-dom";

export default function Unauthorized(props: any) {
    return <div className={"m-auto text-center card p-5"}>
        <h4>401: Unauthorized</h4>
        <label>You are unauthorized to view this resource or your login session has expired.</label>
        <br/>
        <Link className={"text-decoration-none"} to={"/login"}>Click here to log in again.</Link>
    </div>;
}