import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../commons/RequestCommons"
import TimeCommons from "../../../commons/TimeCommons";
import {Box} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import {Link} from "react-router-dom";
import ViewEntryActionButton from "../../shared/tables/ViewEntryActionButton";
import DeleteEntryActionButton from "../../shared/tables/DeleteEntryActionButton";

export default function ViewNavigationLocationPolicies(props: any) {
    const [isLoading, setIsLoading]: any = useState(true);
    const [tableData, setTableData]: any = useState([]);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        let url = process.env.REACT_APP_AUTH_API + "/navs/portals/policies?populate=1";
        const navigationPolicies: any = await RequestCommons.request(url);
        for (let i = 0; i < navigationPolicies.length; i++) {
            const navigationPolicy = navigationPolicies[i];
            navigationPolicy.disabled = navigationPolicy.disabled ? "Yes" : "No";
            navigationPolicy.created = TimeCommons.formatTimestamp(navigationPolicy.created);
        }
        setTableData(navigationPolicies);
    }

    function deleteNavigationLocationPolicy(id: number) {
        const c = window.confirm("Are you sure you want to delete this entry?");
        if (c) {
            const url = process.env.REACT_APP_AUTH_API + "/navs/portals/policies/" + id;
            RequestCommons.request(url, null, null, "DELETE").then(async function (response) {
                await loadData();
            }).catch(function (error) {
                console.error(error);
                alert(error.detail);
            });
        }
    }

    function renderRowActions(data: any) {
        const id = data.row.original.id;
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton to={"/navigation/locations/policies/" + id}/>
            {props.session.permissions.delete ?
                <DeleteEntryActionButton onClick={function (event: any) {
                    event.preventDefault();
                    deleteNavigationLocationPolicy(id);
                }}/> : null}
        </Box>;
    }

    const createLink = props.session.permissions.update ? <Link to={"/navigation/locations/policies/create"}
                                                                className={"d-inline-block btn btn-primary"}
    >
        Create
    </Link> : null;

    const columns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'nav.id',
            header: 'Location Id',
            size: 70
        },
        {
            accessorKey: 'nav.name',
            header: 'Location',
        },
        {
            accessorKey: 'nav.href', //normal accessorKey
            header: 'Location URI',
        },
        {
            accessorKey: 'portal_policy.id',
            header: 'Portal Policy Id',
            size: 70
        },
        {
            accessorKey: 'portal_policy.name',
            header: 'Portal Policy Name',
        },
        {
            accessorKey: 'portal_policy.organization.name',
            header: 'Portal Policy Org',
        },
        {
            accessorKey: 'policy.id',
            header: 'Policy Id',
            size: 70
        },
        {
            accessorKey: 'policy.name',
            header: 'Policy Name',
        },
        {
            accessorKey: 'policy.organization.name',
            header: 'Policy Org',
        },
        {
            accessorKey: 'disabled',
            header: 'Disabled',
            size: 100
        },
        {
            accessorKey: 'created',
            header: 'Created',
            size: 100
        }
    ];

    const defaultPageSize = 10;
    const initialState = {
        sorting: [
            {
                id: 'id', //sort by age by default on page load
                desc: false
            }
        ],
        pagination: {
            pageSize: defaultPageSize,
            pageIndex: 0
        }
    };

    return <div className={"p-5 pt-2 w-100"}>
        <div className={"shadow-sm bg-white rounded border"}>
            <MaterialReactTable
                muiTablePaperProps={props.config.materialReactTable.muiTablePaperProps}
                muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}
                state={{
                    isLoading: isLoading,
                    density: "compact"
                }}
                initialState={initialState}
                enableRowActions={true}
                renderRowActions={renderRowActions}
                enablePagination={true}
                renderTopToolbarCustomActions={() => {
                    return (
                        <div>
                            <h2 className={"me-3 d-inline-block align-top"}>Navigation Location Policies</h2>
                            {createLink}
                        </div>
                    );
                }}
            />
        </div>
    </div>;
}