import Select from "react-select";
import * as React from "react";
import {useState} from "react";

export default function ReactSelectDisabled(props: any) {
    const options = [
        {
            label: "No",
            value: 0
        },
        {
            label: "Yes",
            value: 1
        }
    ];

    const name = props.hasOwnProperty("name") ? props.name : "disabled";
    let value = props.hasOwnProperty("value") ? props.value : null;
    let defaultValue = props.hasOwnProperty("defaultValue") ? props.defaultValue : null;
    if (value !== null) {
        if (value === true || value === 1) {
            defaultValue = options[1];
        } else {
            defaultValue = options[0];
        }
    } else if (defaultValue !== null) {
        if (defaultValue === true || defaultValue === 1) {
            defaultValue = options[1];
        } else {
            defaultValue = options[0];
        }
    } else {
        defaultValue = options[0];
    }
    const [selected, setSelected]: any = useState(defaultValue);
    let onChange: any = props.hasOwnProperty("onChange") ? props.onChange : null;
    const required = props.hasOwnProperty("required") ? props.required : null;
    const className = props.hasOwnProperty("className") ? props.className : null;
    const isDisabled = props.hasOwnProperty("isDisabled") ? props.isDisabled : null;
    const highlightDisabledRed = props.hasOwnProperty("highlightDisabledRed") ? props.highlightDisabledRed : true;

    let selectProps: any = {
        name: name,
        options: options
    };

    if (onChange !== null) {
        selectProps["onChange"] = function (option: any) {
            onChange(option);
            setSelected(option);
        }
    } else {
        selectProps["onChange"] = setSelected;
    }

    if (required !== null) {
        selectProps["required"] = required;
    }

    if (className != null) {
        selectProps["className"] = className;
    }

    if (isDisabled != null) {
        selectProps["isDisabled"] = isDisabled;
    }

    const styles: any = {};
    if (highlightDisabledRed && selected.value === 1) {
        styles["borderColor"] = "#dc3545";
        styles["background"] = "#dc3545";
        styles["color"] = "white";
    }

    if (required) {
        return <div>
            <Select {...selectProps}
                    styles={{
                        singleValue: (baseStyles, state) => ({
                            ...baseStyles,
                            ...styles
                        }),
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            ...styles
                        })
                    }} value={selected} required/>
            <small>Required</small>
        </div>
    } else {
        return <div>
            <Select {...selectProps}
                    styles={{
                        singleValue: (baseStyles, state) => ({
                            ...baseStyles,
                            ...styles
                        }),
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            ...styles
                        })
                    }} value={selected}/>
        </div>;
    }
}