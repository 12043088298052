import Select from "react-select";
import * as React from "react";
import {useEffect, useState} from "react";
import AuthCommons from "../../commons/AuthCommons";
import ReactElementCommons from "../../commons/ReactElementCommons";
import {Accordion, ListGroup} from "react-bootstrap";

export default function CreateTokens(props: any) {
    const [organizationOptions, setOrganizationOptions]: any = useState([]);
    const [selectedOrganizationOption, setSelectedOrganizationOption]: any = useState(null);
    const [token, setToken]: any = useState(null);

    useEffect(() => {
        init();
    }, []);

    async function createToken(organizationId: any, type: any) {
        const uri = "/tokens/create/" + type + "?organization_id=" + organizationId;
        const token = await AuthCommons.request(uri);
        setToken(token);
    }

    async function init() {
        const [organizations]: any = await Promise.all([
            AuthCommons.request("/organizations?populate=1")
        ]);
        const organizationOptions = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id");
        setOrganizationOptions(organizationOptions);
    }

    let tokenBody = null;
    if (token) {
        tokenBody = <div className={"mt-3"}>
            <div className={"text-danger"}>
                <i className="bi bi-exclamation-triangle-fill me-2"/>
                WARNING: Do not share this token with anyone or send it through any unsecure communication medium. It
                should be placed directly into the config of the associated application.
            </div>
            <div className={"border p-3 mt-3 rounded bg-light"}>
                <code className={"text-dark"}>{token}</code>
            </div>
        </div>;
    }

    return <div className={"p-5 pt-2 w-100"}>
        <div className={"bg-white m-auto p-3 border shadow-sm rounded"} style={{
            maxWidth: "600px"
        }}>
            <h1>Tokens</h1>
            <div className={""}>
                <span>There are a couple different types of tokens used during our auth processes that will need to be
                integrated into the apps. More information about the tokens can be viewed below.</span>
                <Accordion alwaysOpen className={"mt-3"}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>App Tokens</Accordion.Header>
                        <Accordion.Body>
                            <ListGroup>
                                <ListGroup.Item>Used for communication between App Servers (IAM, LOA, Daily Review,
                                    etc.)
                                    and the Auth Server</ListGroup.Item>
                                <ListGroup.Item>Each App Server should be using this token for all communication with
                                    the
                                    Auth Server.</ListGroup.Item>
                                <ListGroup.Item>This token should be stored in the application's configuration
                                    file.</ListGroup.Item>
                                <ListGroup.Item>The configuration set up between App Servers may be different so consult
                                    with the product developer for more clarification.</ListGroup.Item>
                                <ListGroup.Item>This token should be passed in as a <i>Bearer</i> token in the HTTP
                                    request headers.</ListGroup.Item>
                                <ListGroup.Item className={"text-danger"}>
                                    <i className="bi bi-exclamation-triangle-fill me-2"/>
                                    WARNING: Do not share this token with anyone or send it through any unsecure
                                    communication medium. It
                                    should be placed directly into the config of the associated application.
                                </ListGroup.Item>
                            </ListGroup>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Login Tokens</Accordion.Header>
                        <Accordion.Body>
                            <ListGroup>
                                <ListGroup.Item>Used in the login requests coming from the <i>Portal Client</i> and <i>Auth
                                    Client</i> pages.</ListGroup.Item>
                                <ListGroup.Item>This is just an added layer of security that is required for all
                                    requests being made to the <i>/login</i> endpoint
                                    of the <i>Auth Server</i>.</ListGroup.Item>
                                <ListGroup.Item>This token should be stored in the <i>Portal Client</i> .env file
                                    immediately.</ListGroup.Item>
                                <ListGroup.Item>This token should be passed in as a <i>Bearer</i> token in the HTTP
                                    request <i>Authorization</i> header.</ListGroup.Item>
                                <ListGroup.Item>The <i>.env</i> field name for the <i>Portal
                                    Client</i> is <i>VITE_CONSUMER_TOKEN</i></ListGroup.Item>
                                <ListGroup.Item>The <i>.env</i> field name for the <i>Auth
                                    Client</i> is <i>REACT_APP_CONSUMER_TOKEN</i></ListGroup.Item>
                                <ListGroup.Item className={"text-danger"}>
                                    <i className="bi bi-exclamation-triangle-fill me-2"/>
                                    WARNING: Do not share this token with anyone or send it through any unsecure
                                    communication medium. It should be placed directly into the .env of the respect <i>Portal
                                    Client</i> application.
                                </ListGroup.Item>
                            </ListGroup>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <form className={"w-100 mt-3"}>
                <h4>Create Tokens</h4>
                <div>
                    <label>Organization</label>
                    <Select
                        name="organization_id"
                        options={organizationOptions}
                        value={selectedOrganizationOption}
                        onChange={function (option) {
                            setSelectedOrganizationOption(option);
                        }}
                    />
                </div>
                <div className={"row mt-3"}>
                    <div className={"col"}>
                        <button type={"button"}
                                className={"btn btn-primary form-control"}
                                onClick={async function (event) {
                                    event.preventDefault();
                                    console.log(selectedOrganizationOption);
                                    await createToken(selectedOrganizationOption.value, "app");
                                }}
                        >
                            Create App Token
                        </button>
                    </div>
                    <div className={"col"}>
                        <button type={"button"}
                                className={"btn btn-primary form-control"}
                                onClick={async function (event) {
                                    event.preventDefault();
                                    await createToken(selectedOrganizationOption.value, "login");
                                }}
                        >
                            Create Login Token
                        </button>
                    </div>
                </div>
                {tokenBody}
            </form>
        </div>
    </div>;
}