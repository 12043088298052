import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {useParams} from "react-router-dom";
import TimeCommons from "../../commons/TimeCommons";
import {MaterialReactTable} from "material-react-table";
import ObjectCommons from "../../commons/ObjectCommons";
import ReactElementCommons from "../../commons/ReactElementCommons";
import Select from "react-select";
import AuthorizationsTable from "../authorizations/AuthorizationsTable";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import {Accordion} from "react-bootstrap";
import AuthCommons from "../../commons/AuthCommons";
import Loading from "../Loading";
import DoesNotExist from "../shared/DoesNotExist";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";
import EntryDeleted from "../shared/EntryDeleted";
import DeleteModal from "../shared/DeleteModal";

export default function UpdateGroup(props: any) {
    const params = useParams();

    const [alert, setAlert] = useState({
        class: "d-none",
        message: ""
    });
    const [groupDeleted, setGroupDeleted]: any = useState(false);
    const [groupExists, setGroupExists]: any = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [group, setGroup]: any = useState({});
    const [organizationOptions, setOrganizationOptions] = useState([]);
    const [organizationSelect, setOrganizationSelect]: any = useState(null);

    const [groupUsersTableData, setGroupUsersTableData]: any = useState([]);
    const [groupUsersRowSelection, setGroupUsersRowSelection]: any = useState({});
    const [groupUsersInitialRowSelection, setGroupUsersInitialRowSelection]: any = useState({});

    const [groupUserRolesTableData, setGroupUserRolesTableData]: any = useState([]);
    const [groupUserRolesRowSelection, setGroupUserRolesRowSelection]: any = useState({});
    const [groupUserRolesInitialRowSelection, setGroupUserRolesInitialRowSelection]: any = useState({});

    const [groupPoliciesTableData, setGroupPoliciesTableData]: any = useState([]);
    const [groupPoliciesRowSelection, setGroupPoliciesRowSelection]: any = useState({});
    const [groupPoliciesInitialRowSelection, setGroupPoliciesInitialRowSelection]: any = useState({});

    const [groupRolesTableData, setGroupRolesTableData]: any = useState([]);
    const [groupRolesRowSelection, setGroupRolesRowSelection]: any = useState({});
    const [groupRolesInitialRowSelection, setGroupRolesInitialRowSelection]: any = useState({});

    const [groupDepartmentsTableData, setGroupDepartmentsTableData]: any = useState([]);
    const [groupDepartmentsRowSelection, setGroupDepartmentsRowSelection]: any = useState({});
    const [groupDepartmentsInitialRowSelection, setGroupDepartmentsInitialRowSelection]: any = useState({});

    const [groupOrganizationsTableData, setGroupOrganizationsTableData]: any = useState([]);
    const [groupOrganizationsRowSelection, setGroupOrganizationsRowSelection]: any = useState({});
    const [groupOrganizationsInitialRowSelection, setGroupOrganizationsInitialRowSelection]: any = useState({});

    const [groupResourcesTableData, setGroupResourcesTableData]: any = useState([]);
    const [groupResourcesRowSelection, setGroupResourcesRowSelection]: any = useState([]);
    const [groupResourcesInitialRowSelection, setGroupResourcesInitialRowSelection]: any = useState([]);

    const [groupEmailsTableData, setGroupEmailsTableData]: any = useState([]);
    const [groupEmailsRowSelection, setGroupEmailsRowSelection]: any = useState([]);
    const [groupEmailsInitialRowSelection, setGroupEmailsInitialRowSelection]: any = useState([]);

    const [groupPhoneNumbersTableData, setGroupPhoneNumbersTableData]: any = useState([]);
    const [groupPhoneNumbersRowSelection, setGroupPhoneNumbersRowSelection]: any = useState([]);
    const [groupPhoneNumbersInitialRowSelection, setGroupPhoneNumbersInitialRowSelection]: any = useState([]);

    const [groupProductsTableData, setGroupProductsTableData]: any = useState([]);
    const [groupProductsRowSelection, setGroupProductsRowSelection]: any = useState([]);
    const [groupProductsInitialRowSelection, setGroupProductsInitialRowSelection]: any = useState([])

    const [authorizations, setAuthorizations]: any = useState([]);

    const [changesMade, setChangesMade] = useState(false);
    const [deleteModalShow, setDeleteModalShow]: any = useState(false);
    const [deleteModalAlert, setDeleteModalAlert]: any = useState(null);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setIsLoading(true);
        await loadData();
    }

    async function loadData() {
        const [groupResponse]: any = await Promise.all([
            AuthCommons.request("/groups/" + params.id, null, null, 'get', 'application/x-www-form-urlencoded', false)
        ]);
        if (groupResponse.status === 204) {
            // display not exists warning
            setGroupExists(false);
            setIsLoading(false);
        } else {
            setGroupExists(true);
            await loadGroup();
            setIsLoading(false);
            await Promise.all([
                loadAuthorizations(),
                loadGroupPolicies(),
                loadGroupUsers(),
                loadGroupUserRoles(),
                loadGroupRoles(),
                loadGroupDepartments(),
                loadGroupOrganizations(),
                loadGroupProducts(),
                loadGroupResources(),
                loadGroupEmails(),
                loadGroupPhoneNumbers()
            ]);
        }
    }

    async function loadGroup() {
        const [group, organizations]: any = await Promise.all([
            RequestCommons.request(process.env.REACT_APP_AUTH_API + "/groups/" + params.id + "?populate=1"),
            RequestCommons.request(process.env.REACT_APP_AUTH_API + "/organizations?populate=1")
        ]);
        setGroup(group);
        const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id");
        setOrganizationOptions(organizationOptions);
        setOrganizationSelect(organizationOptions.find((option: any) => option.value === group.organization_id));
    }

    async function loadAuthorizations() {
        const url = process.env.REACT_APP_AUTH_API + "/authorizations?group_id=" + params.id + "&populate=1";
        const authorizations: any = await RequestCommons.request(url);
        setAuthorizations(authorizations);
    }

    async function updateGroup(event: any) {
        event.preventDefault();
        try {
            setIsUpdating(true);
            setAlert({
                class: "d-none",
                message: ""
            });

            const form: any = event.target;
            const formData = new FormData(form);
            const newGroup: any = Object.fromEntries(formData);
            newGroup.organization_id = parseInt(newGroup.organization_id);
            newGroup.disabled = parseInt(newGroup.disabled);

            const dataToUpdate = [];

            if (group.organization_id !== newGroup.organization_id
                || group.name !== newGroup.name
                || group.description !== newGroup.description
                || group.disabled !== newGroup.disabled
            ) {
                const body = new URLSearchParams(newGroup);
                dataToUpdate.push(replaceGroupInfo(group.id, body));
            }

            if (!ObjectCommons.compareObjects(groupUsersInitialRowSelection, groupUsersRowSelection)) {
                const userIds: any = [];
                for (let userId in groupUsersRowSelection) {
                    userIds.push(parseInt(userId));
                }
                setGroupUsersInitialRowSelection(groupUsersRowSelection) // Reset initial selection after updating
                dataToUpdate.push(replaceGroupUsers(group.id, userIds));
            }
            if (!ObjectCommons.compareObjects(groupPoliciesInitialRowSelection, groupPoliciesRowSelection)) {
                const policyIds: any = [];
                for (let policyId in groupPoliciesRowSelection) {
                    policyIds.push(parseInt(policyId));
                }
                setGroupPoliciesInitialRowSelection(groupPoliciesRowSelection)
                dataToUpdate.push(replaceGroupPolicies(group.id, policyIds))
            }

            if (!ObjectCommons.compareObjects(groupRolesInitialRowSelection, groupRolesRowSelection)) {
                const roleIds: any = [];
                for (let roleId in groupRolesRowSelection) {
                    roleIds.push(parseInt(roleId));
                }
                setGroupRolesInitialRowSelection(groupRolesRowSelection)
                dataToUpdate.push(replaceGroupRoles(group.id, roleIds));
            }

            if (!ObjectCommons.compareObjects(groupUserRolesInitialRowSelection, groupUserRolesRowSelection)) {
                const userRoleIds: any = [];
                for (let userRoleId in groupUserRolesRowSelection) {
                    userRoleIds.push(parseInt(userRoleId));
                }
                setGroupUserRolesInitialRowSelection(groupUserRolesRowSelection)
                dataToUpdate.push(replaceGroupUserRoles(group.id, userRoleIds));
            }

            if (!ObjectCommons.compareObjects(groupDepartmentsInitialRowSelection, groupDepartmentsRowSelection)) {
                const departmentIds: any = [];
                for (let departmentId in groupDepartmentsRowSelection) {
                    departmentIds.push(parseInt(departmentId));
                }
                setGroupDepartmentsInitialRowSelection(groupDepartmentsRowSelection)
                dataToUpdate.push(replaceGroupDepartments(group.id, departmentIds));
            }

            if (!ObjectCommons.compareObjects(groupOrganizationsInitialRowSelection, groupOrganizationsRowSelection)) {
                const organizationIds: any = [];
                for (let organizationId in groupOrganizationsRowSelection) {
                    organizationIds.push(parseInt(organizationId));
                }
                setGroupOrganizationsInitialRowSelection(groupOrganizationsRowSelection)
                dataToUpdate.push(replaceGroupOrganizations(group.id, organizationIds));
            }

            if (!ObjectCommons.compareObjects(groupResourcesInitialRowSelection, groupResourcesRowSelection)) {
                const resourceIds: any = [];
                for (let resourceId in groupResourcesRowSelection) {
                    resourceIds.push(parseInt(resourceId));
                }
                setGroupResourcesInitialRowSelection(groupResourcesRowSelection)
                dataToUpdate.push(replaceGroupResources(group.id, resourceIds));
            }

            if (!ObjectCommons.compareObjects(groupProductsInitialRowSelection, groupProductsRowSelection)) {
                const productIds: any = [];
                for (let productId in groupProductsRowSelection) {
                    productIds.push(parseInt(productId));
                }
                setGroupProductsInitialRowSelection(groupProductsRowSelection)
                dataToUpdate.push(replaceGroupProducts(group.id, productIds));
            }

            if (!ObjectCommons.compareObjects(groupEmailsInitialRowSelection, groupEmailsRowSelection)) {
                const emailIds: any = [];
                for (let emailId in groupEmailsRowSelection) {
                    emailIds.push(parseInt(emailId));
                }
                setGroupEmailsInitialRowSelection(groupEmailsRowSelection)
                dataToUpdate.push(replaceGroupEmails(group.id, emailIds));
            }

            if (!ObjectCommons.compareObjects(groupPhoneNumbersInitialRowSelection, groupPhoneNumbersRowSelection)) {
                const phoneNumberIds: any = [];
                for (let phoneNumberId in groupPhoneNumbersRowSelection) {
                    phoneNumberIds.push(parseInt(phoneNumberId));
                }
                setGroupPhoneNumbersInitialRowSelection(groupPhoneNumbersRowSelection)
                dataToUpdate.push(replaceGroupPhoneNumbers(group.id, phoneNumberIds));
            }

            //Run only the changed updates and handle errors if needed
            await Promise.all(dataToUpdate);
            await loadGroup();
            await loadAuthorizations();
            setAlert({
                class: "alert-success text-center",
                message: "Updated."
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setChangesMade(false);
            setIsUpdating(false);
        }
    }

    async function replaceGroupInfo(groupId: any, body: URLSearchParams) {
        let url = process.env.REACT_APP_AUTH_API + "/groups/" + groupId;
        await RequestCommons.request(url, null, body, "PUT")
    }

    async function replaceGroupUsers(groupId: any, userIds: any) {
        const entries = []
        for (let i = 0; i < userIds.length; i++) {
            entries.push({
                "group_id": groupId,
                "user_id": userIds[i]
            })
        }
        const body: any = JSON.stringify(entries);
        let url = process.env.REACT_APP_AUTH_API + "/groups/users?group_id=" + params.id;
        await RequestCommons.request(url, null, body, "put", "application/json")
    }

    async function replaceGroupPolicies(groupId: any, policyIds: any) {
        const entries = []
        for (let i = 0; i < policyIds.length; i++) {
            entries.push({
                "group_id": groupId,
                "policy_id": policyIds[i]
            })
        }
        const body: any = JSON.stringify(entries);
        let url = process.env.REACT_APP_AUTH_API + "/groups/policies?group_id=" + params.id;
        await RequestCommons.request(url, null, body, "put", "application/json")
    }

    async function replaceGroupRoles(groupId: any, roleIds: any) {
        const entries = []
        for (let i = 0; i < roleIds.length; i++) {
            entries.push({
                "group_id": groupId,
                "role_id": roleIds[i]
            })
        }
        const body: any = JSON.stringify(entries);
        let url = process.env.REACT_APP_AUTH_API + "/groups/roles?group_id=" + params.id;
        await RequestCommons.request(url, null, body, "put", "application/json")
    }

    async function replaceGroupDepartments(groupId: any, departmentIds: any) {
        const entries = []
        for (let i = 0; i < departmentIds.length; i++) {
            entries.push({
                "group_id": groupId,
                "department_id": departmentIds[i]
            })
        }
        const body: any = JSON.stringify(entries);
        let url = process.env.REACT_APP_AUTH_API + "/groups/departments?group_id=" + params.id;
        await RequestCommons.request(url, null, body, "put", "application/json")
    }

    async function replaceGroupOrganizations(groupId: any, organizationIds: any) {
        const entries = []
        for (let i = 0; i < organizationIds.length; i++) {
            entries.push({
                "group_id": groupId,
                "organization_id": organizationIds[i]
            })
        }
        const body: any = JSON.stringify(entries);
        let url = process.env.REACT_APP_AUTH_API + "/groups/organizations?group_id=" + params.id;
        await RequestCommons.request(url, null, body, "put", "application/json")
    }

    async function replaceGroupResources(groupId: any, resourceIds: any) {
        const entries = []
        for (let i = 0; i < resourceIds.length; i++) {
            entries.push({
                "group_id": groupId,
                "resource_id": resourceIds[i]
            })
        }
        const body: any = JSON.stringify(entries);
        let url = process.env.REACT_APP_AUTH_API + "/groups/resources?group_id=" + params.id;
        await RequestCommons.request(url, null, body, "put", "application/json")
    }

    async function replaceGroupProducts(groupId: any, productIds: any) {
        const entries = []
        for (let i = 0; i < productIds.length; i++) {
            entries.push({
                "group_id": groupId,
                "product_id": productIds[i]
            })
        }
        const body: any = JSON.stringify(entries);
        let url = process.env.REACT_APP_AUTH_API + "/groups/products?group_id=" + params.id;
        await RequestCommons.request(url, null, body, "put", "application/json")
    }

    async function replaceGroupEmails(groupId: any, emailIds: any) {
        const entries = []
        for (let i = 0; i < emailIds.length; i++) {
            entries.push({
                "group_id": groupId,
                "email_id": emailIds[i]
            })
        }
        const body: any = JSON.stringify(entries);
        let url = process.env.REACT_APP_AUTH_API + "/groups/emails?group_id=" + params.id;
        await RequestCommons.request(url, null, body, "put", "application/json")
    }

    async function replaceGroupPhoneNumbers(groupId: any, phoneNumberIds: any) {
        const entries = []
        for (let i = 0; i < phoneNumberIds.length; i++) {
            entries.push({
                "group_id": groupId,
                "phone_number_id": phoneNumberIds[i]
            })
        }
        const body: any = JSON.stringify(entries);
        let url = process.env.REACT_APP_AUTH_API + "/groups/numbers/phone?group_id=" + params.id;
        await RequestCommons.request(url, null, body, "put", "application/json")
    }

    async function replaceGroupUserRoles(groupId: any, userRoleIds: any) {
        const entries = []
        for (let i = 0; i < userRoleIds.length; i++) {
            entries.push({
                "group_id": groupId,
                "user_role_id": userRoleIds[i]
            })
        }
        const body: any = JSON.stringify(entries);
        let url = process.env.REACT_APP_AUTH_API + "/groups/users/roles?group_id=" + params.id;
        await RequestCommons.request(url, null, body, "put", "application/json")
    }

    async function loadGroupPolicies() {
        let url = process.env.REACT_APP_AUTH_API + "/policies?populate=1";
        const policies: any = await RequestCommons.request(url);

        url = process.env.REACT_APP_AUTH_API + "/groups/policies?group_id=" + params.id + "&filter=policy_id&sort=1&unique=1";
        const groupPolicies: any = await RequestCommons.request(url);

        const groupPoliciesRowSelection: any = {};
        for (let i = 0; i < groupPolicies.length; i++) {
            const groupPolicy = groupPolicies[i];
            groupPoliciesRowSelection[groupPolicy] = true;
        }
        setGroupPoliciesRowSelection(groupPoliciesRowSelection);
        setGroupPoliciesInitialRowSelection(groupPoliciesRowSelection);

        const groupPoliciesTableData: any = [];
        for (let i = 0; i < policies.length; i++) {
            const policy = policies[i];
            const selected = groupPolicies.includes(policy.id);
            if (selected || props.session.permissions.update) {
                policy.selected = selected ? "Yes" : "No";
                groupPoliciesTableData.push(policy);
            }
        }
        setGroupPoliciesTableData(groupPoliciesTableData);
    }

    async function loadGroupUsers() {
        let url = process.env.REACT_APP_AUTH_API + "/users?populate=1";
        const users: any = await RequestCommons.request(url);

        url = process.env.REACT_APP_AUTH_API + "/groups/users?group_id=" + params.id + "&filter=user_id&sort=1&unique=1";
        const groupUsers: any = await RequestCommons.request(url);

        const groupUsersRowSelection: any = {};
        for (let i = 0; i < groupUsers.length; i++) {
            const groupUser = groupUsers[i];
            groupUsersRowSelection[groupUser] = true;
        }
        setGroupUsersRowSelection(groupUsersRowSelection);
        setGroupUsersInitialRowSelection(groupUsersRowSelection);

        const groupUsersTableData: any = [];
        for (let i = 0; i < users.length; i++) {
            const user = JSON.parse(JSON.stringify(users[i]));
            const selected = groupUsers.includes(user.id);
            if (selected || props.session.permissions.update) {
                user.selected = selected ? "Yes" : "No";
                groupUsersTableData.push(user);
            }
        }
        setGroupUsersTableData(groupUsersTableData);
    }

    async function loadGroupUserRoles() {
        let url = process.env.REACT_APP_AUTH_API + "/users/roles?populate=1";
        const userRoles: any = await RequestCommons.request(url);

        url = process.env.REACT_APP_AUTH_API + "/groups/users/roles?group_id=" + params.id + "&filter=user_role_id&sort=1&unique=1";
        const groupUserRoles: any = await RequestCommons.request(url);

        const groupUserRolesRowSelection: any = {};
        for (let i = 0; i < groupUserRoles.length; i++) {
            const groupUserRole = groupUserRoles[i];
            groupUserRolesRowSelection[groupUserRole] = true;
        }
        setGroupUserRolesRowSelection(groupUserRolesRowSelection);
        setGroupUserRolesInitialRowSelection(groupUserRolesRowSelection);

        const groupUserRolesTableData: any = [];
        for (let i = 0; i < userRoles.length; i++) {
            const userRole: any = userRoles[i];
            const selected = groupUserRoles.includes(userRole.id);
            if (selected || props.session.permissions.update) {
                userRole.selected = selected ? "Yes" : "No";
                groupUserRolesTableData.push(userRole);
            }
        }
        setGroupUserRolesTableData(groupUserRolesTableData);
    }

    async function loadGroupRoles() {
        let url = process.env.REACT_APP_AUTH_API + "/roles?populate=1";
        const roles: any = await RequestCommons.request(url);

        url = process.env.REACT_APP_AUTH_API + "/groups/roles?group_id=" + params.id + "&filter=role_id&sort=1&unique=1";
        const groupRoles: any = await RequestCommons.request(url);

        const groupRolesRowSelection: any = {};
        for (let i = 0; i < groupRoles.length; i++) {
            const groupRole = groupRoles[i];
            groupRolesRowSelection[groupRole] = true;
        }
        setGroupRolesRowSelection(groupRolesRowSelection);
        setGroupRolesInitialRowSelection(groupRolesRowSelection);

        const groupRolesTableData: any = [];
        for (let i = 0; i < roles.length; i++) {
            const role = roles[i];
            const selected = groupRoles.includes(role.id);
            if (selected || props.session.permissions.update) {
                role.selected = selected ? "Yes" : "No";
                groupRolesTableData.push(role);
            }
        }
        setGroupRolesTableData(groupRolesTableData);
    }

    async function loadGroupDepartments() {
        let url = process.env.REACT_APP_AUTH_API + "/departments?populate=1";
        const departments: any = await RequestCommons.request(url);

        url = process.env.REACT_APP_AUTH_API + "/groups/departments?group_id=" + params.id + "&filter=department_id&sort=1&unique=1";
        const groupDepartments: any = await RequestCommons.request(url);

        const groupDepartmentsRowSelection: any = {};
        for (let i = 0; i < groupDepartments.length; i++) {
            const groupDepartment = groupDepartments[i];
            groupDepartmentsRowSelection[groupDepartment] = true;
        }
        setGroupDepartmentsRowSelection(groupDepartmentsRowSelection);
        setGroupDepartmentsInitialRowSelection(groupDepartmentsRowSelection);

        const groupDepartmentsTableData: any = [];
        for (let i = 0; i < departments.length; i++) {
            const department = departments[i];
            const selected = groupDepartments.includes(department.id);
            if (selected || props.session.permissions.update) {
                department.selected = selected ? "Yes" : "No";
                groupDepartmentsTableData.push(department);
            }
        }
        setGroupDepartmentsTableData(groupDepartmentsTableData);
    }

    async function loadGroupOrganizations() {
        let url = process.env.REACT_APP_AUTH_API + "/organizations?populate=1";
        const organizations: any = await RequestCommons.request(url);

        url = process.env.REACT_APP_AUTH_API + "/groups/organizations?group_id=" + params.id + "&filter=organization_id&sort=1&unique=1";
        const groupOrganizations: any = await RequestCommons.request(url);

        const groupOrganizationsRowSelection: any = {};
        for (let i = 0; i < groupOrganizations.length; i++) {
            const groupOrganization = groupOrganizations[i];
            groupOrganizationsRowSelection[groupOrganization] = true;
        }
        setGroupOrganizationsRowSelection(groupOrganizationsRowSelection);
        setGroupOrganizationsInitialRowSelection(groupOrganizationsRowSelection);

        const groupOrganizationsTableData: any = [];
        for (let i = 0; i < organizations.length; i++) {
            const organization = organizations[i];
            const selected = groupOrganizations.includes(organization.id);
            if (selected || props.session.permissions.update) {
                organization.selected = selected ? "Yes" : "No";
                groupOrganizationsTableData.push(organization);
            }
        }
        setGroupOrganizationsTableData(groupOrganizationsTableData);
    }

    async function loadGroupProducts() {
        let url = process.env.REACT_APP_AUTH_API + "/products?populate=1";
        const products: any = await RequestCommons.request(url);

        url = process.env.REACT_APP_AUTH_API + "/groups/products?group_id=" + params.id + "&filter=product_id&sort=1&unique=1";
        const groupProducts: any = await RequestCommons.request(url);

        const groupProductsRowSelection: any = {};
        for (let i = 0; i < groupProducts.length; i++) {
            const groupProduct = groupProducts[i];
            groupProductsRowSelection[groupProduct] = true;
        }
        setGroupProductsRowSelection(groupProductsRowSelection);
        setGroupProductsInitialRowSelection(groupProductsRowSelection);

        const groupProductsTableData: any = [];
        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            const selected = groupProducts.includes(product.id);
            if (selected || props.session.permissions.update) {
                product.selected = selected ? "Yes" : "No";
                groupProductsTableData.push(product);
            }
        }
        setGroupProductsTableData(groupProductsTableData);
    }

    async function loadGroupResources() {
        let url = process.env.REACT_APP_AUTH_API + "/resources?populate=1";
        const resources: any = await RequestCommons.request(url);

        url = process.env.REACT_APP_AUTH_API + "/groups/resources?group_id=" + params.id + "&filter=resource_id&sort=1&unique=1";
        const groupResources: any = await RequestCommons.request(url);
        const groupResourcesRowSelection: any = {};
        for (let i = 0; i < groupResources.length; i++) {
            const groupResource = groupResources[i];
            groupResourcesRowSelection[groupResource] = true;
        }
        setGroupResourcesRowSelection(groupResourcesRowSelection);
        setGroupResourcesInitialRowSelection(groupResourcesRowSelection);

        const groupResourcesTableData: any = [];
        for (let i = 0; i < resources.length; i++) {
            const resource = resources[i];
            const selected = groupResources.includes(resource.id);
            if (selected || props.session.permissions.update) {
                resource.selected = selected ? "Yes" : "No";
                groupResourcesTableData.push(resource);
            }
        }
        setGroupResourcesTableData(groupResourcesTableData);
    }

    async function loadGroupEmails() {
        let url = process.env.REACT_APP_AUTH_API + "/emails?populate=1";
        const emails: any = await RequestCommons.request(url);

        url = process.env.REACT_APP_AUTH_API + "/groups/emails?group_id=" + params.id + "&filter=email_id&sort=1&unique=1";
        const groupEmails: any = await RequestCommons.request(url);

        const groupEmailsRowSelection: any = {};
        for (let i = 0; i < groupEmails.length; i++) {
            const groupEmail = groupEmails[i];
            groupEmailsRowSelection[groupEmail] = true;
        }
        setGroupEmailsRowSelection(groupEmailsRowSelection);
        setGroupEmailsInitialRowSelection(groupEmailsRowSelection);

        const groupEmailsTableData: any = [];
        for (let i = 0; i < emails.length; i++) {
            const email = emails[i];
            const selected = groupEmails.includes(email.id);
            if (selected || props.session.permissions.update) {
                email.selected = selected ? "Yes" : "No";
                email.disabled = email.disabled ? "Yes" : "No";
                groupEmailsTableData.push(email);
            }
        }
        setGroupEmailsTableData(groupEmailsTableData);
    }

    async function loadGroupPhoneNumbers() {
        let url = process.env.REACT_APP_AUTH_API + "/numbers/phone?populate=1";
        const phoneNumbers: any = await RequestCommons.request(url);

        url = process.env.REACT_APP_AUTH_API + "/groups/numbers/phone?group_id=" + params.id + "&filter=phone_number_id&sort=1&unique=1";
        const groupPhoneNumbers: any = await RequestCommons.request(url);

        const groupPhoneNumbersRowSelection: any = {};
        for (let i = 0; i < groupPhoneNumbers.length; i++) {
            const groupEmail = groupPhoneNumbers[i];
            groupPhoneNumbersRowSelection[groupEmail] = true;
        }
        setGroupPhoneNumbersRowSelection(groupPhoneNumbersRowSelection);
        setGroupPhoneNumbersInitialRowSelection(groupPhoneNumbersRowSelection);

        const groupPhoneNumbersTableData: any = [];
        for (let i = 0; i < phoneNumbers.length; i++) {
            const phoneNumber = phoneNumbers[i];
            const selected = groupPhoneNumbers.includes(phoneNumber.id);
            if (selected || props.session.permissions.update) {
                phoneNumber.selected = selected ? "Yes" : "No";
                phoneNumber.disabled = phoneNumber.disabled ? "Yes" : "No";
                groupPhoneNumbersTableData.push(phoneNumber);
            }
        }
        setGroupPhoneNumbersTableData(groupPhoneNumbersTableData);
    }

    function deleteGroup(event: any) {
        event.preventDefault();
        const url = process.env.REACT_APP_AUTH_API + "/groups/" + group.id;
        let deleteModalAlert = <div className={"alert alert-success"}>
            Group deleted.
        </div>;
        RequestCommons.request(url, null, null, "DELETE").then(async function () {
            await loadData();
            setGroupDeleted(true);
        }).catch(function (error) {
            console.error(error);
            deleteModalAlert = <>
                <div className={"alert alert-danger"}>
                    <span>The entry you are trying to delete likely still has entries linked to it.
                        Remove all links from the accordion dropdowns below before deleting.</span>
                </div>
                <Accordion className={"mb-3"} defaultActiveKey={[]}>
                    <Accordion.Item eventKey="details">
                        <Accordion.Header>
                            Details
                        </Accordion.Header>
                        <Accordion.Body>
                            {error.detail}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </>;
        }).finally(function () {
            setDeleteModalAlert(deleteModalAlert);
        });
    }

    function toggleDeleteModalShow() {
        setDeleteModalAlert(null);
        setDeleteModalShow(!deleteModalShow);
    }

    const groupUsersTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
        },
        {
            accessorKey: 'first_name', //normal accessorKey
            header: 'First',
        },
        {
            accessorKey: 'last_name', //normal accessorKey
            header: 'Last',
        }
    ];

    const groupUserRolesTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'user.first_name', //normal accessorKey
            header: 'First',
        },
        {
            accessorKey: 'user.last_name', //normal accessorKey
            header: 'Last',
        },
        {
            accessorKey: 'email.email', //normal accessorKey
            header: 'Email',
        },
        {
            accessorKey: 'role.organization.name', //normal accessorKey
            header: 'Organization',
        },
        {
            accessorKey: 'role.department.name', //normal accessorKey
            header: 'Department',
        },
        {
            accessorKey: 'role.name', //normal accessorKey
            header: 'Role',
        },
        {
            accessorKey: 'user_role_status.name', //normal accessorKey
            header: 'Status',
        }
    ];

    const groupEmailsTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'email',
            header: 'Email',
        },
        {
            accessorKey: 'email_type.name',
            header: 'Type',
        },
        {
            accessorKey: 'organization.name',
            header: 'Organization',
        },
        {
            accessorKey: 'disabled',
            header: 'Disabled',
        }
    ];

    const groupPhoneNumbersTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'phone_number',
            header: 'Phone Number',
            size: 400
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'description',
            header: 'Description',
        },
        {
            accessorKey: 'phone_number_type.name',
            header: 'Type',
        },
        {
            accessorKey: 'organization.name',
            header: 'Organization',
        },
        {
            accessorKey: 'disabled', //normal accessorKey
            header: 'Disabled',
        }
    ];

    const groupOrganizationsTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'name',
            header: 'Name',
        }
    ];

    const groupPoliciesTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'organization.name',
            header: 'Organization',
        },
        {
            accessorKey: 'policy_type.name',
            header: 'Type',
        }
    ];

    const groupDepartmentsTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'organization.name',
            header: 'Organization',
        }
    ];

    const groupResourcesTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'organization.name',
            header: 'Organization',
        },
        {
            accessorKey: 'location',
            header: 'Location',
        }
    ];

    const groupProductsTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'organization.name',
            header: 'Organization',
        }
    ];

    const groupRolesTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'department.name',
            header: 'Department',
        },
        {
            accessorKey: 'organization.name',
            header: 'Organization',
        }
    ];

    const defaultPageSize = 10;

    if (isLoading) {
        return <Loading/>;
    } else if (groupDeleted) {
        return <EntryDeleted/>;
    } else if (!groupExists) {
        return <DoesNotExist/>;
    } else {
        let buttonText: any = "Update";
        if (isUpdating) {
            buttonText = <span>
                        <span className={"loading-spinner"}>
                            <i className="bi bi-arrow-clockwise"></i>
                        </span>
                        <span className={"ms-2"}>Updating...</span>
                    </span>;
        }

        return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " container m-auto w-100"}>
            <DeleteModal show={deleteModalShow} onHide={toggleDeleteModalShow} delete={deleteGroup}
                         alert={deleteModalAlert}/>
            <div className={"mb-3 d-none"}>
                <ReturnToLastPageLink/>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <h2 className={"align-middle"}>
                        <i className="d-inline-block align-middle bi bi-collection-fill me-3"/>
                        <span className="d-inline-block align-middle">Group</span>
                    </h2>
                </div>
                <div className={"col text-end"}>
                    {props.session.permissions.delete ?
                        <form onSubmit={function (event: any) {
                            event.preventDefault();
                            toggleDeleteModalShow();
                        }}>
                            <button className={"btn btn-danger"} title={"Delete"}>
                                <i className="bi bi-trash-fill"/>
                            </button>
                        </form> : null}
                </div>
            </div>
            <form onSubmit={updateGroup}>
                <div className={"row"}>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Id</label>
                        <input name="id" type="number" className="form-control" defaultValue={group.id}
                               disabled/>
                    </div>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Name</label>
                        <input name="name" className="form-control" defaultValue={group.name}
                               disabled={!props.session.permissions.update}
                               onChange={() => setChangesMade(true)}/>
                        <small>Required</small>
                    </div>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Organization</label>
                        <Select
                            name="organization_id"
                            required
                            value={organizationSelect}
                            options={organizationOptions}
                            isDisabled={!props.session.permissions.update}
                            onChange={function (option: any) {
                                setChangesMade(true);
                                setOrganizationSelect(option);
                            }}
                        />
                        <small>Required</small>
                    </div>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Disabled</label>
                        <ReactSelectDisabled required value={group.disabled}
                                             onChange={function (option: any) {
                                                 setChangesMade(option.value !== group.disabled);
                                             }}/>
                    </div>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Created</label>
                        <input value={TimeCommons.formatTimestamp(group.created)} className="form-control"
                               disabled/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className="col mb-3">
                        <label className="form-label">Description</label>
                        <textarea name="description"
                                  disabled={!props.session.permissions.update}
                                  className="form-control" defaultValue={group.description}
                                  onChange={() => setChangesMade(true)}/>
                    </div>
                </div>
                <Accordion className={"mb-3"} defaultActiveKey={["details"]}>
                    <Accordion.Item eventKey="policies">
                        <Accordion.Header>
                            Policies
                            ({Object.values(groupPoliciesRowSelection).filter(i => i === true).length})
                        </Accordion.Header>
                        <Accordion.Body>
                            <MaterialReactTable
                                muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                                columns={groupPoliciesTableColumns}
                                data={groupPoliciesTableData}
                                enableSelectAll={false}
                                positionToolbarAlertBanner={"none"}
                                enableRowSelection={props.session.permissions.update}
                                onRowSelectionChange={function (selection: any) {
                                    setGroupPoliciesRowSelection(selection);
                                    setChangesMade(true);
                                }}
                                state={{
                                    rowSelection: groupPoliciesRowSelection,
                                    isLoading: false,
                                    density: "compact"
                                }}
                                initialState={{
                                    showColumnFilters: true,
                                    columnVisibility: {
                                        selected: false
                                    },
                                    pagination: {
                                        pageSize: defaultPageSize,
                                        pageIndex: 0
                                    },
                                    sorting: [
                                        {
                                            id: "selected",
                                            desc: true
                                        },
                                        {
                                            id: "organization.name",
                                            desc: false
                                        },
                                        {
                                            id: "name",
                                            desc: false
                                        }
                                    ]
                                }}
                                getRowId={(originalRow: any) => originalRow.id}
                                renderTopToolbarCustomActions={() => {
                                    const title = "Consult with Engineering before updating. Adjusting these may severely affect the operation of a product and may cause breaking changes.";
                                    return (
                                        <div className={"d-inline-block align-middle"}>
                                            <h2 className={"me-3 d-inline-block align-middle"}>
                                                <span>Policies</span>
                                            </h2>
                                            {props.session.permissions.update ?
                                                <i className="text-danger bi bi-exclamation-triangle-fill"
                                                   title={title}/> : null}
                                        </div>
                                    );
                                }}
                                muiTableBodyRowProps={({row}: any) => ({
                                    onClick: function () {
                                        window.open("/policies/" + row.original.id, "_blank")
                                    },
                                    sx: {
                                        cursor: 'pointer'
                                    }
                                })}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="user-roles">
                        <Accordion.Header>
                            User Roles
                            ({Object.values(groupUserRolesRowSelection).filter(i => i === true).length})
                        </Accordion.Header>
                        <Accordion.Body>
                            <MaterialReactTable
                                muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                                columns={groupUserRolesTableColumns}
                                data={groupUserRolesTableData}
                                enableSelectAll={false}
                                positionToolbarAlertBanner={"none"}
                                enableRowSelection={props.session.permissions.update}
                                onRowSelectionChange={function (selection: any) {
                                    setGroupUserRolesRowSelection(selection);
                                    setChangesMade(true);
                                }}
                                state={{
                                    rowSelection: groupUserRolesRowSelection,
                                    isLoading: false,
                                }}
                                initialState={{
                                    showColumnFilters: true,
                                    columnVisibility: {
                                        selected: false
                                    },
                                    pagination: {
                                        pageSize: defaultPageSize,
                                        pageIndex: 0
                                    },
                                    sorting: [
                                        {
                                            id: "selected",
                                            desc: true
                                        },
                                        {
                                            id: "user.last_name",
                                            desc: false
                                        },
                                        {
                                            id: "user.first_name",
                                            desc: false
                                        },
                                        {
                                            id: "user.last_name",
                                            desc: false
                                        },
                                        {
                                            id: "role.organization.name",
                                            desc: false
                                        },
                                        {
                                            id: "role.department.name",
                                            desc: false
                                        },
                                        {
                                            id: "role.name",
                                            desc: false
                                        }
                                    ]
                                }}
                                getRowId={(originalRow: any) => originalRow.id}
                                renderTopToolbarCustomActions={() => {
                                    return (
                                        <h2 className={"me-3 d-inline-block align-top"}>User Roles</h2>
                                    );
                                }}
                                muiTableBodyRowProps={({row}: any) => ({
                                    onClick: function () {
                                        window.open("/users/roles/" + row.original.id, "_blank")
                                    },
                                    sx: {
                                        cursor: 'pointer'
                                    }
                                })}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="optional">
                        <Accordion.Header>
                            Optional Settings
                        </Accordion.Header>
                        <Accordion.Body>
                            <Accordion defaultActiveKey={[]}>
                                <Accordion.Item eventKey="roles">
                                    <Accordion.Header>
                                        Roles
                                        ({Object.values(groupRolesRowSelection).filter(i => i === true).length})
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <MaterialReactTable
                                            muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                                            columns={groupRolesTableColumns}
                                            data={groupRolesTableData}
                                            enableSelectAll={false}
                                            positionToolbarAlertBanner={"none"}
                                            enableRowSelection={props.session.permissions.update}
                                            onRowSelectionChange={function (selection: any) {
                                                setGroupRolesRowSelection(selection);
                                                setChangesMade(true);
                                            }}
                                            state={{
                                                rowSelection: groupRolesRowSelection,
                                                isLoading: false,
                                                density: "compact"
                                            }}
                                            initialState={{
                                                showColumnFilters: true,
                                                columnVisibility: {
                                                    selected: false
                                                },
                                                pagination: {
                                                    pageSize: defaultPageSize,
                                                    pageIndex: 0
                                                },
                                                sorting: [
                                                    {
                                                        id: "selected",
                                                        desc: true
                                                    },
                                                    {
                                                        id: "organization.name",
                                                        desc: false
                                                    },
                                                    {
                                                        id: "department.name",
                                                        desc: false
                                                    },
                                                    {
                                                        id: "name",
                                                        desc: false
                                                    }
                                                ]
                                            }}
                                            getRowId={(originalRow: any) => originalRow.id}
                                            renderTopToolbarCustomActions={() => {
                                                const title = "Assigning a `Role` will authorize all `Users` having the `Role`."
                                                return (
                                                    <div className={"d-inline-block align-middle"}>
                                                        <h2 className={"me-3 d-inline-block align-middle"}>
                                                            <span>Roles</span>
                                                        </h2>
                                                        {props.session.permissions.update ?
                                                            <i className="text-danger bi bi-exclamation-triangle-fill"
                                                               title={title}/> : null}
                                                    </div>
                                                );
                                            }}
                                            muiTableBodyRowProps={({row}: any) => ({
                                                onClick: function () {
                                                    window.open("/roles/" + row.original.id, "_blank")
                                                },
                                                sx: {
                                                    cursor: 'pointer'
                                                }
                                            })}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="departments">
                                    <Accordion.Header>
                                        Departments
                                        ({Object.values(groupDepartmentsRowSelection).filter(i => i === true).length})
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <MaterialReactTable
                                            muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                                            columns={groupDepartmentsTableColumns}
                                            data={groupDepartmentsTableData}
                                            enableSelectAll={false}
                                            positionToolbarAlertBanner={"none"}
                                            enableRowSelection={props.session.permissions.update}
                                            onRowSelectionChange={function (selection: any) {
                                                setGroupDepartmentsRowSelection(selection);
                                                setChangesMade(true);
                                            }}
                                            state={{
                                                rowSelection: groupDepartmentsRowSelection,
                                                isLoading: false,
                                                density: "compact"
                                            }}
                                            initialState={{
                                                showColumnFilters: true,
                                                columnVisibility: {
                                                    selected: false
                                                },
                                                pagination: {
                                                    pageSize: defaultPageSize,
                                                    pageIndex: 0
                                                },
                                                sorting: [
                                                    {
                                                        id: "selected",
                                                        desc: true
                                                    },
                                                    {
                                                        id: "organization.name",
                                                        desc: false
                                                    },
                                                    {
                                                        id: "name",
                                                        desc: false
                                                    }
                                                ]
                                            }}
                                            getRowId={(originalRow: any) => originalRow.id}
                                            renderTopToolbarCustomActions={() => {
                                                const title = "Assigning a `Department` will authorize all `Users` having a `Role` within it."
                                                return (
                                                    <div className={"d-inline-block align-middle"}>
                                                        <h2 className={"me-3 d-inline-block align-middle"}>
                                                            <span>Departments</span>
                                                        </h2>
                                                        {props.session.permissions.update ?
                                                            <i className="text-danger bi bi-exclamation-triangle-fill"
                                                               title={title}/> : null}
                                                    </div>
                                                );
                                            }}
                                            muiTableBodyRowProps={({row}: any) => ({
                                                onClick: function () {
                                                    window.open("/departments/" + row.original.id, "_blank")
                                                },
                                                sx: {
                                                    cursor: 'pointer'
                                                }
                                            })}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="organizations">
                                    <Accordion.Header>
                                        Organizations
                                        ({Object.values(groupOrganizationsRowSelection).filter(i => i === true).length})
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <MaterialReactTable
                                            muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                                            columns={groupOrganizationsTableColumns}
                                            data={groupOrganizationsTableData}
                                            enableSelectAll={false}
                                            positionToolbarAlertBanner={"none"}
                                            enableRowSelection={props.session.permissions.update}
                                            onRowSelectionChange={function (selection: any) {
                                                setGroupOrganizationsRowSelection(selection);
                                                setChangesMade(true);
                                            }}
                                            state={{
                                                rowSelection: groupOrganizationsRowSelection,
                                                isLoading: false,
                                                density: "compact"
                                            }}
                                            initialState={{
                                                showColumnFilters: true,
                                                columnVisibility: {
                                                    selected: false
                                                },
                                                pagination: {
                                                    pageSize: defaultPageSize,
                                                    pageIndex: 0
                                                },
                                                sorting: [
                                                    {
                                                        id: "selected",
                                                        desc: true
                                                    },
                                                    {
                                                        id: "name",
                                                        desc: false
                                                    }
                                                ]
                                            }}
                                            getRowId={(originalRow: any) => originalRow.id}
                                            renderTopToolbarCustomActions={() => {
                                                const title = "Assigning a `Organization` will authorize all `Users` having a `Role` within it."
                                                return (
                                                    <div className={"d-inline-block align-middle"}>
                                                        <h2 className={"me-3 d-inline-block align-middle"}>
                                                            <span>Organizations</span>
                                                        </h2>
                                                        {props.session.permissions.update ?
                                                            <i className="text-danger bi bi-exclamation-triangle-fill"
                                                               title={title}/> : null}
                                                    </div>
                                                );
                                            }}
                                            muiTableBodyRowProps={({row}: any) => ({
                                                onClick: function () {
                                                    window.open("/organizations/" + row.original.id, "_blank")
                                                },
                                                sx: {
                                                    cursor: 'pointer'
                                                }
                                            })}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="users">
                                    <Accordion.Header>
                                        Users
                                        ({Object.values(groupUsersRowSelection).filter(i => i === true).length})
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <MaterialReactTable
                                            muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                                            columns={groupUsersTableColumns}
                                            data={groupUsersTableData}
                                            enableSelectAll={false}
                                            positionToolbarAlertBanner={"none"}
                                            enableRowSelection={props.session.permissions.update}
                                            onRowSelectionChange={function (selection: any) {
                                                setGroupUsersRowSelection(selection);
                                                setChangesMade(true);
                                            }}
                                            initialState={{
                                                showColumnFilters: true,
                                                columnVisibility: {
                                                    selected: false
                                                },
                                                pagination: {
                                                    pageSize: defaultPageSize,
                                                    pageIndex: 0
                                                },
                                                sorting: [
                                                    {
                                                        id: "selected",
                                                        desc: true
                                                    },
                                                    {
                                                        id: "last_name",
                                                        desc: false
                                                    },
                                                    {
                                                        id: "first_name",
                                                        desc: false
                                                    }
                                                ]
                                            }}
                                            state={{
                                                rowSelection: groupUsersRowSelection,
                                                isLoading: false,
                                                density: "compact"
                                            }}
                                            getRowId={(originalRow: any) => originalRow.id}
                                            renderTopToolbarCustomActions={() => {
                                                const title = "Assigning a `User` will authorize all `User Roles` that the `User` has."
                                                return (
                                                    <div className={"d-inline-block align-middle"}>
                                                        <h2 className={"me-3 d-inline-block align-middle"}>
                                                            <span>Users</span>
                                                        </h2>
                                                        {props.session.permissions.update ?
                                                            <i className="text-danger bi bi-exclamation-triangle-fill"
                                                               title={title}/> : null}
                                                    </div>
                                                );
                                            }}
                                            muiTableBodyRowProps={({row}: any) => ({
                                                onClick: function () {
                                                    window.open("/users/" + row.original.id, "_blank")
                                                },
                                                sx: {
                                                    cursor: 'pointer'
                                                }
                                            })}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="products">
                                    <Accordion.Header>
                                        Products
                                        ({Object.values(groupProductsRowSelection).filter(i => i === true).length})
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <MaterialReactTable
                                            muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                                            columns={groupProductsTableColumns}
                                            data={groupProductsTableData}
                                            enableSelectAll={false}
                                            positionToolbarAlertBanner={"none"}
                                            enableRowSelection={props.session.permissions.update}
                                            onRowSelectionChange={function (selection: any) {
                                                setGroupProductsRowSelection(selection);
                                                setChangesMade(true);
                                            }}
                                            state={{
                                                rowSelection: groupProductsRowSelection,
                                                isLoading: false,
                                                density: "compact"
                                            }}
                                            initialState={{
                                                showColumnFilters: true,
                                                columnVisibility: {
                                                    selected: false
                                                },
                                                pagination: {
                                                    pageSize: defaultPageSize,
                                                    pageIndex: 0
                                                },
                                                sorting: [
                                                    {
                                                        id: "selected",
                                                        desc: true
                                                    },
                                                    {
                                                        id: "organization.name",
                                                        desc: false
                                                    },
                                                    {
                                                        id: "name",
                                                        desc: false
                                                    }
                                                ]
                                            }}
                                            getRowId={(originalRow: any) => originalRow.id}
                                            renderTopToolbarCustomActions={() => {
                                                return (
                                                    <h2 className={"me-3 d-inline-block align-top"}>Products</h2>
                                                );
                                            }}
                                            muiTableBodyRowProps={({row}: any) => ({
                                                onClick: function () {
                                                    window.open("/products/" + row.original.id, "_blank")
                                                },
                                                sx: {
                                                    cursor: 'pointer'
                                                }
                                            })}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="resources">
                                    <Accordion.Header>
                                        Resources
                                        ({Object.values(groupResourcesRowSelection).filter(i => i === true).length})
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <MaterialReactTable
                                            muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                                            columns={groupResourcesTableColumns}
                                            data={groupResourcesTableData}
                                            enableSelectAll={false}
                                            positionToolbarAlertBanner={"none"}
                                            enableRowSelection={props.session.permissions.update}
                                            onRowSelectionChange={function (selection: any) {
                                                setGroupResourcesRowSelection(selection);
                                                setChangesMade(true);
                                            }}
                                            state={{
                                                rowSelection: groupResourcesRowSelection,
                                                isLoading: false,
                                                density: "compact"
                                            }}
                                            initialState={{
                                                showColumnFilters: true,
                                                columnVisibility: {
                                                    selected: false
                                                },
                                                pagination: {
                                                    pageSize: defaultPageSize,
                                                    pageIndex: 0
                                                },
                                                sorting: [
                                                    {
                                                        id: "selected",
                                                        desc: true
                                                    },
                                                    {
                                                        id: "organization.name",
                                                        desc: false
                                                    },
                                                    {
                                                        id: "name",
                                                        desc: false
                                                    }
                                                ]
                                            }}
                                            getRowId={(originalRow: any) => originalRow.id}
                                            renderTopToolbarCustomActions={() => {
                                                return (
                                                    <h2 className={"me-3 d-inline-block align-top"}>Resources</h2>
                                                );
                                            }}
                                            muiTableBodyRowProps={({row}: any) => ({
                                                onClick: function () {
                                                    window.open("/resources/" + row.original.id, "_blank")
                                                },
                                                sx: {
                                                    cursor: 'pointer'
                                                }
                                            })}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="emails">
                                    <Accordion.Header>
                                        Emails
                                        ({Object.values(groupEmailsRowSelection).filter(i => i === true).length})
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <MaterialReactTable
                                            muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                                            columns={groupEmailsTableColumns}
                                            data={groupEmailsTableData}
                                            enableSelectAll={false}
                                            positionToolbarAlertBanner={"none"}
                                            enableRowSelection={props.session.permissions.update}
                                            onRowSelectionChange={function (selection: any) {
                                                setGroupEmailsRowSelection(selection);
                                                setChangesMade(true);
                                            }}
                                            state={{
                                                rowSelection: groupEmailsRowSelection,
                                                isLoading: false,
                                                density: "compact"
                                            }}
                                            initialState={{
                                                showColumnFilters: true,
                                                columnVisibility: {
                                                    selected: false
                                                },
                                                pagination: {
                                                    pageSize: defaultPageSize,
                                                    pageIndex: 0
                                                },
                                                sorting: [
                                                    {
                                                        id: "selected",
                                                        desc: true
                                                    },
                                                    {
                                                        id: "organization.name",
                                                        desc: false
                                                    },
                                                    {
                                                        id: "email",
                                                        desc: false
                                                    }
                                                ]
                                            }}
                                            getRowId={(originalRow: any) => originalRow.id}
                                            renderTopToolbarCustomActions={() => {
                                                return (
                                                    <h2 className={"me-3 d-inline-block align-top"}>Emails</h2>
                                                );
                                            }}
                                            muiTableBodyRowProps={({row}: any) => ({
                                                onClick: function () {
                                                    window.open("/emails/" + row.original.id, "_blank")
                                                },
                                                sx: {
                                                    cursor: 'pointer'
                                                }
                                            })}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="phone-numbers">
                                    <Accordion.Header>
                                        Phone Numbers
                                        ({Object.values(groupPhoneNumbersRowSelection).filter(i => i === true).length})
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <MaterialReactTable
                                            muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                            columns={groupPhoneNumbersTableColumns}
                                            data={groupPhoneNumbersTableData}
                                            enableSelectAll={false}
                                            positionToolbarAlertBanner={"none"}
                                            enableRowSelection={props.session.permissions.update}
                                            onRowSelectionChange={function (selection: any) {
                                                setGroupPhoneNumbersRowSelection(selection);
                                                setChangesMade(true);
                                            }}
                                            state={{
                                                rowSelection: groupPhoneNumbersRowSelection,
                                                isLoading: false,
                                                density: "compact"
                                            }}
                                            initialState={{
                                                showColumnFilters: true,
                                                columnVisibility: {
                                                    selected: false
                                                },
                                                pagination: {
                                                    pageSize: defaultPageSize,
                                                    pageIndex: 0
                                                },
                                                sorting: [
                                                    {
                                                        id: "selected",
                                                        desc: true
                                                    },
                                                    {
                                                        id: "phone_number",
                                                        desc: false
                                                    }
                                                ]
                                            }}
                                            getRowId={(originalRow: any) => originalRow.id}
                                            renderTopToolbarCustomActions={() => {
                                                return (
                                                    <h2 className={"me-3 d-inline-block align-top"}>Phone Numbers</h2>
                                                );
                                            }}
                                            muiTableBodyRowProps={({row}: any) => ({
                                                onClick: function () {
                                                    window.open("/numbers/phone/" + row.original.id, "_blank")
                                                },
                                                sx: {
                                                    cursor: 'pointer'
                                                }
                                            })}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="authorizations">
                        <Accordion.Header>
                            Authorizations ({authorizations.length})
                        </Accordion.Header>
                        <Accordion.Body>
                            <AuthorizationsTable {...props} authorizations={authorizations}
                                                 showGroup={false}/>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <div className={"mt-3"}>
                    <div className={"alert " + alert.class}>{alert.message}</div>
                </div>
                <div className={"row mt-3"}>
                    <div className={"col"}>
                        <ReturnToLastPageLink/>
                    </div>
                    <div className={"col text-end"}>
                        {props.session.permissions.update ?
                            <button type="submit" className="btn btn-warning ms-auto ps-5 pe-5"
                                    disabled={isUpdating || !changesMade}>{buttonText}</button> : null}
                    </div>
                </div>
            </form>
        </div>;
    }
}