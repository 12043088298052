import * as React from "react";
import {useState} from "react";
import RequestCommons from "../commons/RequestCommons"
import {Link} from "react-router-dom";

export default function ResetPassword(props: any) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let returnUrl: any = urlSearchParams.get("return_url");
    if (returnUrl === null) {
        returnUrl = "/login";
    }

    const [alert, setAlert] = useState({
        class: "d-none",
        message: ""
    });
    const [isRequesting, setIsRequesting] = useState(false);

    let buttonText: any = "Reset";
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Resetting...</span>
            </span>;
    }

    const onSubmit = function (event: any) {
        event.preventDefault();
        const form: any = event.target;
        console.log("on submit")
        console.log(form)
        const formData: any = new FormData(form);
        console.log("form data", formData)
        const credentials: any = Object.fromEntries(formData);
        const body = new URLSearchParams(credentials);
        console.log("body", body)

        setIsRequesting(true);
        const url = process.env.REACT_APP_AUTH_API + "/password/reset";
        RequestCommons.request(url, process.env.REACT_APP_CONSUMER_TOKEN, body, "post").then(function (response: any) {
            setAlert({
                class: "alert-success",
                message: response
            });
            form.reset();
        }).catch(function (error) {
            let message = "An error occurred, please try again.";
            if (error.detail) {
                message = error.detail;
            } else if (error.title) {
                message = error.title;
            }
            setAlert({
                class: "alert-danger",
                message: message
            });
        }).finally(function () {
            setIsRequesting(false);
        });
    }

    return <form className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}
                 onSubmit={onSubmit}>
        <h1>Reset Password</h1>
        <input type={"hidden"} name={"return_url"} value={returnUrl}/>
        <div className="mb-3">
            <label className="form-label">Email</label>
            <input name={"email"} type="email" className="form-control" required autoFocus/>
        </div>
        <button type="submit" className="btn btn-primary form-control mb-3"
                disabled={isRequesting}>{buttonText}</button>
        <div className={"alert mb-3 text-center " + alert.class}>{alert.message}</div>
        <Link to={returnUrl} style={{textDecoration: "none"}}>
            <i className="bi bi-arrow-left"></i>
            <span className={"ms-2"}>Return to Log In</span>
        </Link>
    </form>;
}