import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {Link, useParams} from "react-router-dom";
import TimeCommons from "../../commons/TimeCommons";
import AuthCommons from "../../commons/AuthCommons";
import Select from "react-select";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import ReactElementCommons from "../../commons/ReactElementCommons";
import {Alert} from "react-bootstrap";
import Loading from "../Loading";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";
import DoesNotExist from "../shared/DoesNotExist";

export default function UpdateProduct(props: any) {
    const [alert, setAlert] = useState({
        class: "d-none",
        message: ""
    });
    const [prodExists, setProdExists] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [product, setProduct]: any = useState({});
    const [organizationOptions, setOrganizationOptions]: any = useState([]);
    const [organizationSelect, setOrganizationSelect]: any = useState(null);
    const [domainOptions, setDomainOptions]: any = useState([]);
    const [domainSelect, setDomainSelect]: any = useState(null);

    const params = useParams();

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setIsLoading(true);
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [response]: any = await Promise.all([
            AuthCommons.request("/products/" + params.id, null, null, 'get', 'application/x-www-form-urlencoded', false)
        ]);

        if (response.status === 204) {
            setProdExists(false);
        } else {
            setProdExists(true);
            const product: any = await response.json();
            setProduct(product);

            const [organizations, domains]: any = await Promise.all([
                AuthCommons.request("/organizations"),
                AuthCommons.request("/domains")
            ]);

            const organizationOptions: any[] = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id");
            setOrganizationOptions(organizationOptions);
            setOrganizationSelect(organizationOptions.find((option: any) => option.value === product.organization_id));

            const domainOptions: any[] = ReactElementCommons.createReactSelectOptionsFromList(domains, "name", "id");
            setDomainOptions(domainOptions);
            setDomainSelect(domainOptions.find((option: any) => option.value === product.domain_id));
        }
    }

    async function updateProduct(event: any) {
        event.preventDefault();
        try {
            setIsUpdating(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const formData: any = new FormData(event.target);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/products/" + product.id;
            await RequestCommons.request(url, null, payload, "PUT");

            await loadData();
            setAlert({
                class: "alert-success text-center",
                message: "Updated."
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsUpdating(false);
        }
    }

    if (isLoading) {
        return <Loading/>;
    } else if (!prodExists) {
        return <DoesNotExist/>;
    } else {
        let buttonText: any = "Update";
        if (isUpdating) {
            buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Updating...</span>
            </span>;
        }

        return <div className={"container"}>
            <div className={"row"}>
                <div className={"col col-lg-4 mb-3 m-auto"}>
                    <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " p-4"}>
                        <div className={"row mb-3"}>
                            <div className={"col"}>
                                <ReturnToLastPageLink/>
                            </div>
                        </div>
                        <form onSubmit={updateProduct}>
                            <h2>Product</h2>
                            <div className="mb-3">
                                <label className="form-label">Id</label>
                                <input name="id" type="number" className="form-control" defaultValue={product.id}
                                       disabled/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Name</label>
                                <input name="name" className="form-control" defaultValue={product.name}
                                       disabled={!props.session.permissions.update}/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Organization</label>
                                <Select
                                    name="organization_id"
                                    value={organizationSelect}
                                    options={organizationOptions}
                                    onChange={setOrganizationSelect}
                                    isDisabled={!props.session.permissions.update}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Domain</label>
                                <Select
                                    name="domain_id"
                                    value={domainSelect}
                                    options={domainOptions}
                                    onChange={setDomainSelect}
                                    isDisabled={!props.session.permissions.update}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Description</label>
                                <textarea name="description"
                                          className="form-control" defaultValue={product.description}
                                          disabled={!props.session.permissions.update}/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">URL</label>
                                <input name={"url"} className="form-control" defaultValue={product.url}
                                       disabled={!props.session.permissions.update}/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Disabled</label>
                                <ReactSelectDisabled value={product.disabled} required
                                                     isDisabled={!props.session.permissions.update}/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Created</label>
                                <input value={TimeCommons.formatTimestamp(product.created)} className="form-control"
                                       disabled/>
                            </div>
                            {props.session.permissions.update ?
                                <button type="submit" className="btn btn-warning form-control"
                                        disabled={isUpdating}>{buttonText}</button> : null}
                            <div
                                className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
                        </form>
                        <div className={"row mt-3"}>
                            <div className={"col"}>
                                <ReturnToLastPageLink/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}