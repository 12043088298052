import {Link} from "react-router-dom";
import authSystemDiagram from "../media/auth-system-diagram.png";

export default function Home(props: any) {
    const linkStyle = {
        display: 'inline-block',
        height: "100px",
        minWidth: "125px",
        cursor: "pointer",
        whiteSpace: "nowrap",
    };
    const linkClassName = "btn bg-white d-inline-block p-3 mb-3 me-3 ps-4 pe-4 border shadow-sm";
    const iconStyle = {
        display: "block",
        fontSize: "24px",
        marginBottom: "5px"
    };
    const labelStyle = {
        display: "inline-block",
        cursor: "pointer",
        fontSize: "14px"
    }

    return <div className={"container p-3 text-center"}>
        <div className={"links"}>
            <Link to={"/users"}
                  className={linkClassName}
                  style={linkStyle}>
                <i className="bi bi-people-fill" style={iconStyle}/>
                <label style={labelStyle}>Users</label>
            </Link>
            <Link to={"/groups"}
                  className={linkClassName}
                  style={linkStyle}>
                <i className="bi bi-collection-fill" style={iconStyle}/>
                <label style={labelStyle}>Groups</label>
            </Link>
            <Link to={"/policies"}
                  className={linkClassName}
                  style={linkStyle}>
                <i className="bi bi-shield-lock-fill" style={iconStyle}/>
                <label style={labelStyle}>Policies</label>
            </Link>
            <Link to={"/logs/pages/accessed"}
                  className={linkClassName}
                  style={linkStyle}>
                <i className="bi bi-file-text-fill" style={iconStyle}/>
                <label style={labelStyle}>Page Logs</label>
            </Link>
            <Link to={"/sops"}
                  className={linkClassName}
                  style={linkStyle}>
                <i className="bi bi-gear-wide" style={iconStyle}/>
                <label style={labelStyle}>SOP's</label>
            </Link>
            <Link to={"/sitemap"}
                  className={linkClassName}
                  style={linkStyle}>
                <i className="bi bi-diagram-3-fill" style={iconStyle}/>
                <label style={labelStyle}>Sitemap</label>
            </Link>
        </div>
    </div>;
}