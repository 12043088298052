import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {Link, useNavigate} from "react-router-dom";
import AuthCommons from "../../commons/AuthCommons";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import Select from "react-select";
import ReactElementCommons from "../../commons/ReactElementCommons";
import {MaterialReactTable} from "material-react-table";
import {Accordion} from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";

export default function CreateUser(props: any) {
    const navigate = useNavigate();
    const [alert, setAlert] = useState<{
        class: string,
        message: any
    }>({
        class: "d-none",
        message: ""
    });
    const [isRequesting, setIsRequesting] = useState(false);
    const [avatar, setAvatar]: any = useState(null);
    const [firstName, setFirstName]: any = useState(null);
    const [lastName, setLastName]: any = useState(null);
    const [nameStrings, setNameStrings]: any = useState([]);
    const [nameExists, setNameExists]: any = useState(false);
    const [emailStrings, setEmailStrings]: any = useState([]);
    const [emailExists, setEmailExists]: any = useState(false);
    const [phoneNumberStrings, setPhoneNumberStrings]: any = useState([]);
    const [phoneNumberExists, setPhoneNumberExists]: any = useState(false);
    const [roleOptions, setRoleOptions]: any = useState([]);
    const [roleSelect, setRoleSelect]: any = useState(null);
    const [organizationOptions, setOrganizationOptions]: any = useState([]);
    const [organizationSelect, setOrganizationSelect]: any = useState(null);
    const [departmentOptions, setDepartmentOptions]: any = useState([]);
    const [departmentSelect, setDepartmentSelect]: any = useState(null);
    const [departments, setDepartments]: any = useState([]);
    const [roles, setRoles]: any = useState([]);
    const [payTypeOptions, setPayTypeOptions]: any = useState([]);
    const [selectedPayTypeOption, setSelectedPayTypeOption]: any = useState(null);
    const [userRoleStatusOptions, setUserRoleStatusOptions]: any = useState([]);
    const [selectedUserRoleStatusOption, setSelectedUserRoleStatusOption]: any = useState(null);
    const [userDailyReviewManagersTableData, setUserDailyReviewManagersTableData]: any = useState([]);
    const [userDailyReviewManagersRowSelection, setUserDailyReviewManagersRowSelection]: any = useState({});
    const [userC3ManagersTableData, setUserC3ManagersTableData]: any = useState([]);
    const [userC3ManagersRowSelection, setUserC3ManagersRowSelection]: any = useState({});
    const [userRoleManagersTableData, setUserRoleManagersTableData]: any = useState([]);
    const [userRoleManagersRowSelection, setUserRoleManagersRowSelection]: any = useState([]);
    const [copyUserRolePermissionsSelectedOption, setCopyUserRolePermissionsSelectedOption]: any = useState(null);
    const [copyPermissionsAlert, setCopyPermissionsAlert]: any = useState(null);
    const [userRoleGroupIds, setUserRoleGroupIds]: any = useState([]);
    const [userRoleOptions, setUserRoleOptions]: any = useState([]);
    const [groupUserRolesAll, setGroupUserRolesAll]: any = useState([]);
    const [userRoles, setUserRoles]: any = useState([]);
    const [groupUserRolesTableData, setGroupUserRolesTableData]: any = useState([]);
    const [groupUserRolesRowSelection, setGroupUserRolesRowSelection]: any = useState({});

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        const newTableData = [];
        for (let i = 0; i < groupUserRolesTableData.length; i++) {
            const row = groupUserRolesTableData[i];
            for (let groupId in groupUserRolesRowSelection) {
                if (groupId == row.id && row.selected === "No") {
                    row.selected = "Yes";
                    row.new_selection = "Yes";
                    break;
                }
            }
            newTableData.push(row);
        }
        setGroupUserRolesTableData(newTableData);
    }, [groupUserRolesRowSelection]);

    async function init() {
        await loadData();
    }

    async function loadData() {
        const [emails, organizations, departments, roles, payTypes, userRoleStatuses, phoneNumbers, users, groups, userRoles, groupUserRoles]: any = await Promise.all([
            AuthCommons.request("/emails"),
            AuthCommons.request("/organizations"),
            AuthCommons.request("/departments"),
            AuthCommons.request("/roles"),
            AuthCommons.request("/pay/types"),
            AuthCommons.request("/users/roles/statuses"),
            AuthCommons.request("/numbers/phone"),
            AuthCommons.request("/users"),
            AuthCommons.request("/groups?populate=1"),
            AuthCommons.request("/users/roles?populate=1&user_role_status_id=1"),
            AuthCommons.request("/groups/users/roles")
        ]);

        departments.sort((a: any, b: any) => {
            return a.name.localeCompare(b.name);
        });
        setDepartments(departments);

        roles.sort((a: any, b: any) => {
            return a.name.localeCompare(b.name);
        });
        setRoles(roles);
        setUserDailyReviewManagersTableData(userRoles);
        setUserC3ManagersTableData(userRoles);
        setUserRoleManagersTableData(userRoles);
        setGroupUserRolesAll(groupUserRoles);

        organizations.sort((a: any, b: any) => {
            return a.name.localeCompare(b.name);
        });
        const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id");
        setOrganizationOptions(organizationOptions);

        const payTypeOptions: any = ReactElementCommons.createReactSelectOptionsFromList(payTypes, "name", "id");
        setPayTypeOptions(payTypeOptions);
        setSelectedPayTypeOption(payTypeOptions[0]);

        const userRoleStatusOptions: any = ReactElementCommons.createReactSelectOptionsFromList(userRoleStatuses, "name", "id");
        setUserRoleStatusOptions(userRoleStatusOptions);
        setSelectedUserRoleStatusOption(userRoleStatusOptions[2]);

        const emailStrings: any = [];
        for (let i = 0; i < emails.length; i++) {
            const email = emails[i];
            emailStrings.push(email["email"]);
        }
        setEmailStrings(emailStrings);

        const phoneNumberStrings: any = [];
        for (let i = 0; i < phoneNumbers.length; i++) {
            const phoneNumber = phoneNumbers[i];
            phoneNumberStrings.push(phoneNumber["phone_number"]);
        }
        setPhoneNumberStrings(phoneNumberStrings);

        const nameStrings: any = [];
        for (let i = 0; i < users.length; i++) {
            const user = users[i];
            const name = user["first_name"].toLowerCase() + " " + user["last_name"].toLowerCase();
            nameStrings.push(name);
        }
        setNameStrings(nameStrings);

        userRoles.sort((a: any, b: any) => {
            return a.user.last_name.localeCompare(b.user.last_name)
                || a.user.first_name.localeCompare(b.user.first_name)
                || a.role.organization.short_name.localeCompare(b.role.organization.short_name);
        });

        const userRoleOptions: any = [];
        for (let i = 0; i < userRoles.length; i++) {
            const userRole = userRoles[i];

            let label = userRole.user.first_name + " " + userRole.user.last_name;
            label += " - " + userRole.role.organization.short_name;
            label += " - " + userRole.email.email;
            label += " - " + userRole.user_role_status.name;
            const userRoleOption = {
                label: label,
                value: userRole.id
            }
            userRoleOptions.push(userRoleOption);
        }
        setUserRoleOptions(userRoleOptions);

        const groupUserRolesTableData: any = [];
        for (let i = 0; i < groups.length; i++) {
            const group = groups[i];
            const selected = false;
            if (selected || props.session.permissions.update) {
                group.selected = selected ? "Yes" : "No";
                groupUserRolesTableData.push(group);
            }
        }
        setGroupUserRolesTableData(groupUserRolesTableData);
    }

    async function createUser(event: any) {
        event.preventDefault();
        try {
            setIsRequesting(true);
            setAlert({
                class: "d-none",
                message: ""
            });

            const form: any = event.target;
            const formData: any = new FormData(form);

            if (!formData.get("phone_number")) {
                formData.delete("phone_number");
            }

            if (!formData.get("password")) {
                formData.delete("password");
            }

            const groups = Object.keys(groupUserRolesRowSelection);
            if (groups.length) {
                formData.set("groups", groups);
            }

            const dailyReviewManagers = Object.keys(userDailyReviewManagersRowSelection);
            if (dailyReviewManagers.length) {
                formData.set("daily_review_managers", dailyReviewManagers);
            }

            const c3Managers = Object.keys(userC3ManagersRowSelection);
            if (c3Managers.length) {
                formData.set("c3_managers", c3Managers);
            }

            const managers = Object.keys(userRoleManagersRowSelection);
            if (managers.length) {
                formData.set("managers", managers);
            }

            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/users";
            const id = await RequestCommons.request(url, null, payload, "POST");
            setAlert({
                class: "alert-success text-center",
                message: <>
                    <div>Created user.</div>
                    <div>
                        <Link className={"text-decoration-none"} to={"/users/" + id}>Click here to update the
                            user.</Link>
                    </div>
                </>
            });
            navigate("/users/" + id);
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsRequesting(false);
        }
    }

    function organizationSelectOnChange(option: any) {
        setOrganizationSelect(option);
        setDepartmentSelect(null);
        setRoleSelect(null);

        const organizationId = option.value;
        const departmentOptions: any = [];
        if (organizationId) {
            for (let i = 0; i < departments.length; i++) {
                const department = departments[i];
                if (department.organization_id && department.organization_id === organizationId) {
                    const departmentOption = {
                        label: department.name,
                        value: department.id
                    };
                    departmentOptions.push(departmentOption);
                }
            }
        }
        setDepartmentOptions(departmentOptions);
    }

    function departmentSelectOnChange(option: any) {
        setDepartmentSelect(option);
        setRoleSelect(null);

        const departmentId = option.value;
        const roleOptions: any = [];
        if (departmentId) {
            for (let i = 0; i < roles.length; i++) {
                const role = roles[i];
                if (role.department_id && role.department_id === departmentId) {
                    const roleOption = {
                        label: role.name,
                        value: role.id
                    };
                    roleOptions.push(roleOption);
                }
            }
        }
        setRoleOptions(roleOptions);
    }

    function checkEmailExists(email: string) {
        const exists: any = emailStrings.includes(email);
        setEmailExists(exists);
    }

    function checkPhoneNumberExists(phoneNumber: string) {
        const exists: any = phoneNumberStrings.includes(phoneNumber);
        setPhoneNumberExists(exists);
    }

    async function copyUserRolePermissionsOnChange(option: any) {
        setCopyUserRolePermissionsSelectedOption(option);
        const userRoleId = option.value;
        const groupUserRoles = groupUserRolesAll.filter((groupUserRole: any) => {
            return groupUserRole.user_role_id === userRoleId;
        });
        const groupUserRolesRowSelectionNew = JSON.parse(JSON.stringify(groupUserRolesRowSelection));
        for (let i = 0; i < groupUserRoles.length; i++) {
            const groupUserRole = groupUserRoles[i];
            groupUserRolesRowSelectionNew[groupUserRole.group_id] = true;
        }
        setGroupUserRolesRowSelection(groupUserRolesRowSelectionNew);
        setCopyUserRolePermissionsSelectedOption(null);

        const userRolesFiltered = userRoles.filter((userRole: any) => {
            return userRole.id === userRoleId;
        });

        if (userRolesFiltered && userRolesFiltered[0]) {
            const userRole = userRolesFiltered[0];
            const name = userRole.user.first_name + " " + userRole.user.last_name;
            const alert = <Alert variant={"success"} dismissible onClose={function (event: any) {
                setCopyPermissionsAlert(null);
            }}>
                <span>Added permissions from {name}.</span>
            </Alert>;
            setCopyPermissionsAlert(alert);
        }
    }

    useEffect(() => {
        if (firstName && lastName) {
            const name = firstName.toLowerCase() + " " + lastName.toLowerCase();
            const exists: any = nameStrings.includes(name);
            setNameExists(exists);
        } else {
            setNameExists(null);
        }
    }, [firstName, lastName]);

    let buttonText: any = <span>Create User</span>;
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Creating user...</span>
            </span>;
    }

    let emailFieldDescription: any = <small>Required</small>;
    if (emailExists) {
        emailFieldDescription = <small className={"text-danger"}>Email already exists.</small>;
    }

    let phoneNumberFieldDescription: any = null;
    if (phoneNumberExists) {
        phoneNumberFieldDescription = <small className={"text-warning"}>Phone number already exists.</small>;
    }

    let nameFieldDescription: any = <small>Required</small>;
    if (nameExists) {
        nameFieldDescription = <div>
            <small className={"text-danger"}>Name already exists.</small>
        </div>;
    }

    let avatarPreview = null;
    if (avatar) {
        avatarPreview = <div className={"p-5 mt-3 border text-center"}>
            <img style={{
                width: "100%",
                height: "100px",
                objectFit: "contain"
            }} src={avatar} alt={avatar}/>
        </div>;
    }

    const groupsTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'organization.name',
            header: 'Organization',
        },
        {
            accessorKey: 'description',
            header: 'Description',
        },
    ];

    const userDailyReviewManagersTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'user.first_name',
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'email.email',
            header: 'Email',
        },
        {
            accessorKey: 'role.organization.name',
            header: 'Org',
        }
    ];

    const userC3ManagersTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'user.first_name',
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'email.email',
            header: 'Email',
        },
        {
            accessorKey: 'role.organization.name',
            header: 'Org',
        }
    ];

    const userRoleManagersTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'user.first_name',
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'email.email',
            header: 'Email',
        },
        {
            accessorKey: 'role.organization.name',
            header: 'Org',
        }
    ];

    const groupsSelected = Object.keys(groupUserRolesRowSelection).length;
    const dailyReviewManagersSelected = Object.keys(userDailyReviewManagersRowSelection).length;
    const c3ManagersSelected = Object.keys(userC3ManagersRowSelection).length;
    const managersSelected = Object.keys(userRoleManagersRowSelection).length;

    return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " container m-auto"}>
        <div className={"mb-3 d-none"}>
            <ReturnToLastPageLink/>
        </div>
        <form onSubmit={createUser}>
            <h2>Create User</h2>
            <div className={"row position-relative"}>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">First Name</label>
                    <input name="first_name"
                           className={"form-control " + (nameExists ? "is-invalid text-danger" : "")}
                           onKeyUp={function (event: any) {
                               setFirstName(event.target.value);
                           }}
                           onChange={function (event: any) {
                               setFirstName(event.target.value);
                           }}
                           required
                           autoFocus
                    />
                    {nameFieldDescription}
                </div>
                <div className="col-lg-4 mb-3">
                    <label className="form-label">Last Name</label>
                    <input name="last_name"
                           className={"form-control " + (nameExists ? "is-invalid text-danger" : "")}
                           onKeyUp={function (event: any) {
                               setLastName(event.target.value);
                           }}
                           onChange={function (event: any) {
                               setLastName(event.target.value);
                           }}
                           required
                    />
                    {nameFieldDescription}
                </div>
                <div className={"col-lg-4 mb-3"}>
                    <label className="form-label">
                        <span>Disabled</span>
                        <i className="bi bi-info-circle-fill ms-2 text-primary"
                           title={"This should be set as \"Disabled\" until they have officially started to prevent early access to the portal."}/>
                    </label>
                    <ReactSelectDisabled defaultValue={true} required/>
                </div>
            </div>
            <Accordion defaultActiveKey={"user-role"} className={"mb-3"}>
                <Accordion.Item eventKey="user-role">
                    <Accordion.Header>
                        User Role (Required)
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className={"row position-relative"} style={{
                            zIndex: 999
                        }}>
                            <div className={"col-lg-4 mb-3"}>
                                <label className="form-label">Email</label>
                                <input name="email" type={"email"}
                                       className={"form-control " + (emailExists ? "is-invalid text-danger" : "")}
                                       onChange={function (event: any) {
                                           const email = event.target.value;
                                           checkEmailExists(email);
                                       }}/>
                                {emailFieldDescription}
                            </div>
                            <div className={"col-lg-4 mb-3"}>
                                <label className="form-label">Phone Number</label>
                                <input className="form-control" name="phone_number" type={"phone"}
                                       placeholder={"(123) 456-7890, extension 1234"}
                                       onChange={function (event: any) {
                                           const phoneNumber = event.target.value;
                                           checkPhoneNumberExists(phoneNumber);
                                       }}/>
                                {phoneNumberFieldDescription}
                            </div>
                            <div className={"col-lg-4 mb-3"}>
                                <label className="form-label">Pay Type</label>
                                <Select
                                    name="pay_type_id"
                                    options={payTypeOptions}
                                    onChange={function (option) {
                                        setSelectedPayTypeOption(option);
                                    }}
                                    value={selectedPayTypeOption}
                                    required
                                />
                                <small>Required</small>
                            </div>
                        </div>
                        <div className={"row position-relative"} style={{
                            zIndex: 998
                        }}>
                            <div className="col-lg-4 mb-3">
                                <label className="form-label">Organization</label>
                                <Select
                                    name={"organization_id"}
                                    onChange={organizationSelectOnChange}
                                    options={organizationOptions}
                                    value={organizationSelect}
                                />
                                <small>Required</small>
                            </div>
                            <div className="col-lg-4 mb-3">
                                <label className="form-label">Department</label>
                                <Select
                                    name={"department_id"}
                                    onChange={departmentSelectOnChange}
                                    options={departmentOptions}
                                    value={departmentSelect}
                                    isDisabled={!departmentOptions.length}/>
                                <small>Required</small>
                            </div>
                            <div className={"col-lg-4 mb-3"}>
                                <label className="form-label">Role</label>
                                <Select
                                    name="role_id"
                                    value={roleSelect}
                                    options={roleOptions}
                                    onChange={setRoleSelect}
                                    isDisabled={!roleOptions.length}
                                    required
                                />
                                <small>Required</small>
                            </div>
                        </div>
                        <div className="row position-relative" style={{
                            zIndex: 997
                        }}>
                            <div className={"col-lg-4 mb-3"}>
                                <label className="form-label">Start Date</label>
                                <input type="date" name="start_date" className="form-control" required
                                       onClick={function (event: any) {
                                           event.preventDefault();
                                           event.target.showPicker();
                                       }}/>
                                <small>Required</small>
                            </div>
                            <div className={"col-lg-4 mb-3"}>
                                <label className="form-label">End Date</label>
                                <input type="date" name="end_date" className="form-control"
                                       onClick={function (event: any) {
                                           event.preventDefault();
                                           event.target.showPicker();
                                       }}
                                />
                            </div>
                            <div className={"col-lg-4 mb-3 position-relative"} style={{
                                zIndex: 996
                            }}>
                                <label className="form-label">Disabled</label>
                                <ReactSelectDisabled name={"user_role_disabled"} defaultValue={true} required/>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="groups">
                    <Accordion.Header>
                        Groups ({groupsSelected})
                    </Accordion.Header>
                    <Accordion.Body>
                        <div style={{
                            position: "relative",
                            zIndex: 999,
                            paddingBottom: 0
                        }}>
                            <div className={"mb-3"}>
                                <label>Copy Permissions</label>
                                <Select
                                    value={copyUserRolePermissionsSelectedOption}
                                    onChange={copyUserRolePermissionsOnChange}
                                    options={userRoleOptions}
                                    isDisabled={!props.session.permissions.update}
                                />
                            </div>
                            <div>
                                {copyPermissionsAlert}
                            </div>
                        </div>
                        <div style={{
                            position: "relative",
                            zIndex: 0
                        }}>
                            <MaterialReactTable
                                muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                columns={groupsTableColumns}
                                data={groupUserRolesTableData}
                                enableSelectAll={false}
                                enablePagination={true}
                                positionToolbarAlertBanner={"none"}
                                enableRowSelection={props.session.permissions.update}
                                onRowSelectionChange={setGroupUserRolesRowSelection}
                                state={{
                                    rowSelection: groupUserRolesRowSelection,
                                    isLoading: !groupUserRolesTableData.length,
                                    density: "compact"
                                }}
                                initialState={{
                                    columnVisibility: {
                                        selected: false
                                    },
                                    sorting: [
                                        {
                                            id: "selected",
                                            desc: true
                                        },
                                        {
                                            id: "organization.name",
                                            desc: false
                                        },
                                        {
                                            id: "name",
                                            desc: false
                                        }
                                    ]
                                }}
                                getRowId={(originalRow: any) => originalRow.id}
                                renderTopToolbarCustomActions={() => {
                                    return <div className={"align-middle"}>
                                        <h2 className={"d-inline-block align-middle p-0 m-0 me-3"}>
                                            <span>Groups </span>
                                            <small>({groupsSelected})</small>
                                        </h2>
                                        {props.session.permissions.update ?
                                            <i className="text-primary bi bi-info-circle-fill"
                                               title={"Consult with Engineering for more details on specific product permissions."}/> : null}
                                    </div>;
                                }}
                            />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="daily-review-managers">
                    <Accordion.Header>
                        Daily Review Managers ({dailyReviewManagersSelected})
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className={"mb-2"}>
                            <MaterialReactTable
                                muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                columns={userDailyReviewManagersTableColumns}
                                data={userDailyReviewManagersTableData}
                                enableSelectAll={false}
                                enablePagination={true}
                                positionToolbarAlertBanner={"none"}
                                enableRowSelection={props.session.permissions.update}
                                onRowSelectionChange={setUserDailyReviewManagersRowSelection}
                                state={{
                                    rowSelection: userDailyReviewManagersRowSelection,
                                    isLoading: false,
                                    density: "compact"
                                }}
                                // enableRowActions={true}
                                // renderRowActions={renderUserDailyReviewManagerRowActions}
                                initialState={{
                                    columnVisibility: {
                                        selected: false
                                    },
                                    sorting: [
                                        {
                                            id: "selected",
                                            desc: true
                                        },
                                        {
                                            id: "user.last_name",
                                            desc: false
                                        },
                                        {
                                            id: "user.first_name",
                                            desc: false
                                        },
                                        {
                                            id: "role.organization.name",
                                            desc: false
                                        }
                                    ]
                                }}
                                getRowId={(originalRow: any) => originalRow.id}
                                muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                                renderTopToolbarCustomActions={() => {
                                    return <div>
                                        <h2 className={"me-3 d-inline-block align-top"}>Daily Review
                                            Managers ({dailyReviewManagersSelected})</h2>
                                        <ul>
                                            <li>Will be CC'd on the user's daily review emails</li>
                                        </ul>
                                    </div>;
                                }}
                            />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="c3-managers">
                    <Accordion.Header>
                        C3 Managers ({c3ManagersSelected})
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className={"mb-2"}>
                            <MaterialReactTable
                                muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                columns={userC3ManagersTableColumns}
                                data={userC3ManagersTableData}
                                enableSelectAll={false}
                                enablePagination={true}
                                positionToolbarAlertBanner={"none"}
                                enableRowSelection={props.session.permissions.update}
                                onRowSelectionChange={setUserC3ManagersRowSelection}
                                state={{
                                    rowSelection: userC3ManagersRowSelection,
                                    isLoading: false,
                                    density: "compact"
                                }}
                                // enableRowActions={true}
                                // renderRowActions={renderUserDailyReviewManagerRowActions}
                                initialState={{
                                    columnVisibility: {
                                        selected: false
                                    },
                                    sorting: [
                                        {
                                            id: "selected",
                                            desc: true
                                        },
                                        {
                                            id: "user.last_name",
                                            desc: false
                                        },
                                        {
                                            id: "user.first_name",
                                            desc: false
                                        },
                                        {
                                            id: "role.organization.name",
                                            desc: false
                                        }
                                    ]
                                }}
                                getRowId={(originalRow: any) => originalRow.id}
                                muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                                renderTopToolbarCustomActions={() => {
                                    return <div>
                                        <h2 className={"me-3 d-inline-block align-top"}>C3 Managers
                                            ({c3ManagersSelected})</h2>
                                        <ul>
                                            <li>Will be CC'd on overnight login email warnings to users</li>
                                        </ul>
                                    </div>;
                                }}
                            />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="managers">
                    <Accordion.Header>
                        Managers ({managersSelected})
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className={"mb-2"}>
                            <MaterialReactTable
                                muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                columns={userRoleManagersTableColumns}
                                data={userRoleManagersTableData}
                                enableSelectAll={false}
                                enablePagination={true}
                                positionToolbarAlertBanner={"none"}
                                enableRowSelection={props.session.permissions.update}
                                onRowSelectionChange={setUserRoleManagersRowSelection}
                                state={{
                                    rowSelection: userRoleManagersRowSelection,
                                    isLoading: false,
                                    density: "compact"
                                }}
                                // enableRowActions={true}
                                // renderRowActions={renderUserDailyReviewManagerRowActions}
                                initialState={{
                                    columnVisibility: {
                                        selected: false
                                    },
                                    sorting: [
                                        {
                                            id: "selected",
                                            desc: true
                                        },
                                        {
                                            id: "user.last_name",
                                            desc: false
                                        },
                                        {
                                            id: "user.first_name",
                                            desc: false
                                        },
                                        {
                                            id: "role.organization.name",
                                            desc: false
                                        }
                                    ]
                                }}
                                getRowId={(originalRow: any) => originalRow.id}
                                muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                                renderTopToolbarCustomActions={() => {
                                    return <div>
                                        <h2 className={"me-3 d-inline-block align-top"}>Managers
                                            ({managersSelected})</h2>
                                        <ul>
                                            <li>Legacy manager assignments from old system, not being utilized currently
                                            </li>
                                        </ul>
                                    </div>;
                                }}
                            />
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <div className={"mt-3"}>
                <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
            </div>
            <div className={"row mt-3"}>
                <div className={"col"}>
                    <ReturnToLastPageLink/>
                </div>
                <div className={"col text-end"}>
                    <button type="submit" className="btn btn-primary ps-5 pe-5"
                            disabled={isRequesting || emailExists}>{buttonText}</button>
                </div>
            </div>
        </form>
    </div>;
}