import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../commons/RequestCommons"
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {MaterialReactTable} from "material-react-table";
import AuthCommons from "../../../commons/AuthCommons";
import Select from "react-select";
import ReactElementCommons from "../../../commons/ReactElementCommons";
import ObjectCommons from "../../../commons/ObjectCommons";
import AuthorizationsTable from "../../authorizations/AuthorizationsTable";
import Alert from "react-bootstrap/Alert";
import TimeCommons from "../../../commons/TimeCommons";
import Loading from "../../Loading";
import {Accordion} from "react-bootstrap";
import ReturnToLastPageLink from "../../shared/ReturnToLastPageLink";
import DoesNotExist from "../../shared/DoesNotExist";
import EntryDeleted from "../../shared/EntryDeleted";
import DeleteModal from "../../shared/DeleteModal";
import ReactSelectDisabled from "../../../commons/ReactSelectDisabled";
import CreatableSelect from "react-select/creatable";

export default function UpdateUserRole(props: any) {
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();

    const [alert, setAlert] = useState({
        class: "d-none",
        message: ""
    });
    const [roleExists, setRoleExists] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [userRole, setUserRole]: any = useState({});
    const [userOptions, setUserOptions]: any = useState([]);
    const [userSelect, setUserSelect]: any = useState(null);
    const [roleOptions, setRoleOptions]: any = useState([]);
    const [roleSelect, setRoleSelect]: any = useState(null);
    const [organizationOptions, setOrganizationOptions]: any = useState([]);
    const [organizationSelect, setOrganizationSelect]: any = useState(null);
    const [departmentOptions, setDepartmentOptions]: any = useState([]);
    const [departmentSelect, setDepartmentSelect]: any = useState(null);
    const [departments, setDepartments]: any = useState([]);
    const [roles, setRoles]: any = useState([]);
    const [role, setRole]: any = useState([]);
    const [emailOptions, setEmailOptions]: any = useState([]);
    const [emailSelect, setEmailSelect]: any = useState(null);
    const [phoneNumberOptions, setPhoneNumberOptions]: any = useState([]);
    const [phoneNumberSelect, setPhoneNumberSelect]: any = useState(null);
    const [payTypeOptions, setPayTypeOptions]: any = useState([]);
    const [payTypeSelect, setPayTypeSelect]: any = useState(null);
    const [userRoleStatusOptions, setUserRoleStatusOptions]: any = useState([]);
    const [userRoleStatusSelect, setUserRoleStatusSelect]: any = useState(null);
    const [userRoleManagers, setUserRoleManagers]: any = useState([]);
    const [userRoleManagersTableData, setUserRoleManagersTableData]: any = useState([]);
    const [userRoleManagersRowSelection, setUserRoleManagersRowSelection]: any = useState({});
    const [authorizations, setAuthorizations]: any = useState([]);
    const [userDailyReviewManagers, setUserDailyReviewManagers]: any = useState([]);
    const [userDailyReviewManagersTableData, setUserDailyReviewManagersTableData]: any = useState([]);
    const [userDailyReviewManagersRowSelection, setUserDailyReviewManagersRowSelection]: any = useState({});
    const [userC3Managers, setUserC3Managers]: any = useState([]);
    const [userC3ManagersTableData, setUserC3ManagersTableData]: any = useState([]);
    const [userC3ManagersRowSelection, setUserC3ManagersRowSelection]: any = useState({});
    const [groupUserRoles, setGroupUserRoles]: any = useState([]);
    const [groupUserRolesTableData, setGroupUserRolesTableData]: any = useState([]);
    const [groupUserRolesRowSelection, setGroupUserRolesRowSelection]: any = useState({});
    const [settingsAlert, setSettingsAlert]: any = useState([]);
    const [userRoleGroupIds, setUserRoleGroupIds]: any = useState([]);
    const [userRoleOptions, setUserRoleOptions]: any = useState([]);
    const [groupUserRolesAll, setGroupUserRolesAll]: any = useState([]);
    const [copyUserRolePermissionsSelectedOption, setCopyUserRolePermissionsSelectedOption]: any = useState(null);
    const [copyPermissionsAlert, setCopyPermissionsAlert]: any = useState(null);
    const [userRoles, setUserRoles]: any = useState([]);
    const [userRoleDeleted, setUserRoleDeleted]: any = useState(false);
    const [deleteModalShow, setDeleteModalShow]: any = useState(false);
    const [deleteModalAlert, setDeleteModalAlert]: any = useState(null);
    const [securityAlert, setSecurityAlert]: any = useState(null);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setIsLoading(true);
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [userResponse]: any = await Promise.all([
            AuthCommons.request("/users/roles/" + params.id + "?populate=1", null, null, 'get', 'application/x-www-form-urlencoded', false)
        ]);

        if (userResponse.status === 204) {
            setRoleExists(false);
        } else {
            setRoleExists(true);
            const userRole: any = await userResponse.json();
            const [
                users, organizations, departments, roles, payTypes, userRoles, userRoleStatuses,
                userRoleManagers, dailyReviewManagers, groups, groupUserRoles, groupUserRolesAll,
                sbbManagers, cpManagers, taManagers, c3Managers
            ]: any = await Promise.all([
                AuthCommons.request("/users?order_by=last_name&order_by_direction=asc"),
                AuthCommons.request("/organizations"),
                AuthCommons.request("/departments"),
                AuthCommons.request("/roles"),
                AuthCommons.request("/pay/types"),
                AuthCommons.request("/users/roles?populate=1&disabled=0"),
                AuthCommons.request("/users/roles/statuses"),
                AuthCommons.request("/users/roles/managers?user_role_id=" + params.id + "&populate=1&filter=manager_user_role"),
                AuthCommons.request("/users/roles/managers/reviews/daily?populate=1&user_role_id=" + params.id + "&filter=manager_user_role"),
                AuthCommons.request("/groups?populate=1"),
                AuthCommons.request("/groups/users/roles?user_role_id=" + params.id + "&filter=group_id&sort=1&unique=1"),
                AuthCommons.request("/groups/users/roles"),
                AuthCommons.request("/groups/users/roles?group_id=164&populate=1"),
                AuthCommons.request("/groups/users/roles?group_id=165&populate=1"),
                AuthCommons.request("/groups/users/roles?group_id=166&populate=1"),
                AuthCommons.request("/users/roles/managers/c3?populate=1&user_role_id=" + params.id + "&filter=manager_user_role")
            ]);

            setUserRole(userRole);
            setUserRoleGroupIds(groupUserRoles);
            setGroupUserRolesAll(groupUserRolesAll);
            setUserRoles(userRoles);
            setUserDailyReviewManagers(dailyReviewManagers);
            setUserC3Managers(c3Managers);
            setUserRoleManagers(userRoleManagers);
            setGroupUserRoles(groupUserRoles);

            await loadAuthorizations();

            const [role, userEmails, userPhoneNumbers]: any = await Promise.all([
                AuthCommons.request("/roles/" + userRole.role_id),
                AuthCommons.request("/users/emails?user_id=" + userRole.user_id + "&populate=1&filter=email"),
                AuthCommons.request("/users/numbers/phone?user_id=" + userRole.user_id + "&populate=1&filter=phone_number")
            ]);
            setRole(role);

            const userOptions: any = ReactElementCommons.createReactSelectOptionsFromList(users, ["first_name", "last_name"], "id");
            setUserOptions(userOptions);
            setUserSelect(userOptions.find((option: any) => option.value === userRole.user_id));

            const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id");
            setOrganizationOptions(organizationOptions);
            setOrganizationSelect(organizationOptions.find((option: any) => option.value === userRole.role.organization_id));

            setDepartments(departments);

            for (let i = 0; i < departments.length; i++) {
                const department = departments[i];
                if (department.organization_id && department.organization_id === role.organization_id) {
                    const departmentOption = {
                        label: department.name,
                        value: department.id
                    };
                    departmentOptions.push(departmentOption);
                }
            }
            setDepartmentOptions(departmentOptions);
            setDepartmentSelect(departmentOptions.find((option: any) => option.value === userRole.role.department_id));

            setRoles(roles);

            for (let i = 0; i < roles.length; i++) {
                const r = roles[i];
                if (r.department_id && r.department_id === role.department_id) {
                    const roleOption = {
                        label: r.name,
                        value: r.id
                    };
                    roleOptions.push(roleOption);
                }
            }
            setRoleOptions(roleOptions);
            setRoleSelect(roleOptions.find((option: any) => option.value === userRole.role.id));

            const emailOptions: any = ReactElementCommons.createReactSelectOptionsFromList(userEmails, "email", "id");
            setEmailOptions(emailOptions);
            setEmailSelect(emailOptions.find((option: any) => option.value === userRole.email_id));

            const phoneNumberOptions: any = ReactElementCommons.createReactSelectOptionsFromList(userPhoneNumbers, "phone_number", "id", " ", true);
            setPhoneNumberOptions(phoneNumberOptions);
            setPhoneNumberSelect(phoneNumberOptions.find((option: any) => option.value === userRole.phone_number_id));

            const payTypeOptions: any = ReactElementCommons.createReactSelectOptionsFromList(payTypes, "name", "id");
            setPayTypeOptions(payTypeOptions);
            setPayTypeSelect(payTypeOptions.find((option: any) => option.value === userRole.pay_type_id));

            const userRoleStatusOptions: any = ReactElementCommons.createReactSelectOptionsFromList(userRoleStatuses, "name", "id");
            setUserRoleStatusOptions(userRoleStatusOptions);
            setUserRoleStatusSelect(userRoleStatusOptions.find((option: any) => option.value === userRole.user_role_status_id));

            const userRoleManagersByManagerId: any = ObjectCommons.mapEntriesById(userRoleManagers);
            const userRoleManagersRowSelection: any = {};
            for (let i = 0; i < userRoleManagers.length; i++) {
                const userRoleManager = userRoleManagers[i];
                userRoleManagersRowSelection[userRoleManager.id] = true;
            }
            setUserRoleManagersRowSelection(userRoleManagersRowSelection);

            let managerGroupUserRoles = [];
            switch (userRole.role.organization_id) {
                case 3:
                    break;
                case 4:
                    managerGroupUserRoles = sbbManagers;
                    break;
                case 5:
                    managerGroupUserRoles = taManagers;
                    break;
                case 6:
                    managerGroupUserRoles = cpManagers;
                    break;
            }

            const managerUserRoles: any = [];
            for (let i = 0; i < managerGroupUserRoles.length; i++) {
                const managerUserRole = managerGroupUserRoles[i].user_role;
                managerUserRoles.push(managerUserRole);
            }

            const userRoleManagersTableData: any = [];
            for (let i = 0; i < managerUserRoles.length; i++) {
                const managerUserRole = managerUserRoles[i];
                const selected = userRoleManagersByManagerId[managerUserRole.id];
                if (selected || (managerUserRole.disabled === 0 && props.session.permissions.update)) {
                    managerUserRole.selected = selected ? "Yes" : "No";
                    userRoleManagersTableData.push(managerUserRole);
                }
            }
            setUserRoleManagersTableData(userRoleManagersTableData);

            userRoles.sort((a: any, b: any) => {
                return a.user.last_name.localeCompare(b.user.last_name)
                    || a.user.first_name.localeCompare(b.user.first_name)
                    || a.role.organization.short_name.localeCompare(b.role.organization.short_name);
            });

            const userRoleOptions: any = [];
            for (let i = 0; i < userRoles.length; i++) {
                const userRole = userRoles[i];

                let label = userRole.user.first_name + " " + userRole.user.last_name;
                label += " - " + userRole.role.organization.short_name;
                label += " - " + userRole.email.email;
                label += userRole.Disabled ? " - Disabled" : "";
                const userRoleOption = {
                    label: label,
                    value: userRole.id
                }
                userRoleOptions.push(userRoleOption);
            }
            setUserRoleOptions(userRoleOptions);

            const userDailyReviewManagersByManagerId: any = ObjectCommons.mapEntriesById(dailyReviewManagers);
            const userDailyReviewManagersRowSelection: any = {};
            for (let i = 0; i < dailyReviewManagers.length; i++) {
                const dailyReviewManager: any = dailyReviewManagers[i];
                userDailyReviewManagersRowSelection[dailyReviewManager.id] = true;
            }
            setUserDailyReviewManagersRowSelection(userDailyReviewManagersRowSelection);

            const userDailyReviewManagersTableData: any = [];
            for (let i = 0; i < userRoles.length; i++) {
                const managerUserRole: any = JSON.parse(JSON.stringify(userRoles[i]));
                const selected = userDailyReviewManagersByManagerId[managerUserRole.id];
                if (selected || (managerUserRole.disabled === 0 && props.session.permissions.update)) {
                    managerUserRole.selected = selected ? "Yes" : "No";
                    userDailyReviewManagersTableData.push(managerUserRole);
                }
            }
            setUserDailyReviewManagersTableData(userDailyReviewManagersTableData);

            const userC3ManagersByManagerId: any = ObjectCommons.mapEntriesById(c3Managers);
            const userC3ManagersRowSelection: any = {};
            for (let i = 0; i < c3Managers.length; i++) {
                const c3Manager: any = c3Managers[i];
                userC3ManagersRowSelection[c3Manager.id] = true;
            }
            setUserC3ManagersRowSelection(userC3ManagersRowSelection);

            const userC3ManagersTableData: any = [];
            for (let i = 0; i < userRoles.length; i++) {
                const managerUserRole: any = JSON.parse(JSON.stringify(userRoles[i]));
                const selected = userC3ManagersByManagerId[managerUserRole.id];
                if (selected || (managerUserRole.disabled === 0 && props.session.permissions.update)) {
                    managerUserRole.selected = selected ? "Yes" : "No";
                    userC3ManagersTableData.push(managerUserRole);
                }
            }
            setUserC3ManagersTableData(userC3ManagersTableData);

            const groupUserRolesRowSelection: any = {};
            for (let i = 0; i < groupUserRoles.length; i++) {
                const groupUserRole = groupUserRoles[i];
                groupUserRolesRowSelection[groupUserRole] = true;
            }
            setGroupUserRolesRowSelection(groupUserRolesRowSelection);

            const groupUserRolesTableData: any = [];
            for (let i = 0; i < groups.length; i++) {
                const group = groups[i];
                const selected = groupUserRoles.includes(group.id);
                if (selected || props.session.permissions.update) {
                    group.selected = selected ? "Yes" : "No";
                    group.new_selection = "No";
                    groupUserRolesTableData.push(group);
                }
            }
            setGroupUserRolesTableData(groupUserRolesTableData);
        }
    }

    async function loadAuthorizations() {
        const authorizations = await AuthCommons.request("/authorizations?user_role_id=" + params.id + "&populate=1");
        setAuthorizations(authorizations);
    }

    async function updateUserRole(event: any) {
        event.preventDefault();
        try {
            setIsUpdating(true);
            setAlert({
                class: "d-none",
                message: ""
            });

            let url;

            const formData: any = new FormData(event.target);
            let payload: any = new URLSearchParams(formData);

            const newUserRole = Object.fromEntries(formData);
            newUserRole.email_id = parseInt(newUserRole.email_id);
            newUserRole.user_id = parseInt(newUserRole.user_id);
            newUserRole.role_id = parseInt(newUserRole.role_id);
            newUserRole.disabled = parseInt(newUserRole.disabled);

            if (!newUserRole.pay_type_id) {
                newUserRole.pay_type_id = null;
            } else {
                newUserRole.pay_type_id = parseInt(newUserRole.pay_type_id);
            }

            if (!newUserRole.start_date) {
                newUserRole.start_date = null;
            }

            if (!newUserRole.end_date) {
                newUserRole.end_date = null;
            }

            if (!newUserRole.phone_number_id) {
                newUserRole.phone_number_id = null;
            } else {
                newUserRole.phone_number_id = parseInt(newUserRole.phone_number_id);
            }

            const userRoleUpdated = userRole.user_id !== newUserRole.user_id
                || userRole.email_id !== newUserRole.email_id
                || userRole.phone_number_id !== newUserRole.phone_number_id
                || userRole.role_id !== newUserRole.role_id
                || userRole.pay_type_id !== newUserRole.pay_type_id
                || userRole.start_date !== newUserRole.start_date
                || userRole.end_date !== newUserRole.end_date
                || userRole.disabled !== newUserRole.disabled;

            console.log(userRole, newUserRole, userRoleUpdated);

            if (userRoleUpdated) {
                url = process.env.REACT_APP_AUTH_API + "/users/roles/" + userRole.id;
                await RequestCommons.request(url, null, payload, "PUT");
            }

            const newUserRoleManagerIds: any = Object.keys(userRoleManagersRowSelection).map(Number).sort((a: any, b: any) => a - b);
            const oldUserRoleManagerIds: any = userRoleManagers.map((m: any) => m.id).sort((a: any, b: any) => a - b);
            const roleManagersChanged = JSON.stringify(oldUserRoleManagerIds) != JSON.stringify(newUserRoleManagerIds);
            if (roleManagersChanged) {
                const userRoleManagers = [];
                for (let i = 0; i < newUserRoleManagerIds.length; i++) {
                    const userRoleManager = {
                        user_role_id: userRole.id,
                        manager_user_role_id: parseInt(newUserRoleManagerIds[i])
                    };
                    userRoleManagers.push(userRoleManager);
                }
                payload = JSON.stringify(userRoleManagers);
                url = process.env.REACT_APP_AUTH_API + "/users/roles/managers?user_role_id=" + userRole.id;
                await RequestCommons.request(url, null, payload, "PUT", "application/json");
            }

            const newUserDailyReviewManagerIds: any = Object.keys(userDailyReviewManagersRowSelection).map(Number).sort((a: any, b: any) => a - b);
            const oldUserDailyReviewManagerIds: any = userDailyReviewManagers.map((m: any) => m.id).sort((a: any, b: any) => a - b);
            const dailyReviewManagersChanged = JSON.stringify(oldUserDailyReviewManagerIds) != JSON.stringify(newUserDailyReviewManagerIds);
            if (dailyReviewManagersChanged) {
                const dailyReviewManagers = [];
                for (let i = 0; i < newUserDailyReviewManagerIds.length; i++) {
                    const dailyReviewManager = {
                        user_role_id: userRole.id,
                        manager_user_role_id: parseInt(newUserDailyReviewManagerIds[i])
                    };
                    dailyReviewManagers.push(dailyReviewManager);
                }
                payload = JSON.stringify(dailyReviewManagers);
                url = process.env.REACT_APP_AUTH_API + "/users/roles/managers/reviews/daily?user_role_id=" + userRole.id;
                await RequestCommons.request(url, null, payload, "PUT", "application/json");
            }

            const newUserC3ManagerIds: any = Object.keys(userC3ManagersRowSelection).map(Number).sort((a: any, b: any) => a - b);
            const oldUserC3ManagerIds: any = userC3Managers.map((m: any) => m.id).sort((a: any, b: any) => a - b);
            const c3ManagersChanged = JSON.stringify(oldUserC3ManagerIds) !== JSON.stringify(newUserC3ManagerIds);
            if (c3ManagersChanged) {
                const newUserC3Managers: any = [];
                for (let i = 0; i < newUserC3ManagerIds.length; i++) {
                    const newUserC3Manager = {
                        user_role_id: userRole.id,
                        manager_user_role_id: parseInt(newUserC3ManagerIds[i])
                    };
                    newUserC3Managers.push(newUserC3Manager);
                }
                payload = JSON.stringify(newUserC3Managers);
                url = process.env.REACT_APP_AUTH_API + "/users/roles/managers/c3?user_role_id=" + userRole.id;
                await RequestCommons.request(url, null, payload, "PUT", "application/json");
            }

            const newGroupIds: any = Object.keys(groupUserRolesRowSelection).map(Number).sort((a: any, b: any) => a - b);
            const oldGroupIds: any = groupUserRoles.map(Number).sort((a: any, b: any) => a - b);
            const groupsChanged = JSON.stringify(oldGroupIds) !== JSON.stringify(newGroupIds);
            if (groupsChanged) {
                const groupIds: any = [];
                for (let groupId in groupUserRolesRowSelection) {
                    groupIds.push(parseInt(groupId));
                }
                await replaceGroupUserRoles(userRole.id, groupIds);
            }

            await loadData();

            setAlert({
                class: "alert-success text-center",
                message: "Updated."
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger text-center",
                message: "Error: " + error.detail ? error.detail : JSON.stringify(error)
            });
        } finally {
            setIsUpdating(false);
        }
    }

    function organizationSelectOnChange(option: any) {
        setOrganizationSelect(option);
        setDepartmentSelect(null);
        setRoleSelect(null);

        const organizationId = option.value;
        const departmentOptions: any = [];
        if (organizationId) {
            for (let i = 0; i < departments.length; i++) {
                const department = departments[i];
                if (department.organization_id && department.organization_id === organizationId) {
                    const departmentOption = {
                        label: department.name,
                        value: department.id
                    };
                    departmentOptions.push(departmentOption);
                }
            }
        }
        setDepartmentOptions(departmentOptions);
        setRoleOptions([]);
    }

    function departmentSelectOnChange(option: any) {
        setDepartmentSelect(option);
        setRoleSelect(null);

        const departmentId = option.value;
        const roleOptions: any = [];
        if (departmentId) {
            for (let i = 0; i < roles.length; i++) {
                const role = roles[i];
                if (role.department_id && role.department_id === departmentId) {
                    const roleOption = {
                        label: role.name,
                        value: role.id
                    };
                    roleOptions.push(roleOption);
                }
            }
        }
        setRoleOptions(roleOptions);
    }

    async function replaceGroupUserRoles(userRoleId: any, groupIds: any) {
        const entries = []
        for (let i = 0; i < groupIds.length; i++) {
            entries.push({
                "group_id": groupIds[i],
                "user_role_id": userRoleId
            })
        }
        const body: any = JSON.stringify(entries);
        let url = process.env.REACT_APP_AUTH_API + "/groups/users/roles?user_role_id=" + userRoleId;
        await RequestCommons.request(url, null, body, "put", "application/json")
    }

    async function createGroupFromUserRole(event: any) {
        event.preventDefault();
        try {
            const userName = userRole.user.first_name + " " + userRole.user.last_name;
            let name = userRole.role.organization.short_name;
            name += " - " + userName
            name += " - " + TimeCommons.toLocaleDateString(new Date());
            name += " " + TimeCommons.toLocaleTimeString(new Date());
            let description = "This group was created as a copy of User Role Id: " + userRole.id + " for " + userName + ".";

            const group = {
                name: name,
                organization_id: userRole.role.organization_id,
                description: description
            }

            let url = process.env.REACT_APP_AUTH_API + "/groups";
            const payload = new URLSearchParams(group);
            const newGroupId = await RequestCommons.request(url, null, payload, "POST");

            const policyIds: any = [];
            for (let i = 0; i < userRoleGroupIds.length; i++) {
                const groupId = userRoleGroupIds[i];
                const url = process.env.REACT_APP_AUTH_API + "/groups/policies?group_id=" + groupId;
                const groupPolicies: any = await RequestCommons.request(url);
                for (let j = 0; j < groupPolicies.length; j++) {
                    const groupPolicy = groupPolicies[j];
                    if (!policyIds.includes(groupPolicy.policy_id)) {
                        policyIds.push(groupPolicy.policy_id);
                    }
                }
            }

            const newGroupPolicies = [];
            for (let i = 0; i < policyIds.length; i++) {
                const policyId = policyIds[i];
                const groupPolicy = {
                    group_id: newGroupId,
                    policy_id: policyId
                }
                newGroupPolicies.push(groupPolicy);
            }

            url = process.env.REACT_APP_AUTH_API + "/groups/policies?group_id=" + newGroupId;
            const body = JSON.stringify(newGroupPolicies);
            await RequestCommons.request(url, null, body, "put", "application/json");

            const alert = <Alert variant={"success"}>
                <span>Created new group. Click </span>
                <a className={"text-decoration-none"} href={"/groups/" + newGroupId}>here</a>
                <span> to update the group.</span>
            </Alert>;
            setSettingsAlert(alert);
        } catch (error) {
            console.error(error);
            const alert = <Alert variant={"danger"}>
                <span>Error: {JSON.stringify(error)}</span>
            </Alert>;
            setSettingsAlert(alert);

        }
    }

    async function copyUserRolePermissionsOnChange(option: any) {
        setCopyUserRolePermissionsSelectedOption(option);
        const userRoleId = option.value;
        const groupUserRoles = groupUserRolesAll.filter((groupUserRole: any) => {
            return groupUserRole.user_role_id === userRoleId;
        });
        const groupUserRolesRowSelectionNew = JSON.parse(JSON.stringify(groupUserRolesRowSelection));
        for (let i = 0; i < groupUserRoles.length; i++) {
            const groupUserRole = groupUserRoles[i];
            groupUserRolesRowSelectionNew[groupUserRole.group_id] = true;
        }
        setGroupUserRolesRowSelection(groupUserRolesRowSelectionNew);
        setCopyUserRolePermissionsSelectedOption(null);

        const userRolesFiltered = userRoles.filter((userRole: any) => {
            return userRole.id === userRoleId;
        });

        if (userRolesFiltered && userRolesFiltered[0]) {
            const userRole = userRolesFiltered[0];
            const name = userRole.user.first_name + " " + userRole.user.last_name;
            const alert = <Alert variant={"success"} dismissible onClose={function (event: any) {
                setCopyPermissionsAlert(null);
            }}>
                <span>Added permissions from {name}.</span>
            </Alert>;
            setCopyPermissionsAlert(alert);
        }
    }

    function deleteUserRole(event: any) {
        event.preventDefault();
        let deleteModalAlert = <div className={"alert alert-success"}>
            User role deleted.
        </div>;
        const url = process.env.REACT_APP_AUTH_API + "/users/roles/" + userRole.id;
        RequestCommons.request(url, null, null, "DELETE").then(async function () {
            await loadData();
            setUserRoleDeleted(true);
        }).catch(function (error) {
            console.error(error);
            deleteModalAlert = <>
                <div className={"alert alert-danger"}>
                    <span>The entry you are trying to delete likely still has entries linked to it.
                        Remove all links from the accordion dropdowns below before deleting.</span>
                </div>
                <Accordion className={"mb-3"} defaultActiveKey={[]}>
                    <Accordion.Item eventKey="details">
                        <Accordion.Header>
                            Details
                        </Accordion.Header>
                        <Accordion.Body>
                            {error.detail}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </>;
        }).finally(function () {
            setDeleteModalAlert(deleteModalAlert);
        });
    }

    function toggleDeleteModalShow() {
        setDeleteModalAlert(null);
        setDeleteModalShow(!deleteModalShow);
    }

    function sendSetupAccountEmail() {
        const confirmed = window.confirm("Are you sure?");
        if (confirmed) {
            const formData: any = new FormData();
            formData.append("user_role_id", userRole.id);
            const credentials: any = Object.fromEntries(formData);
            const body = new URLSearchParams(credentials);
            const url = process.env.REACT_APP_AUTH_API + "/account/setup";
            RequestCommons.request(url, process.env.REACT_APP_CONSUMER_TOKEN, body, "post").then(function (response: any) {
                const message = "New account email was sent.";
                const alert = <div className={"alert alert-success mt-3"}>{message}</div>
                setSecurityAlert(alert);
            }).catch(function (error) {
                const message = error.detail ? error.detail : error;
                const alert = <div className={"alert alert-danger mt-3"}>{message}</div>
                setSecurityAlert(alert);
            });
        }
    }

    const userRoleManagersTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'User Role Id',
            size: 70
        },
        {
            accessorKey: 'user.first_name', //normal accessorKey
            header: 'First',
        },
        {
            accessorKey: 'user.last_name', //normal accessorKey
            header: 'Last',
        },
        {
            accessorKey: 'email.email', //normal accessorKey
            header: 'Email',
        },
        {
            accessorKey: 'role.organization.name', //normal accessorKey
            header: 'Organization',
        }
    ];

    const userDailyReviewManagersTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'User Role Id',
            size: 70
        },
        {
            accessorKey: 'user.first_name',
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'email.email',
            header: 'Email',
        },
        {
            accessorKey: 'role.organization.name',
            header: 'Organization',
        }
    ];

    const userC3ManagersTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'User Role Id',
            size: 70
        },
        {
            accessorKey: 'user.first_name',
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'email.email',
            header: 'Email',
        },
        {
            accessorKey: 'role.organization.name',
            header: 'Organization',
        }
    ];

    const groupUserRolesTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'organization.name',
            header: 'Organization',
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'description',
            header: 'Description',
        }
    ];

    const defaultPageSize = 10;

    if (isLoading) {
        return <Loading/>;
    } else if (userRoleDeleted) {
        return <EntryDeleted/>;
    } else if (!roleExists) {
        return <DoesNotExist/>;
    } else {
        let buttonText: any = "Update";
        if (isUpdating) {
            buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Updating...</span>
            </span>;
        }

        let userText: any = null;
        if (userRole && userRole.user && userRole.user.disabled) {
            userText = <small className={"text-danger"}>
                <i className="bi bi-exclamation-triangle-fill me-2"/>
                <span>User is disabled.</span>
            </small>;
        }

        const groupsSelected = Object.values(groupUserRolesRowSelection).filter(i => i === true).length;
        const managersSelected = Object.values(userRoleManagersRowSelection).filter(i => i === true).length;
        const dailyReviewManagersSelected = Object.values(userDailyReviewManagersRowSelection).filter(i => i === true).length;
        const c3ManagersSelected = Object.values(userC3ManagersRowSelection).filter(i => i === true).length;

        const styles: any = {};
        if (userRoleStatusSelect?.value === 2) {
            styles["borderColor"] = "#dc3545";
            styles["background"] = "#dc3545";
            styles["color"] = "white";
        } else if (userRoleStatusSelect?.value === 3) {
            styles["borderColor"] = "#ffc107";
            styles["background"] = "#ffc107";
        } else {
            styles["borderColor"] = "#198754";
            styles["background"] = "#198754";
            styles["color"] = "white";
        }

        return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " container m-auto w-100"}>
            <DeleteModal show={deleteModalShow} onHide={toggleDeleteModalShow} delete={deleteUserRole}
                         alert={deleteModalAlert}/>
            <div className={"mb-3 d-none"}>
                <ReturnToLastPageLink/>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <h1>User Role</h1>
                </div>
                <div className={"col text-end"}>
                    {props.session.permissions.delete ?
                        <form onSubmit={function (event: any) {
                            event.preventDefault();
                            toggleDeleteModalShow();
                        }}>
                            <button className={"btn btn-danger"} title={"Delete"}>
                                <i className="bi bi-trash-fill"/>
                            </button>
                        </form> : null}
                </div>
            </div>
            <form onSubmit={updateUserRole} className={"mb-3"}>
                <input type={"hidden"} name={"user_id"} defaultValue={userRole.user_id}/>
                <div className={"row"}>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Id</label>
                        <input name="id" className="form-control" defaultValue={userRole.id} disabled/>
                    </div>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">User</label>
                        <Select
                            value={userSelect}
                            onChange={setUserSelect}
                            options={userOptions}
                            isDisabled={true}
                        />
                        {userText}
                    </div>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Email</label>
                        <CreatableSelect
                            name={"email_id"}
                            value={emailSelect}
                            onChange={setEmailSelect}
                            isDisabled={!props.session.permissions.update || emailOptions.length === 0}
                            options={emailOptions}
                            required
                        />
                        <small>Required</small>
                    </div>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Phone Number</label>
                        <CreatableSelect
                            name={"phone_number_id"}
                            value={phoneNumberSelect}
                            onChange={setPhoneNumberSelect}
                            isDisabled={!props.session.permissions.update || phoneNumberOptions.length === 0}
                            options={phoneNumberOptions}
                        />
                    </div>
                </div>
                <div className={"row"}>
                    <div className="col-lg-4 mb-3">
                        <label className="form-label">Organization</label>
                        <Select
                            onChange={organizationSelectOnChange}
                            isDisabled={!props.session.permissions.update}
                            value={organizationSelect}
                            options={organizationOptions}
                            required
                        />
                        <small>Required</small>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label className="form-label">Department</label>
                        <Select
                            onChange={departmentSelectOnChange}
                            value={departmentSelect}
                            isDisabled={!props.session.permissions.update || !departmentOptions.length}
                            options={departmentOptions}
                            required
                        />
                        <small>Required</small>
                    </div>
                    <div className="col-lg-4 mb-3">
                        <label className="form-label">Role</label>
                        <Select
                            name="role_id"
                            value={roleSelect}
                            onChange={setRoleSelect}
                            isDisabled={!props.session.permissions.update || !roleOptions.length}
                            options={roleOptions}
                            required
                        />
                        <small>Required</small>
                    </div>
                </div>
                <div className={"row"}>
                    <div className="col-lg-3 mb-3">
                        <label className="form-label">Pay Type</label>
                        <Select
                            name="pay_type_id"
                            value={payTypeSelect}
                            onChange={setPayTypeSelect}
                            options={payTypeOptions}
                            isDisabled={!props.session.permissions.update}
                            required
                        />
                        <small>Required</small>
                    </div>
                    <div className="col-lg-3 mb-3">
                        <label className="form-label">Start Date</label>
                        <input type="date" name="start_date" className="form-control"
                               disabled={!props.session.permissions.update}
                               defaultValue={userRole.start_date} required
                               onClick={function (event: any) {
                                   event.preventDefault();
                                   event.target.showPicker();
                               }}
                        />
                        <small>Required</small>
                    </div>
                    <div className="col-lg-3 mb-3">
                        <label className="form-label">End Date</label>
                        <input type="date" name="end_date" className="form-control"
                               disabled={!props.session.permissions.update}
                               defaultValue={userRole.end_date}
                               onClick={function (event: any) {
                                   event.preventDefault();
                                   event.target.showPicker();
                               }}
                        />
                    </div>
                    <div className="col-lg-3 mb-3">
                        <label className="form-label">Disabled</label>
                        <ReactSelectDisabled value={userRole.disabled} required
                                             isDisabled={!props.session.permissions.update}
                                             highlightDisabledRed={true}/>
                    </div>
                </div>
                <Accordion defaultActiveKey={["0"]} className={"mb-3"}>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Groups ({groupsSelected})</Accordion.Header>
                        <Accordion.Body>
                            <div style={{
                                position: "relative",
                                zIndex: 999,
                                paddingBottom: 0
                            }}>
                                <div className={"mb-3"}>
                                    <label className={"mb-1"}>Copy Permissions</label>
                                    <Select
                                        value={copyUserRolePermissionsSelectedOption}
                                        onChange={copyUserRolePermissionsOnChange}
                                        options={userRoleOptions}
                                        isDisabled={!props.session.permissions.update}
                                    />
                                </div>
                                <div>
                                    {copyPermissionsAlert}
                                </div>
                            </div>
                            <div style={{
                                position: "relative",
                                zIndex: 0
                            }}>
                                <MaterialReactTable
                                    muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                    muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                                    columns={groupUserRolesTableColumns}
                                    data={groupUserRolesTableData}
                                    enableSelectAll={false}
                                    enablePagination={true}
                                    positionToolbarAlertBanner={"none"}
                                    enableRowSelection={props.session.permissions.update}
                                    onRowSelectionChange={setGroupUserRolesRowSelection}
                                    state={{
                                        rowSelection: groupUserRolesRowSelection,
                                        isLoading: false,
                                        density: "compact"
                                    }}
                                    initialState={{
                                        showColumnFilters: true,
                                        columnVisibility: {
                                            selected: false
                                        },
                                        pagination: {
                                            pageSize: defaultPageSize,
                                            pageIndex: 0
                                        },
                                        sorting: [
                                            {
                                                id: "selected",
                                                desc: true
                                            },
                                            {
                                                id: "organization.name",
                                                desc: false
                                            },
                                            {
                                                id: "name",
                                                desc: false
                                            }
                                        ]
                                    }}
                                    muiTableBodyCellProps={{
                                        sx: {
                                            whiteSpace: "wrap",
                                            textWrap: "break-word"
                                        },
                                    }}
                                    getRowId={(originalRow: any) => originalRow.id}
                                    renderTopToolbarCustomActions={() => {
                                        return (
                                            <h2 className={"me-3 d-inline-block align-top"}>Groups
                                                ({groupsSelected})</h2>
                                        );
                                    }}
                                    muiTableBodyRowProps={({row}: any) => ({
                                        onClick: function () {
                                            window.open("/groups/" + row.original.id, "_blank");
                                        },
                                        sx: {
                                            cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                            background: row.original.new_selection === "Yes" ? "red" : ""
                                        },
                                    })}
                                />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Daily Review Managers ({dailyReviewManagersSelected})</Accordion.Header>
                        <Accordion.Body>
                            <MaterialReactTable
                                muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                                columns={userDailyReviewManagersTableColumns}
                                data={userDailyReviewManagersTableData}
                                enableSelectAll={false}
                                enablePagination={true}
                                positionToolbarAlertBanner={"none"}
                                enableRowSelection={props.session.permissions.update}
                                onRowSelectionChange={setUserDailyReviewManagersRowSelection}
                                state={{
                                    rowSelection: userDailyReviewManagersRowSelection,
                                    isLoading: false,
                                    density: "compact"
                                }}
                                initialState={{
                                    showColumnFilters: true,
                                    columnVisibility: {
                                        selected: false
                                    },
                                    pagination: {
                                        pageSize: defaultPageSize,
                                        pageIndex: 0
                                    },
                                    sorting: [
                                        {
                                            id: "selected",
                                            desc: true
                                        },
                                        {
                                            id: "user.last_name",
                                            desc: false
                                        },
                                        {
                                            id: "user.first_name",
                                            desc: false
                                        },
                                        {
                                            id: "role.organization.name",
                                            desc: false
                                        }
                                    ]
                                }}
                                getRowId={(originalRow: any) => originalRow.id}
                                renderTopToolbarCustomActions={() => {
                                    return (
                                        <div>
                                            <h2 className={"me-3 d-inline-block align-top"}>Daily Review
                                                Managers ({dailyReviewManagersSelected})</h2>
                                            <ul>
                                                <li>Will be CC'd on the user's daily review emails</li>
                                            </ul>
                                        </div>
                                    );
                                }}
                                muiTableBodyRowProps={({row}: any) => ({
                                    onClick: function () {
                                        window.open("/users/roles/" + row.original.id, "_blank");
                                    },
                                    sx: {
                                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                    },
                                })}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="c3-managers">
                        <Accordion.Header>C3 Managers ({c3ManagersSelected})</Accordion.Header>
                        <Accordion.Body>
                            <MaterialReactTable
                                muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                                columns={userC3ManagersTableColumns}
                                data={userC3ManagersTableData}
                                enableSelectAll={false}
                                enablePagination={true}
                                positionToolbarAlertBanner={"none"}
                                enableRowSelection={props.session.permissions.update}
                                onRowSelectionChange={setUserC3ManagersRowSelection}
                                state={{
                                    rowSelection: userC3ManagersRowSelection,
                                    isLoading: false,
                                    density: "compact"
                                }}
                                initialState={{
                                    showColumnFilters: true,
                                    columnVisibility: {
                                        selected: false
                                    },
                                    pagination: {
                                        pageSize: defaultPageSize,
                                        pageIndex: 0
                                    },
                                    sorting: [
                                        {
                                            id: "selected",
                                            desc: true
                                        },
                                        {
                                            id: "user.last_name",
                                            desc: false
                                        },
                                        {
                                            id: "user.first_name",
                                            desc: false
                                        },
                                        {
                                            id: "role.organization.name",
                                            desc: false
                                        }
                                    ]
                                }}
                                getRowId={(originalRow: any) => originalRow.id}
                                renderTopToolbarCustomActions={() => {
                                    return (
                                        <div>
                                            <h2 className={"me-3 d-inline-block align-top"}>C3 Managers
                                                ({c3ManagersSelected})</h2>
                                            <ul>
                                                <li>Will be CC'd on overnight login email warnings to users</li>
                                            </ul>
                                        </div>
                                    );
                                }}
                                muiTableBodyRowProps={({row}: any) => ({
                                    onClick: function () {
                                        window.open("/users/roles/" + row.original.id, "_blank");
                                    },
                                    sx: {
                                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                    },
                                })}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Managers ({managersSelected})</Accordion.Header>
                        <Accordion.Body>
                            <MaterialReactTable
                                muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                                muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                                columns={userRoleManagersTableColumns}
                                data={userRoleManagersTableData}
                                enableSelectAll={false}
                                enablePagination={true}
                                positionToolbarAlertBanner={"none"}
                                enableRowSelection={props.session.permissions.update}
                                onRowSelectionChange={setUserRoleManagersRowSelection}
                                state={{
                                    rowSelection: userRoleManagersRowSelection,
                                    isLoading: false,
                                    density: "compact"
                                }}
                                initialState={{
                                    showColumnFilters: true,
                                    columnVisibility: {
                                        selected: false
                                    },
                                    pagination: {
                                        pageSize: defaultPageSize,
                                        pageIndex: 0
                                    },
                                    sorting: [
                                        {
                                            id: "selected",
                                            desc: true
                                        },
                                        {
                                            id: "user.last_name",
                                            desc: false
                                        },
                                        {
                                            id: "user.first_name",
                                            desc: false
                                        }
                                    ]
                                }}
                                getRowId={(originalRow: any) => originalRow.id}
                                renderTopToolbarCustomActions={() => {
                                    return (
                                        <div>
                                            <h2 className={"me-3 d-inline-block align-top"}>Managers
                                                ({managersSelected})</h2>
                                            <ul>
                                                <li>Legacy manager assignments from old system, not being utilized
                                                    currently
                                                </li>
                                            </ul>
                                        </div>
                                    );
                                }}
                                muiTableBodyRowProps={({row}: any) => ({
                                    onClick: function () {
                                        window.open("/users/roles/" + row.original.id, "_blank");
                                    },
                                    sx: {
                                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                    },
                                })}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Authorizations ({authorizations.length})</Accordion.Header>
                        <Accordion.Body>
                            <AuthorizationsTable {...props} authorizations={authorizations} showName={false}
                                                 showUserRole={false}/>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="Security">
                        <Accordion.Header>Account Settings</Accordion.Header>
                        <Accordion.Body>
                            <button className="btn btn-primary"
                                    type={"button"}
                                    onClick={sendSetupAccountEmail}>
                                Send New Account Email
                            </button>
                            {securityAlert}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <div className={"mt-3"}>
                    <div className={"alert text-center " + alert.class}>{alert.message}</div>
                </div>
                <div className={"row mt-3"}>
                    <div className={"col mt-2"}>
                        <ReturnToLastPageLink/>
                    </div>
                    <div className={"col text-end"}>
                        {props.session.permissions.update ?
                            <button type="submit" className="btn btn-warning ms-auto ps-5 pe-5"
                                    disabled={isUpdating}>{buttonText}</button> : null}
                    </div>
                </div>
            </form>
        </div>;
    }
}