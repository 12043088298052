import * as React from "react";
import {Link} from "react-router-dom";

export default function Nav(props: any) {
    const name = props.session.user.first_name + " " + props.session.user.last_name;
    const showIcons = false;
    const iconClassName = "me-2" + (showIcons ? "" : " d-none");
    return <nav className="navbar navbar-expand-lg bg-white bororder shadow-sm">
        <div className="container-fluid">
            <Link to="/" className="nav-link me-4 ms-3">
                <img src={"/favicon.png"} alt="logo" style={{
                    height: "20px",
                    objectFit: "contain",
                    marginTop: "-4px"
                }}/>
                <span className={"ms-2"}>Auth Portal</span>
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item me-2">
                        <Link to="/" className="nav-link" aria-current="page">
                            <i className={"bi bi-house-door-fill " + iconClassName}/>
                            <span>Home</span>
                        </Link>
                    </li>
                    <li className="nav-item me-2">
                        <Link to="/users" className="nav-link" aria-current="page">
                            <i className={"bi bi-people-fill " + iconClassName}/>
                            <span>Users</span>
                        </Link>
                    </li>
                    <li className="nav-item me-2">
                        <Link to="/groups" className="nav-link" aria-current="page">
                            <i className={"bi bi-collection-fill " + iconClassName}/>
                            <span>Groups</span>
                        </Link>
                    </li>
                    <li className="nav-item me-2">
                        <Link to="/policies" className="nav-link" aria-current="page">
                            <i className={"bi bi-shield-lock-fill " + iconClassName}/>
                            <span>Policies</span>
                        </Link>
                    </li>
                    <li className="nav-item me-2">
                        <Link to="/logs/pages/accessed" className="nav-link" aria-current="page">
                            <i className={"bi bi-list-columns-reverse " + iconClassName}/>
                            <span>Page Logs</span>
                        </Link>
                    </li>
                    <li className="nav-item me-2">
                        <Link to="/sops" className="nav-link" aria-current="page">
                            <i className={"bi bi-question-circle-fill " + iconClassName}/>
                            <span>SOP's</span>
                        </Link>
                    </li>
                    <li className="nav-item me-2">
                        <Link to="/sitemap" className="nav-link" aria-current="page">
                            <i className={"bi bi-diagram-2-fill " + iconClassName}/>
                            <span>Sitemap</span>
                        </Link>
                    </li>
                </ul>
                <ul className="d-flex navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="src/components#" role="button"
                           data-bs-toggle="dropdown"
                           aria-expanded="false">
                            <span className={"me-2" + (props.session.user.avatar ? "" : " d-none")}>
                                <img key={props.session.user.avatar} className={"rounded-circle"}
                                     src={props.session.user.avatar}
                                     alt="avatar"
                                     height={32} style={{
                                    objectFit: "contain"
                                }}/>
                            </span>
                            <span>{name}</span>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end">
                            {props.session.permissions.update ? <li>
                                <Link to={"/users/" + props.session.user.id} className="dropdown-item">Update
                                    Profile</Link>
                            </li> : null}
                            <li>
                                <Link to="/password/update" className="dropdown-item">Update Password</Link>
                            </li>
                            <li>
                                <Link to="/logout" className="dropdown-item">Logout</Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>;
}