import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {MaterialReactTable} from "material-react-table";
import TimeCommons from "../../commons/TimeCommons";

export default function ViewEmailLogs(props: any) {
    const [isLoading, setIsLoading]: any = useState(true);
    const [tableData, setTableData]: any = useState([]);
    const [startDate, setStartDate]: any = useState(TimeCommons.toYmd(TimeCommons.toDate()));
    const [endDate, setEndDate]: any = useState(TimeCommons.toYmd(TimeCommons.toDate()));

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        loadData();
    }, [startDate, endDate])

    async function init() {
        await loadData();
    }

    async function loadData() {
        setIsLoading(true);
        const params: any = {
            limit: 500,
            order_by: "id",
            order_by_direction: "desc",
            populate: 1
        }
        if (startDate) {
            params["created_start_date"] = startDate;
            delete params["limit"];
        }
        if (endDate) {
            params["created_end_date"] = endDate;
            delete params["limit"];
        }
        const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        const url = process.env.REACT_APP_AUTH_API + "/logs/emails?" + queryString;
        const emails: any = await RequestCommons.request(url);
        const tableData: any = [];
        for (let i = 0; i < emails.length; i++) {
            const email: any = emails[i];
            email.error = email.error ? "Yes" : "No";
            email.created = TimeCommons.formatTimestamp(email.created);
            tableData.push(email);
        }
        setTableData(tableData);
        setIsLoading(false);
    }

    const errorColumns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'to',
            header: 'To',
        },
        {
            accessorKey: 'fro',
            header: 'From',
        },
        {
            accessorKey: 'subject',
            header: 'Subject',
        },
        {
            accessorKey: 'error',
            header: 'Error',
        },
        {
            accessorKey: 'created',
            header: 'Created',
            size: 100
        }
    ];

    return <div className={"container shadow-sm bg-white rounded border p-4"}>
        <h2>Emails</h2>
        <form className={"d-inline-block position-relative"} style={{
            zIndex: 999
        }} onSubmit={async function (event: any) {
            event.preventDefault();
            await loadData();
        }}>
            <div className={"mb-3 me-3 d-inline-block"}>
                <label>Start Date</label>
                <input
                    type={"date"}
                    name={"start-date"}
                    className={"form-control"}
                    onChange={function (event: any) {
                        setStartDate(event.target.value);
                    }}
                    onClick={function (event: any) {
                        event.target.showPicker();
                    }}
                    value={startDate}
                />
            </div>
            <div className={"mb-3 me-3 d-inline-block"}>
                <label>End Date</label>
                <input
                    type={"date"}
                    name={"end-date"}
                    className={"form-control"}
                    onChange={function (event: any) {
                        setEndDate(event.target.value);
                    }}
                    onClick={function (event: any) {
                        event.target.showPicker();
                    }}
                    value={endDate}
                />
            </div>
        </form>
        <MaterialReactTable
            muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
            enableStickyHeader={true}
            columns={errorColumns}
            data={tableData}
            initialState={{
                sorting: [{
                    id: "id",
                    desc: true
                }]
            }}
            state={{
                isLoading: isLoading,
                density: "compact"
            }}
        />
    </div>;
}