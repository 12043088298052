import * as React from "react";

export default function Footer(props: any) {
    let content = "Auth Portal";
    if (props?.session?.user_role?.role?.organization?.name) {
        content = props.session.user_role.role.organization.name;
    }
    content += " © 2024";
    return <footer id={"footer"} className={"bg-light w-100 p-3 text-center flex-shrink-0 py-4"}>
        {content}
    </footer>;
}