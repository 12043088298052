import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import TimeCommons from "../../commons/TimeCommons";
import {Box, IconButton} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import {DeleteOutlined as DeleteIcon, StorageOutlined as ViewResourceIcon} from "@mui/icons-material";
import {Link} from "react-router-dom";
import ViewEntryActionButton from "../shared/tables/ViewEntryActionButton";
import DeleteEntryActionButton from "../shared/tables/DeleteEntryActionButton";

export default function ViewResources(props: any) {
    const [isLoading, setIsLoading]: any = useState(true);
    const [tableData, setTableData]: any = useState([]);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        let url = process.env.REACT_APP_AUTH_API + "/resources?populate=1";
        const resources: any = await RequestCommons.request(url);

        const tableData: any = [];
        for (let i = 0; i < resources.length; i++) {
            const resource = resources[i];
            resource.created = TimeCommons.formatTimestamp(resource.created);
            tableData.push(resource);
        }
        setTableData(tableData);
    }

    function deleteResource(id: number) {
        const c = window.confirm("Are you sure you want to delete this entry?");
        if (c) {
            const url = process.env.REACT_APP_AUTH_API + "/resources/" + id;
            RequestCommons.request(url, null, null, "DELETE").then(async function (response) {
                await loadData();
            }).catch(function (error) {
                console.error(error);
                alert(error.detail);
            });
        }
    }

    function renderRowActions(data: any) {
        const id = data.row.original.id;
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton to={"/resources/" + id}/>
            {props.session.permissions.delete ?
                <DeleteEntryActionButton onClick={function (event: any) {
                    event.preventDefault();
                    deleteResource(id);
                }}/> : null}
        </Box>;
    }

    const createLink = props.session.permissions.update ? <Link to={"/resources/create"}
                                                                className={"d-inline-block btn btn-primary"}
    >
        Create
    </Link> : null;

    const columns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'resource_type.name',
            header: 'Type',
        },
        {
            accessorKey: 'organization.name',
            header: 'Organization',
        },
        {
            accessorKey: 'location',
            header: 'Location',
        },
        {
            accessorKey: 'created',
            header: 'Created',
            size: 100
        }
    ];

    const defaultPageSize = 10;
    const initialState = {
        sorting: [
            {
                id: 'name', //sort by age by default on page load
                desc: false
            },
            {
                id: 'organization.name', //sort by age by default on page load
                desc: false
            },
            {
                id: 'location', //sort by age by default on page load
                desc: false
            }
        ],
        pagination: {
            pageSize: defaultPageSize,
            pageIndex: 0
        }
    };

    return <div className={"m-auto container shadow-sm bg-white rounded border"}>
        <MaterialReactTable
            muiTablePaperProps={props.config.materialReactTable.muiTablePaperProps}
            muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
            enableStickyHeader={true}
            columns={columns}
            data={tableData}
            state={{
                isLoading: isLoading,
                density: "compact"
            }}
            initialState={initialState}
            enableRowActions={true}
            renderRowActions={renderRowActions}
            enablePagination={true}
            renderTopToolbarCustomActions={() => {
                return (
                    <div>
                        <h2 className={"me-3 d-inline-block align-top"}>Resources</h2>
                        {createLink}
                    </div>
                );
            }}
        />
    </div>;
}