import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../../commons/RequestCommons"
import {useParams} from "react-router-dom";
import Select from "react-select";
import ReactSelectDisabled from "../../../commons/ReactSelectDisabled";
import ReactElementCommons from "../../../commons/ReactElementCommons";
import AuthCommons from "../../../commons/AuthCommons";
import {Alert} from "react-bootstrap";
import ReturnToLastPageLink from "../../shared/ReturnToLastPageLink";
import Loading from "../../Loading";
import DoesNotExist from "../../shared/DoesNotExist";

export default function UpdatePhoneNumber(props: any) {
    const [alert, setAlert] = useState({
        class: "d-none",
        message: ""
    });
    const [phoneExists, setPhoneExists] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [phoneNumber, setPhoneNumber]: any = useState({});
    const [organizationOptions, setOrganizationOptions]: any = useState([]);
    const [organizationSelect, setOrganizationSelect]: any = useState(null);
    const [phoneNumberTypeOptions, setPhoneNumberTypeOptions]: any = useState([]);
    const [phoneNumberTypeSelect, setPhoneNumberTypeSelect]: any = useState(null);

    const params = useParams();

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setIsLoading(true);
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [response]: any = await Promise.all([
            AuthCommons.request("/numbers/phone/" + params.id + "?populate=1", null, null, 'get', 'application/x-www-form-urlencoded', false)
        ]);
        if (response.status === 204) {
            setPhoneExists(false);
        } else {
            setPhoneExists(true);

            const phoneNumber: any = await response.json();
            setPhoneNumber(phoneNumber);

            const [organizations, phoneNumberTypes]: any = await Promise.all([
                AuthCommons.request("/organizations"),
                AuthCommons.request("/numbers/phone/types")
            ]);

            const organizationOptions: any = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id", " ", true);
            setOrganizationOptions(organizationOptions);
            setOrganizationSelect(organizationOptions.find((option: any) => option.value === phoneNumber.organization_id));

            const phoneNumberTypeOptions: any = ReactElementCommons.createReactSelectOptionsFromList(phoneNumberTypes, "name", "id");
            setPhoneNumberTypeOptions(phoneNumberTypeOptions);
            setPhoneNumberTypeSelect(phoneNumberTypeOptions.find((option: any) => option.value === phoneNumber.phone_number_type_id));
        }
    }

    async function updatePhoneNumber(event: any) {
        event.preventDefault();
        try {
            setIsUpdating(true);
            setAlert({
                class: "d-none",
                message: ""
            });

            const form: any = event.target;
            const formData: any = new FormData(form);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/numbers/phone/" + params.id;
            await RequestCommons.request(url, null, payload, "PUT");
            await loadData();
            setAlert({
                class: "alert-success text-center",
                message: "Updated."
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsUpdating(false);
        }
    }

    if (isLoading) {
        return <Loading/>;
    } else if (!phoneExists) {
        return <DoesNotExist/>;
    } else {
        let buttonText: any = "Update";
        if (isUpdating) {
            buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Updating...</span>
            </span>;
        }
        return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
            <form onSubmit={updatePhoneNumber}>
                <h1>Phone Number</h1>
                <div className="mb-3">
                    <label className="form-label">Phone Number</label>
                    <input name="phone_number" className="form-control" required
                           disabled={!props.session.permissions.update}
                           defaultValue={phoneNumber.phone_number}/>
                    <small>Required</small>
                </div>
                <div className="mb-3">
                    <label className="form-label">Type</label>
                    <Select
                        isDisabled={!props.session.permissions.update}
                        name="phone_number_type_id"
                        value={phoneNumberTypeSelect}
                        options={phoneNumberTypeOptions}
                        onChange={setPhoneNumberTypeSelect}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Organization</label>
                    <Select
                        isDisabled={!props.session.permissions.update}
                        name="organization_id"
                        value={organizationSelect}
                        options={organizationOptions}
                        onChange={setOrganizationSelect}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input name="name" className="form-control" defaultValue={phoneNumber.name}
                           disabled={!props.session.permissions.update}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Description</label>
                    <textarea name="description"
                              disabled={!props.session.permissions.update}
                              className="form-control" defaultValue={phoneNumber.description}/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Disabled</label>
                    <ReactSelectDisabled value={phoneNumber.disabled} required
                                         isDisabled={!props.session.permissions.update}/>
                </div>
                {props.session.permissions.update ?
                    <button type="submit" className="btn btn-warning form-control"
                            disabled={isUpdating}>{buttonText}</button> : null}
                <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
            </form>
            <div className={"row mt-3"}>
                <div className={"col"}>
                    <ReturnToLastPageLink/>
                </div>
            </div>
        </div>;
    }
}