import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../commons/RequestCommons"
import AuthCommons from "../commons/AuthCommons";
import Select from "react-select";
import ReactElementCommons from "../commons/ReactElementCommons";

export default function Authorization(props: any) {
    const [alert, setAlert] = useState({
        class: "d-none",
        message: ""
    });
    const [policyOptions, setPolicyOptions] = useState([]);
    const [policySelect, setPolicySelect]: any = useState(null);
    const [isRequesting, setIsRequesting] = useState(false);

    useEffect(() => {
        init();
    }, []);

    async function init() {
        await loadData();
    }

    async function loadData() {
        let url = process.env.REACT_APP_AUTH_API + "/policies";
        const policies: any = await RequestCommons.request(url);
        const policyOptions: any = ReactElementCommons.createReactSelectOptionsFromList(policies, "name", "id");
        setPolicyOptions(policyOptions);
    }

    async function onSubmit(event: any) {
        event.preventDefault();

        setIsRequesting(true);

        const policyId = policySelect.value;

        const isAuthorized = await AuthCommons.isAuthorized(policyId).finally(function () {
            setIsRequesting(false);
        });

        if (isAuthorized) {
            setAlert({
                class: "alert-success",
                message: "Yes."
            });
        } else {
            setAlert({
                class: "alert-danger",
                message: "No."
            });
        }
    }

    let buttonText: any = "Is Authorized?";
    if (isRequesting) {
        buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Authorizing...</span>
            </span>;
    }

    return <form className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}
                 onSubmit={onSubmit}>
        <h1>Authorization</h1>
        <div className="mb-3">
            <label className="form-label">Policy</label>
            <Select
                name={"policy_id"}
                options={policyOptions}
                value={policySelect}
                onChange={setPolicySelect}
            />
        </div>
        <button type="submit" className="btn btn-primary form-control">{buttonText}</button>
        <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
    </form>;
}