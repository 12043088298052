import * as React from "react";
import {useEffect, useState} from "react";
import AuthCommons from "../../../commons/AuthCommons";
import RequestCommons from "../../../commons/RequestCommons";
import {MaterialReactTable} from "material-react-table";
import {Box} from "@mui/material";
import {Alert} from "react-bootstrap";
import ViewEntryActionButton from "../../shared/tables/ViewEntryActionButton";
import ReturnToLastPageLink from "../../shared/ReturnToLastPageLink";

export default function ImportLegacyUser(props: any) {
    const [isLoading, setIsLoading] = useState(true);
    const [alerts, setAlerts]: any = useState({});
    const [tableData, setTableData]: any = useState([]);

    useEffect(() => {
        init();
    }, []);

    function addAlert(name: any, alert: any) {
        const newAlerts = alerts;
        newAlerts[name] = alert;
        setAlerts(newAlerts);
    }

    function removeAlert(name: any) {
        if (Object.keys(alerts).includes(name)) {
            const newAlerts = alerts;
            delete newAlerts[name];
            setAlerts(newAlerts);
        }
    }

    async function init() {
        const originalUsers = await AuthCommons.getLegacyUsersOriginal();
        setTableData(originalUsers);
        setIsLoading(false);
    }

    async function importLegacyUser(username: any) {
        const url = process.env.REACT_APP_AUTH_API + "/users/legacy/import?username=" + username;
        await RequestCommons.request(url).then(function (response: any) {
            if (response && response[0] && response[0].updates) {
                const updates = response[0].updates;
                if (updates.errors.length) {
                    const alertErrorLis = [];
                    for (let i = 0; i < updates.errors.length; i++) {
                        const error = updates.error[i];
                        alertErrorLis.push(<li>{error}</li>);
                    }
                    const alertName = "alert-errors";
                    const alert = <Alert key={alertName} variant={"danger"}
                                         onClose={() => removeAlert(alertName)}
                                         dismissible={true}>
                        <Alert.Heading>Error</Alert.Heading>
                        <ul>
                            {alertErrorLis}
                        </ul>
                    </Alert>;
                    addAlert(alertName, alert);
                } else if (updates.created.length) {
                    const alertName = "alert-success";
                    const alert = <Alert key={alertName} variant={"success"}
                                         onClose={() => removeAlert(alertName)}
                                         dismissible={true}>
                        <Alert.Heading>Success</Alert.Heading>
                        <span>{"User was imported."}</span>
                    </Alert>;
                    addAlert(alertName, alert);
                } else if (updates.warnings.length) {
                    const lis = [];
                    for (let i = 0; i < updates.warnings.length; i++) {
                        const warning = updates.warnings[i];
                        lis.push(<li>{warning}</li>);
                    }
                    const alertName = "alert-warning";
                    const alert = <Alert key={alertName} variant={"warning"}
                                         onClose={() => removeAlert(alertName)}
                                         dismissible={true}>
                        <Alert.Heading>Warning</Alert.Heading>
                        <ul>{lis}</ul>
                    </Alert>;
                    addAlert(alertName, alert);
                } else {
                    const alertName = "alert-success";
                    const alert = <Alert key={alertName} variant={"primary"}
                                         onClose={() => removeAlert(alertName)}
                                         dismissible={true}>
                        <Alert.Heading>Success</Alert.Heading>
                        <span>{"User was already imported."}</span>
                    </Alert>;
                    addAlert(alertName, alert);
                }
            }
        }).catch(function (error: any) {
            const alertName = "alert-error";
            const alert = <Alert key={alertName} variant={"danger"} onClose={() => removeAlert(alertName)}
                                 dismissible={true}>
                <Alert.Heading>Error</Alert.Heading>
                {error.detail ? error.detail : "An error occurred."}
            </Alert>;
            addAlert(alertName, alert);
        });
    }

    const tableColumns: any = [
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
        },
        {
            accessorKey: 'imported',
            header: 'Imported'
        },
        {
            accessorKey: 'business', //access nested data with dot notation
            header: 'Business',
        },
        {
            accessorKey: 'first_name', //access nested data with dot notation
            header: 'First Name',
        },
        {
            accessorKey: 'last_name', //access nested data with dot notation
            header: 'Last Name',
        },
        {
            accessorKey: 'legacy_user_id', //access nested data with dot notation
            header: 'Legacy User Id',
        },
        {
            accessorKey: 'legacy_user_location_id', //access nested data with dot notation
            header: 'Legacy User Location Id',
        },
        {
            accessorKey: 'legacy_user_location_name', //access nested data with dot notation
            header: 'Legacy User Location Name',
        },
        {
            accessorKey: 'email', //access nested data with dot notation
            header: 'Email',
        },
        {
            accessorKey: 'username', //access nested data with dot notation
            header: 'Username',
        },
        {
            accessorKey: 'start_date', //access nested data with dot notation
            header: 'Start Date',
        },
        {
            accessorKey: 'retired', //access nested data with dot notation
            header: 'Retired',
        }
    ];

    function renderTableRowActions(data: any) {
        const id = data.row.original.id;
        const username = data.row.original.username;
        const userId = data.row.original.user_id;
        const imported = data.row.original.imported;

        const importIconColor = imported ? "text-warning" : "text-success";
        const importIconTitle = imported ? "Re-import" : "Import";
        const importIconConfirm = imported ? "Are you sure you want to re-import this user?" : "Are you sure you want to import this user?";
        const importIcon = <i className={"bi bi-person-fill-down di-inline-block align-middle me-3 " + importIconColor}
                              style={{
                                  fontSize: "20px",
                                  cursor: "pointer"
                              }}
                              title={importIconTitle}
                              onClick={async (event: any) => {
                                  if (window.confirm(importIconConfirm)) {
                                      try {
                                          await importLegacyUser(username);
                                          await init();
                                      } catch (error: any) {
                                          const alertName = "alert-error";
                                          const alert = <Alert key={alertName} variant={"danger"}
                                                               onClose={() => removeAlert(alertName)}
                                                               dismissible={true}>
                                              <Alert.Heading>Error</Alert.Heading>
                                              <span>{error.detail}</span>
                                          </Alert>;
                                          addAlert(alertName, alert);
                                      }
                                  }
                              }}/>;

        let userLink = null;
        if (userId && props.session.permissions.admin) {
            userLink = <ViewEntryActionButton to={"/users/" + data.row.original.user_id}/>;
        }
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            {props.session.permissions.admin ?
                <div className={"d-inline-block align-middle"}>
                    {importIcon}
                    {userLink}
                </div> : null}
        </Box>;
    }

    let alertsElement = null;
    if (Object.keys(alerts).length) {
        const alertElements = [];
        for (let name in alerts) {
            alertElements.push(alerts[name]);
        }
        alertsElement = <div>
            {alertElements}
        </div>;
    }

    return <div className={"m-auto container bg-white border shadow-sm rounded p-3"}>
        {alertsElement}
        <div className={"mb-3 d-none"}>
            <ReturnToLastPageLink/>
        </div>
        <div>
            <MaterialReactTable
                muiTablePaperProps={props.config.materialReactTable.muiTablePaperPropsNoPadding}
                columns={tableColumns}
                data={tableData}
                state={{
                    isLoading: isLoading,
                    density: "compact"
                }}
                initialState={{
                    sorting: [
                        {
                            id: "id",
                            desc: true
                        }
                    ]
                }}
                enableRowActions={true}
                renderRowActions={renderTableRowActions}
                renderTopToolbarCustomActions={() => {
                    return (
                        <div>
                            <h2 className={"me-3 d-inline-block align-top"}>
                                <i className="bi bi-person-fill-down align-middle me-3"/>
                                <span>Import Legacy Users</span>
                            </h2>
                        </div>
                    );
                }}
                muiTableBodyRowProps={function (row: any) {
                    return {
                        sx: {
                            background: !isLoading && !row.row.original.imported ? "#f1e4bc" : ""
                        }
                    };
                }}
            />
        </div>
        <div className={"mt-3"}>
            <ReturnToLastPageLink/>
        </div>
    </div>;
}