import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {Link, useParams} from "react-router-dom";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import AuthCommons from "../../commons/AuthCommons";
import DoesNotExist from "../shared/DoesNotExist";
import Loading from "../Loading";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";

export default function UpdateTag(props: any) {
    const [alert, setAlert] = useState({
        class: "d-none",
        message: ""
    });
    const [tagExists, setTagExists] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [tag, setTag]: any = useState({});
    const [color, setColor]: any = useState(null);
    const [name, setName]: any = useState(null);

    const params = useParams();

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setIsLoading(true);
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [response]: any = await Promise.all([
            AuthCommons.request("/tags/" + params.id, null, null, 'get', 'application/x-www-form-urlencoded', false)
        ]);
        if (response.status === 204) {
            setTagExists(false);
        } else {
            setTagExists(true);
            const tag: any = await response.json();
            setTag(tag)
            setColor(tag.color);
            setName(tag.name);
        }
    }

    async function updateTag(event: any) {
        event.preventDefault();
        try {
            setIsUpdating(true);
            setAlert({
                class: "d-none",
                message: ""
            });
            const formData: any = new FormData(event.target);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/tags/" + tag.id;
            await RequestCommons.request(url, null, payload, "PUT");
            await loadData();
            setAlert({
                class: "alert-success text-center",
                message: "Updated."
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsUpdating(false);
        }
    }

    let colorEle: any = null;
    if (color) {
        colorEle = <div key={"color"} className={"p-5 mt-3 border"} style={{
            background: color
        }}></div>
    }

    let preview: any = <div
        key={"preview"}
        className={"p-1 ps-2 pe-2 mt-3 d-inline-block border border-sm rounded text-white small"} style={{
        background: color
    }}>
        {name}
    </div>;

    if (isLoading) {
        return <Loading/>;
    } else if (!tagExists) {
        return <DoesNotExist/>;
    } else {
        let buttonText: any = "Update";
        if (isUpdating) {
            buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Updating...</span>
            </span>;
        }

        return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " m-auto"} style={{width: "400px"}}>
            <form onSubmit={updateTag}>
                <h1>Tag</h1>
                <div className="mb-3">
                    <label className="form-label">Id</label>
                    <input name="id" type="number" className="form-control" defaultValue={tag.id}
                           disabled/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input name="name" className="form-control" defaultValue={tag.name} required
                           onKeyUp={function (event: any) {
                               const name = event.target.value;
                               setName(name);
                           }}
                           disabled={!props.session.permissions.update}/>
                    <small>Required</small>
                </div>
                <div className="mb-3">
                    <label className="form-label">Description</label>
                    <textarea name="description"
                              disabled={!props.session.permissions.update}
                              className="form-control"/>
                </div>
                <div className="mb-3">
                    <label className="form-label">Color</label>
                    <input name="color" className="form-control" defaultValue={tag.color}
                           onKeyUp={function (event: any) {
                               const color = event.target.value;
                               setColor(color);
                           }}
                           disabled={!props.session.permissions.update}/>
                    {colorEle}
                </div>
                <div className="mb-3">
                    <label className="form-label">Disabled</label>
                    <ReactSelectDisabled
                        required
                        isDisabled={!props.session.permissions.update}
                        value={tag.disabled}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Preview</label>
                    <div>
                        {preview}
                    </div>
                </div>
                {props.session.permissions.update ?
                    <button type="submit" className="btn btn-warning form-control"
                            disabled={isUpdating}>{buttonText}</button> : null}
                <div className={"alert mt-3 text-center " + alert.class}>{alert.message}</div>
            </form>
            <div className={"row mt-3"}>
                <div className={"col"}>
                    <ReturnToLastPageLink/>
                </div>
            </div>
        </div>;
    }
}