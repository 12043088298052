import React from "react";
import {Accordion, ListGroup} from "react-bootstrap";
import EngineeringIcon from '@mui/icons-material/Engineering';
import ViewEntryActionButton from "./shared/tables/ViewEntryActionButton";
import userDisabledImg from "../media/user_disabled.png";
import viewUsersIcon from "../media/view_users_icon.png";
import searchUsersImg from "../media/search_users.png";
import userRolesAccordionImg from "../media/user_roles_accordion.png";
import userRolePageImg from "../media/user_role_page.png";
import dailyReviewManagersAccordionImg from "../media/daily_review_managers_accordion.png";
import groupsAccordionImg from "../media/groups_accordion.png";
import importUserButtonImg from "../media/import_user_button.png";
import importUserTableActionImg from "../media/import_user_table_action.png";
import retireUserRoleImg from "../media/retire_user_role.png";
import userSecurityAccordionImg from "../media/user_security_accordion.png";
import addUserRoleImg from "../media/add_user_role.png";
import DisableEntryActionButton from "./shared/tables/DisableEntryActionButton";
import ReturnToLastPageLink from "./shared/ReturnToLastPageLink";
import authSystemDiagram from "../media/auth-system-diagram.png";

export default function Sops(props: any) {
    return <div className={"container p-4 bg-white border shadow-sm rounded"}>
        <div className={"mb-3 d-none"}>
            <ReturnToLastPageLink/>
        </div>
        <h2 className={"mb-3"}>
            <i className="bi bi-gear-wide me-3 align-middle"/>
            <span>Standard Operating Procedures</span>
        </h2>
        <Accordion alwaysOpen defaultActiveKey={["about"]}>
            <Accordion.Item eventKey="about">
                <Accordion.Header>
                    <span>General Information</span>
                </Accordion.Header>
                <Accordion.Body>
                    <img className={"w-100"} src={authSystemDiagram} alt={authSystemDiagram}/>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="adding_user_roles">
                <Accordion.Header>
                    <span>Adding a User Role</span>
                </Accordion.Header>
                <Accordion.Body>
                    <div className={"mb-2"}>
                        <i className="bi bi-info-circle-fill text-primary me-2"/>
                        <span>A user may need another role assigned to them if they are dual-hatted.</span>
                    </div>
                    <ListGroup>
                        <ListGroup.Item>Follow the <i>Updating a User</i> guide to get to the <a href={"/users"}
                                                                                                 className={"text-decoration-none"}>Users
                        </a> page and select a User.</ListGroup.Item>
                        <ListGroup.Item>Open the <i>Roles</i> tab</ListGroup.Item>
                        <ListGroup.Item>Click the <i>Add</i> button</ListGroup.Item>
                        <ListGroup.Item><img className={"w-100"} src={addUserRoleImg}
                                             alt={addUserRoleImg}/></ListGroup.Item>
                        <ListGroup.Item>Set the appropriate fields. You may type in an email and/or phone
                            number.</ListGroup.Item>
                        <ListGroup.Item>Click the <i>Create</i> button to complete the process</ListGroup.Item>
                        <ListGroup.Item>Assign the appropriate permissions using the <i>Update a User's
                            Permissions</i> SOP</ListGroup.Item>
                        <ListGroup.Item>Assign daily review managers using the <i>Update a User's Daily Review
                            Managers</i> SOP</ListGroup.Item>
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="create_new_policies">
                <Accordion.Header>
                    <span>Adding App Permissions</span>
                    <span title={"Engineering"} className={"ms-3"}>
                        <EngineeringIcon/>
                    </span>
                </Accordion.Header>
                <Accordion.Body>
                    <div className={"mb-2"}>
                        <i className="bi bi-exclamation-triangle-fill me-2 text-danger"/>
                        <span>Consult with Engineering before continuing.</span>
                    </div>
                    <div className={"mb-2"}>
                        <i className="bi bi-info-circle-fill text-primary me-2"/>
                        <span>New permissions (policies) will need to be added for new applications or for updates. Typically
                        this will be handled by Engineering since the policies will need to be directly implemented
                        within the application(s).</span>
                    </div>
                    <ListGroup>
                        <ListGroup.Item>Create a <i>Policy</i> by going to the <a className="text-decoration-none"
                                                                                  href={"/policies/create"}
                                                                                  target={"_blank"}>Create
                            Policy Page</a>
                        </ListGroup.Item>
                        <ListGroup.Item>Inform the product developers of the new <i>Policy Id(s)</i> as they will need
                            to set this within the configuration of the respective applications.</ListGroup.Item>
                        <ListGroup.Item>Create a Group by going to the <a className="text-decoration-none"
                                                                          href={"/groups/create"} target={"_blank"}>Create
                            Group Page</a>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <span>Assign the new <i>Policy(s)</i> to the new <i>Group</i></span>
                            <ListGroup className={"mt-2"}>
                                <ListGroup.Item>
                                    <i className="bi bi-exclamation-triangle-fill me-2 text-danger"/>
                                    Depending on the use case for the policy, you may need to also add <i>Product
                                    Access</i> policies to the new group as well so that the
                                    assigned users can access the page or application.
                                </ListGroup.Item>
                            </ListGroup>
                        </ListGroup.Item>
                        <ListGroup.Item>Assign <i>User Roles</i> to the new <i>Group</i></ListGroup.Item>
                        <ListGroup.Item>Save the <i>Group</i></ListGroup.Item>
                        <ListGroup.Item>Verify with end-users that they now have access to this new
                            permission</ListGroup.Item>
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="create_user">
                <Accordion.Header>Creating New Users</Accordion.Header>
                <Accordion.Body>
                    <div className={"mb-2"}>
                        <i className="bi bi-info-circle-fill text-primary me-2"/>
                        <span>For the time being while we are transitioning between the old platform and the new portals,
                        users will still need to first be created in the old Software website and then imported into the
                        new system. The following steps must be taking when creating new users.</span>
                    </div>
                    <ListGroup>
                        <ListGroup.Item>Create the new user through the old <a
                            href={"https://software.barnettcapital.com/admin/users/addModify.php"} target={"_blank"}
                            className={"text-decoration-none"}>Software Add User Page</a>.</ListGroup.Item>
                        <ListGroup.Item>Go to the <a
                            href={"/users/legacy/import"} target={"_blank"}
                            className={"text-decoration-none"}>Import Legacy User Page</a> or you can click on the <i>Import
                            User</i> button from the
                            <a href={"/"} target={"_blank"}
                               className={"text-decoration-none"}> Home Page</a>.</ListGroup.Item>
                        <ListGroup.Item><img src={importUserButtonImg} alt={importUserButtonImg}/></ListGroup.Item>
                        <ListGroup.Item>Click the <i>Green Arrows Import</i> button under
                            the <i>Actions</i> column</ListGroup.Item>
                        <ListGroup.Item><img className="w-100" src={importUserTableActionImg}
                                             alt={importUserTableActionImg}/></ListGroup.Item>
                        <ListGroup.Item>Follow the <i>Update a User</i> guide to update their
                            permissions.</ListGroup.Item>
                        <ListGroup.Item>Their daily review managers should already be imported but this should be double
                            checked for accuracy. You can also follow the <i>Update Daily Review
                                Managers</i> SOP.</ListGroup.Item>
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="generate_tokens">
                <Accordion.Header>
                    <span>Generating Tokens</span>
                    <span title={"Engineering"} className={"ms-3"}>
                        <EngineeringIcon/>
                    </span>
                </Accordion.Header>
                <Accordion.Body>
                    <div className={"mb-2"}>
                        <i className="bi bi-exclamation-triangle-fill me-2 text-danger"/>
                        <span>Consult with Engineering before continuing.</span>
                    </div>
                    <ListGroup>
                        <ListGroup.Item>
                            <i className="bi bi-info-circle-fill text-primary me-2"/>
                            <span>There are a couple of different tokens that are needed for App Servers and the Portal
                        Clients. This will primarily be handled by Engineering. Go to the <a href={"/tokens/create"}
                                                                                             target={"_blank"}
                                                                                             className={"text-decoration-none"}>Create Tokens Page</a> to
                        see more information about these tokens.</span>
                        </ListGroup.Item>
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="reset_mfa_secret">
                <Accordion.Header>Resetting a User's MFA Secret</Accordion.Header>
                <Accordion.Body>
                    <div className={"mb-2"}>
                        <i className="bi bi-info-circle-fill text-primary me-2"/>
                        <span>A user may have lost their current device, removed the entry from their Authenticator, or
                            their MFA is just no longer working. Their MFA must then be reset using the following
                            steps.</span>
                    </div>
                    <ListGroup>
                        <ListGroup.Item>Follow the <i>Updating a User</i> guide to get to the <a href={"/users"}
                                                                                                 className={"text-decoration-none"}>Users
                        </a> page and select a User.</ListGroup.Item>
                        <ListGroup.Item>Click on the <i>Security</i> tab</ListGroup.Item>
                        <ListGroup.Item><img className={"w-100"} src={userSecurityAccordionImg}
                                             alt={userSecurityAccordionImg}/></ListGroup.Item>
                        <ListGroup.Item>Click on the red disable icon <DisableEntryActionButton/>in the <i>MFA
                            Secrets</i> table under the <i>Actions
                            Column</i></ListGroup.Item>
                        <ListGroup.Item>Handle the popup prompt to finalize</ListGroup.Item>
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="retire_user">
                <Accordion.Header>Retire a User</Accordion.Header>
                <Accordion.Body>
                    <div className={"mb-2"}>
                        <i className="bi bi-info-circle-fill text-primary me-2"/>
                        <span>If you are retiring a single role for dual-hat users, follow the <i>Retire a User Role</i> SOP.</span>
                    </div>
                    <ListGroup>
                        <ListGroup.Item>Follow the <i>Updating a User</i> guide to get to the <a href={"/users"}
                                                                                                 className={"text-decoration-none"}>Users
                        </a> page and select a User.</ListGroup.Item>
                        <ListGroup.Item>Set <i>Disabled</i> to <i>Yes</i></ListGroup.Item>
                        <ListGroup.Item><img className={"w-100"} src={userDisabledImg}
                                             alt={userDisabledImg}/></ListGroup.Item>
                        <ListGroup.Item>Press the <button className={"btn btn-warning"}>Update</button> button at the
                            bottom of the page to save the
                            updates</ListGroup.Item>
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="retire_user_role">
                <Accordion.Header>Retire a User Role</Accordion.Header>
                <Accordion.Body>
                    <div className={"mb-2"}>
                        <i className="bi bi-info-circle-fill text-primary me-2"/>
                        <span>If you are retiring a dual-hat user for all roles across multiple businesses or you want to
                            disable the user from accessing all portals then follow the <i>Retire a User</i> SOP.</span>
                    </div>
                    <ListGroup>
                        <ListGroup.Item>Follow the <i>Updating a User</i> guide to get to the <a href={"/users"}
                                                                                                 className={"text-decoration-none"}>Users
                        </a> page and select a User.</ListGroup.Item>
                        <ListGroup.Item>Open the <i>Roles</i> tab</ListGroup.Item>
                        <ListGroup.Item>Select the <i>Role</i> you want to retire</ListGroup.Item>
                        <ListGroup.Item>Set <i>Status</i> to <i>Retired</i></ListGroup.Item>
                        <ListGroup.Item><img className={"w-100"} src={retireUserRoleImg}
                                             alt={retireUserRoleImg}/></ListGroup.Item>
                        <ListGroup.Item>Press the <button className={"btn btn-warning"}>Update</button> button at the
                            bottom of the page to save the
                            updates</ListGroup.Item>
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="update_user">
                <Accordion.Header>Update a User</Accordion.Header>
                <Accordion.Body>
                    <div className={"mb-2"}>
                        <i className="bi bi-info-circle-fill text-primary me-2"/>
                        <span>User permissions are controlled through <i>User Roles</i> that are assigned to a <i>User</i>. You'll need
                        to got to the <i>User</i> page to select a <i>User Role</i> to make the appropriate updates.</span>
                    </div>
                    <ListGroup>
                        <ListGroup.Item>
                            Go to the <a href={"/users"} target={"_blank"}
                                         className={"text-decoration-none"}>View Users</a> page or select the <i>View
                            Users Icon</i> from <a href={"/"} target={"_blank"}
                                                   className={"text-decoration-none"}>Home</a> page
                        </ListGroup.Item>
                        <ListGroup.Item><img src={viewUsersIcon} alt={viewUsersIcon}/></ListGroup.Item>
                        <ListGroup.Item>Search for the <i>User</i> within the table using the <i>Search Icon</i> that
                            looks
                            like a magnifying glass in the top right corner of the table</ListGroup.Item>
                        <ListGroup.Item><img className={"w-100"} src={searchUsersImg}
                                             alt={searchUsersImg}/></ListGroup.Item>
                        <ListGroup.Item>Click on the <ViewEntryActionButton/>icon under
                            the <i>Actions</i> column to
                            open the <i>Update User</i> page</ListGroup.Item>
                        <ListGroup.Item>Click on the <i>Roles</i> tab</ListGroup.Item>
                        <ListGroup.Item><img className={"w-100"} src={userRolesAccordionImg}
                                             alt={userRolesAccordionImg}/></ListGroup.Item>
                        <ListGroup.Item>Click on the <i>Role</i> you want to update to open the <i>User
                            Role</i> page.</ListGroup.Item>
                        <ListGroup.Item><img className={"w-100"} src={userRolePageImg}
                                             alt={userRolePageImg}/></ListGroup.Item>
                        <ListGroup.Item>Make appropriate updates</ListGroup.Item>
                        <ListGroup.Item>Press the <button className={"btn btn-warning"}>Update</button> button at the
                            bottom of the page to save the
                            updates</ListGroup.Item>
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="daily_review_managers">
                <Accordion.Header>Update Daily Review Managers</Accordion.Header>
                <Accordion.Body>
                    <ListGroup>
                        <ListGroup.Item>Follow the <i>Update a User</i> guide to get to the <i>User
                            Role</i> page</ListGroup.Item>
                        <ListGroup.Item>Click on the <i>Daily Review Managers</i> tab</ListGroup.Item>
                        <ListGroup.Item><img className={"w-100"} src={dailyReviewManagersAccordionImg}
                                             alt={dailyReviewManagersAccordionImg}/></ListGroup.Item>
                        <ListGroup.Item>Check all of the appropriate <i>Daily Review Managers</i></ListGroup.Item>
                        <ListGroup.Item>Press the <button className={"btn btn-warning"}>Update</button> button at the
                            bottom of the page to save the
                            updates</ListGroup.Item>
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="user_permissions">
                <Accordion.Header>Update User Permissions</Accordion.Header>
                <Accordion.Body>
                    <ListGroup>
                        <ListGroup.Item>Follow the <i>Update a User</i> guide to get to the <i>User
                            Role</i> page</ListGroup.Item>
                        <ListGroup.Item>Click on the <i>Groups</i> tab</ListGroup.Item>
                        <ListGroup.Item><img className={"w-100"} src={groupsAccordionImg}
                                             alt={groupsAccordionImg}/></ListGroup.Item>
                        <ListGroup.Item>
                            <i className="bi bi-exclamation-triangle-fill me-2 text-danger"/>
                            <i>Groups</i> have been set up and tailored specifically to the applications so
                            you may
                            need guidance or clarification from <i>Engineering</i> to determine
                            which <i>Groups</i> are
                            required for certain actions within an application.
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <i className="bi bi-exclamation-triangle-fill me-2 text-danger"/>
                            <i>Groups</i> may hold multiple <i>Policies</i> (permissions). You can click on
                            the <ViewEntryActionButton/>icon to view more details about the <i>Group</i>.
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <i className="bi bi-info-circle-fill text-primary me-2"/>
                            <span>You may copy another <i>User Role's</i> permissions with the <i>Copy Permissions</i> dropdown.</span>
                        </ListGroup.Item>
                        <ListGroup.Item>Check all of the needed <i>Groups</i></ListGroup.Item>
                        <ListGroup.Item>Press the <button className={"btn btn-warning"}>Update</button> button at the
                            bottom of the page to save the
                            updates</ListGroup.Item>
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <div className={"mt-3 d-none"}>
            <ReturnToLastPageLink/>
        </div>
    </div>;
}