export default class RequestCommons {
    static async request(url: string, token: string | null = null, body: any = null, method: string = "get", contentType = 'application/x-www-form-urlencoded', handleResponse = true) {
        let headers: any = {
            'Content-Type': contentType
        }

        if (token) {
            headers['Authorization'] = 'Bearer ' + token;
        }

        const options: any = {
            credentials: "include",
            method: method,
            headers: new Headers(headers)
        };

        if (body !== null) {
            switch (method.toLowerCase()) {
                case "get":
                    url += "?" + encodeURIComponent(body);
                    break;
                case "put":
                case "post":
                    options.body = body;
                    break;
                default:
                    console.error("Method type '" + method + "' cannot have a body.");
            }
        }

        return new Promise(function (resolve, reject) {
            try {
                fetch(url, options)
                    .then(async function (response) {
                        if (handleResponse) {
                            if (response.status === 200) {
                                let data = response;
                                try {
                                    data = await response.json();
                                } catch (error) {
                                    console.error(error);
                                    reject(error);
                                }
                                resolve(data);
                            } else if (response.status === 204) {
                                console.log("supposed to be a warning")
                            } else if (response.status === 401) {
                                window.location.href = "/session/expired";
                            } else {
                                let data: any = response;
                                try {
                                    data = await response.json();
                                } catch (error) {
                                    console.error(error);
                                    if (response.statusText) {
                                        data = response.statusText;
                                    }
                                }
                                reject(data);
                            }
                        } else {
                            resolve(response);
                        }
                    }).catch(function (error) {
                    console.error(error);
                    reject(error);
                });
            } catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }
}