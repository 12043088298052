import * as React from "react";
import {useEffect, useState} from "react";
import RequestCommons from "../../commons/RequestCommons"
import {useLocation, useParams} from "react-router-dom";
import {MaterialReactTable} from "material-react-table";
import {Box} from "@mui/material";
import TimeCommons from "../../commons/TimeCommons";
import AuthCommons from "../../commons/AuthCommons";
import Select from "react-select";
import ReactElementCommons from "../../commons/ReactElementCommons";
import ReactSelectDisabled from "../../commons/ReactSelectDisabled";
import {Accordion, Alert} from "react-bootstrap";
import ViewEntryActionButton from "../shared/tables/ViewEntryActionButton";
import ReturnToLastPageLink from "../shared/ReturnToLastPageLink";
import Loading from "../Loading";
import DoesNotExist from "../shared/DoesNotExist";

export default function UpdateDepartment(props: any) {
    const [alert, setAlert] = useState({
        class: "d-none",
        message: ""
    });
    const [deptExists, setDeptExists] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [organizationOptions, setOrganizationOptions]: any = useState([]);
    const [organizationSelect, setOrganizationSelect]: any = useState(null);
    const [department, setDepartment]: any = useState({});
    const [departmentUsersTableData, setDepartmentUsersTableData]: any = useState([]);
    const [departmentRolesTableData, setDepartmentRolesTableData]: any = useState([]);
    const [departmentManagersTableData, setDepartmentManagersTableData]: any = useState([]);
    const [departmentManagersRowSelection, setDepartmentManagersRowSelection]: any = useState({});
    const [authorizations, setAuthorizations]: any = useState([]);

    const params = useParams();
    const location = useLocation();

    useEffect(() => {
        init();
    }, []);

    async function init() {
        setIsLoading(true);
        await loadData();
        setIsLoading(false);
    }

    async function loadData() {
        const [response]: any = await Promise.all([
            AuthCommons.request("/departments/" + params.id, null, null, 'get', 'application/x-www-form-urlencoded', false)
        ]);

        if (response.status === 204) {
            setDeptExists(false);
        } else {
            setDeptExists(true);
            const department: any = await response.json();
            setDepartment(department);

            const [organizations, departmentManagers, departmentRoles, userRoles, authorizations]: any = await Promise.all([
                AuthCommons.request("/organizations?populate=1"),
                AuthCommons.request("/departments/managers?department_id=" + params.id + "&filter=user_role_id&sort=1&unique=1"),
                AuthCommons.request("/roles?department_id=" + params.id),
                AuthCommons.request("/users/roles?populate=1"),
                AuthCommons.request("/authorizations?role_department_id=" + params.id + "&association_type=group_departments&populate=1")
            ]);

            const organizationOptions: any[] = ReactElementCommons.createReactSelectOptionsFromList(organizations, "name", "id");
            setOrganizationOptions(organizationOptions);
            setOrganizationSelect(organizationOptions.find((option: any) => option.value === department.organization_id));

            const departmentManagersRowSelection: any = {};
            for (let i = 0; i < departmentManagers.length; i++) {
                const departmentManager: any = departmentManagers[i];
                departmentManagersRowSelection[departmentManager] = true;
            }
            setDepartmentManagersRowSelection(departmentManagersRowSelection);

            const departmentManagersTableData: any = [];
            for (let i = 0; i < userRoles.length; i++) {
                const userRole: any = userRoles[i];
                const selected = departmentManagers.includes(userRole.id);
                if (selected || props.session.permissions.update) {
                    userRole.selected = selected ? "Yes" : "No";
                    departmentManagersTableData.push(userRole);
                }
            }
            setDepartmentManagersTableData(departmentManagersTableData);

            for (let i = 0; i < departmentRoles.length; i++) {
                const departmentRole = departmentRoles[i];
                departmentRole.created = TimeCommons.formatTimestamp(departmentRole.created) + " (" + TimeCommons.timeAgo(departmentRole.created) + ")";
            }
            setDepartmentRolesTableData(departmentRoles);

            const departmentUsersTableData: any = [];
            for (let i = 0; i < userRoles.length; i++) {
                const userRole = userRoles[i];
                if (userRole.role.department_id == params.id) {
                    departmentUsersTableData.push(userRole);
                }
            }
            setDepartmentUsersTableData(departmentUsersTableData);

            setAuthorizations(authorizations);
            setDeptExists(true);
        }
    }

    async function updateDepartment(event: any) {
        event.preventDefault();
        try {
            setIsUpdating(true);
            setAlert({
                class: "d-none",
                message: ""
            });

            const formData: any = new FormData(event.target);
            const payload = new URLSearchParams(formData);
            const url = process.env.REACT_APP_AUTH_API + "/departments/" + department.id;
            await RequestCommons.request(url, null, payload, "PUT");

            const managerIds: any = [];
            for (let managerId in departmentManagersRowSelection) {
                managerIds.push(parseInt(managerId));
            }

            await replaceDepartmentManagers(department.id, managerIds);

            await loadData();

            setAlert({
                class: "alert-success text-center",
                message: "Updated."
            });
        } catch (error: any) {
            console.error(error);
            setAlert({
                class: "alert-danger",
                message: error.detail ? error.detail : error
            });
        } finally {
            setIsUpdating(false);
        }
    }

    async function replaceDepartmentManagers(departmentId: any, userRoleIds: any) {
        const entries = [];
        for (let i = 0; i < userRoleIds.length; i++) {
            entries.push({
                "department_id": departmentId,
                "user_role_id": userRoleIds[i]
            });
        }
        const body: any = JSON.stringify(entries);
        let url = process.env.REACT_APP_AUTH_API + "/departments/managers?department_id=" + params.id;
        await RequestCommons.request(url, null, body, "put", "application/json").then(async function () {
            console.log("Replaced department managers.");
        }).catch(function (error) {
            console.error(error);
        });
    }

    const departmentManagersTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70,
        },
        {
            accessorKey: 'user.first_name',
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'email.email',
            header: 'Email',
        },
        {
            accessorKey: 'role.organization.name',
            header: 'Organization',
        }
    ];

    const departmentRolesTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'created',
            header: 'Created',
            size: 100
        }
    ];

    const departmentUsersTableColumns: any = [
        {
            accessorKey: 'selected', //access nested data with dot notation
            header: 'Selected',
        },
        {
            accessorKey: 'user.id', //access nested data with dot notation
            header: 'Id',
            size: 70
        },
        {
            accessorKey: 'user.first_name',
            header: 'First Name',
        },
        {
            accessorKey: 'user.last_name',
            header: 'Last Name',
        },
        {
            accessorKey: 'email.email',
            header: 'Role Email',
        },
        {
            accessorKey: 'role.name',
            header: 'Role',
        },
        {
            accessorKey: 'user_role_status.name',
            header: 'Status',
        }
    ];

    function renderDepartmentManagerRowActions(data: any) {
        const id = data.row.original.id;
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton to={"/users/roles/" + id}/>
        </Box>;
    }

    function renderDepartmentRoleRowActions(data: any) {
        const id = data.row.original.id;
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton to={"/roles/" + id}/>
        </Box>;
    }

    function renderDepartmentUserRowActions(data: any) {
        const id = data.row.original.id;
        return <Box sx={{display: 'flex', flexWrap: 'nowrap', gap: '8px'}}>
            <ViewEntryActionButton to={"/users/" + id}/>
        </Box>;
    }

    if (isLoading) {
        return <Loading/>;
    } else if (!deptExists) {
        return <DoesNotExist/>;
    } else {
        let buttonText: any = "Update";
        if (isUpdating) {
            buttonText = <span>
                <span className={"loading-spinner"}>
                    <i className="bi bi-arrow-clockwise"></i>
                </span>
                <span className={"ms-2"}>Updating...</span>
            </span>;
        }

        const managersSelected = Object.values(departmentManagersRowSelection).filter(i => i === true).length;
        const roleCount = departmentRolesTableData.length;
        const userRoleCount = departmentUsersTableData.length;

        return <div className={process.env.REACT_APP_CLASSES_FORMS_DEFAULT + " container m-auto w-100"}>
            <div className={"row mb-3"}>
                <ReturnToLastPageLink/>
            </div>
            <form onSubmit={updateDepartment}>
                <h2>Department</h2>
                <div className={"row"}>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Id</label>
                        <input name="id" type="number" className="form-control" defaultValue={department.id}
                               disabled/>
                    </div>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Organization</label>
                        <Select
                            name="organization_id"
                            onChange={setOrganizationSelect}
                            value={organizationSelect}
                            options={organizationOptions}
                            isDisabled={!props.session.permissions.update}
                            required
                        />
                        <small>Required</small>
                    </div>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Name</label>
                        <input name="name" className="form-control" defaultValue={department.name}
                               disabled={!props.session.permissions.update} required/>
                        <small>Required</small>
                    </div>
                    <div className="col-12 col-lg mb-3">
                        <label className="form-label">Disabled</label>
                        <ReactSelectDisabled required value={department.disabled}
                                             isDisabled={!props.session.permissions.update}/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className="col mb-3">
                        <label className="form-label">Description</label>
                        <textarea name="description"
                                  className="form-control" defaultValue={department.description}
                                  disabled={!props.session.permissions.update}/>
                    </div>
                </div>
                <Accordion defaultActiveKey={[]} className={"mb-3"}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Managers ({managersSelected})
                        </Accordion.Header>
                        <Accordion.Body>

                            <MaterialReactTable
                                muiTablePaperProps={props.config.materialReactTable.muiTablePaperProps}
                                muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                                enableStickyHeader={true}
                                columns={departmentManagersTableColumns}
                                data={departmentManagersTableData}
                                enableSelectAll={false}
                                enableRowSelection={props.session.permissions.update}
                                onRowSelectionChange={setDepartmentManagersRowSelection}
                                state={{
                                    rowSelection: departmentManagersRowSelection,
                                    isLoading: false,
                                    density: "compact"
                                }}
                                enableRowActions={true}
                                renderRowActions={renderDepartmentManagerRowActions}
                                initialState={{
                                    columnVisibility: {
                                        selected: false
                                    },
                                    sorting: [{
                                        id: "selected",
                                        desc: true
                                    }, {
                                        id: "user.last_name",
                                        desc: false
                                    }, {
                                        id: "user.first_name",
                                        desc: false
                                    }]
                                }}
                                getRowId={(originalRow: any) => originalRow.id}
                                renderTopToolbarCustomActions={() => {
                                    return (
                                        <h2 className={"me-3 d-inline-block align-top"}>Managers
                                            ({managersSelected})</h2>
                                    );
                                }}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            Roles ({roleCount})
                        </Accordion.Header>
                        <Accordion.Body>
                            <MaterialReactTable
                                muiTablePaperProps={props.config.materialReactTable.muiTablePaperProps}
                                muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                                enableStickyHeader={true}
                                columns={departmentRolesTableColumns}
                                data={departmentRolesTableData}
                                state={{
                                    isLoading: false,
                                    density: "compact"
                                }}
                                enableRowActions={true}
                                renderRowActions={renderDepartmentRoleRowActions}
                                initialState={{
                                    columnVisibility: {
                                        selected: false
                                    },
                                    sorting: [{
                                        id: "selected",
                                        desc: true
                                    }]
                                }}
                                getRowId={(originalRow: any) => originalRow.id}
                                renderTopToolbarCustomActions={() => {
                                    return (
                                        <h2 className={"me-3 d-inline-block align-top"}>Roles ({roleCount})</h2>
                                    );
                                }}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            User Roles ({userRoleCount})
                        </Accordion.Header>
                        <Accordion.Body>
                            <MaterialReactTable
                                muiTablePaperProps={props.config.materialReactTable.muiTablePaperProps}
                                muiTableContainerProps={props.config.materialReactTable.muiTableContainerProps}
                                enableStickyHeader={true}
                                columns={departmentUsersTableColumns}
                                data={departmentUsersTableData}
                                state={{
                                    isLoading: false,
                                    density: "compact"
                                }}
                                enableRowActions={true}
                                renderRowActions={renderDepartmentUserRowActions}
                                initialState={{
                                    columnVisibility: {
                                        selected: false
                                    },
                                    sorting: [{
                                        id: "selected",
                                        desc: true
                                    }, {
                                        id: "user.last_name",
                                        desc: false
                                    }, {
                                        id: "user.first_name",
                                        desc: false
                                    }, {
                                        id: "role.name",
                                        desc: false
                                    }]
                                }}
                                getRowId={(originalRow: any) => originalRow.id}
                                renderTopToolbarCustomActions={() => {
                                    return (
                                        <h2 className={"me-3 d-inline-block align-top"}>User Roles
                                            ({userRoleCount})</h2>
                                    );
                                }}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <div className={"mt-3"}>
                    <div className={"alert text-center " + alert.class}>{alert.message}</div>
                </div>
                <div className={"row mt-3"}>
                    <div className={"col mt-2"}>
                        <ReturnToLastPageLink/>
                    </div>
                    <div className={"col text-end"}>
                        {props.session.permissions.update ?
                            <button type="submit" className="btn btn-warning ps-5 pe-5"
                                    disabled={isUpdating}>{buttonText}</button> : null}
                    </div>
                </div>
            </form>
        </div>;
    }
}